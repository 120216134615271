
import Vue from 'vue';
import moment from 'moment';
import PhoneNumber from 'awesome-phonenumber';
import { mapActions, mapGetters } from 'vuex';

import { ErrorManager } from '@/models/error';
import { CompanyNote, ICompany, ICompanyNote } from '@/models';
import dateFormat from '@/utils/dateFormat';

// Components
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import CompanyDetailFormDialog from './CompanyDetailFormDialog.vue';
import CompanyNoteDetailFormDialog from './CompanyNoteDetailFormDialog.vue';
import CompanyStatusDisplay from './CompanyStatusDisplay.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import { dateFromNow } from '@/utils/dateHelpers';

interface Options {
  $refs: {
    companyFormDialog: InstanceType<typeof CompanyDetailFormDialog>;
    companyNoteDetailFormDialog: InstanceType<
      typeof CompanyNoteDetailFormDialog
    >;
  };
}

export default Vue.extend<Options>({ functional: false }).extend({
  components: {
    CompanyDetailFormDialog,
    CompanyStatusDisplay,
    ErrorAlert,
    CompanyNoteDetailFormDialog,
    ConfirmationDialog
  },
  name: 'CompanyDetailCard',

  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },

  data() {
    return {
      errorMessage: '',
      errorCompanyNoteDialogMessage: '',

      deleteCompanyNoteDialog: false,
      deleteInProgress: false,

      selectedCompanyNote: new CompanyNote(),

      loading: false,
      notesLoading: false,

      tab: null
    };
  },

  computed: {
    ...mapGetters({
      getCompanyById: 'companyV2/getCompanyById',
      getCompanyNotes: 'companyNoteV2/getCompanyNotes'
    }),

    companyId(): number {
      return Number(this.id);
    },

    company(): ICompany | undefined {
      return this.getCompanyById(this.companyId);
    },

    notes(): ICompanyNote[] {
      return this.getCompanyNotes;
    },

    companyName(): string {
      if (this.company && this.company.name) {
        return this.company.name;
      }
      return '';
    }
  },

  methods: {
    dateFromNow,

    ...mapActions({
      fetchCompanyNotes: 'companyNoteV2/fetchCompanyNotes',
      deleteCompanyNote: 'companyNoteV2/deleteCompanyNote',
      fetchCompany: 'companyV2/fetchCompany'
    }),

    dateFormat(
      date?: Date | string | number | (number | string)[],
      format?: string
    ) {
      return dateFormat(date, format, false);
    },

    fetchNotes() {
      this.notesLoading = true;
      this.fetchCompanyNotes(this.companyId)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.notesLoading = false;
        });
    },

    formatPhone(phone: string) {
      const phoneFormat = new PhoneNumber(phone).getNumber('international');
      // can potentially be undefined if input is bad
      if (phoneFormat) return phoneFormat;
      return phone;
    },

    /**
     * open  dialog
     */
    openCompanyFormDialog(instance: ICompany) {
      return this.$refs.companyFormDialog.open(instance, true);
    },

    openDeleteCompanyNoteDialog(company: any) {
      this.selectedCompanyNote = company;
      this.deleteCompanyNoteDialog = true;
    },

    closeCompanyNoteDeleteDialog() {
      this.clearAllDialogErrors();
      this.selectedCompanyNote = new CompanyNote();
      this.deleteCompanyNoteDialog = false;
    },

    destroyCompanyNote(noteId: number) {
      this.notesLoading = true;
      this.deleteCompanyNote({
        companyId: this.companyId,
        noteId: noteId
      })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.notesLoading = false;
          this.deleteCompanyNoteDialog = false;
        });
    },

    openCompanyNoteDetailDialog(companyNote?: ICompanyNote, isEdit?: boolean) {
      const companyNoteRef = this.$refs.companyNoteDetailFormDialog;
      companyNoteRef.open(companyNote, isEdit);
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    clearAllDialogErrors() {
      this.errorCompanyNoteDialogMessage = '';
    }
  },

  mounted() {
    this.loading = true;
    this.fetchCompany(this.companyId)
      .then(() => this.fetchNotes())
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  }
});
