export const errorCatch = (error: any) => {
  if (error.response) {
    return {
      code: error.response.status as number | null,
      text: error.response.statusText as string | null,
      ...(error.response.data as object | object[])
    };
  } else {
    return {
      detail: 'Cannot establish connection to server',
      error: error
    } as object;
  }
};
