import goTo from 'vuetify/src/services/goto';
import { waitForReadystate } from '@/utils/componentHelpers';
import { GoToOptions } from 'vuetify/types/services/goto';
const IN_BROWSER = typeof window !== 'undefined';
export default async function (
  to: any,
  from: any,
  savedPosition: any
): Promise<any> {
  if (!IN_BROWSER || (from && to.path === from.path)) return;

  let scrollTo = 0;

  if (to.hash) scrollTo = to.hash;
  else if (savedPosition) scrollTo = savedPosition.y;

  // TODO: https://github.com/vuejs/vue-router/pull/3199
  // scroll-behavior is not called on
  // load handled in views/Documentation.vue
  return new Promise<void>((resolve, reject) => {
    // Options 1
    const options: GoToOptions = {};

    if (!scrollTo || to.hash) options.duration = 0;

    window.requestAnimationFrame(async () => {
      goTo(scrollTo, options).catch(reject).finally(resolve);
    });
  });
}
