
import accounts from '@/mixins/accounts';
import auth from '@/mixins/auth';
import mixins from '@/utils/mixins';

export default mixins(accounts, auth).extend({
  name: 'Home',
  data: () => ({
    profile: false
  }),
  computed: {
    // getInitials(): string {
    //   return `${this.getCurrentUser.first_name} ${this.getCurrentUser.last_name}`
    //     .toUpperCase()
    //     .split(' ')
    //     .map(n => n[0])
    //     .join('');
    // }
  },
  methods: {
    signOut() {
      this.logout().then(() => {
        this.$router.push({ name: 'sign-in' });
      });
    }
  }
});
