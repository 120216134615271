import { render, staticRenderFns } from "./ItemMasterDetail.vue?vue&type=template&id=71bf8afd&scoped=true&"
import script from "./ItemMasterDetail.vue?vue&type=script&lang=ts&"
export * from "./ItemMasterDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ItemMasterDetail.vue?vue&type=style&index=0&id=71bf8afd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71bf8afd",
  null
  
)

export default component.exports