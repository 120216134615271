
import { mapActions, mapGetters } from 'vuex';

import Proxyable from '@/mixins/proxyable';
import mixins from '@/utils/mixins';

export default mixins(Proxyable).extend({
  name: 'CompanyPicker',

  data() {
    return {
      search: '',

      loading: false
    };
  },

  computed: {
    ...mapGetters({ companies: 'companyV2/getCompanies' })
  },

  methods: {
    ...mapActions({
      fetchCompanies: 'companyV2/fetchCompanies'
    }),

    fetchCompaniesIfNeeded() {
      // fetch if company list is empty
      if (this.companies.length == 0) {
        this.loading = true;
        this.fetchCompanies().finally(() => {
          this.loading = false;
        });
      }
    }
  }
});
