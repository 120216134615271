import {
  IEmployeeActions,
  EmployeeContext,
  DeleteEmployeeAttachmentActionPayload
} from './actions.types';
import { IEmployeeData } from '@/models';

export const actions: IEmployeeActions = {
  fetchEmployees(context: EmployeeContext): Promise<void> {
    return this.$services.employee
      .fetchEmployees()
      .then((employees: IEmployeeData[]) => {
        context.commit('SET_EMPLOYEES', employees);
      });
  },

  fetchEmployee(context: EmployeeContext, payload: number): Promise<void> {
    return this.$services.employee
      .fetchEmployee(payload)
      .then((employee: IEmployeeData) => {
        const index = context.state.employees.findIndex(
          (v: IEmployeeData) => v.id === employee.id
        );
        if (index === -1) {
          context.commit('ADD_EMPLOYEE', employee);
        } else {
          context.commit('UPDATE_EMPLOYEE', employee);
        }
      });
  },

  createEmployee(
    context: EmployeeContext,
    payload: IEmployeeData
  ): Promise<void> {
    return this.$services.employee
      .createEmployee(payload)
      .then((employee: IEmployeeData) =>
        context.commit('ADD_EMPLOYEE', employee)
      );
  },

  updateEmployee(
    context: EmployeeContext,
    payload: IEmployeeData
  ): Promise<void> {
    return this.$services.employee
      .updateEmployee(payload)
      .then((employee: IEmployeeData) => {
        const index = context.state.employees.findIndex(
          (v: IEmployeeData) => v.id === employee.id
        );
        if (index === -1) {
          context.commit('ADD_EMPLOYEE', employee);
        } else {
          context.commit('UPDATE_EMPLOYEE', employee);
        }
      });
  },

  deleteEmployee(context: EmployeeContext, payload: number): Promise<void> {
    return this.$services.employee.deleteEmployee(payload).then(() => {
      context.commit('REMOVE_EMPLOYEE', payload);
    });
  },

  deleteEmployeeAttachment(
    context: EmployeeContext,
    payload: DeleteEmployeeAttachmentActionPayload
  ): Promise<void> {
    return this.$services.employee
      .deleteEmployeeAttachment(payload.id, payload.attachmentId)
      .then((employee: IEmployeeData) => {
        const index = context.state.employees.findIndex(
          (v: IEmployeeData) => v.id === employee.id
        );
        if (index === -1) {
          context.commit('ADD_EMPLOYEE', employee);
        } else {
          context.commit('UPDATE_EMPLOYEE', employee);
        }
      });
  }
};
