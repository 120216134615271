
import props from './common/props';
import Vue from 'vue';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { deepEqual } from '@/utils/helpers';

import { Logger, setLogLevel } from '@/utils/logger';

export const logger = new Logger('Calendar');
logger.setLogLevel('debug');

export default Vue.extend({
  props: {
    ...props.base,
    ...props.time
    // calendars: props.detail.calendars
  },
  data: () => ({
    selectedCalendars: [],
    calendars: [] as any[]
  }),
  computed: {
    ...mapGetters({
      serverCalendars: 'calendar/sortedCalendars',
      getCalendarById: 'calendar/getCalendarByIdNew',
      enabledCalendars: 'calendar/enabledCalendars'
    }),
    ...mapState({
      calendarsById: (state: any) => state.calendar.calendarsById
    }),
    enabledCalendarsIds(): number[] {
      return this.enabledCalendars.map((cal: { id: number }) => cal.id);
    }
  },
  methods: {
    getSafeOnHandler({ click }: { click: Function }): { click: Function } {
      return {
        click(event: Event) {
          event.preventDefault();
          event.stopPropagation();
          return setTimeout(() => click(event), 0);
        }
      };
    },
    ...mapActions({
      // updateCalendarListOrder: "calendar/updateCalendarListOrder",
      toggleCalendarEnabled: 'calendar/toggleCalendarEnabled',
      snackError: 'snackbar/snackError'
    }),

    toggleEnabled(calendar: any) {
      this.toggleCalendarEnabled({ calendar })
        .then(() => {
          logger.debug('Enabled Calendars', this.enabledCalendarsIds);
        })
        .catch((error) => {
          this.snackError({
            msg: 'An error occurred, unable to change visibility of the calendar.'
          });
        });
    },

    showOnly(calendar: any) {
      this.serverCalendars.map((serverCalendar: any) => {
        if (serverCalendar.id !== calendar.id) {
          if (serverCalendar.enabled) {
            this.toggleEnabled(this.calendarsById[serverCalendar.id]);
          } else {
            this.toggleEnabled(this.calendarsById[calendar.id]);
          }
        } else {
          if (!calendar.enabled) {
            this.toggleEnabled(this.calendarsById[calendar.id]);
          }
        }
      });
    }
  },
  watch: {
    serverCalendars: {
      handler(val: any[]) {
        this.calendars = val;
      },
      immediate: true
    }
  }
});
