export class Task {
  id?: number;
  name?: string;
  description?: string;
  due_date?: null | Date;
  assignees?: Assignees[] | any;
  status?: States | number;
  order?: number;
  owner?: string;
  created_at?: Date;
  updated_at?: Date;
  completed_at?: null | Date;
}

export interface ITaskFieldErrorData {
  assignees?: Assignees[] | any;
  name?: string[];
  description?: string[];
  due_date?: string[];
  status?: string[];
  order?: string[];
  completed_at?: string[];
}
export interface ITaskFieldError extends ITaskFieldErrorData {
  clearErrors(): void;
}

export class TaskFieldError implements ITaskFieldError {
  name: string[] = [];
  description: string[] = [];
  due_date: string[] = [];
  status: string[] = [];
  order: string[] = [];
  completed_at: string[] = [];

  constructor(data?: ITaskFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
      if (data.description) {
        this.description = data.description;
      }
      if (data.due_date) {
        this.due_date = data.due_date;
      }
      if (data.status) {
        this.status = data.status;
      }
      if (data.order) {
        this.order = data.order;
      }
      if (data.completed_at) {
        this.completed_at = data.completed_at;
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
    this.description.splice(0, this.description.length);
    this.due_date.splice(0, this.due_date.length);
    this.status.splice(0, this.status.length);
    this.order.splice(0, this.order.length);
    this.completed_at.splice(0, this.completed_at.length);
  }

  static isTaskFieldError(data: ITaskFieldErrorData) {
    if (data.name && Array.isArray(data.name)) {
      return true;
    }
    if (data.description && Array.isArray(data.description)) {
      return true;
    }
    if (data.due_date && Array.isArray(data.due_date)) {
      return true;
    }
    if (data.status && Array.isArray(data.status)) {
      return true;
    }
    if (data.order && Array.isArray(data.order)) {
      return true;
    }
    if (data.completed_at && Array.isArray(data.completed_at)) {
      return true;
    }
    return false;
  }
}

export class Assignees {
  id?: number;
  first_name?: string;
  last_name?: string;
}

export class States {
  id?: number;
  name?: string;

  color?: string;
  order?: number;
  is_done?: boolean;
}
