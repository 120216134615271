import { IFeedbackService } from './feedback.types';
import { AxiosInstance } from 'axios';
import { IFeedbackData } from '@/models';

export class FeedbackService implements IFeedbackService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/support/send_message/';
  }

  sendFeedback(data: IFeedbackData): Promise<void> {
    return this.axios
      .post<IFeedbackData>(`${this.endpoint}`, data)
      .then(() => {});
  }
}
