
import Vue, { PropType } from 'vue';
import { PropValidator } from 'vue/types/options';
export type InputMessage = string | string[];
export type InputValidationRule = (value: any) => string | boolean;

export type InputValidationRules = (InputValidationRule | string)[];
interface Options extends InstanceType<typeof Vue> {
  $refs: {
    files: HTMLInputElement;
  };
}
export default Vue.extend<Options>({ functional: false }).extend({
  name: 'VDragDropAvatarFileInput',

  props: {
    value: {
      type: [File, String] as PropType<File | string | null>
    },
    size: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: 'Drag & Drop files here'
    },
    placeholderDrag: {
      type: String,
      default: 'Drop your file here'
    },

    backgroundColor: {
      type: String,
      default: 'grey lighten-3'
    },

    errorMessages: {
      type: [String, Array],
      default: () => []
    } as PropValidator<InputMessage | null>
  },

  data() {
    return {
      lazyValue: this.value,
      dragging: false,
      blobURL: ''
    };
  },
  computed: {
    internalValue: {
      get(): File | string | null {
        return this.lazyValue;
      },
      set(val: File | null) {
        this.lazyValue = val;
        this.$emit('input', this.lazyValue);
      }
    },
    internalErrorMessages(): (InputValidationRule | string)[] {
      return this.genInternalMessages(this.errorMessages);
    },

    internalValueAsImageSrc(): string | null {
      if (this.internalValue instanceof File) {
        return URL.createObjectURL(this.internalValue);
      }
      return this.internalValue;
    }
  },

  beforeDestroy() {
    if (this.internalValueAsImageSrc) {
      URL.revokeObjectURL(this.internalValueAsImageSrc);
    }
  },

  methods: {
    onInputChange(e: MouseEvent) {
      const files = [...((e.target as HTMLInputElement).files || [])];

      this.internalValue = files[0];

      this.$nextTick(() => {
        this.$refs.files.value = '';
      });
    },

    genInternalMessages(messages: InputMessage | null): InputValidationRules {
      if (!messages) return [];
      else if (Array.isArray(messages)) return messages;
      else return [messages];
    },

    clearInput() {
      this.internalValue = null;

      this.$nextTick(() => {
        this.$refs.files.value = '';
      });
    }
  }
});
