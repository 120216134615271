
import { sync } from 'vuex-pathify';

import Vue from 'vue';
// import kebabCase from 'lodash/kebabCase';
// import { genChip } from "@/utils/componentHelpers";
// import { timeSince } from "@/utils/timeSince";
export default Vue.extend({
  name: 'NotificationBadge',
  computed: {
    ...(sync('app/*') as any)
    // API: get("api/API") as any
  }
});
