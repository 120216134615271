
import { FormRules } from '@/mixins/FormRules';
import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import mixpanel from 'mixpanel-browser';

export enum AuthRegisterStep {
  REGISTER = 1,
  COMPLETE = 2
}
import Vue from 'vue';
export default Vue.extend({
  name: 'AltoleapSignUpWizard',

  mixins: [FormRules],

  props: {
    provider: {
      required: true,
      type: Object
    },

    // boilerplate
    company: String,
    logo: String,
    logoSize: { type: [Number, String], default: 70 },
    color: {
      type: String,
      default: 'primary'
    },
    useRedirect: Boolean
  },

  data: () => ({
    showPassword: false,

    user: new User(),
    password1: '',
    password2: '',

    step: AuthRegisterStep.REGISTER,
    AuthRegisterStep
  }),

  computed: {
    useOrganization(): boolean {
      return this.$altoleapAccounts.showOrganization;
    },
    firstButton() {
      switch (this.step) {
        case 1:
          return 'Sign In';
        default:
          return 'Back';
      }
    }
  },

  methods: {
    back() {
      return this.step--;
    },

    next() {
      return this.step++;
    },

    secondaryCardButton() {
      switch (this.step) {
        case 1:
          return this.$router.push({ name: 'sign-in' });
        case 2:
          return this.back();
        default:
          return this.back();
      }
    },

    primaryCardButton() {
      const authRegisterData = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        password1: this.password1,
        password2: this.password2,
        organization: this.$altoleapAccounts.showOrganization
          ? this.user.organization
          : undefined
      };

      switch (this.step) {
        case 1:
          // create object with proper data structure for register
          return this.createAccount(authRegisterData);
        case 2:
          return this.$router.push({ name: 'sign-in' });

        default:
          return 'Account created';
      }
    },

    async createAccount(userData: any) {
      await this.provider.clearError();
      await this.provider.register(userData).then(() => {
        this.next();
        try {
          mixpanel.alias(userData.email);
          mixpanel.track('New User Registered');
        } catch (error) {
          console.warn(error);
        }
      });
    }
  }
});
