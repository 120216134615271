
import { mapState, mapActions, mapGetters } from 'vuex';

//functions
import dateFormat from '@/utils/dateFormat';
import { IFetchJobReportActionPayload, JobReport } from '@/models/jobReport';
import { timeAgo } from '@vue-altoleap-libraries/vue-altoleap-accounts-ui/src/functions';

import { ErrorManager } from '@/models/error';
import { accountsProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';

import auth from '@/mixins/auth';
import mixins from '@/utils/mixins';
import moment from 'moment';
import { Account } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/account';
import functions from '@/mixins/functions';

export default mixins(auth, functions).extend({
  data() {
    return {
      title: 'Job Reports',
      search: '',
      searchHolder: '', //this holds the search value so it can be used on filter when nothing happens on search watch
      errorMessage: '',

      loading: false,

      startFilterDate: moment(new Date())
        .subtract(2, 'weeks')
        .startOf('day')
        .utc(true)
        .toDate(),

      endFilterDate: moment(new Date()).endOf('day').utc(true).toDate(),

      employeeActiveView: -1,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapGetters('jobReports', {
      jobReports: 'getJobReports',
      itemCount: 'getJobReportCount'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    accountsProvider(): any {
      return accountsProvider(this.$store);
    },

    canFetchAllUsers(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    employeeViews(): any {
      const allViewsOption = [{ text: 'All', value: -1 }];

      (this as any).accountsProvider.accounts.map((account: Account) => {
        const option = {
          text: `${account.first_name} ${account.last_name}`,
          value: account.id
        };
        allViewsOption.push(option);
      });

      return allViewsOption;
    },

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Job',
          value: 'job.title'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Start Time',
          value: 'job.date.start'
        },
        {
          text: 'End Time',
          value: 'job.date.end'
        },
        {
          text: 'Created By',
          value: 'owner'
        },
        {
          text: 'Last Updated',
          value: 'updated_at'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //remove column action
      }

      return headerList;
    }
  },

  // these watchers were implemented to act as an onChange Listener for our date picker component
  watch: {
    startFilterDate: {
      handler() {
        this.genFilteredJobReportList();
      }
    },
    endFilterDate: {
      handler() {
        this.genFilteredJobReportList();
      }
    },
    page: {
      handler(val) {
        this.genFilteredJobReportList(val);
      }
    },
    itemsPerPage: {
      handler() {
        this.genFilteredJobReportList();
      }
    },
    search(newSearch, prevSearch) {
      this.checkJobReportSearch(newSearch, prevSearch);
    }
  },

  methods: {
    ...mapActions({
      fetchJobReports: 'jobReports/fetchJobReports',
      deleteJobReport: 'jobReports/deleteJobReport'
    }),

    timeAgo,
    dateFormat,

    async checkJobReportSearch(newSearch: string, prevSearch: string) {
      if (newSearch !== prevSearch) {
        this.searchHolder = newSearch;
        await this.debounce(this.genFilteredJobReportList, 1000)();
      }
    },

    async deleteJobReportById(id: number) {
      if (
        await (this as any).$refs.deleteConfirmDialog.open(
          'Are you sure you want to delete this Job Report?',
          {
            left: {
              text: 'No'
            },
            right: {
              color: 'primary',
              text: 'yes'
            }
          }
        )
      ) {
        return this.deleteJobReport(id);
      } else {
        return;
      }
    },

    async genFilterParams(): Promise<IFetchJobReportActionPayload> {
      const params: IFetchJobReportActionPayload =
        {} as IFetchJobReportActionPayload;

      if (this.employeeActiveView != -1) {
        params.employee = this.employeeActiveView;
      }

      params.page_size = this.itemsPerPage != -1 ? this.itemsPerPage : 1000; //get max page size on server if user selects all;
      params.occurrence_after = this.startFilterDate;
      params.occurrence_before = this.endFilterDate;

      return params;
    },

    async genFilteredJobReportList(page = 1) {
      this.loading = true;

      const params: IFetchJobReportActionPayload = await this.genFilterParams();

      params.page = page;

      // goes back to first page if user is on another page and does a filter
      if (page == 1) {
        this.page = 1;
      }

      params.search = this.searchHolder == '' ? undefined : this.searchHolder;

      this.fetchJobReports(params)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    },

    openJobReportDetailsPage(instance: JobReport) {
      return this.$router.push({
        name: 'jobReports-detail',
        params: { jobReportId: String(instance.id) }
      });
    },

    setUserFullName(fullName: string) {
      return fullName == '' ? 'No Name' : fullName;
    }
  },

  async mounted() {
    this.loading = true;

    if (this.canFetchAllUsers) {
      this.accountsProvider.fetchAccounts(true).catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
    }

    this.genFilteredJobReportList();
  }
});
