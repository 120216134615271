var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-sheet',{staticStyle:{"height":"calc(100vh - 118px)"}},[_c('div',{staticStyle:{"height":"2px"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","height":"2"}}):_vm._e()],1),_c('ErrorAlert',{staticClass:"mb-0",attrs:{"dense":"","errorMessage":_vm.errorMessage,"detailErrorMessage":_vm.errorMessageDetail}}),_c('v-calendar',_vm._g({ref:"calendar",attrs:{"type":_vm.type,"min-weeks":_vm.minWeeks,"max-days":_vm.maxDays,"now":_vm.today,"dark":_vm.dark,"weekdays":_vm.calendarWeekdays,"first-interval":_vm.intervals.first,"interval-minutes":_vm.intervals.minutes,"interval-count":_vm.intervals.count,"interval-height":_vm.intervals.height,"show-interval-label":_vm.showIntervalLabel,"short-intervals":"","short-months":_vm.shortMonths,"short-weekdays":_vm.shortWeekdays,"color":"primary","events":_vm.calendarOccurrences,"event-height":_vm.minimumEventHeight,"event-start":"start_time","event-end":"end_time","event-overlap-mode":_vm.mode,"event-overlap-threshold":45,"event-color":(e) => 'transparent',"event-text-color":"black","event-name":"title","categories":_vm.categories,"event-category":"category","category-show-all":"","event-ripple":false},on:{"click:more":_vm.viewDay,"click:date":(e, n) => _vm.viewDay(e, n),"change":_vm.updateRange,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startDragTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag,"contextmenu:time":(_, $event) => {
          $event.stopPropagation();
          $event.preventDefault();
        },"contextmenu:event":(_, $event) => {
          $event.preventDefault();
        }},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"event",fn:function({ eventParsed, event, start, end, day }){return [_c('v-menu',{ref:`event-menu-${eventParsed.index}`,attrs:{"close-on-content-click":false,"close-on-click":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs }){return [_c('div',_vm._b({staticClass:"rounded fill-height",on:{"click":($event) => _vm.onEventMenuClick($event, eventParsed.index)}},'div',_vm.genJobStyle(attrs, event),false),[_c('div',{staticClass:"d-flex flex-column fill-height",class:{ 'pa-1': !_vm.isViewMonth },attrs:{"flat":"","id":`event-id-${eventParsed.index}`}},[_c('div',{class:`v-event-draggable data-recurrence-id-${new Date(
                    event.start_time
                  ).valueOf()} d-inline-block text-truncate`,attrs:{"id":"events"}},[_c('div',{staticClass:"v-event-draggable"},[_c('strong',[_vm._v(_vm._s(event.title))]),_c('br'),_c('div',{staticClass:"ellipses"},[_vm._v(" "+_vm._s(_vm.genStartEndTime(event))+" ")])])]),(!_vm.isViewMonth)?[(
                      _vm.computeEventDurationInPixels(
                        day,
                        eventParsed,
                        start,
                        end
                      ) >=
                      _vm.intervals.height * 1.5
                    )?_c('div',{staticClass:"mt-2 pl-1 font-weight-medium d-inline-block text-truncate"},[_vm._v(" "+_vm._s(event.location)+" ")]):_vm._e(),(
                      _vm.computeEventDurationInPixels(
                        day,
                        eventParsed,
                        start,
                        end
                      ) >=
                      _vm.intervals.height * 2.5
                    )?[_c('div',{staticClass:"mt-auto mb-1 pl-1"},[_c('StackedParticipantAvatarList',{attrs:{"color":"teal darken-2","participants":event.participants}})],1)]:_vm._e()]:_vm._e()],2)])]}}],null,true)},[_c('data-calendar-menu-card',{attrs:{"loading":_vm.loading,"errorMessage":_vm.errorMessage,"event":event,"canUpdateEvent":_vm.canUpdateEvent},on:{"update:loading":function($event){_vm.loading=$event},"update:errorMessage":function($event){_vm.errorMessage=$event},"update:error-message":function($event){_vm.errorMessage=$event},"open":_vm.edit,"close":function($event){_vm.$refs[`event-menu-${eventParsed.index}`].isActive = false},"delete":_vm.deleteEventDialog,"rebuild":_vm.rebuild,"send-invite:event":_vm.sendEventInvite,"send-invite:occurrence":_vm.sendOccurrenceInvite}})],1)]}},{key:"day-body",fn:function({ present }){return [(present && _vm.currentTimeMarker > -1)?_c('div',{staticClass:"v-current-time",class:{ first: present },style:({ top: `${_vm.currentTimeMarker}px` })}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}},_vm.$listeners))],1),_c('RecurrenceSaveDialog',{ref:"recurrenceSaveDialog",attrs:{"dense":""},on:{"rebuild":_vm.rebuild,"send-invite:event":_vm.sendEventInvite,"send-invite:occurrence":_vm.sendOccurrenceInvite}}),_c('core-choice-dialog',{ref:"choiceDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }