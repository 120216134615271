import { axios } from '@/plugins/axios';
import {
  CheckList,
  IFetchJobReportActionPayload,
  IJobReportPaginatedListData,
  JobReport
} from '@/models/jobReport';

export class JobReportService {
  endpoint: string;

  constructor() {
    this.endpoint = 'v2/job-reports';
  }

  async createJobReport(model: JobReport) {
    return axios
      .post<JobReport>(`${this.endpoint}/`, model)
      .then(({ data }) => data);
  }

  async fetchJobReports(payload?: IFetchJobReportActionPayload) {
    return axios
      .get<IJobReportPaginatedListData>(`${this.endpoint}/`, {
        timeout: 30 * 1000, // 30 seconds
        params: payload
          ? {
              page: payload.page,
              page_size: payload.page_size,
              search: payload.search,
              owner: payload.employee,
              occurrence_before: payload.occurrence_before,
              occurrence_after: payload.occurrence_after
            }
          : undefined
      })
      .then(({ data }) => data);
  }

  async fetchJobReportById(jobReportId: number) {
    return axios
      .get<JobReport>(`${this.endpoint}/${jobReportId}/`)
      .then(({ data }) => data);
  }

  async fetchJobChecklist(job_id: number) {
    return axios
      .get<CheckList[]>(`v1/jobs/${job_id}/checklist/`)
      .then(({ data }) => data);
  }

  async updateJobReport(jobReportId: number, job_report: JobReport) {
    return axios
      .patch(`${this.endpoint}/${jobReportId}/`, job_report)
      .then(({ data }) => data);
  }

  async updateJobReportChecklist(jobReportId: number, data: CheckList) {
    return axios
      .put<CheckList[]>(
        `${this.endpoint}/${jobReportId}/checklist-item/${data.id}/update-response/`,
        { response: data.response }
      )
      .then(({ data }) => data);
  }

  async deleteJobReport(id: number) {
    return axios.delete(`${this.endpoint}/${id}/`);
  }

  async addJobReportImage(jobReportId: number, job_report: any) {
    return axios
      .put(`${this.endpoint}/${jobReportId}/upload_file/`, job_report)
      .then(({ data }) => data);
  }
}
