import { axios } from '@/plugins/axios';
import { MovingItem } from '@/models/movingItem';

export class MovingItemService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'v1/moving-inventory-management/moving-inventories/';
  }

  async createMovingItem(movingItem: any) {
    return axios
      .post<MovingItem>(
        `${this.endpoint}${movingItem.inventory}/items/`,
        movingItem.data
      )
      .then(({ data }) => data);
  }

  async fetchMovingItems(inventoryId: number) {
    return axios
      .get<MovingItem[]>(`${this.endpoint}${inventoryId}/items/`)
      .then(({ data }) => data);
  }

  async fetchMovingItem(movingItem: MovingItem) {
    return axios
      .get<MovingItem>(
        `${this.endpoint}${movingItem.inventory}/items/${movingItem.id}/`
      )
      .then(({ data }) => data);
  }

  async updateMovingItem(movingItem: any) {
    return axios
      .put<MovingItem>(
        `${this.endpoint}${movingItem.inventory}/items/${movingItem.id}/`,
        movingItem.data
      )
      .then(({ data }) => data);
  }

  async deleteMovingItem(movingItem: MovingItem) {
    return axios.delete(
      `${this.endpoint}${movingItem.inventory}/items/${movingItem.id}/`
    );
  }

  async fetchSharedMovingItems(id: number) {
    return axios
      .get(`${this.endpoint}${id}/items/shared/shared-item/`, {
        headers: {
          Authorization: null
        }
      })
      .then(({ data }) => data);
  }
}
