import { IEmployeeState } from '@/store/employee';
import { IEmployeeGetters } from './getters.types';
import { IEmployee, Employee } from '@/models';

export const getters: IEmployeeGetters = {
  getEmployees(state: IEmployeeState): IEmployee[] {
    return state.employees.map((data) => new Employee(data));
  },

  getEmployeeById:
    (state: IEmployeeState) =>
    (id: number): IEmployee | undefined => {
      const data = state.employees.find((employee) => employee.id === id);
      if (!data) {
        return undefined;
      }

      return new Employee(data);
    }
};
