// Utilities
import { get, sync } from 'vuex-pathify';
import mixins from '@/utils/mixins';
import Routable from '@/mixins/routable';

import { VNode } from 'vue/types/umd';

export default mixins(Routable).extend({
  name: 'LayoutBreadcrumbs',

  props: {
    activeClass: {
      type: String,
      default: 'v-breadcrumbs__item--disabled'
    }
  },
  data: () => ({
    tab: null
  }),

  computed: {
    classes(): object {
      return {
        'v-breadcrumbs__item': true,
        [this.activeClass]: this.disabled
      };
    }
  },
  render(h): VNode {
    const { tag, data } = this.generateRouteLink();

    return h('li', [
      h(
        tag,
        {
          ...data,
          attrs: {
            ...data.attrs,
            'aria-current': this.isActive && this.isLink ? 'page' : undefined
          }
        },
        this.$slots.default
      )
    ]);
  }
});
// </script>
