import Vue from 'vue';
import { get } from 'vuex-pathify';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'companies-callable',

  computed: {
    authLoading: get('accounts/getLoading'),
    getError: get('error/getError')
  },

  methods: {
    ...mapActions({
      fetchAccount: 'accounts/fetchAccount',
      fetchAccounts: 'accounts/fetchAccounts'
    })
  }
});
