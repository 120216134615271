import { Calendar, CalendarEvent } from '@/models/calendar';
// import { Session } from '@/models/session';
import { PropType } from 'vue';
import { validateWeekdays } from './utils';
import { validateTimestamp, parseDate } from './timestamp';

export const base = {
  loading: {
    type: Boolean,
    default: false
  }
};
export const detail = {
  options: {
    type: Object as PropType<any>
  },
  calendars: {
    type: Array as PropType<Calendar[]>,
    required: true,
    default: () => []
  },
  events: {
    type: Array as PropType<CalendarEvent[]>,
    default: () => []
  }
};

export const time = {
  start: {
    type: [Date, String, Object],
    validate: validateTimestamp,
    default: () => parseDate(new Date()).date
  },
  end: {
    type: [Date, String, Object],
    validate: validateTimestamp,
    default: () => parseDate(new Date()).date
  },
  weekdays: {
    type: [Array, String],
    default: () => [0, 1, 2, 3, 4, 5, 6],
    validate: validateWeekdays
  },
  shortWeekdays: {
    type: Boolean,
    default: true
  },
  weekdayFormat: {
    type: Function,
    default: null
  },
  dayFormat: {
    type: Function,
    default: null
  }
};
export const functions = {
  save: {
    type: Function,
    required: false,
    validate: () => ({})
  }
};
export default { base, detail, functions, time };
