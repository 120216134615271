// API Data
export interface ICountryData {
  code?: string;
  name?: string;
}

export interface ICountryFieldErrorData {
  code?: string[];
  name?: string[];
}

// Business Logic Interface
export interface ICountry extends ICountryData {
  validate(): boolean;
}

export interface ICountryFieldError extends ICountryFieldErrorData {
  clearErrors(): void;
}

// Constants - Used for validation
export const COUNTRY_CODE_LENGTH = 2;
