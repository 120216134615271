import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Warehouse } from '@/models/inventory';
import { WarehouseService } from '@/services/inventory/warehouse-service';

const warehouseService = new WarehouseService();

export const state = {
  warehouse: [] as Warehouse[]
};

export type WarehouseState = typeof state;

export const mutations: MutationTree<WarehouseState> = {
  SET_WAREHOUSE(state: any, payload: Warehouse[]) {
    state.warehouse = payload;
  },

  ADD_WAREHOUSE(state: any, payload: Warehouse) {
    state.warehouse.push(payload);
  },

  UPDATE_WAREHOUSE(state: any, payload: Warehouse) {
    const index = state.warehouse.findIndex(
      (model: Warehouse) => model.id === payload.id
    );
    state.warehouse.splice(index, 1, payload);
  },

  REMOVE_WAREHOUSE(state: any, payload: number) {
    const index = state.warehouse.findIndex(
      (model: Warehouse) => model.id === payload
    );
    state.warehouse.splice(index, 1);
  }
};

export const actions: ActionTree<WarehouseState, WarehouseState> = {
  createWarehouse: async ({ commit }: any, payload: Warehouse) =>
    warehouseService
      .createWarehouse(payload)
      .then((model: Warehouse) => commit('ADD_WAREHOUSE', model)),

  fetchWarehouses: async ({ commit }: any) =>
    warehouseService.fetchWarehouses().then((warehouse: Warehouse[]) => {
      commit('SET_WAREHOUSE', warehouse);
    }),

  fetchWarehouse: async ({ commit }: any, payload: number) =>
    warehouseService.fetchWarehouse(payload).then((model: Warehouse) => {
      const index = state.warehouse.findIndex(
        (v: Warehouse) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_WAREHOUSE', model);
      } else {
        commit('UPDATE_WAREHOUSE', model);
      }
    }),

  updateWarehouse: async ({ state, commit }: any, payload: Warehouse) =>
    warehouseService.updateWarehouse(payload).then((model: Warehouse) => {
      const index = state.warehouse.findIndex(
        (v: Warehouse) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_WAREHOUSE', model);
      } else {
        commit('UPDATE_WAREHOUSE', model);
      }
    }),

  deleteWarehouse: async ({ commit }: any, payload: number) =>
    warehouseService.deleteWarehouse(payload).then(() => {
      commit('REMOVE_WAREHOUSE', payload);
    })
};

export const getters: GetterTree<WarehouseState, WarehouseState> = {
  getWarehouseById: (state: WarehouseState) => (id: number) => {
    return state.warehouse.find((model) => model.id === id);
  }
};
