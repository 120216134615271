import Vue from 'vue';

export default Vue.extend({
  name: 'functions',
  data: () => ({
    // final const timer
    // should not be changed outside the debounce function
    timer: 0 as any | number
  }),

  methods: {
    /**
     * Creates a debounced function that delays invoking func until after wait milliseconds have elapsed since
     * the last time the debounced function was invoked
     *
     * @convention prepend debounce when calling this function e.g debounceQuantity
     * ```
     * debounceQuantity(quantity: number) {
     *    return this.debounce(this.updateQuantity)(quantity);
     * }
     * ```
     *
     * @param {Function} fn The function to debounce
     * @param {=1000} delay The number of milliseconds to delay.
     */
    debounce(fn: Function, delay = 1000) {
      // should this only be within the scope of the function? let timer = 0
      if (this.timer) clearTimeout(this.timer);

      return (...args: any[]) => {
        this.timer = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    }
  }
});
