
import mixins from '@/utils/mixins';
import vcalendar from '@/mixins/calendar';
import { mapGetters, mapState } from 'vuex';
import { CalendarFormatter } from 'vuetify';
import { createNativeLocaleFormatter } from './common/timestamp';
import moment from 'moment';
import { CalendarType } from '@/models/job/index';
import { CalendarOccurrence } from '@/models/calendar';

const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6];
const weekdaysWork = [1, 2, 3, 4, 5];

export default mixins(vcalendar).extend({
  props: {
    canCreateEvent: {
      type: Boolean,
      default: () => false
    },
    canUpdateEvent: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      CalendarType,
      calendarMenu: false,
      searchMode: false,
      weekdaysLocalValue: weekdaysDefault,
      weekdaysDefault,
      weekdaysWork,
      labels: {
        today: 'today',
        todayIcon: 'mdi-calendar'
      },
      typeLabelList: [
        {
          type: CalendarType.CALENDAR_MONTH,
          label: 'Calendar - Month'
        },
        {
          type: CalendarType.CALENDAR_WEEK,
          label: 'Calendar - Week'
        },
        {
          type: CalendarType.CALENDAR_DAY,
          label: 'Calendar - Day'
        },
        {
          type: CalendarType.TIMELINE_DAY,
          label: 'Timeline - Day'
        },
        {
          type: CalendarType.TIMELINE_WEEK,
          label: 'Timeline - Week'
        }
      ]
    };
  },
  computed: {
    ...mapState('calendar', ['calendars']),

    ...mapGetters({
      getDraftEventsList: 'calendar/getDraftEventsList'
    }),

    draftEventsList(): CalendarOccurrence[] {
      return this.getDraftEventsList;
    },

    typeOptions(): object {
      const typeOptionsList = [
        {
          id: 'TH',
          label: 'Timeline',
          type: 'timeline-header',
          items: [
            {
              id: 'TD',
              label: 'Day',
              shortcut: 'TD',
              type: CalendarType.TIMELINE_DAY,
              size: 1,
              focus: 0.0,
              repeat: false,
              listTimes: false,
              updateRows: false,
              schedule: true
            },
            {
              id: 'TW',
              label: 'Week',
              shortcut: 'TW',
              type: CalendarType.TIMELINE_WEEK,
              size: 1,
              focus: 0.0,
              repeat: false,
              listTimes: false,
              updateRows: false,
              schedule: true
            }
          ]
        },
        {
          id: 'CH',
          label: 'Calendar',
          type: 'calendar-header',
          items: [
            {
              id: 'D',
              label: 'Day',
              shortcut: 'D',
              type: CalendarType.CALENDAR_DAY,
              size: 1,
              focus: 0.4999,
              repeat: true,
              listTimes: true,
              updateRows: true,
              schedule: false
            },
            {
              id: 'W',
              label: 'Week',
              shortcut: 'W',
              type: CalendarType.CALENDAR_WEEK,
              size: 1,
              focus: 0.4999,
              repeat: true,
              listTimes: true,
              updateRows: true,
              schedule: false
            },
            {
              id: 'M',
              label: 'Month',
              shortcut: 'M',
              type: CalendarType.CALENDAR_MONTH,
              size: 1,
              focus: 0.4999,
              repeat: true,
              listTimes: false,
              updateRows: true,
              schedule: false
            }
          ]
        }
      ];

      return typeOptionsList;
    },

    typeToLabel(): string {
      const labelObject = this.typeLabelList.find(
        (label) => label.type == this.type
      );
      return labelObject!.label;
    },

    todayDate: (): string => moment().format('MMM DD, YYYY hh:mm:ssa'),

    title(): string {
      const { calendarStart, calendarEnd } = this;
      if (!calendarStart || !calendarEnd) {
        return '';
      }

      const startMonth: any = this.monthFormatter(calendarStart, false);
      const endMonth: any = this.monthFormatter(calendarEnd, false);
      const suffixMonth = startMonth === endMonth ? '' : endMonth;

      const startYear = calendarStart.year;
      const endYear = calendarEnd.year;
      const suffixYear = startYear === endYear ? '' : startYear;

      const startDay = calendarStart.day + this.nth(calendarStart.day);
      const endDay = calendarEnd.day + this.nth(calendarEnd.day);

      switch (this.type) {
        case CalendarType.CALENDAR_MONTH:
          return `${startMonth} ${startYear}`;

        case CalendarType.CALENDAR_WEEK:
        case CalendarType.TIMELINE_WEEK:
          return `${startMonth} ${startDay} ${suffixYear} - ${suffixMonth} ${endDay}, ${endYear}`;

        case CalendarType.CALENDAR_DAY:
        case CalendarType.TIMELINE_DAY:
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return '';
    },

    monthFormatter(): CalendarFormatter {
      return this.getFormatter({
        timeZone: 'UTC',
        month: 'long'
      });
    }
  },
  watch: {
    weekdaysLocalValue: {
      handler(val) {
        this.setWeekdays(val);
      },
      immediate: true
    },
    focus: {
      handler(val) {
        this.$emit('update:focus', val);
      }
    }
  },
  methods: {
    prev(): any {
      this.$emit('prev');
    },

    next() {
      this.$emit('next');
    },

    focusToday() {
      if (this.type == CalendarType.TIMELINE_WEEK) {
        this.focus = moment(this.today).startOf('week').format('YYYY-MM-DD');
      } else {
        this.focus = this.today;
      }
    },

    async genCalendarDateTime() {
      this.$emit('genCalendarDateTime');
    },

    openCalendarSideNavigationDrawer() {
      this.$emit('openCalendarSideNavigationDrawer', true);
    },

    openPublishDrawer() {
      this.$emit('openJobPublishDrawer', true);
    },
    nth(d: number): string {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
    },
    getFormatter(options: object) {
      return createNativeLocaleFormatter('en', (_tms, _short) => options);
    }
  },
  async beforeMount() {
    this.focus = this.today;
    this.type = CalendarType.TIMELINE_WEEK;
  }
});
