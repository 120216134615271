
import { Attendee } from '@/models/calendar';
import { Participant } from '@/models/job';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'StackedParticipantList',

  props: {
    participants: {
      type: Array as PropType<Participant[]>,
      default: () => []
    },
    color: {
      type: String as PropType<string>,
      default: 'primary'
    },
    loading: { type: Boolean }
  },

  methods: {
    getAttendeeInitials: (attendee: Attendee): string =>
      `${attendee.first_name} ${attendee.last_name}`
        .toUpperCase()
        .split(' ')
        .map((n) => n[0])
        .join(''),

    getAttendeeFullName: (attendee: Attendee): string =>
      `${attendee.first_name} ${attendee.last_name}`
  }
});
