import { CheckList } from '@/models/jobReport';

export interface TimelineTimestamp extends CalendarTimestamp {
  showtimelineWeekDate?: boolean;
}

export interface CalendarEvent {
  id: number | null;
  title: string;
  location: string;
  description: string;
  date: DateClass;
  recurrence: Recurrence;
  calendar: number;
  owner: string;
  participants: Participant[] | Attendee[] | { attendee: number }[] | never[];
  reminder: Reminder;
  checklist?: CheckList;
}
export interface DateClass {
  start?: Date | string;
  end?: Date | string;
  timezone?: string;
}

export interface Recurrence {
  enabled?: boolean;
  rules?: string[];
}

export interface Patterns {
  id?: number;
  frequency?: number;
  week_start?: null;
  count?: number;
  interval?: number;
  until?: Date | string;
  by_set_pos?: any[];
  by_months?: any[];
  by_month_days?: any[];
  by_year_days?: any[];
  by_year_weeks?: any[];
  byweekday?: ByWeekday[] | number[];
  by_hours?: any[];
  by_minutes?: any[];
  recurrence_type?: number;
}

export interface ByWeekday {
  day?: number;
}

export interface Reminder {
  id?: number;
  enabled: boolean;
  minutes: number;
}
export class CalendarOccurrence {
  id?: number | null;
  title?: string;
  description?: string;
  location?: string;
  date?: DateClass;
  recurrence?: Recurrence;
  cancelled?: boolean;
  task_id?: string;
  event?: number;
  calendar?: number;
  participants?: Participant[] | Attendee[] | { attendee: number }[] | any;
  original_start?: Date | string;
  original_end?: Date | string;
  owner?: string;
  reminder?: Reminder | null;
  checklist?: CheckList | null;
  state?: number;
}

export interface Participant {
  id?: number;
  attendee: Attendee;
  rsvp_status: number;
}
export interface Attendee {
  id: number;
  first_name: string;
  last_name: string;
  email: boolean;
  is_active: boolean;
}

import { detectColor, uidToHexColor } from '@/utils/color';
import { CalendarTimestamp } from 'vuetify';
/**
 * Creates a complete calendar-object based on given props
 */
export const getDefaultCalendarObject = (props: object = {}): Calendar =>
  Object.assign(
    {},
    {
      // Id of the calendar
      id: '',
      // Visible display name
      name: '',
      // Visible display description

      description: '',

      // Color of the calendar
      color: uidToHexColor(''),
      // Whether or not the calendar is visible in the grid
      enabled: true,
      // Whether or not the calendar is loading events at the moment
      loading: false,
      // // Whether this calendar supports VEvents
      // supportsEvents: true,
      // // Whether this calendar supports VJournals
      // supportsJournals: false,
      // // Whether this calendar supports VTodos
      // supportsTasks: false,
      // The principal uri of the owner
      owner: '',
      // Timezone set for this calendar
      timezone: 'UTC',
      // List of shares
      shares: [],
      // // Published url
      // publishURL: null,
      // // Internal CalDAV url of this calendar
      // url: '',
      // Whether this calendar is read-only
      readOnly: false,
      // The order of this calendar in the calendar-list
      order: 0,
      // Whether or not the calendar is shared with me
      // isSharedWithMe: false,
      // // Whether or not the calendar can be shared by me
      // canBeShared: false,
      // // Whether or not the calendar can be published by me
      // canBePublished: false,
      // // Reference to cdav-lib object
      // dav: false,
      // // All calendar-objects from this calendar that have already been fetched
      calendarObjects: [],
      // Time-ranges that have already been fetched for this calendar
      fetchedTimeRanges: []
    },
    props
  );

export const mapCollectionToCalendar = (calendar: Calendar) => {
  const name = calendar.name;
  const description = calendar.description;
  // || getCalendarUriFromUrl(calendar.url)
  // calendar.color can be set to anything on the server,
  // so make sure it's something that remotely looks like a color
  const color = detectColor(calendar.color!) ?? uidToHexColor(name);
  const owner = calendar.owner;
  // const readOnly = !calendar.isWriteable()
  const order = calendar.order || 0;
  const timezone = calendar.timezone || null;
  const enabled = calendar.enabled || true;

  return getDefaultCalendarObject({
    id: calendar.id,
    name,
    description,
    color,
    order,
    enabled,
    owner,
    readOnly: calendar.readOnly,
    timezone
    // dav: calendar,
  });
};

export interface Calendar {
  id: any;
  order?: any;
  readOnly?: boolean;
  calendarObjects?: any;
  fetchedTimeRanges?: any;
  name: string;
  description?: string;
  color?: string;
  enabled?: boolean;
  loading?: boolean;
  owner?: string;
  timezone?: string;
  shares?: any[];
}

// Constants
export enum RecurrenceEditType {
  SINGLE_OCCURRENCE = 0,
  FUTURE_OCCURRENCES = 1,
  ALL_OCCURRENCE = 2
}

export interface IJobDateFieldErrorData {
  start?: string[];
  end?: string[];
  timezone?: string[];
}

export interface IJobFieldErrorData {
  title?: string[];
  location?: string[];
  description?: string[];
  date?: IJobDateFieldErrorData;
  contact: IJobContactFieldErrorData;
}

export interface IJobContactFieldErrorData {
  first_name?: string[];
  last_name?: string[];
  email?: string[];
  phone?: string[];
}

export interface IJobDateFieldError {
  clearErrors(): void;
}
export interface IJobContactFieldError {
  clearErrors(): void;
  hasAnyErrors(): boolean;
}

export interface IJobFieldError {
  date: IJobDateFieldError;
  clearErrors(): void;
}

export class JobContactFieldError implements IJobContactFieldError {
  first_name: string[] = [];
  last_name: string[] = [];
  email: string[] = [];
  phone: string[] = [];

  constructor(data?: IJobContactFieldErrorData) {
    if (data) {
      this.first_name = data.first_name ?? [];
      this.last_name = data.last_name ?? [];
      this.email = data.email ?? [];
      this.phone = data.phone ?? [];
    }
  }

  clearErrors(): void {
    this.first_name.splice(0, this.first_name.length);
    this.last_name.splice(0, this.last_name.length);
    this.email.splice(0, this.email.length);
    this.phone.splice(0, this.phone.length);
  }

  static isJobContactFieldError(data: IJobContactFieldErrorData) {
    if (data.first_name && Array.isArray(data.first_name)) {
      return true;
    }
    if (data.last_name && Array.isArray(data.last_name)) {
      return true;
    }
    if (data.email && Array.isArray(data.email)) {
      return true;
    }
    if (data.phone && Array.isArray(data.phone)) {
      return true;
    }

    return false;
  }

  hasAnyErrors(): boolean {
    if (this.first_name.length != 0) {
      return true;
    }
    if (this.last_name.length != 0) {
      return true;
    }
    if (this.email.length != 0) {
      return true;
    }
    if (this.phone.length != 0) {
      return true;
    }

    return false;
  }
}
export class JobDateFieldError implements IJobDateFieldError {
  start: string[] = [];
  end: string[] = [];
  timezone: string[] = [];

  constructor(data?: IJobDateFieldErrorData) {
    if (data) {
      this.start = data.start ?? [];
      this.end = data.end ?? [];
      this.timezone = data.timezone ?? [];
    }
  }

  clearErrors(): void {
    this.start.splice(0, this.start.length);
    this.end.splice(0, this.end.length);
    this.timezone.splice(0, this.timezone.length);
  }

  static isJobDateFieldError(data: IJobDateFieldErrorData) {
    if (data.start && Array.isArray(data.start)) {
      return true;
    }
    if (data.end && Array.isArray(data.end)) {
      return true;
    }
    if (data.timezone && Array.isArray(data.timezone)) {
      return true;
    }
    return false;
  }
}
export class JobFieldError implements IJobFieldError {
  title: string[] = [];
  location: string[] = [];
  description: string[] = [];
  date: IJobDateFieldError = new JobDateFieldError();
  contact: IJobContactFieldError = new JobContactFieldError();

  constructor(data?: IJobFieldErrorData) {
    if (data) {
      this.title = data.title ?? [];
      this.location = data.location ?? [];
      this.description = data.description ?? [];
      this.date = new JobDateFieldError(data.date);
      this.contact = new JobContactFieldError(data.contact);
    }
  }

  clearErrors(): void {
    this.title.splice(0, this.title.length);
    this.location.splice(0, this.location.length);
    this.description.splice(0, this.description.length);
    this.date.clearErrors();
    this.contact.clearErrors();
  }

  static isJobFieldError(data: IJobFieldErrorData) {
    if (data.title && Array.isArray(data.title)) {
      return true;
    }
    if (data.location && Array.isArray(data.location)) {
      return true;
    }
    if (data.description && Array.isArray(data.description)) {
      return true;
    }
    if (data.date && JobDateFieldError.isJobDateFieldError(data.date)) {
      return true;
    }
    if (
      data.contact &&
      JobContactFieldError.isJobContactFieldError(data.contact)
    ) {
      return true;
    }
    return false;
  }
}
