import { axios } from '@/plugins/axios';
import { IProvider } from './provider.types';
import { EmployeeService } from './employee';
import { ProductService } from './product';
import { FeedbackService } from './feedback';
import { JobService } from './job/index';
import { OrganizationService } from './organization';

// CRM
import { ContactService } from './contact';
import { CompanyService } from './company';

export const provider = (): IProvider => ({
  employee: new EmployeeService(axios),
  product: new ProductService(axios),
  feedback: new FeedbackService(axios),
  job: new JobService(axios),
  organization: new OrganizationService(axios),

  // CRM
  contact: new ContactService(axios),
  company: new CompanyService(axios)
});
