
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Organization } from '@/models';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  name: 'OrganizationList',
  data() {
    return {
      loading: false,
      search: '',
      errorMessage: '',
      headers: [
        {
          text: '#',
          sortable: false,
          align: 'start',
          value: 'id',
          width: 80
        },
        {
          text: 'Name',
          sortable: true,
          align: 'start',
          value: 'name'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('organization', {
      organizations: 'getOrganizations'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganizations: 'organization/fetchOrganizations'
    }),

    goToOrganization(organization: Organization) {
      this.$router.push({
        name: 'organization-detail',
        params: { organizationId: String(organization.id) }
      });
    }
  },

  mounted() {
    this.loading = true;
    this.fetchOrganizations()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  }
});
