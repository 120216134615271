
import Vue from 'vue';
export default Vue.extend({
  name: 'AddressInfoCard',
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object,
      default: () => ({
        address1: '',
        address2: '',
        locality: '',
        region: '',
        postal_code: '',
        country: {
          name: ''
        }
      })
    }
  }
});
