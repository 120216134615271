import { IEmployeeState } from '@/store/employee';
import { IEmployeeMutations } from './mutations.types';
import { IEmployeeData } from '@/models';

export const mutations: IEmployeeMutations = {
  SET_EMPLOYEES(state: IEmployeeState, payload: IEmployeeData[]): void {
    state.employees = payload;
  },

  ADD_EMPLOYEE(state: IEmployeeState, payload: IEmployeeData): void {
    state.employees.push(payload);
  },

  UPDATE_EMPLOYEE(state: IEmployeeState, payload: IEmployeeData): void {
    const index = state.employees.findIndex(
      (employee: IEmployeeData) => employee.id === payload.id
    );

    state.employees.splice(index, 1, payload);
  },

  REMOVE_EMPLOYEE(state: IEmployeeState, payload: number): void {
    const index = state.employees.findIndex(
      (employee: IEmployeeData) => employee.id === payload
    );

    state.employees.splice(index, 1);
  }
};
