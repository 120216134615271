import { Company } from './company';
import { Contact } from './contact';
import { Country } from './country';

export class Address {
  id?: number;
  address1?: string;
  address2?: string;
  city?: string;
  region?: string;
  postal_code?: string;
  country?: Country;

  constructor() {
    this.country = new Country();
  }
}

export class Item {
  id?: number;
  name?: string;
  identifier?: string;
  media?: any;
  //color?: string;
  description?: string;
  unit?: string;
  price?: number;
  //category?: Category | { id?: number; category_name?: string; color?: string;} | any;
  vendor?: Vendor;

  constructor() {
    this.vendor = new Vendor();
  }
}

export class Category {
  id?: number;
  category_name?: string;
  color?: string;
}

export class Vendor {
  id?: number;
  contact?: Contact | any;
  company?: Company | any;

  constructor() {
    this.company = new Company();
    this.contact = new Contact();
  }
}

export class Warehouse {
  id?: number;
  name = '';
  identifier = '';
  description?: string;
  address?: Address;
  phone?: string;

  constructor() {
    this.address = new Address();
  }
}

export class Inventory {
  id?: number;
  warehouse?: Warehouse;
  item?: Item;
  quantity?: number | string;
  location?: Location;
  notes?: string;

  constructor() {
    this.warehouse = new Warehouse();
    this.item = new Item();
    this.location = new Location();
  }
}

export class ActivityFeed {
  id?: number;
  user?: string;
  action?: string;
  inventory?: Inventory;
  created_at?: string;

  constructor() {
    this.inventory = new Inventory();
  }
}

export class Location {
  id?: number;
  aisle?: string;
  rack?: string;
  bay?: string;
  shelf?: string;
  bin?: string;
}
