import {
  IContact,
  IContactData,
  IContactNote,
  IContactNoteData
} from '../contact.types';
import { Contact, ContactNote } from '../contact';

export const mockContactsData = (): IContactData[] => [
  {
    avatar: null,
    company: { id: 30, name: 'awdawdawdawd', logo: null },
    created_at: '2022-08-17T15:35:41.125491Z',
    email: '',
    id: 9,
    name: { id: 9, title: 'Mr.', first: 'awd', last: 'awdawd' },
    owner: 'Test Last',
    phone: '',
    status: { value: 0, display_name: 'Lead' },
    title: '',
    updated_at: '2022-08-17T16:19:55.638143Z',
    website: ''
  },
  {
    avatar: null,
    company: null,
    created_at: '2022-08-17T13:46:25.409418Z',
    email: '',
    id: 7,
    name: { id: 7, title: 'Mr.', first: 'wad', last: 'awd' },
    owner: 'Test Last',
    phone: '',
    status: { value: 0, display_name: 'Lead' },
    title: '',
    updated_at: '2022-08-17T13:46:25.409418Z',
    website: ''
  }
];

export const mockContactNotesData = (): IContactNoteData[] => [
  {
    id: 1,
    text: 'note 1',
    owner: 1,
    contact: 2
  },
  {
    id: 2,
    text: 'note 3',
    owner: 2,
    contact: 3
  }
];

export const mockContactNotes = (
  data: IContactNoteData[] = mockContactNotesData()
): IContactNote[] => data.map((item) => new ContactNote(item));

export const mockContacts = (
  data: IContactData[] = mockContactsData()
): IContact[] => data.map((item) => new Contact(item));
