import { IStore } from '@/store';
import {
  authProvider,
  User
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { startCase } from 'lodash';

import mixpanel, {
  Mixpanel,
  Config,
  RequestOptions,
  Dict,
  Callback
} from 'mixpanel-browser';

import Vue, { PluginObject, VueConstructor } from 'vue';
import VueRouter, { Route } from 'vue-router';

export interface MixpanelPluginOptions {
  token: string;
  config: Partial<Config>;
  router: VueRouter;
  store: IStore;
}

export const MixpanelPlugin: PluginObject<MixpanelPluginOptions> = {
  install(vue, options) {
    Vue.prototype.$mixpanel = mixpanel;

    if (options?.token) {
      Vue.prototype.$mixpanel.init(options.token);

      const config = options?.config || {};
      (Vue.prototype.$mixpanel as Mixpanel).set_config(config);

      if (options.router) {
        options.router.afterEach((to: Route, from: Route) => {
          const routeEventName = `${startCase(to.name ?? to.path)} Page Viewed`;
          mixpanel.track(routeEventName, {
            page: to.path,
            name: to.name,
            query: to.query
          });
        });
      }

      if (options?.store) {
        options?.store.subscribeAction({
          before: (action) => {
            switch (action.type) {
              case 'auth/logout':
                (Vue.prototype.$mixpanel as Mixpanel).reset();
                break;
              default:
                break;
            }
          },
          after: (action) => {
            // TODO: LH 2022/07/26 getUser is assigned from login but getCurrentUser is not; this should not be the case
            const user: User = (authProvider(options.store) as any).getUser;

            switch (action.type) {
              case 'auth/login': {
                mixpanel.identify(user.email);

                const properties: Record<string, any> = {};
                properties.$email = user.email;
                properties.$first_name = user.first_name;
                properties.$last_name = user.last_name;
                mixpanel.track('User Signed In', { ...properties });

                mixpanel.people.set({
                  id: user.id,
                  $first_name: user.first_name,
                  $last_name: user.last_name,
                  $email: user.email,
                  'Joined At': user.date_joined,
                  Organization: user.organization?.name ?? ''
                });
                break;
              }
              case 'notifications/pushNotification':
                mixpanel.track(`New Notification Created`);
                break;

              case 'accounts/createAccount':
                mixpanel.track(`New User Created`);
                break;

              default:
                break;
            }
          }
        });
      }
    }
  }
};

export function registerMixpanel(
  Vue: VueConstructor,
  store: IStore,
  router: VueRouter
): void {
  if (process.env.VUE_APP_ENABLE_LOGGING === 'true') {
    // Enabling the debug mode flag is useful during implementation,
    // but it's recommended you remove it for production
    Vue.use<MixpanelPluginOptions>(MixpanelPlugin, {
      // TODO: LH - 2022-07-27 - Would probably have to look into client-side proxy to not send the token over the air
      // https://developer.mixpanel.com/docs/client-side-vs-server-side-tracking
      // https://community.mixpanel.com/sending-data-to-mixpanel-11/best-practice-for-protecting-mixpanel-token-in-web-4164
      token: process.env.VUE_APP_MIXPANEL_TOKEN,
      config: {
        debug: process.env.VUE_APP_ENVIRONMENT !== 'production'
      },
      router: router,
      store: store
    });
  }
}
