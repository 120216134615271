import { IOrganizationData } from '@/models';
import { IOrganizationService } from './organization.types';
import { AxiosInstance } from 'axios';
import { objectToFormData } from '@/utils/objectToFormData';
import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';

export class OrganizationService implements IOrganizationService {
  private endpoint: string;
  private userEndpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/accounts/organizations/';
    this.userEndpoint = 'v1/accounts/users/';
  }

  fetchOrganizations(): Promise<IOrganizationData[]> {
    return this.axios
      .get<IOrganizationData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchOrganization(id: number): Promise<IOrganizationData> {
    return this.axios
      .get<IOrganizationData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createOrganization(data: IOrganizationData): Promise<IOrganizationData> {
    return this.axios
      .post<IOrganizationData>(`${this.endpoint}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  updateOrganization(data: IOrganizationData): Promise<IOrganizationData> {
    return this.axios
      .put<IOrganizationData>(`${this.endpoint}${data.id}/`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  deleteOrganization(id: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }

  fetchOrganizationUsers(id: number): Promise<User[]> {
    return this.axios
      .get<User[]>(`${this.userEndpoint}`, {
        params: { organization: id }
      })
      .then(({ data }) => data);
  }
}
