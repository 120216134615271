import {
  IContactActions,
  ContactContext,
  IContactStatusPayload
} from './actions.types';
import { IContactData } from '@/models';

export const actions: IContactActions = {
  fetchContacts(context: ContactContext): Promise<void> {
    return this.$services.contact
      .fetchContacts()
      .then((contacts: IContactData[]) => {
        context.commit('SET_CONTACTS', contacts);
      });
  },

  fetchContact(context: ContactContext, payload: number): Promise<void> {
    return this.$services.contact
      .fetchContact(payload)
      .then((contact: IContactData) => {
        const index = context.state.contacts.findIndex(
          (v: IContactData) => v.id === contact.id
        );
        if (index === -1) {
          context.commit('ADD_CONTACT', contact);
        } else {
          context.commit('UPDATE_CONTACT', contact);
        }
      });
  },

  createContact(context: ContactContext, payload: IContactData): Promise<void> {
    return this.$services.contact
      .createContact(payload)
      .then((contact: IContactData) => context.commit('ADD_CONTACT', contact));
  },

  updateContact(context: ContactContext, payload: IContactData): Promise<void> {
    return this.$services.contact
      .updateContact(payload)
      .then((contact: IContactData) => {
        const index = context.state.contacts.findIndex(
          (v: IContactData) => v.id === contact.id
        );
        if (index === -1) {
          context.commit('ADD_CONTACT', contact);
        } else {
          context.commit('UPDATE_CONTACT', contact);
        }
      });
  },

  deleteContact(context: ContactContext, payload: number): Promise<void> {
    return this.$services.contact.deleteContact(payload).then(() => {
      context.commit('REMOVE_CONTACT', payload);
    });
  },

  updateContactStatus(
    context: ContactContext,
    payload: IContactStatusPayload
  ): Promise<void> {
    return this.$services.contact
      .updateContactStatus(payload.id, payload.state)
      .then((status) => {
        const index = context.state.contacts.findIndex(
          (v: IContactData) => v.id === payload.id
        );
        if (index !== -1) {
          const newContact: IContactData = {
            ...context.state.contacts[index],
            status: status
          };
          context.commit('UPDATE_CONTACT', newContact);
        }
      });
  }
};
