import { IJobState } from '@/store/job';
import { IJobMutations } from './mutations.types';
import { IJobData, IJobTimelineOccurrencesData } from '@/models/job/index';

export const mutations: IJobMutations = {
  SET_JOBS(state: IJobState, payload: IJobTimelineOccurrencesData[]): void {
    state.jobs = payload;
  },

  ADD_JOB(state: IJobState, payload: IJobTimelineOccurrencesData): void {
    state.jobs.push(payload);
  },

  SET_LAST_PROMISE(state: IJobState, payload: Promise<any>): void {
    state.lastPromise = payload;
  }
};
