
import Vue from 'vue';
import PhoneNumber from 'awesome-phonenumber';
import { mapActions, mapGetters } from 'vuex';

import { ErrorManager } from '@/models/error';
import { ContactNote, IContact, IContactNote } from '@/models';
import dateFormat from '@/utils/dateFormat';

// Components
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import ContactDetailFormDialog from './ContactDetailFormDialog.vue';
import ContactNoteDetailFormDialog from './ContactNoteDetailFormDialog.vue';
import ContactStatusDisplay from './ContactStatusDisplay.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import { dateFromNow } from '@/utils/dateHelpers';

interface Options {
  $refs: {
    contactFormDialog: InstanceType<typeof ContactDetailFormDialog>;
    contactNoteDetailFormDialog: InstanceType<
      typeof ContactNoteDetailFormDialog
    >;
  };
}

export default Vue.extend<Options>({ functional: false }).extend({
  components: {
    ContactDetailFormDialog,
    ContactStatusDisplay,
    ErrorAlert,
    ContactNoteDetailFormDialog,
    ConfirmationDialog
  },
  name: 'ContactDetailCard',

  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },

  data() {
    return {
      errorMessage: '',
      errorContactNoteDialogMessage: '',

      deleteContactNoteDialog: false,
      deleteInProgress: false,

      selectedContactNote: new ContactNote(),

      loading: false,
      notesLoading: false,

      tab: null
    };
  },

  computed: {
    ...mapGetters({
      getContactById: 'contactV2/getContactById',
      getContactNotes: 'contactNoteV2/getContactNotes'
    }),

    contactId(): number {
      return Number(this.id);
    },

    contact(): IContact | undefined {
      return this.getContactById(this.contactId);
    },

    notes(): IContactNote[] {
      return this.getContactNotes;
    }
  },

  methods: {
    dateFromNow,

    ...mapActions({
      fetchContactNotes: 'contactNoteV2/fetchContactNotes',
      deleteContactNote: 'contactNoteV2/deleteContactNote',
      fetchContact: 'contactV2/fetchContact'
    }),

    dateFormat(
      date?: Date | string | number | (number | string)[],
      format?: string
    ) {
      return dateFormat(date, format, false);
    },

    fetchNotes() {
      this.notesLoading = true;
      this.fetchContactNotes(this.contactId)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.notesLoading = false;
        });
    },

    formatPhone(phone: string) {
      const phoneFormat = new PhoneNumber(phone).getNumber('international');
      // can potentially be undefined if input is bad
      if (phoneFormat) return phoneFormat;
      return phone;
    },

    /**
     * open  dialog
     */
    openContactFormDialog(instance: IContact) {
      return this.$refs.contactFormDialog.open(instance, true);
    },

    openDeleteContactNoteDialog(contact: any) {
      this.selectedContactNote = contact;
      this.deleteContactNoteDialog = true;
    },

    closeContactNoteDeleteDialog() {
      this.clearAllDialogErrors();
      this.selectedContactNote = new ContactNote();
      this.deleteContactNoteDialog = false;
    },

    destroyContactNote(noteId: number) {
      this.notesLoading = true;
      this.deleteContactNote({
        contactId: this.contactId,
        noteId: noteId
      })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.notesLoading = false;
          this.deleteContactNoteDialog = false;
        });
    },

    openContactNodeDetailFormDialog(
      contactNote?: IContactNote,
      isEdit?: boolean
    ) {
      const contactNoteRef = this.$refs.contactNoteDetailFormDialog;
      contactNoteRef.open(contactNote, isEdit);
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    clearAllDialogErrors() {
      this.errorContactNoteDialogMessage = '';
    }
  },

  mounted() {
    this.loading = true;
    this.fetchContact(this.contactId)
      .then(() => this.fetchNotes())
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  }
});
