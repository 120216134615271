
import Vue from 'vue';
import { get } from 'vuex-pathify';
// Utilities
import { genChip } from '@/utils/componentHelpers';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({
        chip: '',
        text: '',
        group: '',
        children: []
      })
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    actionIcon: {
      type: [Boolean, String],
      default: false
    },
    chip: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    list: null
  }),

  methods: {
    genChip
    // genGroup(children: any): any[] {
    //   return children
    //     .map((item: any) => {
    //       const parent = item.group || this.item.group;
    //       let group = `${parent}/${kebabCase(item.namespace)}`;

    //       if (item.children) {
    //         group = `${group}|${this.genGroup(item.children)}`;
    //       }

    //       return group;
    //     })
    //     .join('|');
    // }
  },
  computed: {
    mini: get('app/mini'),
    children() {
      // return this.item.children.map(item => ({
      //   ...item,
      //   to: `${this.item.group}/${item.namespace}`
      // }));
      return this.item.children;
    },
    // group() {
    //   return this.genGroup(this.item.children);
    // },
    chipColor() {
      switch (this.item.chip) {
        case 'new':
          return 'primary';
        case 'updated':
          return 'warning';
        case 'deprecated':
          return 'black';
        case 'help':
          return 'error';
        case 'dev':
          return 'secondary';
        default:
          return 'primary';
      }
    }
  }
});
