import Vue from 'vue';
// when running documentation do @ts-ignore to run styleguide
import App from './App.vue';

import '@/registerServiceWorker';
// import '@/components';
import router from './router';
import { makeStore } from './store';

import { createVuetify } from '@/plugins/vuetify';
import { registerPlugins } from '@/plugins';
import { prepareServices } from './plugins/services';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { sync } from 'vuex-router-sync';
import { setLogLevel } from './utils/logger';
Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV === 'development';

const store = makeStore();
prepareServices(store);

sync(store, router);

registerPlugins(Vue, store, router);
const vuetify = createVuetify(store);

setLogLevel('debug');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
