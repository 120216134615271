import { IJobTimelineOccurrencesData } from '../job.types';

export const mockJobData = (): IJobTimelineOccurrencesData[] => {
  return [
    {
      user: {
        id: 17,
        first_name: 'Test',
        last_name: 'User',
        email: 'testuser@gmail.com'
      },
      jobs: [
        {
          id: 19,
          title: 'New Job',
          color: '#00AEEF',
          description: 'deaitlri',
          state: 0,
          date: {
            start: '2021-11-11T16:57:00Z',
            end: '2021-11-11T17:57:00Z',
            timezone: 'America/Bogota'
          },
          recurrence: {
            enabled: false,
            rules: []
          },
          location: 'job lication',
          participants: [
            {
              id: 47,
              attendee: {
                id: 4,
                first_name: "M'Nadi",
                last_name: 'Grant',
                email: 'mgrant@altoleap.com'
              },
              rsvp_status: 2
            }
          ],
          reminder: {
            enabled: false,
            minutes: 10
          },
          conference: {
            enabled: false,
            platform: 0,
            session: {
              key: '',
              password: '',
              start_url: '',
              join_url: ''
            }
          },
          checklist: {
            id: 20,
            items: []
          },
          contact: null,
          original_start: '2022-01-17T19:30:00Z',
          event: 107,
          calendar: 3
        }
      ]
    }
  ];
};
