
// TODO FIX date value changing on focus
import Vue from 'vue';
import { PropType } from 'vue';
type InputValidationRule = (value: any) => string | boolean;
type InputValidationRules = (InputValidationRule | string | any)[];
import dateFormat from '@/utils/dateFormat';

const prop = 'value',
  event = 'input';

export default Vue.extend({
  model: { prop, event },

  props: {
    value: {
      type: Date as PropType<Date>,
      required: false,
      default: () => new Date()
    },
    rules: {
      type: Array as PropType<InputValidationRules>,
      default: () => []
    },
    errorMessages: [String, Array],
    dateLabel: {
      type: String,
      default: 'Date'
    },
    timeLabel: {
      type: String,
      default: 'Time'
    }
  },

  data() {
    return {
      dropdownOpenDate: false,
      dropdownOpenTime: false,
      dateModel: '',
      timeModel: '',
      lazyValue: this.value
    };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(model: any) {}
    } as any,

    internalValue: {
      get(): Date {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
      }
    } as any
  },
  watch: {
    value(val) {
      this.lazyValue = val;
    },
    dateModel(val) {
      this.onUpdateDate();
    },
    timeModel(val) {
      this.onUpdateDate();
    },

    dropdownOpenDate(val): void {
      if (val) return this.setTimeData();
    },
    dropdownOpenTime(val): void {
      if (val) return this.setTimeData();
    }
  },
  methods: {
    dateFormat,

    // SET data fields for time objects datemodel timemodel etc
    setTimeData() {
      this.dateModel = dateFormat((this as any).lazyValue, 'YYYY-MM-DD', true);
      this.timeModel = dateFormat((this as any).lazyValue, 'HH:mm', true);
    },
    // Format the date and trigger the input event
    onUpdateDate() {
      const time: string = this.timeModel ?? '00:00:00';
      const dateString: string = this.dateModel + 'T' + time + 'Z';
      const d = new Date(dateString);

      if (isNaN(d.getTime())) {
        return;
      } else {
        this.internalValue = d;
        this.$emit(event, this.internalValue);
      }
    }
  }
});
