
import Returnable from '@/mixins/returnable';
import { keyCodes } from '@/utils/helpers';
import mixins from '@/utils/mixins';

export default mixins(Returnable).extend({
  name: 'settings-inline-dialog',
  props: {
    cancelText: {
      default: 'Cancel'
    },
    allowEdit: Boolean,
    large: Boolean,
    eager: Boolean,
    persistent: Boolean,
    saveText: {
      default: 'Save'
    },
    transition: {
      type: String,
      default: 'slide-x-reverse-transition'
    }
  },

  data: () => ({
    hover: false
  }),

  watch: {
    isActive(val) {
      if (val) {
        this.$emit('open');
        setTimeout(this.focus, 50); // Give DOM time to paint
      } else {
        this.$emit('close');
      }
    }
  },
  computed: {
    getHover() {
      return !this.returnValue || this.hover;
    }
  },
  methods: {
    cancel() {
      this.isActive = false;
      this.$emit('cancel');
    },
    focus() {
      const input = (this.$refs.content as Element).querySelector('input');
      input && input.focus();
    },
    selectSave(e: KeyboardEvent) {
      const input = (this.$refs.content as Element).querySelector('input');
      e.keyCode === keyCodes.esc && this.cancel();
      if (e.keyCode === keyCodes.enter && input) {
        this.save(input.value);
        this.$emit('save');
      }
    }
  }
});
