
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';

import { ContactNote, ContactNoteFieldError, IContactNote } from '@/models';

import { diffDeep } from '@/utils/helpers';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '@/components/common/ErrorAlert.vue';

export default Vue.extend({
  name: 'ContactNoteDetailFormDialog',

  components: { ErrorAlert },

  props: {
    contactId: Number
  },

  data: () => ({
    loading: false,
    isEdit: false,
    dialog: false,

    contactNoteOriginalCopy: null as null | IContactNote,
    contactNote: null as null | IContactNote,
    errorMessage: '',
    fieldError: new ContactNoteFieldError()
  }),

  methods: {
    ...mapActions({
      updateContactNote: 'contactNoteV2/updateContactNote',
      createContactNote: 'contactNoteV2/createContactNote'
    }),

    open(contactNote: IContactNote = new ContactNote(), isEdit = false) {
      this.contactNoteOriginalCopy = contactNote;
      this.contactNote = cloneDeep(this.contactNoteOriginalCopy);
      this.isEdit = isEdit;
      this.clearAllErrors();
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    async saveContactNote(): Promise<void> {
      this.clearAllErrors();

      this.loading = true;

      try {
        if (this.isEdit) {
          const contactNoteDelta = diffDeep(
            this.contactNote,
            this.contactNoteOriginalCopy,
            true
          );
          await this.updateContactNote({
            contactId: this.contactId,
            note: contactNoteDelta
          });
        } else {
          this.contactNote!.contact = this.contactId;
          await this.createContactNote({
            contactId: this.contactId,
            note: this.contactNote
          });
        }

        this.loading = false;

        this.close();
      } catch (error: any) {
        this.loading = false;
        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (
            ContactNoteFieldError.isContactNoteFieldError(error.response.data)
          ) {
            this.fieldError = new ContactNoteFieldError(error.response.data);
          }
        }
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    }
  }
});
