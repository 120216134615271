import {
  IProduct,
  IProductPrice,
  IProductData,
  IProductPriceData,
  IProductFieldError,
  IProductPriceFieldError,
  IProductFieldErrorData,
  IProductPriceFieldErrorData,
  ProductPriceFrequencyOptions,
  IProductPlanData,
  IProductPlan
} from './product.types';

export class ProductPlan implements IProductPlan {
  readonly id?: number;
  readonly max_active_users?: number;
  readonly is_crm_supported?: boolean;
  readonly is_inventory_management_supported?: boolean;

  constructor(data: IProductPlanData) {
    if (data.id) {
      this.id = data.id;
    }

    this.max_active_users = data.max_active_users ?? 1;

    this.is_crm_supported = data.is_crm_supported ?? false;

    this.is_inventory_management_supported =
      data.is_inventory_management_supported ?? false;
  }

  validate(): boolean {
    //TODO: Add Validators
    throw new Error('Method not implemented.');
  }
}

export class ProductPrice implements IProductPrice {
  readonly id?: number;
  price: number;
  frequency: number;

  constructor(data: IProductPriceData) {
    if (data.id) {
      this.id = data.id;
    }

    this.price = data.price;
    this.frequency = data.frequency;
  }

  validate(): boolean {
    //TODO: Add Validators
    throw new Error('Method not implemented.');
  }
}

export class Product implements IProduct {
  readonly id?: number;
  name: string;
  description: string;
  prices: IProductPrice[] = [];
  plan: IProductPlan | null = null;

  readonly created_at?: Date | string;
  readonly updated_at?: Date | string;

  constructor(data: IProductData) {
    if (data.id) {
      this.id = data.id;
    }

    this.name = data.name;
    this.description = data.description ?? '';

    if (data.plan) {
      this.plan = new ProductPlan(data.plan) ?? null;
    }

    if (data.prices) {
      this.prices = data.prices.map((price) => new ProductPrice(price));
    }

    if (data.created_at) {
      this.created_at = data.created_at;
    }

    if (data.updated_at) {
      this.updated_at = data.updated_at;
    }
  }

  getMonthlyPrice(): number | undefined {
    const priceData = this.prices.find(
      (price) => price.frequency === ProductPriceFrequencyOptions.Monthly
    );
    if (!priceData) {
      return undefined;
    }

    return priceData.price;
  }

  getYearlyPrice(): number | undefined {
    const priceData = this.prices.find(
      (price) => price.frequency === ProductPriceFrequencyOptions.Yearly
    );
    if (!priceData) {
      return undefined;
    }

    return priceData.price;
  }

  validate(): boolean {
    //TODO: Add Validators
    throw new Error('Method not implemented.');
  }
}

export class ProductPriceFieldError implements IProductPriceFieldError {
  readonly price: string[] = [];
  readonly frequency: string[] = [];

  constructor(data?: IProductPriceFieldErrorData) {
    if (data) {
      if (data.frequency) {
        this.frequency = data.frequency;
      }

      if (data.price) {
        this.price = data.price;
      }
    }
  }

  clearErrors(): void {
    this.frequency.splice(0, this.frequency.length);
    this.price.splice(0, this.price.length);
  }

  static isProductPriceFieldError(data: IProductPriceFieldErrorData): boolean {
    if (data.frequency && Array.isArray(data.frequency)) {
      return true;
    } else if (data.price && Array.isArray(data.price)) {
      return true;
    }
    return false;
  }
}

export class ProductFieldError implements IProductFieldError {
  readonly name: string[] = [];
  readonly description: string[] = [];
  readonly prices: IProductPriceFieldError[] = [];

  constructor(data?: IProductFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }

      if (data.description) {
        this.description = data.description;
      }

      if (data.prices) {
        this.prices = data.prices.map(
          (price) => new ProductPriceFieldError(price)
        );
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
    this.description.splice(0, this.description.length);
    this.prices.splice(0, this.prices.length);
  }

  static isProductFieldError(data: IProductFieldErrorData): boolean {
    if (data.name && Array.isArray(data.name)) {
      return true;
    } else if (data.description && Array.isArray(data.description)) {
      return true;
    } else if (data.prices && Array.isArray(data.prices)) {
      return true;
    }
    return false;
  }
}
