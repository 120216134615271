
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { Warehouse } from '@/models/inventory';
import { ErrorManager } from '@/models/error';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';

export default mixins(auth).extend({
  data() {
    return {
      title: 'Warehouse',
      search: '',
      errorMessage: '',
      errorFlag: false,
      loading: false,
      deleteWarehouseDialog: false,
      selectedWarehouse: new Warehouse()
    };
  },
  computed: {
    ...mapState('warehouseStore', ['warehouse']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    canCreateWarehouse(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Warehouse #',
          width: '130px',
          value: 'identifier'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Description',
          width: '100px',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Address',
          sortable: false,
          value: 'address'
        },
        {
          text: 'Phone Number',
          sortable: false,
          value: 'phone'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //removes actions column
      }

      return headerList;
    }
  },
  methods: {
    ...mapActions({
      fetchWarehouses: 'warehouseStore/fetchWarehouses',
      deleteWarehouse: 'warehouseStore/deleteWarehouse'
    }),

    /**
     * open  dialog
     *
     *
     */
    openWarehouseDialog(isEdit = false, instance: Warehouse) {
      (this as any).$refs.warehouseDialog.open(isEdit, instance);
    },

    openDeleteWarehouseDialog(warehouse: Warehouse) {
      this.selectedWarehouse = warehouse;
      this.deleteWarehouseDialog = true;
    },

    deleteWarehouseAction() {
      this.deleteWarehouse(this.selectedWarehouse.id).finally(
        () => (this.deleteWarehouseDialog = false)
      );
    },

    goToWarehouseDetail(warehouse: Warehouse) {
      return this.$router.push({
        name: 'warehouse-detail',
        params: { warehouseId: String(warehouse.id) }
      });
    }
  },
  async mounted() {
    this.loading = true;
    this.fetchWarehouses()
      .then(() => (this.loading = false))
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
  }
});
