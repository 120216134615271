
import Vue from 'vue';
import mixins from '@/utils/mixins';
import { orderBy, cloneDeep } from 'lodash';
import { States } from '@/models/task';
import { mapState, mapActions } from 'vuex';
import { parseColor, isString } from '@/utils/helpers';
import { ErrorManager } from '@/models/error';

const EMTPYLIST = Object.freeze({
  name: '',
  order: 0,
  color: '#FFFFFF',
  is_done: false
});

export default mixins(Vue).extend({
  name: 'TaskCreateForm',

  components: {
    draggable: () => import('vuedraggable')
  },

  props: {
    useActiviator: {
      type: Boolean,
      default: false
    },
    noSlot: Boolean
  },

  data: () => ({
    dialog: false,
    valid: false,
    menu: false,
    editable: true,
    isDragging: false,
    delayedDragging: false,

    errorMessage: '',
    orderQuery: 'order',

    labels: {
      title: 'Edit Column'
    },
    list: {
      name: '',
      order: 0,
      color: '#FFFFFF',
      is_done: false
    } as States
  }),

  computed: {
    ...mapState({
      taskLoading: (state: any) => state.tasks.loading,
      tasks: (state: any) => state.tasks.tasks,
      states: (state: any): States[] => state.states.states
    }),

    computedStates(): any[] {
      return orderBy<States>(cloneDeep(this.states), [this.orderQuery], 'asc');
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    dragOptions(): object {
      return {
        animation: 1,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      };
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.list.order === 0
          ? (this.list.order =
              Math.max(
                ...this.states.map(function (o) {
                  return o.order!;
                })
              ) + 1)
          : null;
      }
    }
  },

  methods: {
    ...mapActions({
      updateState: 'states/updateState'
    }),

    open(resource?: any) {
      if (resource) {
        this.list = resource;
      }
      this.dialog = true;
    },

    cancel() {
      this.dialog = false;

      this.reset();
      this.resetValidation();
      this.clearData();
      this.$emit('cancel');
    },

    async clearData() {
      await setTimeout(() => {
        this.list = Object.assign(this.list, EMTPYLIST);
      }, 250);
    },

    save() {
      this.$emit('save', this.list);

      window.requestAnimationFrame(() => {
        this.$nextTick(() => {
          this.list = {
            id: 0,
            name: '',
            order: 0,
            color: '#FFFFFF',
            is_done: false
          };
        });
        this.resetValidation();
        this.dialog = false;
      });
    },

    reset() {
      (this as any).$refs.createForm.reset();
    },

    resetValidation() {
      (this as any).$refs.createForm.resetValidation();
    },

    onMove(event: any) {
      const draggedElement = event.draggedContext.element;
      //   this.task = draggedElement;
    },

    startList(val: any) {
      this.isDragging = true;
    },

    updateColumn(event: any) {
      const states: States[] = this.states.map(function (
        state: States,
        index: number
      ) {
        state.order = index + 1;
        return state;
      });

      states.forEach((state) =>
        this.updateState(state).catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
      );
    },

    getFontColor(color: string) {
      const COLOR_BLACK = 'black';
      const COLOR_WHITE = 'white';
      if (isString<string>(color)) {
        const { r, g, b } = parseColor(color);
        const luminance = Math.sqrt(
          0.241 * Math.pow(r, 2) +
            0.691 * Math.pow(g, 2) +
            0.068 * Math.pow(b, 2)
        );
        if (luminance >= 130) {
          return COLOR_BLACK;
        } else {
          return COLOR_WHITE;
        }
      } else {
        return COLOR_BLACK;
      }
    }
  }
});
