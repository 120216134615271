import {
  Timesheet,
  IFetchTimesheetsActionPayload,
  ITimesheetPaginatedListData
} from '@/models/timesheet';
import { TimesheetService } from '@/services/timesheets';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
const timesheetService = new TimesheetService();

export const state = {
  count: 0 as number,
  timesheets: [] as Timesheet[]
};

type TimesheetState = typeof state;

export const mutations: MutationTree<TimesheetState> = {
  ADD_TIMESHEET(state, payload: Timesheet) {
    state.timesheets.push(payload);
  },
  SET_TIMESHEETS(state, payload: Timesheet[]) {
    state.timesheets = payload;
  },
  UPDATE_TIMESHEET(state, payload: Timesheet) {
    const index = state.timesheets.findIndex(
      (timesheet: Timesheet) => timesheet.id === payload.id
    );
    state.timesheets.splice(index, 1, payload);
  },
  REMOVE_TIMESHEET(state, payload: number) {
    const index = state.timesheets.findIndex(
      (timesheet: Timesheet) => timesheet.id === payload
    );
    state.timesheets.splice(index, 1);
  },

  SET_COUNT(state, payload: number): void {
    state.count = payload;
  },

  INCREMENT_COUNT(state): void {
    state.count += 1;
  },

  DECREMENT_COUNT(state): void {
    state.count -= 1;
  }
};

export const actions: ActionTree<TimesheetState, TimesheetState> = {
  createTimesheet: async ({ commit, dispatch }, payload: Timesheet) => {
    return timesheetService
      .createTimesheet(payload)
      .then((timesheet: Timesheet) => {
        commit('ADD_TIMESHEET', timesheet);
        commit('INCREMENT_COUNT');
        return timesheet;
      });
  },

  fetchTimesheets: async (
    { commit, dispatch },
    payload?: IFetchTimesheetsActionPayload
  ) => {
    return timesheetService
      .fetchTimesheets(payload)
      .then((timesheets: ITimesheetPaginatedListData) => {
        commit('SET_TIMESHEETS', timesheets.results);
        commit('SET_COUNT', timesheets.count);
      });
  },

  fetchTimesheet: async ({ commit, state, dispatch }, payload: number) => {
    return timesheetService
      .fetchTimesheet(payload)
      .then((timesheet: Timesheet) => {
        const timesheets = [...state.timesheets];
        const index = timesheets.findIndex((t: Timesheet) => t.id === payload);
        if (index === -1) {
          commit('ADD_TIMESHEET', timesheet);
          commit('INCREMENT_COUNT');
        } else {
          commit('UPDATE_TIMESHEET', timesheet);
        }
      });
  },

  updateTimesheet: async ({ commit, dispatch }, payload: Timesheet) => {
    return timesheetService
      .updateTimesheet(payload.id!, payload)
      .then((timesheet: Timesheet) => {
        const index = state.timesheets.findIndex(
          (timesheet: Timesheet) => timesheet.id === payload.id
        );
        if (index === -1) {
          commit('ADD_TIMESHEET', timesheet);
          commit('INCREMENT_COUNT');
        } else {
          commit('UPDATE_TIMESHEET', timesheet);
        }
        return timesheet;
      });
  },

  deleteTimesheet: async ({ commit, dispatch }, payload: number) => {
    return timesheetService.deleteTimesheet(payload).then(() => {
      commit('REMOVE_TIMESHEET', payload);
      commit('DECREMENT_COUNT');
    });
  },

  setTimesheetStatus: async (
    { commit, dispatch },
    { id, status }: { id: number; status: number }
  ) => {
    return timesheetService
      .setStatus(id, status)
      .then((timesheet: Timesheet) => {
        const index = state.timesheets.findIndex(
          (t: Timesheet) => t.id === timesheet.id
        );
        if (index === -1) {
          commit('ADD_TIMESHEET', timesheet);
          commit('INCREMENT_COUNT');
        } else {
          commit('UPDATE_TIMESHEET', timesheet);
        }
      });
  },

  exportTimesheet: async (
    { commit, dispatch },
    payload: IFetchTimesheetsActionPayload
  ) => {
    return timesheetService.exportTimesheet(
      payload?.employee,
      payload?.state,
      payload?.occurrence_before,
      payload?.occurrence_after,
      payload?.in_progress
    );
  }
};

export const getters: GetterTree<TimesheetState, TimesheetState> = {
  getTimesheets: (state: TimesheetState): Timesheet[] => {
    return state.timesheets.map((timesheet) => new Timesheet(timesheet));
  },
  getTimesheetCount(state: TimesheetState): number {
    return state.count;
  },
  getTimesheetById:
    (state: TimesheetState) =>
    (id: number): Timesheet | undefined => {
      const timesheet = state.timesheets.find(
        (timesheet) => timesheet.id === id
      );
      if (timesheet) {
        return new Timesheet(timesheet);
      }
      return undefined;
    }
};
