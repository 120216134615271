import { TwitterService } from '@/services/social';

const twitterService = new TwitterService();

export const state = {
  loading: false
};
export const mutations = {
  SET_LOADING(state: any, payload: false) {
    state.loading = payload;
  }
};

export const actions = {
  sendTweet: async ({ dispatch, commit }: any, payload: string) => {
    commit('SET_LOADING', true);
    await new Promise<void>((resolve, reject) => {
      twitterService
        .notify(payload)
        .then((res: any) => {
          console.log({ res });
          resolve();
        })
        .catch((err: any) => {
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  }
};
