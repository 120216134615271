
import { mapActions, mapGetters } from 'vuex';

import { EmployeeUser, IEmployee, IEmployeeUser } from '@/models';
import { ErrorManager } from '@/models/error';

import { DataTableHeader } from 'vuetify';
import { isObject } from 'lodash';

import { defaultFilter, getObjectValueByPath } from '@/utils/helpers';

import mixins from '@/utils/mixins';
import functions from '@/mixins/functions';
import ErrorAlert from '../common/ErrorAlert.vue';

enum EmployeeView {
  All = -1,
  Active = 1,
  InActive = 2
}

export default mixins(functions).extend({
  name: 'EmployeeList',

  components: { ErrorAlert },

  data() {
    return {
      loading: false,
      errorMessage: '',
      errorMessageDetail: '',
      headers: [
        {
          text: 'Employee',
          sortable: false,
          align: 'start',
          value: 'user'
        },
        {
          text: 'Role',
          sortable: true,
          align: 'start',
          value: 'role'
        },
        {
          text: 'Phone',
          sortable: false,
          align: 'start',
          value: 'phone'
        },
        {
          text: 'Rate',
          sortable: false,
          align: 'start',
          value: 'rate'
        },
        {
          text: 'Attachments',
          sortable: false,
          align: 'end',
          value: 'attachments'
        }
      ] as DataTableHeader<IEmployee>[],
      views: [
        { text: 'All', value: EmployeeView.All },
        { text: 'Active', value: EmployeeView.Active },
        { text: 'Inactive', value: EmployeeView.InActive }
      ],
      activeView: EmployeeView.Active,

      sortBy: 'id',
      sortDesc: true,
      params: {
        search: this.$route.query.search as string,
        page: Number(this.$route.query.page ?? 1)
      }
    };
  },

  computed: {
    ...mapGetters('employee', {
      employees: 'getEmployees'
    }),

    filteredEmployeeList(): IEmployee[] {
      return this.employees.filter((employee: IEmployee) => {
        switch (this.activeView) {
          case EmployeeView.Active:
            return (employee.user as EmployeeUser).is_active == true;
          case EmployeeView.InActive:
            return (employee.user as EmployeeUser).is_active == false;
          case EmployeeView.All:
          default:
            return true;
        }
      });
    }
  },

  watch: {
    params: {
      handler() {
        this.clearAllErrors();
        // update route search query param to save current query
        this.debounce(this.setRoutewithSearchParam)();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchEmployees: 'employee/fetchEmployees',
      deleteEmployee: 'employee/deleteEmployee'
    }),

    goToEmployee(employee: IEmployee) {
      this.$router.push({
        name: 'employee-detail',
        params: { employeeId: String(employee.id) }
      });
    },

    clearAllErrors(): void {
      this.errorMessage = '';
      this.errorMessageDetail = '';
    },

    itemClass(item: IEmployee) {
      if (!(item.user as IEmployeeUser).is_active) {
        return 'item--disabled';
      }
    },

    setRoutewithSearchParam() {
      this.$router
        .replace({
          name: 'employees-list',
          query: this.params as Record<string, any>
        })
        .catch((e: Error) => e); //catch navigation duplication error
    },

    searchEmployees() {
      this.clearAllErrors();
      this.loading = true;
      /* this will update the URL query based on param values. */
      this.setRoutewithSearchParam();
      return this.fetchEmployees()
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.errorMessageDetail = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    customFilter(value: any, search: string | null, item?: any) {
      if (isObject(value)) {
        const found = Object.keys(value).some((key) =>
          defaultFilter(getObjectValueByPath(value, key), search)
        );
        return found;
      } else {
        return defaultFilter(value, search);
      }
    }
  },

  mounted() {
    // update `this.params` from the route query object.
    // omitting `search` and `page` as they are already set in the data vue option
    this.params = {
      search: (this.$route.query.search as string) ?? '',
      page: Number(this.$route.query.page ?? 1)
    };

    this.searchEmployees();
  }
});
