export type Unit = 'millis' | 'second' | 'minute' | 'hour';

export type UnitRecord<T> = Record<Unit, T>;
export type Differ = (a: Date, b: Date) => number;
export const diff: UnitRecord<Differ> = {
  millis: diffMilliseconds,
  second: diffSeconds,
  minute: diffMinutes,
  hour: diffHours
};
const MILLIS_IN_SECOND = 1000;

const SECONDS_IN_MINUTE = 60;

const MILLIS_IN_MINUTE = MILLIS_IN_SECOND * SECONDS_IN_MINUTE;

const MILLIS_IN_HOUR = MILLIS_IN_MINUTE * SECONDS_IN_MINUTE;

export function diffMilliseconds(a: Date, b: Date): number {
  return a.getTime() - b.getTime();
}
export function diffSeconds(a: Date, b: Date): number {
  return diffMilliseconds(a, b) / MILLIS_IN_SECOND;
}
export function diffMinutes(a: Date, b: Date): number {
  return diffMilliseconds(a, b) / MILLIS_IN_MINUTE;
}
export function diffHours(a: Date, b: Date): number {
  return diffMilliseconds(a, b) / MILLIS_IN_HOUR;
}

export function between(unit: Unit, dateA: Date, dateB: Date): number {
  return diff[unit](dateA, dateB);
}
