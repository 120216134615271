
import { EmployeeWorkTime } from '@/models/employeeWorkTime';
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  data() {
    return {
      MAX_WORK_HOURS_PER_DAY: 8 as number,
      employeesWorkTime: [] as EmployeeWorkTime[],
      headers: [
        {
          text: 'Employee Name',
          align: 'start',
          value: 'user',
          width: '192px'
        },
        { text: 'Days Scheduled', width: '92px', value: 'days' },
        { text: 'Hours Scheduled', width: '100px', value: 'hours_scheduled' }
      ]
    };
  },

  methods: {
    ...mapActions({
      fetchEmployeesWorkTime: 'calendar/fetchEmployeesWorkTime'
    }),

    /**
     * set color of progress bar
     *
     * @param hoursDone total amount of hours done by user
     * @param hoursAssigned total amount of hours assigned
     *
     *
     *
     */
    setProgressColor(hoursDone: number, hoursAssigned: number) {
      if (hoursDone < hoursAssigned) return 'green';
      else return 'amber';
    },

    /**
     * gets the employee work time from server and set it to object
     *
     * @param dateRange the start and end date selected by user
     *
     *
     */
    setEmployeeWorkTime(dateRange: string[]) {
      this.fetchEmployeesWorkTime({
        start: dateRange[0],
        end: dateRange[1]
      }).then((employees: any) => {
        this.employeesWorkTime = employees;
      });
    },

    /**
     * open employee schedule detail dialog
     *
     *
     */
    openDialog() {
      (this as any).$refs.employeeScheduleDetail.open(this.employeesWorkTime);
    }
  }
});
