import kebabCase from 'lodash/kebabCase';
import { RouteConfig } from 'vue-router';

interface Meta {
  requiresAuth?: boolean;
  public?: boolean;

  label?: string;
  onlyWhenLoggedOut?: boolean;
}

export function trailingSlash(str: string) {
  return str.endsWith('/') ? str : str + '/';
}
export function redirect(redirect: () => string) {
  return { path: '*', redirect };
}
export interface RouteType extends Omit<RouteConfig, 'component'> {
  component: string;
}
export function route(
  path: RouteType['path'],
  name?: RouteType['name'],
  children?: RouteType['children'],
  meta?: Meta,
  redirect?: any,
  file?: string
) {
  const folder = (file || `${kebabCase(name)}`).toLowerCase();

  return {
    // the chunk name this will apply the name of the component to the dist folder to seperate them
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ `@/views/${folder}/Index.vue`
      ),
    name,
    path,
    meta: meta,
    children,
    redirect,
    props: true
  };
}
export function subRoute(config: Partial<RouteType>): RouteConfig {
  return {
    component: () =>
      import(
        /* webpackChunkName: "component-[request]" */ `@/${config.component}.vue`
      ),
    name: config.name,
    path: trailingSlash(config.path!),
    meta: config.meta,
    children: config.children,
    redirect: config.redirect,
    props: config.props ?? true
  };
}

export function layout(
  path: string,
  name: string,
  children?: any[],
  redirect?: {},
  meta?: Meta
) {
  const folder = kebabCase(name);

  return {
    path,
    component: () =>
      import(
        /* webpackChunkName: "layout-[request]" */ `@/layouts/${folder}/Index.vue`
      ),
    props: true,
    children,
    redirect,
    meta
  };
}

export function root(children: any[]) {
  return [layout('', 'root', children)];
}
