
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
// mixins
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';

import { JobContactFieldError } from '@/models/calendar';
import { ErrorManager } from '@/models/error';
import { JobContact } from '@/models/job/job';
import { IJobContact, IJobContactData } from '@/models/job/job.types';
import { defaultFilter, getObjectValueByPath } from '@/utils/helpers';
import { IContact } from '@/models';
import { VAutocomplete, VForm } from 'vuetify/lib';

const baseMixins = mixins(auth);

interface Options extends InstanceType<typeof baseMixins> {
  $refs: {
    files: HTMLInputElement;
    form: InstanceType<typeof VForm> & {
      validate(): boolean;
    };
    contactSearch: InstanceType<typeof VAutocomplete> & {
      clearableCallback(): void;
    };
  };
}

export default baseMixins.extend<Options>({ functional: false }).extend({
  name: 'CalendarContactSection',

  props: {
    value: {
      type: Object as PropType<IJobContact | null>
    },

    fieldError: {
      type: JobContactFieldError,
      default: () => new JobContactFieldError()
    },

    isCreateCrmContact: {
      type: Boolean
    }
  },

  data() {
    return {
      searchContactsQuery: '',
      errorMessage: '',
      isCrmContactsLoading: false,
      contactHasData: false,
      rules: {
        required: (v: any) => !!v || 'This field is requied'
      },
      crmContactId: null as null | number
    };
  },

  watch: {
    value: {
      handler(value: IJobContactData | null) {
        // when loading from a list the data is not going to be an
        // instance of JobContact but only the object structure
        // best to construct a new instance of JobContact regardless
        if (value) {
          this.contactHasData = Object.values(value as IJobContact).some(
            (x) => !!x
          );

          if (this.contactHasData) {
            this.$refs.form.validate();
          }
        }
      },
      deep: true
    },

    searchContactsQuery: {
      handler() {
        // Items have already been loaded
        if (this.contacts.length > 0) return;

        // Items have already been requested
        if (this.isCrmContactsLoading) return;

        // Clear error message on search callback
        this.errorMessage = '';
        this.isCrmContactsLoading = true;
        this.fetchContacts()
          .catch((err: any) => {
            this.errorMessage = ErrorManager.extractApiError(err);
          })
          .finally(() => {
            this.isCrmContactsLoading = false;
          });
      }
    }
  },

  computed: {
    ...mapGetters({ getContacts: 'contactV2/getContacts' }),

    internalValue: {
      get(): unknown {
        return this.value;
      },
      set(val: any) {
        // hack for passing multiple function on the emit values
        this.$emit('input', val);
      }
    },

    contacts(): IContact[] {
      return this.getContacts;
    },

    isCrmSupported(): boolean {
      return this.subscription.contract?.is_crm_supported ?? false;
    },

    isCreateContact: {
      get(): boolean {
        return this.isCreateCrmContact;
      },
      set(value: boolean) {
        this.$emit('update:isCreateCrmContact', value);
      }
    }
  },

  methods: {
    ...mapActions({ fetchContacts: 'contactV2/fetchContacts' }),

    clearContact() {
      this.internalValue = null;
      this.isCreateContact = false;
    },

    createContact() {
      this.$refs.contactSearch.clearableCallback();
      this.crmContactId = null;
      this.isCreateContact = true;
      this.searchContactsQuery = '';
      this.internalValue = new JobContact();
    },

    addSelectedContact(contact?: IContact) {
      if (contact) {
        this.crmContactId = contact.id!;
        this.internalValue = new JobContact({
          first_name: contact.name?.first ?? '',
          last_name: contact.name?.last ?? '',
          email: contact.email ?? '',
          phone: contact.phone ?? ''
        });
        this.isCreateContact = false;
      }
      // await next frame to call validation
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },

    onPhoneNumberInput(
      _formattedNumber: string,
      { number }: { number: { international: string } }
    ): void {
      this.internalValue.phone = number.international;
    },

    contactsFilter: (item: any, queryText: string): boolean => {
      const filterList = [
        'name.first',
        'name.last',
        'title',
        'email',
        'phone',
        'company.name',
        '_full_name',
        '_fullname'
      ];
      // added proxy filter and value keys to filter by
      item['_full_name'] = `${item.name?.first} ${item.name?.last}`;
      item['_fullname'] = `${item.name?.first}${item.name?.last}`;
      return filterList.some((key) =>
        defaultFilter(getObjectValueByPath(item, key), queryText, item)
      );
    }
  }
});
