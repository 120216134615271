
import mixins from '@/utils/mixins';
import vcalendar from '@/mixins/calendar';
import TimelineDayIntervalMixin from '@/mixins/timeline/TimelineDayIntervalMixin';
import { CalendarTimestamp } from 'vuetify';
import { convertToUnit, waitForElm } from '@/utils/helpers';
import { PropType } from 'vue';
import VNodes from './VNodes.vue';
import moment from 'moment';
import { CalendarOccurrence } from '@/models/calendar';
import { genStartEndTime, roundTime } from '@/utils/dateHelpers';
import functions from '@/mixins/functions';

import {
  IJobData,
  IJobOccurrenceData,
  IJobTimelineOccurrencesData,
  JobDeleteType,
  JobStateType
} from '@/models/job/index';
import { getNameInitials } from '@/utils/helpers';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

const TOTAL_MINUTES_IN_DAY = 1440;
const TOTAL_HOURS_IN_DAY = 24;
const DEFAULT_YAXIS_POS = 30;
const HALF_HOUR = 30;
const QUARTER_HOUR = 14.75; // offset by 0.25 to take into account the div spaces between slots
const DEFAULT_TIMELINE_HEIGHT = 60;
const HEIGHT_OFFSET = 6;
const REFRESH_TIMER_INTERVAL = 15 * 60 * 1000; //15 minutes;

export type CurrentTimeMarker = {
  height: string;
  left: string;
};

export default mixins(vcalendar, TimelineDayIntervalMixin, functions).extend({
  components: { VNodes },

  name: 'TimelineDay',

  props: {
    canCreateEvent: {
      type: Boolean
    },
    canUpdateEvent: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean
    },
    timeline: {
      type: Array as PropType<IJobTimelineOccurrencesData[]>,
      default: () => []
    }
  },

  data() {
    return {
      QUARTER_HOUR,
      height: 40,
      currentTimeIndicatorHeight: 0,

      widthOffset: 0, //the offset of the timeline pane width with respect to the screen size
      intervalWidthOffset: 0, //the offset of each individual slot with respect the widthOffset Value
      mousePositionOffset: 0, //the offset off the mouse position with respect to the screen size

      timelineLoaded: false,
      showCurrentTimeMarker: false,
      didMouseMove: false,

      timeMarkerCoordinates: {} as CurrentTimeMarker,

      timelineEvent: [] as IJobTimelineOccurrencesData[],
      createdJobInstance: null as IJobOccurrenceData | null,
      selectedJobInstance: null as IJobOccurrenceData | null,
      originalDragJobInstance: null as IJobOccurrenceData | null,
      jobInstance: null as IJobOccurrenceData | null
    };
  },

  computed: {
    isToday(): boolean {
      return this.focus == moment(new Date()).format('YYYY-MM-DD');
    }
  },

  watch: {
    intervalHeight: {
      handler(value) {
        this.height += value;
      },
      immediate: true
    },
    days: {
      handler(value: CalendarTimestamp[]) {
        //days property in v-calendar cannot be manipulated from here so we have to store it locaaly
        if (!this.timelineDays[0].showtimelineWeekDate) {
          this.timelineDays = value;
        } else {
          //initialize the checker value to false and also triggers the timelineDay watcher
          this.timelineDays[0].showtimelineWeekDate = false;
        }
      },
      deep: true
    },
    focus: {
      handler(value) {
        if (value) {
          this.timelineDays[0].date = moment(value).format('YYYY-MM-DD');
          this.timelineDays[0].present =
            moment().format('YYYY-MM-DD') == this.timelineDays[0].date;
          this.updateCalendarTimestamp(this.timelineDays[0]);
        }
      }
    },
    timelineDays: {
      handler(value) {
        this.setFocus(value[0].date);
        this.genTimelineJobOccurrennces();
        this.showCurrentTimeMarker = false;
      },
      deep: true
    },
    timeline: {
      handler(value) {
        this.timelineEvent = value;

        if (this.isToday) {
          this.genTimerForCurrentTimeMarker(); //start the interval timer refresh for marker
          this.debounce(this.genCurrentTimeMarker, 500)(); //waits to create the time marker to ensure that all user height is loaded
        }
      },
      deep: true
    }
  },

  async mounted() {
    this.$nextTick().then(() => {
      this.onResize();
      this.timelineLoaded = true;
      this.updateCalendarTimestamp(this.timelineDays[0]);
    });
  },

  methods: {
    ...mapActions('calendarEvent', ['getCalendarEventInstanceForNewEvent']),

    roundTime,
    getNameInitials,
    genStartEndTime,
    unit: convertToUnit,

    prev() {
      this.setFocus(
        moment(this.timelineDays[0].date)
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      );
    },

    next() {
      this.setFocus(
        moment(this.timelineDays[0].date).add(1, 'day').format('YYYY-MM-DD')
      );
    },

    genTimelineJobOccurrennces() {
      this.$emit('genJobOccurrences');
    },

    genTimeScheduleText(time_scheduled_in_seconds: number): string {
      const hours = Math.floor(
        moment.duration(time_scheduled_in_seconds, 'seconds').asHours()
      );
      const minutes = moment
        .duration(time_scheduled_in_seconds, 'seconds')
        .minutes();

      const minuteStr = minutes ? `${minutes}m` : '';

      return `${hours}h ` + minuteStr;
    },

    timelineHeightInterval(jobs: IJobOccurrenceData[]): number {
      const timelineHeight: number =
        jobs.length <= 1
          ? this.height
          : DEFAULT_TIMELINE_HEIGHT * jobs.length - 1;

      this.currentTimeIndicatorHeight = timelineHeight;

      return timelineHeight;
    },

    onResize() {
      this.scrollPush = this.getScrollPush();
    },

    getScrollPush(): number {
      const area = this.$refs.scrollArea as HTMLElement;
      const pane = this.$refs.pane as HTMLElement;

      this.widthOffset = 0;
      this.intervalWidthOffset = QUARTER_HOUR;

      if (pane.offsetWidth > TOTAL_MINUTES_IN_DAY) {
        //width offset for each slot hour
        this.widthOffset =
          (pane.offsetWidth - TOTAL_MINUTES_IN_DAY) / TOTAL_HOURS_IN_DAY;

        // width offset is divided in the 4 sub layers then added to quarter hhour to give it each slot siza
        this.intervalWidthOffset = Number(
          Number(QUARTER_HOUR + this.widthOffset / 4).toFixed(3)
        );
      }

      if (this.isToday) {
        this.genCurrentTimeMarker(); //updates time marker position
      }

      return area && pane ? area.offsetWidth - pane.offsetWidth : 0;
    },

    genTimerForCurrentTimeMarker() {
      const timeRefreshInterval = setInterval(() => {
        if (this.isToday) {
          this.genCurrentTimeMarker();
        }
      }, REFRESH_TIMER_INTERVAL);

      if (!this.isToday) {
        clearInterval(timeRefreshInterval);
      }
    },

    genCurrentTimeMarker() {
      let timeMarkerHeight: number = HEIGHT_OFFSET;

      this.timeMarkerCoordinates = {
        height: '0px',
        left: '0px'
      } as CurrentTimeMarker;

      for (let i = 0; this.timelineEvent.length > i; i++) {
        if (!document.getElementById(`attendee__${i}`)) {
          waitForElm(`#attendee__${i}`).then((element) => {
            timeMarkerHeight +=
              element instanceof HTMLElement ? element.offsetHeight : 0;
          });
        } else {
          timeMarkerHeight += document.getElementById(
            `attendee__${i}`
          )!.offsetHeight;
        }
      }

      this.timeMarkerCoordinates = this.genXCoordinates();

      this.timeMarkerCoordinates.height = `${timeMarkerHeight}px`;

      this.showCurrentTimeMarker = true;
    },

    genXCoordinates(): CurrentTimeMarker {
      const now = moment(new Date());
      const differenceInMinutes = moment(now).diff(
        moment(now).startOf('day'),
        'minutes'
      );

      const left = Number(
        this.timeMarkerCoordinates.left.slice(
          0,
          this.timeMarkerCoordinates.left.length - 2
        )
      );

      const startTimeWidthOffset = Number(
        Number(differenceInMinutes / 15) *
          Number(this.intervalWidthOffset - QUARTER_HOUR)
      ).toFixed(0);

      this.timeMarkerCoordinates.left = `${
        left + differenceInMinutes + Number(startTimeWidthOffset)
      }px`;

      return this.timeMarkerCoordinates;
    },

    genTimedEvent(job: CalendarOccurrence | any, index: number): object {
      const jobStartTime = moment(new Date(job.date!.start!))
        .tz(job.date.timezone || 'UTC', true)
        .utc();

      const jobEndTime = moment(new Date(job.date!.end!))
        .tz(job.date.timezone || 'UTC', true)
        .utc();

      const startOfDay = moment(this.timelineDays[0].date)
        .startOf('day')
        .utc(true);

      const startTimeInMinutes = jobStartTime.isBefore(startOfDay)
        ? 0
        : moment.duration(jobStartTime.diff(startOfDay)).asMinutes();

      const endTimeInMinutes =
        moment.duration(jobEndTime.diff(startOfDay)).asMinutes() >
        TOTAL_MINUTES_IN_DAY
          ? TOTAL_MINUTES_IN_DAY
          : moment.duration(jobEndTime.diff(startOfDay)).asMinutes();

      const startTimeOnXAxis = startTimeInMinutes;

      const jobOnYAxis = index * DEFAULT_YAXIS_POS;

      const startTimeWidthOffset = Number(
        Number(startTimeOnXAxis / 15) *
          Number(this.intervalWidthOffset - QUARTER_HOUR)
      ).toFixed(0);

      const endTimeWidthOffset = Number(
        Number(endTimeInMinutes / 15) *
          Number(this.intervalWidthOffset - QUARTER_HOUR)
      ).toFixed(0);

      const timeDelta =
        Number(endTimeWidthOffset) +
        endTimeInMinutes -
        (Number(startTimeWidthOffset) + startTimeOnXAxis);

      const jobStyle =
        job.state == JobStateType.DRAFT
          ? {
              top: `${jobOnYAxis}px`,
              left: `${startTimeOnXAxis + Number(startTimeWidthOffset)}px`,
              width: `${timeDelta}px`,
              background: 'white',
              'border-color': `${job.color} !important`,
              'border-style': 'dashed !important',
              'border-width': '1px !important',
              color: 'black'
            }
          : {
              top: `${jobOnYAxis}px`,
              left: `${startTimeOnXAxis + Number(startTimeWidthOffset)}px`,
              width: `${timeDelta}px`,
              background: job.color,
              color: 'white'
            };

      return jobStyle;
    },

    genStartTimePositionOffset(mouseEvent: MouseEvent | any): number {
      const positionOffset = Number(
        Number(mouseEvent.layerX / this.intervalWidthOffset)
      ).toFixed(0);

      const startTimePosition =
        this.widthOffset == 0
          ? mouseEvent.layerX
          : Number(positionOffset) * QUARTER_HOUR;

      return startTimePosition;
    },

    startDragEvent(timeline: IJobOccurrenceData) {
      this.selectedJobInstance = timeline;
      this.originalDragJobInstance = cloneDeep(timeline);
      this.createdJobInstance = null;
    },

    async startDrag(
      mouseEvent: MouseEvent | any,
      day: string,
      timeline: IJobTimelineOccurrencesData
    ) {
      mouseEvent.preventDefault();

      this.selectedJobInstance = null;

      const startTimePosition = this.genStartTimePositionOffset(mouseEvent);

      const dragDate = moment(day)
        .add(startTimePosition, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');

      const startTimeDelta = moment(dragDate).minutes();

      const start =
        startTimeDelta >= HALF_HOUR
          ? moment(dragDate).startOf('hours').add(HALF_HOUR, 'minute').valueOf()
          : moment(dragDate).startOf('hours').valueOf();

      const end =
        startTimeDelta >= HALF_HOUR
          ? moment(start).add(HALF_HOUR, 'minute').valueOf()
          : moment(start).startOf('hours').add(HALF_HOUR, 'minute').valueOf();

      this.createdJobInstance = await this.getCalendarEventInstanceForNewEvent({
        start,
        end
      });

      this.createdJobInstance!.title = 'No Title';

      const index = this.timelineEvent.findIndex(
        (data) => data.user == timeline.user
      );

      this.createdJobInstance!.participants.push({ attendee: timeline.user });

      if (index != -1) {
        this.timelineEvent[index].jobs?.push(this.createdJobInstance!);
      }
    },

    mouseMove(mouseEvent: MouseEvent | any, mouseOverSlot = false) {
      const leftButton = mouseEvent.buttons === 1;
      mouseEvent.preventDefault();
      this.didMouseMove = false;

      if (leftButton) {
        this.didMouseMove = true;
        this.moveEvent(mouseEvent, mouseOverSlot);
      }
    },

    moveEvent(mouseEvent: MouseEvent | any, mouseOverSlot: boolean) {
      const startTimePosition = this.genStartTimePositionOffset(mouseEvent);

      // if a existing job was selected to be moved
      if (this.selectedJobInstance != null) {
        const startDateUTC = moment(
          new Date(this.selectedJobInstance!.date.start)
        ).utc();
        const endDateUTC = moment(
          new Date(this.selectedJobInstance!.date.end)
        ).utc();

        const startDate = moment
          .duration(
            moment(startDateUTC).diff(moment(startDateUTC).startOf('day'))
          )
          .asMinutes();

        const delta = moment(endDateUTC).diff(
          moment(startDateUTC),
          'milliseconds'
        );

        const dragStartDateMins = moment(this.selectedJobInstance!.date.start)
          .add(startTimePosition - startDate, 'minutes')
          .valueOf();

        const roundedDragDateTime = this.roundTime(
          dragStartDateMins,
          15,
          false
        );

        const endDate = moment(roundedDragDateTime + delta).format(
          'YYYY-MM-DDTHH:mm:ss'
        );

        this.$set(
          this.selectedJobInstance!.date,
          'start',
          new Date(roundedDragDateTime)
        );
        this.$set(this.selectedJobInstance!.date, 'end', endDate);
      }

      // if a created job is being dragged for end time adjustment
      else if (this.createdJobInstance != null && mouseOverSlot) {
        const startDateUTC = moment(this.createdJobInstance!.date.start)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');

        const startDate = moment
          .duration(
            moment(startDateUTC).diff(moment(startDateUTC).startOf('day'))
          )
          .asMinutes();

        const endDate = moment(this.createdJobInstance!.date.start)
          .add(startTimePosition - startDate, 'minutes')
          .valueOf();

        const roundedEndDateTime = this.roundTime(endDate, 15, false);

        this.$set(this.createdJobInstance!.date, 'end', roundedEndDateTime);
      }
    },

    endDrag(mouseEvent: MouseEvent) {
      mouseEvent.preventDefault();
      if (this.createdJobInstance != null) {
        if (
          moment(this.createdJobInstance?.date.end).isAfter(
            moment(this.createdJobInstance?.date.start)
          )
        ) {
          this.$emit('add', {
            attendee: this.createdJobInstance!.participants[0].attendee,
            date: this.createdJobInstance!.date
          });
        }
        this.createdJobInstance = null;
      }

      if (this.selectedJobInstance != null) {
        const jobInstance = cloneDeep(this.selectedJobInstance);
        jobInstance.date!.end = moment(new Date(jobInstance.date!.end))
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');

        this.$emit(
          'eventDialog',
          {
            job: jobInstance,
            choice: JobDeleteType.SINGLE_PARTICIPANT
          },
          this.originalDragJobInstance!
        );
      }
    },

    async sendEventInvite(job: IJobData, create: boolean) {
      this.$emit('send-invite:event', job, create);
    },

    async sendOccurrenceInvite(job: IJobData) {
      this.$emit('send-invite:occurrence', job);
    },

    async edit(occurrence: IJobTimelineOccurrencesData) {
      this.$emit('edit', occurrence);
    },

    async deleteEventDialog(occurrence: IJobTimelineOccurrencesData) {
      this.$emit('delete', occurrence);
    }
  }
});
