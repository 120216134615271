
import Proxyable from '@/mixins/proxyable';
import { PropValidator } from 'vue/types/options';

export default Proxyable.extend({
  name: 'ConfirmationDialog',

  props: {
    errorMessages: {
      type: [String, Array],
      default: () => []
    } as PropValidator<string | string[]>,
    title: String,
    message: String,
    persistent: Boolean,
    titleClass: String,
    messageClass: String,
    width: {
      type: Number,
      default: 400
    }
  },

  computed: {
    isActionsSlotProvided(): boolean {
      return !this.$scopedSlots.actions;
    },

    isContentSlotProvided(): boolean {
      return !this.$scopedSlots.content;
    },

    internalErrorMessages(): string[] {
      return this.genInternalMessages(this.errorMessages);
    },

    errorOccurred(): boolean {
      return this.internalErrorMessages.length > 0;
    }
  },

  mounted() {
    if (this.isActionsSlotProvided) {
      console.warn(
        `${this.$options.name}: No actions slot provided, use scoped slot named 'actions'`
      );
    }
  },

  methods: {
    genInternalMessages(messages: string | string[]): string[] {
      // is case message is an empty string
      if (!messages) return [];
      else if (Array.isArray(messages)) return messages;
      else return [messages];
    }
  }
});
