
import Vue from 'vue';
export default Vue.extend({
  name: 'InfoSectionCard',
  data: () => ({
    items: ['Foo', 'Bar', 'Fizz', 'Buzz']
  }),
  components: {
    // quillEditor
  },
  props: {
    name: {
      type: String,
      default: null
    },
    itemsprop: {
      type: Array,
      Function,
      default: () => []
    },
    // itemsOptions: {
    itemValue: {
      type: [String, Array, Function],
      default: 'value'
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text'
    },
    // },
    primaryFunction: {
      type: Function,
      default: () => ({})
    },
    secondaryFunction: {
      type: Function,
      default: () => ({})
    }
  }
});
