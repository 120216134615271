export enum LogLevel {
  DEBUG,
  VERBOSE,
  INFO,
  WARN,
  ERROR,
  SILENT,
  FATAL
}

export type LogLevelString =
  | 'debug'
  | 'verbose'
  | 'info'
  | 'warn'
  | 'error'
  | 'silent'
  | 'fatal';

const LogLevelStringArray = [
  'debug',
  'verbose',
  'info',
  'warn',
  'error',
  'silent',
  'fatal'
];

const levelStringToEnum: { [key in LogLevelString]: LogLevel } = {
  debug: LogLevel.DEBUG,
  verbose: LogLevel.VERBOSE,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
  silent: LogLevel.SILENT,
  fatal: LogLevel.FATAL
};

export type LogHandler = (
  loggerInstance: Logger,
  logType: LogLevel,
  ...args: unknown[]
) => void;

/**
 * By default, `console.debug` is not displayed in the developer console (in
 * chrome). To avoid forcing users to have to opt-in to these logs twice
 * (i.e. once in the console), we are sending `DEBUG`
 * logs to the `console.log` function.
 */
const ConsoleMethod = {
  [LogLevel.DEBUG]: 'debug',
  [LogLevel.VERBOSE]: 'log',
  [LogLevel.INFO]: 'info',
  [LogLevel.WARN]: 'warn',
  [LogLevel.ERROR]: 'error',
  [LogLevel.FATAL]: 'error'
};

/**
 * The default log handler will forward DEBUG, VERBOSE, INFO, WARN, and ERROR
 * messages on to their corresponding console counterparts (if the log method
 * is supported by the current log level)
 */
const defaultLogHandler: LogHandler = (instance, logType, ...args): void => {
  if (logType < instance.logLevel) {
    return;
  }
  const now = instance.logTime
    ? ' (' + new Date().toISOString().substring(0, 19) + ')'
    : '';
  const method = ConsoleMethod[logType as keyof typeof ConsoleMethod];
  if (method) {
    let msg = '[' + LogLevelStringArray[logType].toUpperCase() + ']';
    console[method as 'log' | 'info' | 'warn' | 'error'](
      `${(msg += ' ' + instance.name)}${now + ':'}`,
      ...args
    );
  } else {
    throw new Error(
      `Attempted to log a message with an invalid logType (value: ${logType})`
    );
  }
};

/**
 * A container for all of the Logger instances
 */
export const instances: Logger[] = [];
/**
 * The default log level
 */
const defaultLogLevel: LogLevel = LogLevel.VERBOSE;

export class Logger {
  constructor(public name: string, public logTime: boolean = false) {
    /**
     * Capture the current instance for later use
     */
    instances.push(this);
  }

  private _logLevel = defaultLogLevel;

  get logLevel(): LogLevel {
    return this._logLevel;
  }

  set logLevel(val: LogLevel) {
    if (!(val in LogLevel)) {
      throw new TypeError(`Invalid value "${val}" assigned to \`logLevel\``);
    }
    this._logLevel = val;
  }

  // Workaround for setter/getter having to be the same type.
  setLogLevel(val: LogLevel | LogLevelString): void {
    this._logLevel = typeof val === 'string' ? levelStringToEnum[val] : val;
  }

  /**
   * The main (internal) log handler for the Logger instance.
   * Can be set to a new function in internal package code but not by user.
   */
  private _logHandler: LogHandler = defaultLogHandler;
  get logHandler(): LogHandler {
    return this._logHandler;
  }
  set logHandler(val: LogHandler) {
    if (typeof val !== 'function') {
      throw new TypeError('Value assigned to `logHandler` must be a function');
    }
    this._logHandler = val;
  }

  /**
   * The functions below are all based on the `console` interface
   */

  debug(...args: unknown[]): void {
    this._logHandler(this, LogLevel.DEBUG, ...args);
  }
  log(...args: unknown[]): void {
    this._logHandler(this, LogLevel.VERBOSE, ...args);
  }
  info(...args: unknown[]): void {
    this._logHandler(this, LogLevel.INFO, ...args);
  }
  warn(...args: unknown[]): void {
    this._logHandler(this, LogLevel.WARN, ...args);
  }
  error(...args: unknown[]): void {
    this._logHandler(this, LogLevel.ERROR, ...args);
  }
  fatal(...args: unknown[]): void {
    this._logHandler(this, LogLevel.FATAL, ...args);
  }
}

export function setLogLevel(level: LogLevelString | LogLevel): void {
  instances.forEach((inst) => {
    inst.setLogLevel(level);
  });
}
