var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"participant-section"},[_c('div',{staticClass:"calendar-details-tabs ml-4",staticStyle:{"width":"100%"}},[_c('v-tabs',{staticClass:"vtabs",staticStyle:{"vtabs":"first-child {"}},[_c('v-tab',{staticClass:"text-capitalize"},[_vm._v("Employees")])],1)],1),_c('div',{staticClass:"calendar-details-content"},[_c('span',{staticClass:"pt-3 d-flex flex-column",staticStyle:{"flex":"1 1 auto","overflow":"hidden"}},[_c('div',{staticClass:"py-1 pl-4",staticStyle:{"position":"relative","width":"100%"}},[_c('v-autocomplete',{attrs:{"menu-props":{ closeOnContentClick: true },"items":_vm.searchParticipantList,"loading":_vm.participantsIsLoading,"search-input":_vm.searchParticipantsQuery,"return-object":"","cache-items":"","item-text":"first_name","item-value":"id","solo":"","flat":"","background-color":"grey lighten-4","hide-details":"auto","hide-selected":"","hide-no-data":"","error-messages":_vm.errorMessage,"label":"Add Employee","id":"participants-search"},on:{"update:searchInput":function($event){_vm.searchParticipantsQuery=$event},"update:search-input":function($event){_vm.searchParticipantsQuery=$event},"input":function($event){//clear errors whenever user types
            _vm.errorMessage.splice(0, _vm.errorMessage.length)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item',{on:{"click":function($event){return _vm.addSelectedAttendee(data.item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.first_name + ' ' + data.item.last_name)}})],1)],1)]}}])})],1),_c('div',{staticClass:"mt-2",staticStyle:{"flex":"1 1 auto","overflow-x":"hidden","overflow-y":"auto"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-list',{staticClass:"smooth-transition pl-4 pt-0"},[_c('v-slide-x-transition',{attrs:{"mode":"out-in","hide-on-leave":""}},[(_vm.internalValue.length > 1)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.internalValue.length)+" employees")])],1)],1):_vm._e()],1),_vm._l((_vm.internalValue),function(participant,i){return _c('v-list-item',{key:i,staticClass:"smooth-transition",attrs:{"dense":""}},[_c('v-list-item-avatar',{staticClass:"pa-0",class:{
                  'white--text': participant.attendee.is_active,
                  'primary--text': !participant.attendee.is_active
                },attrs:{"color":participant.attendee.is_active
                    ? 'primary'
                    : 'rgba(0, 0, 0, 0.18)'}},[(participant.attendee.avatar)?_c('v-img',{staticClass:"primary white--text",attrs:{"src":participant.attendee.avatar}}):_c('span',{staticClass:"body-1 white--text"},[_vm._v(" "+_vm._s(participant.attendee.first_name.charAt(0).toUpperCase())+_vm._s(participant.attendee.last_name.charAt(0).toUpperCase())+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{style:({
                    color: participant.attendee.is_active
                      ? 'rgba(0, 0, 0, 0.87)'
                      : 'rgba(0, 0, 0, 0.38)'
                  }),domProps:{"textContent":_vm._s(
                    participant.attendee.first_name +
                    ' ' +
                    participant.attendee.last_name
                  )}}),(!participant.attendee.is_active)?_c('v-list-item-subtitle',{staticClass:"caption"},[_c('v-chip',{staticClass:"white--text",attrs:{"x-small":"","color":"rgba(255, 82, 82, 0.700)"}},[_vm._v("Inactive")])],1):_vm._e()],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeParticipant(participant)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)})],2)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }