
// import calendarComp from '@/composition/schedule/calendar.composition'
import Vue from 'vue';

export default Vue.extend({
  props: {
    // OBJECTS
    selectedEvent: {
      type: Object,
      default: () => {}
    },
    defaultEvent: {
      type: Object,
      default: () => {
        return {
          participants: []
        };
      }
    },
    clientEvent: {
      type: Object,
      default: () => ({})
    },
    // FUNCTIONS
    createEvent: {
      type: Function,
      default: () => {}
    },
    deleteEvent: {
      type: Function,
      default: () => {}
    },
    saveEvent: {
      type: Function,
      default: () => {}
    },
    closeCard: {
      type: Function,
      default: () => {}
    },
    routeTo: {
      type: Function,
      default: () => {}
    },
    selectedElement: {
      type: [HTMLDivElement, HTMLSpanElement, HTMLElement],
      default: null
    },
    // BOOLEANS
    selectedOpen: {
      type: Boolean,
      default: false
    },
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    participants: [],
    searchParticipants: null,
    values: [{ id: '1', first_name: 'test name' }],
    // create
    type: 0,
    time: false,
    buttons: [
      {
        value: 0,
        icon: 'folder',
        text: 'Event'
      },
      {
        value: 1,
        icon: 'mdi-calendar',
        text: 'Schedule'
      },
      {
        value: 2,
        icon: 'mdi-folder',
        text: 'Reminder'
      }
    ],
    // DATA
    frequencyToLabel: {
      0: 'Yearly',
      1: 'Monthly',
      2: 'Weekly',
      3: 'Daily'
    },
    show: false,
    items: [
      {
        icon: 'mdi-solid',
        iconClass: 'lighten-1 primary--text',
        title: 'Schedule'
      },
      {
        icon: 'mdi-folder',
        iconClass: 'grey lighten-1 white--text',
        title: 'Reminder'
      },
      {
        icon: 'folder',
        iconClass: 'grey lighten-1 white--text',
        title: 'Event'
      }
    ]
  })
});
