import { IJobActions, JobContext } from './actions.types';
import {
  IJobDateOptions,
  IJobStateParams,
  IJobTimelineOccurrencesData
} from '@/models/job/index';

export const actions: IJobActions = {
  fetchJobTimelineOccurrences: function (
    context: JobContext,
    payload: IJobDateOptions
  ): Promise<void> {
    const promise = this.$services.job.fetchJobTimelineOccurrences(payload);
    context.commit('SET_LAST_PROMISE', promise);
    return promise.then((jobs: IJobTimelineOccurrencesData[]) => {
      if (promise == context.state.lastPromise) {
        context.commit('SET_JOBS', jobs);
        context.commit('SET_LAST_PROMISE', null);
      }
    });
  },
  updateJobState: function (
    context: JobContext,
    params: IJobStateParams
  ): Promise<void> {
    return this.$services.job.updateJobState(params);
  }
};
