export const FormRules = {
  data: () => ({
    rules: {
      required: (value: string) => !!value || 'Field Required.',
      counter: (value: string) => value.length <= 20 || 'Max 20 characters',
      email: (value: string) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      },
      size: (value: any) =>
        !value ||
        value.size < 2000000 ||
        'Avatar size should be less than 2 MB!'
      // startDateRule: (value )
    },
    computed: {
      match() {
        return (value: string) => !!value || 'Field Required.';
      }
    }
  })
};
