
import { Inventory, Item } from '@/models/inventory';
import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  data() {
    return {
      dialog: false,
      valid: false,
      enableEdit: false,

      selectedInventory: new Inventory(),

      //warehouse
      identifier: '' as any,

      //itemMaster
      itemMaster_number: '' as any,

      rules: {
        required: (v: any) => !!v || 'This is required',
        noneZero: (value: string) => {
          if (isNaN(parseInt(value, 10))) {
            return 'Must be a number';
          }
          return parseInt(value, 10) > 0 || 'Quantity cannot be less than 1.';
        }
      },
      errorMessage: '',
      fieldErrors: { warehouse: [], item: [] } as any
    };
  },
  computed: {
    ...mapState('warehouseStore', ['warehouse']),

    ...mapState('itemStore', ['item']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    defaultFieldErrors(): any {
      return { warehouse: [], item: [] };
    },

    warehouseList(): object[] {
      const list = [];
      for (let i = 0; this.warehouse.length > i; i++) {
        const warehouseObj = {
          name: this.warehouse[i].name,
          identifier: this.warehouse[i].identifier
        };
        list.push(warehouseObj);
      }
      return list;
    },

    itemMasterList(): object[] {
      const itemList = [];
      for (let i = 0; this.item.length > i; i++) {
        const itemObj = {
          name: this.item[i].name,
          identifier: this.item[i].identifier
        };
        itemList.push(itemObj);
      }
      return itemList;
    }
  },

  methods: {
    ...mapActions({
      createInventory: 'inventoryStore/createInventory',
      updateInventory: 'inventoryStore/updateInventory',
      fetchWarehouses: 'warehouseStore/fetchWarehouses',
      fetchItems: 'itemStore/fetchItems'
    }),

    open(isEdit = false, selectedInventory: Inventory) {
      this.enableEdit = isEdit;

      this.dialog = true;

      if (this.enableEdit) {
        this.identifier = selectedInventory.warehouse!.identifier;
        this.itemMaster_number = selectedInventory.item!.identifier;
        this.selectedInventory = cloneDeep(selectedInventory!);
      } else {
        (this.$refs.form as any).reset();
        this.selectedInventory.quantity = 0;
        this.identifier = '';
        this.itemMaster_number = '';
        this.selectedInventory = new Inventory();
        this.selectedInventory.item = new Item();
      }
    },

    setInventoryWarehouse() {
      for (let i = 0; this.warehouseList.length > i; i++) {
        if (this.warehouse[i].identifier == this.identifier) {
          this.selectedInventory.warehouse = this.warehouse[i];
          break;
        }
      }
    },

    setInventoryItemMaster() {
      if (this.itemMaster_number == null) {
        this.selectedInventory.item = new Item();
      }

      for (let i = 0; this.itemMasterList.length > i; i++) {
        if (this.item[i].identifier == this.itemMaster_number) {
          this.selectedInventory.item = this.item[i];
          break;
        }
      }
    },

    filterWarehouseObject(
      item: { name: string; identifier: string },
      queryText: string,
      itemText: any
    ) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.identifier
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    filterItemMasterObject(
      item: { name: string; identifier: string },
      queryText: string,
      itemText: any
    ) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.identifier
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    close() {
      this.clearErrors();
      (this.$refs.form as any).reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedInventory = new Inventory();
        this.selectedInventory.item = new Item();
        this.$emit('setData');
      });
    },

    clearErrors(): void {
      this.fieldErrors = this.defaultFieldErrors;
      this.errorMessage = '';
    },

    createNewInventory() {
      const inventoryObject = this.setInventoryObject();

      this.createInventory(inventoryObject)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.non_field_errors) {
            this.fieldErrors.warehouse = [
              'Item already exist in this warehouse'
            ];
            this.fieldErrors.item = ['Item already exist in this warehouse'];
          }

          if (error.response.data.warehouse) {
            this.fieldErrors.warehouse = error.response.data.warehouse;
          }

          if (error.response.data.item) {
            this.fieldErrors.item = error.response.data.item;
          }

          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    editSelectedInventory() {
      const inventoryObject = this.setInventoryObject();

      this.updateInventory(inventoryObject)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.non_field_errors) {
            this.fieldErrors.warehouse = [
              'Item already exist in this warehouse'
            ];
            this.fieldErrors.item = ['Item already exist in this warehouse'];
          }

          if (error.response.data.warehouse) {
            this.fieldErrors.warehouse = error.response.data.warehouse;
          }

          if (error.response.data.item) {
            this.fieldErrors.item = error.response.data.item;
          }

          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    setInventoryObject() {
      const inventory = {
        id: this.selectedInventory.id,
        warehouse: this.selectedInventory.warehouse!.id,
        item: this.selectedInventory.item!.id,
        quantity: this.selectedInventory.quantity,
        location: this.selectedInventory.location,
        notes: this.selectedInventory.notes
      };
      return inventory;
    }
  },
  mounted() {
    this.fetchWarehouses();
    this.fetchItems();
  }
});
