import {
  IProductData,
  IStripeSessionData,
  ProductPriceFrequencyOptions
} from '@/models';
import { IProductService } from './product.types';
import { AxiosInstance } from 'axios';

export class ProductService implements IProductService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/products/';
  }

  fetchProducts(): Promise<IProductData[]> {
    return this.axios
      .get<IProductData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchProduct(id: number): Promise<IProductData> {
    return this.axios
      .get<IProductData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createProduct(data: IProductData): Promise<IProductData> {
    return this.axios
      .post<IProductData>(`${this.endpoint}`, data)
      .then(({ data }) => data);
  }

  updateProduct(data: IProductData): Promise<IProductData> {
    return this.axios
      .patch<IProductData>(`${this.endpoint}${data.id}/`, data)
      .then(({ data }) => data);
  }

  deleteProduct(id: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }

  buyProduct(
    id: number,
    frequency: ProductPriceFrequencyOptions
  ): Promise<IStripeSessionData> {
    return this.axios
      .post<IStripeSessionData>(
        `${this.endpoint}${id}/create_checkout_session/`,
        null,
        {
          params: { frequency }
        }
      )
      .then(({ data }) => data);
  }
}
