
import { MovingItem } from '@/models/movingItem';
import dateFormat from '@/utils/dateFormat';
import { mapActions, mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { ErrorManager } from '@/models/error';
import { MovingInventory } from '@/models/movingInventory';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';

export default mixins(auth).extend({
  data() {
    return {
      success: false,
      loadingBtn: false,
      imageDialog: false,
      deleteMovingItemDialog: false,
      loading: false,
      snackbar: false,

      search: '',
      selectedImage: '' as any,
      errorMessage: '',

      selectedMovingItem: new MovingItem(),
      selectedMovingInventory: [] as MovingInventory[]
    };
  },

  computed: {
    ...mapState('movingItem', ['movingItem']),
    ...mapState('movingInventory', ['movingInventory']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    canAddItem(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Thumbnail',
          sortable: false,
          value: 'media'
        },
        {
          text: 'Item #',
          value: 'identifier'
        },
        {
          text: 'Description',
          sortable: false,
          width: '100px',
          value: 'description'
        },
        {
          text: 'Dimensions',
          sortable: false,
          value: 'dimensions'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location'
        },
        {
          text: 'Out Date',
          value: 'out_date'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //removes actions column
      }
      return headerList;
    },

    ...mapGetters({
      getMovingInventoryById: 'movingInventory/getMovingInventoryById'
    })
  },

  methods: {
    ...mapActions({
      fetchMovingItems: 'movingItem/fetchMovingItems',
      deleteMovingItem: 'movingItem/deleteMovingItem',
      fetchMovingInventory: 'movingInventory/fetchMovingInventory',
      resetState: 'movingItem/resetState'
    }),
    dateFormat,

    copyUrlLink() {
      this.loadingBtn = true;

      const Url = document.createElement('textarea');
      document.body.appendChild(Url);
      const textUrl = location.protocol + '//' + location.host;
      Url.innerHTML = `${textUrl}/shared-inventory/${this.$route.params.movingItemId}/shared/shared-item`;

      Url.select();
      document.execCommand('copy');
      document.body.removeChild(Url);

      this.snackbar = true;
      this.loadingBtn = false;
      this.success = true;
    },

    openDeleteMovingItemDialog(movingItem: MovingItem) {
      this.selectedMovingItem = movingItem;
      this.deleteMovingItemDialog = true;
    },

    deleteMovingItemAction() {
      this.deleteMovingItem(this.selectedMovingItem)
        .then(() => {
          this.deleteMovingItemDialog = false;
          this.setMovingInventoryTable();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    /**
     * open Image dialog
     *
     *
     */
    openImageDialog(item: MovingItem) {
      this.selectedImage = item.media;
      this.imageDialog = true;
    },

    openMovingInventory() {
      this.resetState();
      this.$router.push({
        name: 'moving-inventory-management'
      });
    },

    async tryGetOrFetchMovingInventory(inventoryId: string) {
      try {
        return this.fetchMovingInventory(inventoryId).then(() =>
          this.getMovingInventoryById(inventoryId)
        );
      } catch (error) {
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    },

    /**
     * open  dialog
     *
     *
     */
    openMovingItemDialog(isEdit = false, instance: MovingItem) {
      (this as any).$refs.movingItemDialog.open(isEdit, instance);
    },

    async setMovingInventoryTable() {
      const result = await this.tryGetOrFetchMovingInventory(
        this.$route.params.movingItemId
      );
      this.selectedMovingInventory = result;
    }
  },

  async mounted() {
    this.loading = true;

    this.setMovingInventoryTable();

    this.fetchMovingItems(this.$route.params.movingItemId)
      .then(() => {
        if (this.$route.params.action == 'Create-Item') {
          this.openMovingItemDialog(false, this.selectedMovingItem);
        }
      })
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  }
});
