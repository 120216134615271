import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const requireComponent = require.context(
  '@/components',
  true,
  /(index.ts)$|\.vue$/
);

requireComponent.keys().forEach((fileName) => {
  if (fileName !== './index.ts') {
    const componentName = upperFirst(
      camelCase(
        fileName
          .replace(/^\.\//, '')
          .replace(/\.\w+$/, '')
          .replace(/(\.\/|Index)/gi, '')
      )
    );

    Vue.component(componentName, (resolve) => {
      const componentConfig = requireComponent(fileName);

      resolve(componentConfig.default || componentConfig);
    });
  }
});
