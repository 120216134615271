import { IContactState } from '@/store/v2/contact';
import { IContactGetters } from './getters.types';
import { IContact, Contact } from '@/models';

export const getters: IContactGetters = {
  getContacts(state: IContactState): IContact[] {
    return state.contacts.map((data) => new Contact(data));
  },

  getContactById:
    (state: IContactState) =>
    (id: number): IContact | undefined => {
      const data = state.contacts.find((contact) => contact.id === id);
      if (!data) {
        return undefined;
      }

      return new Contact(data);
    },

  getContactByEmail:
    (state: IContactState) =>
    (email: string): IContact | undefined => {
      const data = state.contacts.find((contact) => contact.email === email);
      if (!data) {
        return undefined;
      }

      return new Contact(data);
    }
};
