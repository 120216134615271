<template>
  <common-group :item="item" />
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        text: '',
        icon: false
      })
    }
  }
};
</script>
