import { IContactData, IContactNoteData, IContactStatusData } from '@/models';
import { IContactService } from './contact.types';
import { AxiosInstance } from 'axios';
import { objectToFormData } from '@/utils/objectToFormData';

export class ContactService implements IContactService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/crm/contacts/';
  }

  fetchContacts(): Promise<IContactData[]> {
    return this.axios
      .get<IContactData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchContact(id: number): Promise<IContactData> {
    return this.axios
      .get<IContactData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createContact(data: IContactData): Promise<IContactData> {
    const formData = objectToFormData(data);

    return this.axios
      .post<IContactData>(`${this.endpoint}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  updateContact(data: IContactData): Promise<IContactData> {
    const formData = objectToFormData(data);

    return this.axios
      .patch<IContactData>(`${this.endpoint}${data.id}/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  deleteContact(id: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }

  updateContactStatus(id: number, state: number): Promise<any> {
    return this.axios
      .put<IContactStatusData>(`${this.endpoint}${id}/set_state/${state}/`)
      .then(({ data }) => data);
  }

  /** ##### Contact Note ##### **/
  fetchContactNotes(contactId: number): Promise<IContactNoteData[]> {
    return this.axios
      .get<IContactNoteData[]>(`${this.endpoint}${contactId}/notes/`)
      .then(({ data }) => data);
  }

  fetchContactNote(
    contactId: number,
    noteId: number
  ): Promise<IContactNoteData> {
    return this.axios
      .get<IContactNoteData>(`${this.endpoint}${contactId}/notes/${noteId}/`)
      .then(({ data }) => data);
  }

  createContactNote(
    contactId: number,
    data: IContactNoteData
  ): Promise<IContactNoteData> {
    return this.axios
      .post<IContactNoteData>(`${this.endpoint}${contactId}/notes/`, data)
      .then(({ data }) => data);
  }

  updateContactNote(
    contactId: number,
    data: IContactNoteData
  ): Promise<IContactNoteData> {
    return this.axios
      .patch<IContactNoteData>(
        `${this.endpoint}${contactId}/notes/${data.id}/`,
        data
      )
      .then(({ data }) => data);
  }

  deleteContactNote(contactId: number, noteId: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${contactId}/notes/${noteId}/`);
  }
}
