import { IOrganizationActions, OrganizationContext } from './actions.types';
import { IOrganizationData } from '@/models';
import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';

export const actions: IOrganizationActions = {
  fetchOrganizations(context: OrganizationContext): Promise<void> {
    return this.$services.organization
      .fetchOrganizations()
      .then((organizations: IOrganizationData[]) => {
        context.commit('SET_ORGANIZATIONS', organizations);
      });
  },

  fetchOrganization(
    context: OrganizationContext,
    payload: number
  ): Promise<void> {
    return this.$services.organization
      .fetchOrganization(payload)
      .then((organization: IOrganizationData) => {
        const index = context.state.organizations.findIndex(
          (v: IOrganizationData) => v.id === organization.id
        );
        if (index === -1) {
          context.commit('ADD_ORGANIZATION', organization);
        } else {
          context.commit('UPDATE_ORGANIZATION', organization);
        }
      });
  },

  createOrganization(
    context: OrganizationContext,
    payload: IOrganizationData
  ): Promise<void> {
    return this.$services.organization
      .createOrganization(payload)
      .then((organization: IOrganizationData) =>
        context.commit('ADD_ORGANIZATION', organization)
      );
  },

  updateOrganization(
    context: OrganizationContext,
    payload: IOrganizationData
  ): Promise<void> {
    return this.$services.organization
      .updateOrganization(payload)
      .then((organization: IOrganizationData) => {
        const index = context.state.organizations.findIndex(
          (v: IOrganizationData) => v.id === organization.id
        );
        if (index === -1) {
          context.commit('ADD_ORGANIZATION', organization);
        } else {
          context.commit('UPDATE_ORGANIZATION', organization);
        }
      });
  },

  deleteOrganization(
    context: OrganizationContext,
    payload: number
  ): Promise<void> {
    return this.$services.organization.deleteOrganization(payload).then(() => {
      context.commit('REMOVE_ORGANIZATION', payload);
    });
  },

  fetchOrganizationUsers(
    context: OrganizationContext,
    payload: number
  ): Promise<void> {
    return this.$services.organization
      .fetchOrganizationUsers(payload)
      .then((organizationUsers: User[]) => {
        context.commit('SET_ORGANIZATION_USERS', organizationUsers);
      });
  }
};
