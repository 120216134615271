import Vue from 'vue';
import { get, sync } from 'vuex-pathify';
import { mapActions } from 'vuex';

import {
  authProvider,
  subscriptionProvider
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { Group } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';
import { wrapInArray } from '@/utils/helpers';
import { Organization } from '@/models';

import { BillingSubscription } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/subscription';

import { cloneDeep } from 'lodash';
import moment from 'moment';

export const promoStartDate = new Date('2022-04-01T05:00:00Z'); //first day of april (EST)
export default Vue.extend({
  name: 'companies-callable',

  data: () => ({
    initialUserRolesLoaded: false
  }),
  computed: {
    changed: sync('auth/passwordChanged'),
    getLoading: get('accounts/getLoading'),
    getAuthError: get('auth/getAuthError'),
    getCurrentUser: get('auth/getCurrentUser'),
    getCurrentUserIntials: get('auth/getInitials'),

    userOrganization(): Organization {
      return this.getCurrentUser.organization;
    },

    authProvider(): any {
      return authProvider(this.$store);
    },

    subscriptionProvider(): any {
      return subscriptionProvider(this.$store);
    },

    subscription(): BillingSubscription {
      return this.subscriptionProvider.getSubscription;
    },

    isUserSupervisor(): boolean {
      return this.userHasRoles('Supervisor');
    },

    isUserOrganizationAdmin(): boolean {
      return this.userHasRoles('Organization Admin');
    },

    isUserEmployee(): boolean {
      return !this.userHasRoles(['Organization Admin', 'Supervisor']);
    },

    isUserSuperuser(): boolean {
      return this.authProvider.getCurrentUser.is_superuser;
    },

    isOnFreeTrial(): boolean {
      if (this.isUserSuperuser) {
        return false;
      }
      return (
        this.isUserValidForFreeTrial(
          new Date(this.getCurrentUser.organization.created_at)
        ) && Boolean(this.subscription && !this.subscription.product)
      );
    },

    canUserViewProducts(): boolean {
      return Boolean(this.subscription.product && !this.subscription.is_paid);
    },

    freeTrialDayLength: () => 14 // days
  },
  methods: {
    ...mapActions({
      passwordConfirm: 'auth/passwordConfirm',
      passwordResetConfirm: 'auth/passwordResetConfirm',
      forgotPassword: 'auth/forgotPassword',
      verifyEmail: 'auth/verifyEmail',
      requestEmail: 'auth/requestEmail',
      logout: 'auth/logout',
      login: 'auth/login',
      clearError: 'auth/clearError',
      fetchMe: 'auth/fetchMe'
    }),

    userHasRoles(authRoles: string | string[]): boolean {
      const userGroups: Group[] = this.authProvider.getCurrentUser.groups ?? [];
      const isUserinGroupList = (group: Group) =>
        wrapInArray(authRoles).includes(group.name!);
      const hasSupervisorRole = userGroups.some((group) =>
        isUserinGroupList(group)
      );
      this.initialUserRolesLoaded = true;
      return hasSupervisorRole || this.authProvider.isUserAdmin;
    },

    isUserValidForFreeTrial(date: Date) {
      if (date >= promoStartDate) {
        const originalTrialStartDate = moment(date);
        const freeTrialEndDate = cloneDeep(originalTrialStartDate).add({
          days: this.freeTrialDayLength
        });

        return freeTrialEndDate.isAfter(Date.now());
      }
      return false;
    }
  }
});
