import Vue from 'vue';
import { get } from 'vuex-pathify';
import { mapState, mapActions, mapMutations } from 'vuex';
import { CalendarTimestamp } from 'vuetify';
import { CalendarType } from '@/models/job/job';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { DAYS_IN_WEEK } from '@/components/data/calendar/common/timestamp';
import { TimelineTimestamp } from '@/models/calendar';

export default Vue.extend({
  name: 'vcalendar-callable',
  data: () => ({
    scrollPush: 0,
    resourceWidth: 210,
    userDataColumnWidth: 200
  }),
  computed: {
    ...mapState({
      vfocus: (state: any) => state.vcalendar.focus,
      vtype: (state: any) => state.vcalendar.type,
      vweekdays: (state: any) => state.vcalendar.calendarWeekdays,
      vCalendarStart: (state: any) => state.vcalendar.calendarStart,
      vCalendarEnd: (state: any) => state.vcalendar.calendarEnd,
      vTimelineDays: (state: any) => state.vcalendar.timelineDays
    }),

    calendarWeekdays: {
      get(): number[] {
        return this.vweekdays;
      },
      set(v: number[]) {
        this.setWeekdays(v);
      }
    },

    type: {
      get(): string {
        return this.vtype;
      },
      set(v: string) {
        this.setType(v);
      }
    },
    focus: {
      get(): string | Date {
        return this.vfocus;
      },
      set(v: string | Date) {
        this.setFocus(v);
      }
    },
    today: get('vcalendar/today'),
    calendarStart: {
      get(): CalendarTimestamp {
        return this.vCalendarStart;
      },
      set(v: CalendarTimestamp) {
        this.setCalendarStart(v);
      }
    },
    calendarEnd: {
      get(): CalendarTimestamp {
        return this.vCalendarEnd;
      },
      set(v: CalendarTimestamp) {
        this.setCalendarEnd(v);
      }
    },
    timelineDays: {
      get(): TimelineTimestamp[] {
        return this.vTimelineDays;
      },
      set(v: TimelineTimestamp[]) {
        this.setTimelineDays(v);
      }
    }
  },
  methods: {
    ...mapMutations('vcalendar', [
      'setFocus',
      'setType',
      'setWeekdays',
      'setCalendarStart',
      'setCalendarEnd',
      'setTimelineDays'
    ]),

    //this function updates the calendar timestamp start and end time
    //which is used to display the datetime range lookup by the calendar title header
    updateCalendarTimestamp(timelineDays: CalendarTimestamp) {
      const timeline = cloneDeep(timelineDays);

      if (this.type == CalendarType.TIMELINE_DAY) {
        timeline.day = moment(timeline.date).date();

        this.calendarStart = cloneDeep(timeline);
        this.calendarEnd = timeline;
      }

      if (this.type == CalendarType.TIMELINE_WEEK) {
        this.calendarStart = cloneDeep(timeline);
        if (this.calendarWeekdays.length < DAYS_IN_WEEK) {
          timeline.date = moment(timeline.date)
            .add(4, 'days')
            .format('YYYY-MM-DD');
        } else {
          timeline.date = moment(timeline.date)
            .endOf('week')
            .format('YYYY-MM-DD');
        }

        timeline.day = moment(timeline.date).date();
        this.calendarEnd = timeline;
      }
    }
  }
});
