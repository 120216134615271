import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IProductState } from './product.types';
import { IProductData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IProductState = {
  version: 1,
  products: [] as IProductData[]
};

const namespaced = true;

export const product: Module<IProductState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
