import {
  ICompanyNoteActions,
  CompanyNoteContext,
  ICompanyNoteActionPayload,
  ICompanyNoteFetchActionPayload
} from './actions.types';
import { ICompanyNoteData } from '@/models';

export const actions: ICompanyNoteActions = {
  fetchCompanyNotes(
    context: CompanyNoteContext,
    payload: number
  ): Promise<void> {
    return this.$services.company
      .fetchCompanyNotes(payload)
      .then((companynotes: ICompanyNoteData[]) => {
        context.commit('SET_COMPANY_NOTES', companynotes);
      });
  },

  fetchCompanyNote(
    context: CompanyNoteContext,
    payload: ICompanyNoteFetchActionPayload
  ): Promise<void> {
    return this.$services.company
      .fetchCompanyNote(payload.companyId, payload.noteId)
      .then((companynote: ICompanyNoteData) => {
        const index = context.state.companyNotes.findIndex(
          (v: ICompanyNoteData) => v.id === companynote.id
        );
        if (index === -1) {
          context.commit('ADD_COMPANY_NOTE', companynote);
        } else {
          context.commit('UPDATE_COMPANY_NOTE', companynote);
        }
      });
  },

  createCompanyNote(
    context: CompanyNoteContext,
    payload: ICompanyNoteActionPayload
  ): Promise<void> {
    return this.$services.company
      .createCompanyNote(payload.companyId, payload.note)
      .then((companynote: ICompanyNoteData) =>
        context.commit('ADD_COMPANY_NOTE', companynote)
      );
  },

  updateCompanyNote(
    context: CompanyNoteContext,
    payload: ICompanyNoteActionPayload
  ): Promise<void> {
    return this.$services.company
      .updateCompanyNote(payload.companyId, payload.note)
      .then((companynote: ICompanyNoteData) => {
        const index = context.state.companyNotes.findIndex(
          (v: ICompanyNoteData) => v.id === companynote.id
        );
        if (index === -1) {
          context.commit('ADD_COMPANY_NOTE', companynote);
        } else {
          context.commit('UPDATE_COMPANY_NOTE', companynote);
        }
      });
  },

  deleteCompanyNote(
    context: CompanyNoteContext,
    payload: ICompanyNoteFetchActionPayload
  ): Promise<void> {
    return this.$services.company
      .deleteCompanyNote(payload.companyId, payload.noteId)
      .then(() => {
        context.commit('REMOVE_COMPANY_NOTE', payload);
      });
  }
};
