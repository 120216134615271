import {
  ICompanyActions,
  CompanyContext,
  ICompanyStatusPayload
} from './actions.types';
import { ICompanyData } from '@/models';

export const actions: ICompanyActions = {
  fetchCompanies(context: CompanyContext): Promise<void> {
    return this.$services.company
      .fetchCompanies()
      .then((companies: ICompanyData[]) => {
        context.commit('SET_COMPANIES', companies);
      });
  },

  fetchCompany(context: CompanyContext, payload: number): Promise<void> {
    return this.$services.company
      .fetchCompany(payload)
      .then((company: ICompanyData) => {
        const index = context.state.companies.findIndex(
          (v: ICompanyData) => v.id === company.id
        );
        if (index === -1) {
          context.commit('ADD_COMPANY', company);
        } else {
          context.commit('UPDATE_COMPANY', company);
        }
      });
  },

  createCompany(context: CompanyContext, payload: ICompanyData): Promise<void> {
    return this.$services.company
      .createCompany(payload)
      .then((company: ICompanyData) => context.commit('ADD_COMPANY', company));
  },

  updateCompany(context: CompanyContext, payload: ICompanyData): Promise<void> {
    return this.$services.company
      .updateCompany(payload)
      .then((company: ICompanyData) => {
        const index = context.state.companies.findIndex(
          (v: ICompanyData) => v.id === company.id
        );
        if (index === -1) {
          context.commit('ADD_COMPANY', company);
        } else {
          context.commit('UPDATE_COMPANY', company);
        }
      });
  },

  deleteCompany(context: CompanyContext, payload: number): Promise<void> {
    return this.$services.company.deleteCompany(payload).then(() => {
      context.commit('REMOVE_COMPANY', payload);
    });
  },

  updateCompanyStatus(
    context: CompanyContext,
    payload: ICompanyStatusPayload
  ): Promise<void> {
    return this.$services.company
      .updateCompanyStatus(payload.id, payload.state)
      .then((status) => {
        const index = context.state.companies.findIndex(
          (v: ICompanyData) => v.id === payload.id
        );
        if (index !== -1) {
          const newCompany: ICompanyData = {
            ...context.state.companies[index],
            status: status
          };
          context.commit('UPDATE_COMPANY', newCompany);
        }
      });
  }
};
