
import { Warehouse } from '@/models/inventory';
import { mapActions } from 'vuex';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import PhoneInputPicker from '@/components/core/PhoneInputPicker/Index.vue';

export default Vue.extend({
  components: { ErrorAlert, PhoneInputPicker },
  data() {
    return {
      dialog: false,
      enableEdit: false,
      valid: false,
      warehouse: new Warehouse(),

      fieldRequiredRule: [(v: any) => !!v || 'This is required'],
      errorMessage: '',
      detailErrorMessage: '',
      fieldError: { phone: [] as string[], identifier: [] as string[] }
    };
  },

  methods: {
    ...mapActions({
      createWarehouse: 'warehouseStore/createWarehouse',
      updateWarehouse: 'warehouseStore/updateWarehouse'
    }),

    clearAllErrors(): void {
      this.fieldError = { phone: [], identifier: [] };
      this.errorMessage = '';
      this.detailErrorMessage = '';
    },

    open(isEdit = false, warehouse: Warehouse = new Warehouse()) {
      this.enableEdit = isEdit;

      this.dialog = true;

      this.clearAllErrors();

      this.warehouse = cloneDeep(warehouse);

      if (this.enableEdit) {
        //TODO: MG - 06-05-2021 - Look at alternative way to solve this problem
        this.warehouse.address!.country =
          this.warehouse.address!.country! == ''
            ? { code: '', name: '' }
            : {
                code: this.warehouse.address!.country!.code,
                name: this.warehouse.address!.country!.name
              };
      }
    },

    close() {
      (this.$refs.form as any).reset();
      this.dialog = false;
    },

    onPhoneNumberInput(
      fromattedNumber: string,
      { number }: { number: { international: string } }
    ) {
      this.warehouse.phone = number.international;
      this.fieldError.phone = [];

      if (this.warehouse.phone == undefined) {
        this.warehouse.phone = '';
      }
    },

    addWarehouse() {
      this.createWarehouse(this.warehouse)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response) {
            if (error.response.data.identifier) {
              this.fieldError.identifier = ['warehouse already exist'];
            }
            if (error.response.data.phone) {
              this.fieldError.phone = error.response.data.phone;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    editWarehouse() {
      this.updateWarehouse(this.warehouse)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response) {
            if (error.response.data.identifier) {
              this.fieldError.identifier = ['warehouse already exist'];
            }
            if (error.response.data.phone) {
              this.fieldError.phone = error.response.data.phone;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    }
  }
});
