
import Vue, { PropType } from 'vue';
import { mapActions } from 'vuex';

import { ErrorManager } from '@/models/error';
import {
  ContactStatusChoices,
  ContactStatusTransition,
  IContact
} from '@/models';

// Components
import ErrorAlert from '@/components/common/ErrorAlert.vue';

export default Vue.extend({
  name: 'ContactStatusDisplay',

  components: { ErrorAlert },

  props: {
    contact: { type: Object as PropType<IContact>, required: true },

    minWidth: { type: Number, default: 150 },
    maxWidth: { type: Number },

    height: { type: Number, default: 30 }
  },

  data: () => ({
    loading: false,
    display: false,

    errorMessage: ''
  }),

  computed: {
    styles(): Record<string, string> {
      const styles: Record<string, string> = {};
      const minWidth = `${Number(this.minWidth)}px`;
      const maxWidth = `${Number(this.maxWidth)}px`;
      const height = `${Number(this.height)}px`;

      if (minWidth) styles.minWidth = minWidth;
      if (maxWidth) styles.maxWidth = maxWidth;
      if (height) styles.height = height;

      return styles;
    },

    statusValue(): number | undefined {
      return this.contact.status?.value;
    },

    availableTransitions(): ContactStatusTransition[] {
      return [
        {
          display_name: this.getStatusText(ContactStatusChoices.LEAD),
          color: this.getStatusColor(ContactStatusChoices.LEAD),
          value: ContactStatusChoices.LEAD
        },
        {
          display_name: this.getStatusText(ContactStatusChoices.FOLLOW_UP),
          color: this.getStatusColor(ContactStatusChoices.FOLLOW_UP),
          value: ContactStatusChoices.FOLLOW_UP
        },
        {
          display_name: this.getStatusText(ContactStatusChoices.SOLD),
          color: this.getStatusColor(ContactStatusChoices.SOLD),
          value: ContactStatusChoices.SOLD
        },
        {
          display_name: this.getStatusText(ContactStatusChoices.ACTIVE_LEAD),
          color: this.getStatusColor(ContactStatusChoices.ACTIVE_LEAD),
          value: ContactStatusChoices.ACTIVE_LEAD
        },
        {
          display_name: this.getStatusText(ContactStatusChoices.RETIRED_LEAD),
          color: this.getStatusColor(ContactStatusChoices.RETIRED_LEAD),
          value: ContactStatusChoices.RETIRED_LEAD
        }
      ];
    }
  },

  methods: {
    ...mapActions({
      updateContactStatus: 'contactV2/updateContactStatus'
    }),

    updateStatus(state: number) {
      this.clearAllErrors();
      this.loading = true;
      return this.updateContactStatus({
        id: this.contact.id,
        state
      })
        .then(() => {
          this.display = false;
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clearAllErrors(): void {
      this.errorMessage = '';
    },

    getStatusColor(state: number): string {
      switch (state) {
        case ContactStatusChoices.LEAD:
          return 'grey';
        case ContactStatusChoices.FOLLOW_UP:
          return 'yellow darken-1';
        case ContactStatusChoices.SOLD:
          return 'success';
        case ContactStatusChoices.ACTIVE_LEAD:
          return 'teal';
        case ContactStatusChoices.RETIRED_LEAD:
          return 'red';
        default:
          return 'white';
      }
    },

    getStatusText(state: number): string {
      switch (state) {
        case ContactStatusChoices.LEAD:
          return 'Lead';
        case ContactStatusChoices.FOLLOW_UP:
          return 'Follow Up';
        case ContactStatusChoices.SOLD:
          return 'Sold';
        case ContactStatusChoices.ACTIVE_LEAD:
          return 'Active Lead';
        case ContactStatusChoices.RETIRED_LEAD:
          return 'Retired Lead';
        default:
          return 'Invalid';
      }
    }
  }
});
