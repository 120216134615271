import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IJobState } from './job.types';
import {
  IJobData,
  IJobOccurrenceData,
  IJobTimelineOccurrencesData
} from '@/models/job/index';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IJobState = {
  version: 1,
  jobs: [] as IJobTimelineOccurrencesData[],
  lastPromise: null
};

const namespaced = true;

export const job: Module<IJobState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
