var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"resize",rawName:"v-resize.quiet",value:(_vm.onResize),expression:"onResize",modifiers:{"quiet":true}}],staticClass:"v-calendar-timeline d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-column border-top border-left",style:(`min-width: ${_vm.unit(_vm.resourceWidth)}`)},[_c('div',{staticClass:"d-flex flex-column border-bottom align-center justify-center",style:(`min-width: ${_vm.unit(_vm.resourceWidth)}; flex: 0 0 79.5px`)}),[_vm._l((_vm.timeline),function(job,index){return [_c('div',{key:index,staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex border-bottom align-center ml-2",style:(`min-width: ${_vm.unit(_vm.resourceWidth)}; height: ${_vm.unit(
              _vm.timelineHeightInterval(job.jobs)
            )}; left: 0; `)},[_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"ma-0 pa-0 d-flex custom-font",style:(`width: ${_vm.unit(_vm.userDataColumnWidth)}`),attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex",style:(`width: ${_vm.unit(_vm.userDataColumnWidth)}`)},'div',attrs,false),on),[(job.user.id)?_c('v-avatar',{staticClass:"mr-3 ml-2",attrs:{"color":job.user.is_active ? 'primary' : '#9e9e9e55',"size":"54"}},[_c('span',{staticClass:"white--text headline font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getNameInitials( job.user.first_name, job.user.last_name )))])]):_c('v-icon',{staticClass:"mr-3 ml-2",attrs:{"color":"grey","size":"54"},domProps:{"textContent":_vm._s('mdi-close-circle-outline')}}),_c('div',{staticClass:"user-username"},[_c('div',{staticClass:"text--secondary",class:{ 'text--disabled': !job.user.is_active },style:(job.user.id ? 'font-weight: 500' : 'color:gray')},[_vm._v(" "+_vm._s(job.user.first_name)+" "+_vm._s(job.user.last_name)+" ")]),_c('div',{staticClass:"text--secondary",class:{ 'text--disabled': !job.user.is_active },staticStyle:{"font-weight":"500"}},[_c('span',[_vm._v(" "+_vm._s(_vm.genTimeScheduleText(job.stats.time_scheduled))+" ")])]),(!job.user.is_active)?_c('div',[_c('v-chip',{attrs:{"type":"error","color":"error","x-small":""}},[_vm._v(" Inactive ")])],1):_vm._e()])],1)]}}],null,true)},[(job.user.is_active)?[_c('span',[_vm._v("Hours Scheduled: "+_vm._s(_vm.genTimeScheduleText(job.stats.time_scheduled)))])]:[_vm._v(" User is not active ")]],2)],1)],1)],1)])]})]],2),_c('div',{ref:"scrollArea",staticClass:"v-calendar-timeline-daily__scroll-area border-left border-top"},[_c('div',{ref:"pane",staticClass:"v-calendar-daily-timeline__pane d-flex flex-column"},[_c('div',{staticClass:"v-calendar-daily__head d-flex",style:(`margin-right: ${_vm.unit(_vm.scrollPush)};
        width: inherit;`)},_vm._l((_vm.timelineDays),function(day,dayIndex){return _c('div',{key:dayIndex,staticClass:"v-calendar-daily_head-day"},[_c('VNodes',{key:`${dayIndex}__${day.day}`,attrs:{"nodes":_vm.genHeadDay(day, dayIndex)}}),_c('VNodes',{key:dayIndex,attrs:{"nodes":_vm.genDayLabel(day)}})],1)}),0),_vm._l((_vm.timeline),function(job,timelineIndex){return [_c('div',{key:`${timelineIndex}__${job}`,staticClass:"d-flex flex-row",staticStyle:{"width":"inherit"},attrs:{"id":`employee_${timelineIndex}`}},[_vm._l((_vm.timelineDays),function(day,dayIndex){return [_c('div',{key:`${dayIndex}__${timelineIndex}`,staticClass:"v-calendar-timeline-daily__day-interval d-flex flex-row pt-0",style:({
                height: _vm.unit(_vm.timelineHeightInterval(job.jobs))
              }),attrs:{"data-date":day.date},on:{"dragend":_vm.endDrag,"dragover":function($event){$event.preventDefault();return ((e) =>
                  _vm.selectedDragEvent == null
                    ? null
                    : _vm.dragOver(job.user, day.date)).apply(null, arguments)},"dragenter":function($event){$event.preventDefault();}}},[[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{key:`${dayIndex}__${timelineIndex}`,staticClass:"v-calendar-timeline-daily__day-interval border-right border-bottom"},[(_vm.canCreateEvent)?_c('v-card',{staticClass:"d-flex align-center justify-center",style:(hover ? _vm.genHoverStyle(job.jobs, day.date) : ''),attrs:{"color":"grey lighten-2","flat":""},on:{"click":function($event){return _vm.openCreateEventDialog(job.user, day.date)}}},[(hover)?[_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s('mdi-plus-circle')}})]:_vm._e()],2):_vm._e()],1)]}}],null,true)})],_vm._l((_vm.getDayJobs(job.jobs, day.date)),function(userJob,index){return [_c('v-menu',{key:index,ref:`event-menu-day_${dayIndex}-${timelineIndex}${index}`,refInFor:true,attrs:{"close-on-content-click":false,"close-on-click":"","absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticStyle:{"position":"absolute","pointer-events":"auto"},style:({
                        ..._vm.genTimedEvent(userJob, index),
                        opacity: job.user.is_active ? 1 : 0.4
                      }),attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag({
                          job: userJob,
                          originalAttendee: job.user
                        })}}},[(_vm.timelineLoaded)?_c('div',_vm._g(_vm._b({staticClass:"v-event-timed"},'div',attrs,false),on),[_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"v-timeline-event-summary",style:(userJob.state == _vm.JobStateType.DRAFT
                                ? 'color:black'
                                : 'color:white')},[_c('span',{staticClass:"d-flex justify-space-between"},[_c('strong',{staticClass:"v-timeline-event-title"},[_vm._v(_vm._s(userJob.title))]),(_vm.jobEndNextDay(userJob))?_c('v-icon',{staticClass:"mr-1",attrs:{"color":userJob.state == _vm.JobStateType.DRAFT
                                    ? 'black'
                                    : 'white',"x-small":""},domProps:{"textContent":_vm._s('mdi-brightness-4')}}):_vm._e()],1),_vm._v(" "+_vm._s(_vm.genStartEndTime(userJob))+" ")])])]):_vm._e()])]}}],null,true)},[_c('data-calendar-menu-card',{attrs:{"event":userJob,"canUpdateEvent":_vm.canUpdateEvent},on:{"open":_vm.edit,"close":function($event){_vm.$refs[
                        `event-menu-day_${dayIndex}-${timelineIndex}${index}`
                      ][0].isActive = false},"delete":function($event){return _vm.deleteEventDialog({
                        user: job.user,
                        jobs: [userJob]
                      })},"rebuild":_vm.genTimelineJobOccurrennces,"send-invite:event":_vm.sendEventInvite,"send-invite:occurrence":_vm.sendOccurrenceInvite}})],1)]})],2)]})],2)]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }