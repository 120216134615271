import { States } from '@/models/task';
import { StateService } from '@/services/states';
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index';
import { errorCatch } from '../utils';

const stateService = new StateService();

export const state = {
  states: [] as States[],
  loading: false,
  error: {}
};
type StateType = typeof state;
export const mutations: MutationTree<StateType> = {
  START_LOADING(state) {
    state.loading = true;
  },

  STOP_LOADING(state) {
    state.loading = false;
  },

  SET_LOADING(state, payload: boolean) {
    state.loading = payload;
  },

  ADD_STATE(state, payload: States) {
    state.states.push(payload);
  },

  SET_STATES(state, payload: States[]) {
    state.states = payload;
  },

  UPDATE_STATE(state, payload: States) {
    const index = state.states.findIndex(
      (state: States) => state.id === payload.id
    );
    state.states.splice(index, 1, payload);
  },

  REMOVE_STATE(state, payload: number) {
    const index = state.states.findIndex(
      (state: States) => state.id === payload
    );
    state.states.splice(index, 1);
  },

  SET_ERROR(state, error: any) {
    errorCatch(state, error);
  },

  CLEAR_ERROR(state) {
    state.error = {};
  }
};

export const actions: ActionTree<StateType, StateType> = {
  clearError: ({ commit }) => commit('CLEAR_ERROR'),

  createState: async ({ commit, dispatch }, payload: States) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      stateService
        .createState(payload)
        .then((state) => {
          commit('ADD_STATE', state);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchStates: async ({ commit, dispatch }) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      stateService
        .fetchStates()
        .then((states) => {
          commit('SET_STATES', states);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchState: async ({ commit, state, dispatch }, payload: number) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      stateService
        .fetchState(payload)
        .then((state) => {
          const states = [...state.states];
          const index = states.findIndex((t: States) => t.id === payload);
          if (index === -1) {
            commit('ADD_STATE', state);
          } else {
            commit('UPDATE_STATE', state);
          }
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  updateState: async ({ commit, dispatch }, payload: States) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      stateService
        .updateState(payload.id!, payload)
        .then((state) => {
          commit('UPDATE_STATE', state);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  deleteState: async ({ commit, dispatch }, payload: number) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      stateService
        .deleteState(payload)
        .then(() => {
          commit('REMOVE_STATE', payload);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  }
};

export const getters: GetterTree<StateType, StateType> = {
  // TODO: [LF] 2020-03-08 - Need to replace all magic numbers
  getIsDoneStates: (state) => {
    return state.states.filter((state: States) => state.is_done);
  },

  getIsNotDoneStates: (state) => {
    return state.states.filter((state: States) => !state.is_done);
  },

  getStateById: (state) => (id: number) => {
    return state.states.find((state: States) => state.id === id);
  },

  getStateByOrder: (state) => (order: number) => {
    return state.states.find((state: States) => state.order === order);
  },

  getLastState: (state) => {
    if (state.states.length > 0) {
      const stateColumn = state.states[state.states.length - 1];
      return stateColumn;
    }
    return undefined;
  },

  getFirstState: (state) => {
    if (state.states.length > 0) {
      const stateColumn = state.states[0];
      return stateColumn;
    }
    return undefined;
  },

  getStates: (state) => {
    return state.states;
  }
};
