
import Vue from 'vue';
import { EmployeeWorkTime } from '@/models/employeeWorkTime';

export default Vue.extend({
  data: () => ({
    dialog: false,
    search: '',
    totalHours: 0,
    teamCapacity: 0,
    headers: [
      {
        text: 'Emplyee Name',
        align: 'start',
        value: 'full_name',
        width: '288px',
        class: 'grey lighten-4'
      },
      {
        text: 'Days Scheduled',
        value: 'days',
        align: 'center',
        width: '110px',
        class: 'grey lighten-4'
      },
      {
        text: 'Jobs Done',
        value: 'jobs',
        align: 'center',
        width: '110px',
        class: 'grey lighten-4'
      },
      {
        text: 'Hours Worked',
        align: 'end',
        width: '90px',
        value: 'hoursnum',
        class: 'grey lighten-4'
      },
      {
        text: '',
        value: 'hours_worked',
        width: '390px',
        class: 'grey lighten-4'
      },
      {
        text: 'Hours Scheduled',
        value: 'hours_scheduled',
        class: 'grey lighten-4',
        width: '110px'
      }
    ],
    employeesWorkTime: [] as EmployeeWorkTime[]
  }),
  methods: {
    /**
     * set color of progress bar
     *
     * @param hoursDone total amount of hours done by user
     * @param hoursAssigned total amount of hours assigned
     *
     *
     *
     */
    setProgressColor(hoursDone: number, hoursAssigned: number) {
      if (hoursDone < hoursAssigned) return 'green';
      else return 'amber';
    },

    /**
     * gets job report data and set it to text area
     *
     * @param employees job Report ogbject data
     *
     *
     */
    open(employees: EmployeeWorkTime[]) {
      this.totalHours = 0;
      this.teamCapacity = 0;

      employees.map((data: EmployeeWorkTime) => {
        this.totalHours += data.hours_worked!;
        this.teamCapacity += data.hours_scheduled!;
        data.full_name = data.user!.first_name + ' ' + data.user!.last_name;
      });
      this.employeesWorkTime = employees;
      this.dialog = true;
    },

    /**
     * closes dialog
     *
     *
     */
    close() {
      this.dialog = false;
    }
  }
});
