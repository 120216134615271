
import Vue, { PropType } from 'vue';
import RRule, { Options as RRuleOptions, Frequency } from 'rrule';
import { isArray, mergeDeep } from '@/utils/helpers';
import { startsWith } from 'lodash';
import moment from 'moment';

export type endChoice = 'until' | 'count' | 'never';

const prop = 'value',
  event = 'input';

const NUM_DAYS_IN_WEEK = 7;

export default Vue.extend({
  model: { prop, event },

  props: {
    disableSlot: Boolean,

    value: null as any as PropType<string[] | null>,

    start: {
      required: true,
      type: [Date] as PropType<Date>
    }
  },

  data() {
    return {
      lazyValue: this.value,

      isActive: false,

      days: [
        RRule.SU,
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA
      ],

      useCountOrUntil: 'until' as endChoice,

      defaultRule: {
        freq: Frequency.WEEKLY,
        dtstart: undefined,
        interval: 1,
        count: 30,
        until: undefined,
        byweekday: [
          (NUM_DAYS_IN_WEEK - 1 + this.start.getUTCDay()) % NUM_DAYS_IN_WEEK
        ]
      } as Partial<RRuleOptions>,

      recurrenceRule: {
        freq: Frequency.WEEKLY,
        interval: 1,
        byweekday: [
          (NUM_DAYS_IN_WEEK - 1 + this.start.getUTCDay()) % NUM_DAYS_IN_WEEK
        ],
        count: 30,
        until: undefined
      } as Partial<RRuleOptions>
    };
  },

  watch: {
    value(val: string[]) {
      (this as any).lazyValue = val;
      this.setRecurrenceRule(val);
    },

    useCountOrUntil: {
      immediate: true,

      handler(val: endChoice) {
        switch (val) {
          case 'until':
            if (!this.recurrenceRule.until) {
              this.recurrenceRule.until = moment(this.start)
                .add(30, 'days')
                .toDate();
            }
            break;

          case 'count':
            if (!this.recurrenceRule.count) {
              this.recurrenceRule.count = 30;
            }
            break;
        }
      }
    },

    start(val: Date) {
      if (this.value!.length) {
        //* Only update the time and not the day
        this.recurrenceRule.dtstart?.setUTCHours(
          val.getUTCHours(),
          val.getUTCMinutes()
        );

        const newRecurrenceRuleString = new RRule(
          this.recurrenceRule
        ).toString();

        this.setRecurrenceRule([newRecurrenceRuleString]);

        this.save();
      } else {
        if (this.recurrenceRule.freq === Frequency.WEEKLY) {
          // Default weekday based on the updated start date
          this.recurrenceRule.byweekday = [
            (NUM_DAYS_IN_WEEK - 1 + val.getUTCDay()) % NUM_DAYS_IN_WEEK
          ];
        }
      }

      if (this.useCountOrUntil === 'until') {
        this.recurrenceRule.until = moment(this.start).add(30, 'days').toDate();
      }
    }
  },

  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue as any;
      },
      set(val: RRule | null) {
        this.lazyValue = val;
        this.$emit(event, val);
      }
    } as any,

    frequencies(): any[] {
      return [
        {
          text: this.recurrenceRule.interval! > 1 ? 'Days' : 'Day',
          value: Frequency.DAILY
        },
        {
          text: this.recurrenceRule.interval! > 1 ? 'Weeks' : 'Week',
          value: Frequency.WEEKLY
        },
        {
          text: this.recurrenceRule.interval! > 1 ? 'Months' : 'Month',
          value: Frequency.MONTHLY
        },
        {
          text: this.recurrenceRule.interval! > 1 ? 'Years' : 'Year',
          value: Frequency.YEARLY
        }
      ];
    },

    Frequency: () => Frequency
  },

  mounted() {
    if (this.value) this.setRecurrenceRule(this.value);
  },

  methods: {
    computedRules(rules: string[]): string[] {
      return rules.filter(
        (e: string) => startsWith(e, 'RR') || !startsWith(e, 'DT')
      );
    },

    setRecurrenceRule(rules: string[]) {
      for (let i = 0; i < rules.length; i++) {
        const rule = RRule.fromString(rules[i]);

        // Set array of days selected to a number
        if (
          isArray(rule.origOptions!.byweekday!) &&
          (rule.origOptions!.byweekday! as any[]).length
        ) {
          rule.origOptions.byweekday = (
            rule.origOptions.byweekday as any[]
          ).map((day) => day.weekday);
        }

        // may have to remove
        if (!rule.origOptions.interval) {
          rule.origOptions.interval = 1;
        }

        this.recurrenceRule = rule.origOptions;

        if (rule.origOptions.count) {
          this.useCountOrUntil = 'count';
        } else if (rule.origOptions.until) {
          this.useCountOrUntil = 'until';
        } else {
          this.useCountOrUntil = 'never';
        }
      }
    },

    // @see: https://github.com/vuetifyjs/vuetify/issues/7021
    getSafeOnHandler({ click }: { click: Function }): { click: Function } {
      return {
        click(event: Event) {
          return setTimeout(() => click(event), 10);
        }
      };
    },

    parseRRule(rule: Partial<RRuleOptions>): Partial<RRuleOptions> {
      if (rule.freq !== Frequency.WEEKLY) {
        rule.byweekday = [];
      }
      return rule;
    },

    async save() {
      let patterns: Partial<RRuleOptions> = {
        ...this.recurrenceRule,
        dtstart: this.recurrenceRule.dtstart ?? (this.start as Date)
      };

      patterns = this.parseRRule(patterns);

      switch (this.useCountOrUntil) {
        case 'never':
          patterns['until'] = undefined;
          patterns['count'] = undefined;
          break;

        case 'until':
          patterns['until'] = this.recurrenceRule.until;
          patterns['count'] = undefined;
          break;

        case 'count':
          patterns['count'] = this.recurrenceRule.count;
          patterns['until'] = undefined;
          break;

        default:
          patterns['count'] = undefined;
          patterns['until'] = undefined;
          break;
      }

      await this.$emit(event, [new RRule(patterns).toString()]);
      this.isActive = false;
    },

    cancel() {
      this.recurrenceRule = this.defaultRule;
      this.isActive = false;
    }
  }
});
