
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { MovingInventory } from '@/models/movingInventory';
import dateFormat from '@/utils/dateFormat';
import { ErrorManager } from '@/models/error';
import auth from '@/mixins/auth';
import mixins from '@/utils/mixins';

export default mixins(auth).extend({
  data() {
    return {
      title: 'MovingInventory',
      search: '',
      errorMessage: '',

      selectedMovingInventory: new MovingInventory(),

      deleteMovingInventoryDialog: false,
      dateDialog: false,
      snackbar: false,
      loading: false
    };
  },

  computed: {
    ...mapState('movingInventory', ['movingInventory']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    canModifyMovingInventory(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Customer',
          value: 'customer'
        },
        {
          text: 'Order #',
          width: '130px',
          value: 'order_number'
        },
        {
          text: 'Date',
          value: 'occurrence_date'
        },
        {
          text: '# of Items',
          sortable: false,
          value: 'quantity'
        },
        {
          text: 'Received Time',
          align: 'center',
          value: 'received_time'
        },
        {
          text: 'Delivery Time',
          align: 'center',
          value: 'delivered_time'
        },
        {
          text: 'Actions',
          sortable: false,
          width: '100px',
          value: 'actions'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //removes actions column
      }
      return headerList;
    }
  },

  methods: {
    ...mapActions({
      fetchMovingInventories: 'movingInventory/fetchMovingInventories',
      deleteMovingInventory: 'movingInventory/deleteMovingInventory'
    }),
    dateFormat,

    /**
     * open  dialog
     *
     *
     */
    openMovingInventoryForm(movingInventory: MovingInventory) {
      const action = movingInventory ? 'Edit-Inventory' : 'Create-Inventory';
      const movingInventoryId = movingInventory
        ? (movingInventory.id as number)
        : 0;

      this.$router.push({
        name: 'moving-inventory-form',
        params: {
          action: action,
          movingInventoryId: String(movingInventoryId)
        }
      });
    },

    openDateDialog(movingInventory: MovingInventory, timesheetType: string) {
      (this as any).$refs.timestampForm.open(movingInventory, timesheetType);
    },

    openMovingItems(
      movingInventory: MovingInventory,
      openMovingItemDialog = false as boolean
    ) {
      this.$router.push({
        name: 'moving-item-list',
        params: {
          movingItemId: String(movingInventory.id),
          action: openMovingItemDialog == true ? 'Create-Item' : 'View'
        }
      });
    },

    openDeleteMovingInventoryDialog(movingInventory: MovingInventory) {
      this.selectedMovingInventory = movingInventory;
      this.deleteMovingInventoryDialog = true;
    },

    deleteMovingInventoryAction() {
      this.deleteMovingInventory(this.selectedMovingInventory.id)
        .then(() => (this.deleteMovingInventoryDialog = false))
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    }
  },

  async mounted() {
    this.loading = true;
    this.fetchMovingInventories()
      .then(() => (this.loading = false))
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
  }
});
