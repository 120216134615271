import { startCase } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Plugin } from 'vuex/types/index';
import {
  User,
  authProvider
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';

import { IRootState, IStore } from '../store.types';

function mixpanelStoreInterceptor(store: IStore) {
  if (process.env.VUE_APP_ENABLE_LOGGING !== 'true') return;
  store.subscribeAction({
    after: (action, state) => {
      const actionType = action.type;

      // Convert all text to locale lower case before comparing
      const isCreateAction = actionType
        .toString()
        .toLocaleLowerCase()
        .includes('create'.toLocaleLowerCase());

      if (isCreateAction) {
        /**
         * Split the action name by the create seperator 'create' eg calendarEvent/createCalendarEvent
         */
        const [_actionNamespace, actionName] = actionType.split('create');
        /**
         * Construct a new string value that prepends the CRUD functionality with a formatted actionName eg 'New CalendarEvent Created'
         */
        const eventName = `New ${startCase(actionName)} Created`;

        const currentUser: User = (authProvider(store) as any).getCurrentUser;

        if (currentUser) {
          const properties: Record<any, any> = {};

          properties.$email = currentUser.email;

          //   let's not track data if the current user cannot be found in app
          try {
            mixpanel.track(eventName, properties);
          } catch (e) {
            console.warn(e);
          }
        }
      }
    }
  });
}

export const mixpanelStorePlugin: Plugin<IRootState> = (store) => {
  mixpanelStoreInterceptor(store);
};
