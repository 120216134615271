import { Item, Location, Warehouse } from './inventory';
import { MovingInventory } from './movingInventory';

export class MovingItem extends Item {
  inventory?: MovingInventory | number | string;
  warehouse?: Warehouse | number | string | any;
  out_date?: Date | string | null;
  dimensions?: Dimension;
  quantity?: number;
  location?: Location;

  constructor() {
    super();
    this.location = new Location();
    this.inventory = new MovingInventory();
    this.warehouse = new Warehouse();
    this.dimensions = new Dimension();
  }
}

export class Dimension {
  length?: number | string;
  width?: number | string;
  height?: number | string;
  unit?: number | string;
}
