// Utilities
import { set } from '../utils';

export const DEFAULT_SNACKBAR = Object.freeze({
  color: 'primary',
  href: false,
  msg: '',
  actionText: 'Close',
  to: false,
  timeout: 6000,
  bottom: true,
  right: true,
  action: function () {}
});

export const state = {
  snackbar: {
    ...DEFAULT_SNACKBAR
  },
  value: false
};

export const mutations = {
  setSnackbar: (state: any, payload: object) => {
    state.snackbar = Object.assign({}, DEFAULT_SNACKBAR, payload);
  },
  showMessage: (state: any, payload: object) => {
    state.snackbar = Object.assign({}, DEFAULT_SNACKBAR, { ...payload });
  },
  showError: (state: any, payload: object) => {
    state.snackbar = Object.assign({}, DEFAULT_SNACKBAR, {
      ...payload,
      type: 'error',
      color: 'error'
    });
  },
  showWarning: (state: any, payload: object) => {
    state.snackbar = Object.assign({}, DEFAULT_SNACKBAR, {
      ...payload,
      type: 'warning',
      color: 'warning'
    });
  },
  showSuccess: (state: any, payload: object) => {
    state.snackbar = Object.assign({}, DEFAULT_SNACKBAR, {
      ...payload,
      type: 'success',
      color: 'success'
    });
  },
  showUndo: (state: any, payload: object) => {
    // needs alot more logic
    state.snackbar = Object.assign({}, DEFAULT_SNACKBAR, {
      ...payload,
      type: 'primary',
      color: 'primary'
    });
  },
  setValue: set('value')
};

export const actions = {
  createSnack: ({ commit }: any, payload: any) => {
    commit('setSnackbar', payload);
  },
  snackError: ({ commit }: any, payload: any) => {
    commit('showError', payload);
  },
  snackWarning: ({ commit }: any, payload: any) => {
    commit('showWarning', payload);
  },
  snackMessage: ({ commit }: any, payload: any) => {
    commit('showMessage', payload);
  },
  snackSuccess: ({ commit }: any, payload: any) => {
    commit('showSuccess', payload);
  }
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
