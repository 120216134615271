import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IFeedbackState } from './feedback.types';
import { actions } from './actions';

export const state: IFeedbackState = {
  version: 1
};

const namespaced = true;

export const feedback: Module<IFeedbackState, IRootState> = {
  namespaced,
  state,
  actions
};
