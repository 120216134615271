import calendar from '@/mixins/calendar';
import { CalendarEvent, CalendarOccurrence } from '@/models/calendar';
import { setPartsToUTCDate } from '@/utils/dateHelpers';
import { AxiosError } from 'axios';
import moment from 'moment-timezone';
import { CalendarService } from '@/services/calendar';
import { CheckList } from '@/models/jobReport';
import { IJobContact } from '@/models/job/job.types';

interface MutationEventOccurrence {
  /**
   * The calendar-object currently being created
   */
  calendarEvent: CalendarEvent;
  calendarEventInstance: CalendarOccurrence;
  eventId?: number;
  recurrenceId?: number;
}

const calendarService = new CalendarService();

export const state = {
  isNew: null,
  calendarEvents: null as unknown as CalendarEvent,
  calendarEventInstance: null as unknown as CalendarOccurrence,
  existingEvent: {
    eventId: null,
    recurrenceId: null
  }
};

export const mutations = {
  setCalendarEvents(state: any, events: CalendarEvent[]) {
    state.calendarEvents = events;
  },

  // setCalendarEvents(state: any, events: CalendarEvent[]) {
  //   state.events = events
  // },
  /**
   * Set a calendar-object-instance that will be opened in the editor (existing event)
   */
  setCalendarEventInstanceForExistingEvent(
    state: any,
    {
      calendarEvent,
      calendarEventInstance,
      eventId,
      recurrenceId
    }: MutationEventOccurrence
  ) {
    state.isNew = false;
    state.calendarEvent = calendarEvent;
    state.calendarEventInstance = calendarEventInstance;
    state.existingEvent.objectId = eventId;
    state.existingEvent.recurrenceId = recurrenceId;
  },

  /**
   *  Set a calendar-object-instance that will be opened in the editor (new event)
   */
  setCalendarEventInstanceForNewEvent(
    state: any,
    { calendarEvent, calendarEventInstance }: MutationEventOccurrence
  ) {
    state.isNew = true;
    state.calendarEvent = calendarEvent;
    state.calendarEventInstance = calendarEventInstance;
    state.existingEvent.objectId = null;
    state.existingEvent.recurrenceId = null;
  },
  /**
   *
   * Resets data from calendar event instane
   */
  resetCalendarEventInstanceEventIdAndRecurrenceId(state: any) {
    state.isNew = false;
    state.calendarEvent = null;
    state.calendarEventInstance = null;
    state.existingEvent.objectId = null;
    state.existingEvent.recurrenceId = null;
  },

  /**
   * Change the title of the event
   * @param {Object} state The Vuex state
   */
  changeTitle(state: any, { calendarEventInstance, title }: any) {
    // calendarEventInstance.eventComponent.title = title;
    calendarEventInstance.title = title;
  }
};

export const actions = {
  async getCalendarEventInstanceForNewEvent(
    { state, rootState, dispatch, commit }: any,
    { start, end }: { start: number | Date; end?: number | Date }
  ) {
    const calendarEvent = {
      id: null,
      title: '',
      description: '',
      location: '',
      state: 0,
      recurrence: {
        enabled: false,
        rules: []
      },
      event: 0,
      calendar: rootState.calendar.calendars[0].id ?? 0,
      color: '#00AEEF',
      owner: 'Organizer Unknown',
      participants: [],
      reminder: {
        enabled: false,
        minutes: 10
      },
      conference: {
        enabled: false,
        platform: 0
      },
      date: {
        timezone: moment.tz.guess() ?? rootState.calendar.calendars[0].timezone,
        start:
          typeof start == 'string' ? start : setPartsToUTCDate(new Date(start)),
        end:
          typeof end == 'string'
            ? end
            : end
            ? setPartsToUTCDate(new Date(end))
            : moment(setPartsToUTCDate(new Date(start)))
                .add(1, 'h')
                .toDate()
      },
      checklist: new CheckList(),
      contact: null as null | IJobContact
    };
    await commit('setCalendarEventInstanceForNewEvent', {
      calendarEvent,
      calendarEventInstance: calendarEvent
    });
    return calendarEvent;
  },

  createCalendarEvent: async (
    { commit, dispatch }: any,
    { event }: { event: CalendarEvent }
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .createCalendarEvent({
          data: event
        })
        .then((calendarEvent) => {
          commit('setCalendarEventInstanceForNewEvent', {
            calendarEvent,
            calendarEventInstance: calendarEvent
          });

          resolve(calendarEvent);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  },

  updateCalendarEvent: async (
    { commit, dispatch }: any,
    {
      calendarId,
      eventId,
      event
    }: { calendarId: number; eventId: number; event: CalendarEvent }
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .updateCalendarEvent(calendarId, eventId, event)
        .then((calendarEvent: CalendarEvent) => {
          commit('setCalendarEventInstanceForExistingEvent', {
            calendarEvent,
            calendarEventInstance: calendarEvent,
            eventId: calendarEvent.id,
            recurrenceId: new Date(calendarEvent.date.start!).getTime()
          });
          resolve(calendarEvent);
        })
        .catch((err: AxiosError) => {
          reject(err);
        })
        .finally(() => {});
    });
  },
  deleteCalendarEvent: async (
    { commit, dispatch }: any,
    { calendarId, eventId }: { calendarId: number; eventId: number }
  ) => {
    return await new Promise<void>((resolve, reject) => {
      calendarService
        .deleteCalendarEvent({
          calendarId,
          eventId
        })
        .then(() => {
          resolve();
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  },

  sendCalendarEventInvite: async (
    { commit, dispatch }: any,
    {
      calendarId,
      eventId,
      create
    }: { calendarId: number; eventId: number; create: boolean }
  ) => {
    await new Promise((resolve, reject) => {
      calendarService
        .sendCalendarEventInvite(
          {
            calendarId: calendarId,
            eventId: eventId
          },
          create
        )
        .then((event) => {
          resolve(event);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {});
    });
  },
  getCalendarEventInvite: async (
    { commit, dispatch }: any,
    {
      calendarId,
      eventId,
      token,
      rsvp
    }: { calendarId: number; eventId: number; token: string; rsvp: number }
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .getCalendarEventInvite({
          calendarId,
          eventId,
          token,
          rsvp
        })
        .then((result: Partial<CalendarOccurrence & { rsvp: number }>) => {
          resolve(result);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }
};
