'use strict';

import Vue from 'vue';
import axios, { AxiosStatic, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

// Full config:  https://github.com/axios/axios#request-config
const token = `Bearer ${localStorage.getItem('access__token')}`;
axios.defaults.headers.common['Authorization'] = token;

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10 * 1000, // default 10 second timeout
  headers: {
    'Content-Type': 'application/json'
  }
};
const _axios = axios.create(config);

axiosRetry(_axios, {
  retries: 2,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isSafeRequestError
});

Vue.use({
  install() {
    Vue.prototype.$axios = _axios;
  }
});

declare module 'vue/types/vue' {
  interface Vue {
    axios: AxiosStatic;
  }
}

export { _axios as axios, AxiosResponse };
