import { isEmptyObject } from '@/utils/helpers';
export const state = {
  error: {}
};

export const mutations = {
  SET_ERROR(state: any, payload: any) {
    if (payload.response) {
      state.error = {
        code: payload.response.status as number,
        text: payload.response.statusText as string,
        ...(payload.response.data as object | object[])
      };
    } else if (!isEmptyObject(payload)) {
      state.error = {
        detail: payload.message,
        payload: payload
      };
    }
  },
  CLEAR_ERROR(state: any): void {
    // Object.assign(state.error, {})
    state.error = {};
  }
};

export const actions = {
  clearError: ({ commit }: any) => commit('CLEAR_ERROR')
};

export const getters = {
  getError: (state: any) => state.error
};
