import { ICompanyData, ICompanyNoteData, ICompanyStatusData } from '@/models';
import { ICompanyService } from './company.types';
import { AxiosInstance } from 'axios';
import { objectToFormData } from '@/utils/objectToFormData';

export class CompanyService implements ICompanyService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/crm/companies/';
  }

  fetchCompanies(): Promise<ICompanyData[]> {
    return this.axios
      .get<ICompanyData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchCompany(id: number): Promise<ICompanyData> {
    return this.axios
      .get<ICompanyData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createCompany(data: ICompanyData): Promise<ICompanyData> {
    const formData = objectToFormData(data);

    return this.axios
      .post<ICompanyData>(`${this.endpoint}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  updateCompany(data: ICompanyData): Promise<ICompanyData> {
    const formData = objectToFormData(data);

    return this.axios
      .patch<ICompanyData>(`${this.endpoint}${data.id}/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  deleteCompany(id: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }

  updateCompanyStatus(id: number, state: number): Promise<any> {
    return this.axios
      .put<ICompanyStatusData>(`${this.endpoint}${id}/set_state/${state}/`)
      .then(({ data }) => data);
  }

  /** ##### Company Note ##### **/
  fetchCompanyNotes(companyId: number): Promise<ICompanyNoteData[]> {
    return this.axios
      .get<ICompanyNoteData[]>(`${this.endpoint}${companyId}/notes/`)
      .then(({ data }) => data);
  }

  fetchCompanyNote(
    companyId: number,
    noteId: number
  ): Promise<ICompanyNoteData> {
    return this.axios
      .get<ICompanyNoteData>(`${this.endpoint}${companyId}/notes/${noteId}/`)
      .then(({ data }) => data);
  }

  createCompanyNote(
    companyId: number,
    data: ICompanyNoteData
  ): Promise<ICompanyNoteData> {
    return this.axios
      .post<ICompanyNoteData>(`${this.endpoint}${companyId}/notes/`, data)
      .then(({ data }) => data);
  }

  updateCompanyNote(
    companyId: number,
    data: ICompanyNoteData
  ): Promise<ICompanyNoteData> {
    return this.axios
      .patch<ICompanyNoteData>(
        `${this.endpoint}${companyId}/notes/${data.id}/`,
        data
      )
      .then(({ data }) => data);
  }

  deleteCompanyNote(companyId: number, noteId: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${companyId}/notes/${noteId}/`);
  }
}
