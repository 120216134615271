
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { Category, Item } from '@/models/inventory';
import { ErrorManager } from '@/models/error';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';

export default mixins(auth).extend({
  data() {
    return {
      title: 'Item-Master',

      errorFlag: false,
      imageDialog: false,
      deleteItemMasterDialog: false,
      loading: false,

      search: '',
      selectedImage: '' as any,
      errorMessage: '',

      selectedItem: new Item()
      // activeView: -1
    };
  },

  computed: {
    ...mapState('itemStore', ['item']),
    ...mapState('itemCategoryStore', ['categories']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    canCreateItem(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Thumbnail',
          sortable: false,
          value: 'media'
        },
        {
          text: 'Item #',
          value: 'identifier'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Description',
          sortable: false,
          width: '100px',
          value: 'description'
        },
        {
          text: 'Unit of Measure',
          sortable: false,
          value: 'unit'
        },
        // {
        //   text: 'Category',
        //   sortable: false,
        //   value: 'category'
        // },
        {
          text: 'Last Purchase Price',
          value: 'price'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //removes actions column
      }

      return headerList;
    }

    // views(): any {
    //   const views = [{ text: 'All', value: -1 }];
    //   for (let i = 0; this.categories.length > i; i++) {
    //     const object = {
    //       text: this.categories[i].category_name,
    //       value: i + 1
    //     };
    //     views.push(object);
    //   }
    //   return views;
    // },

    // filteredItemMasterList(): Item[] {
    //   const item_without_null_category = [];

    //   for (let i = 0; this.item.length > i; i++) {
    //     if (this.item[i].category !== null) {
    //       item_without_null_category.push(this.item[i]);
    //     }
    //   }

    //   if (this.activeView != -1) {
    //     const filterText = this.views[this.activeView].text.toLowerCase();
    //     return item_without_null_category.filter(
    //       (item: Item) =>
    //         item.category!.category_name?.toLowerCase() == filterText
    //     );
    //   } else {
    //     return this.item;
    //   }
    // }
  },

  methods: {
    ...mapActions({
      fetchItems: 'itemStore/fetchItems',
      // fetchCategories: 'itemCategoryStore/fetchCategories',
      deleteItem: 'itemStore/deleteItem'
    }),

    openDeleteItemMasterDialog(item: Item) {
      this.selectedItem = item;
      this.deleteItemMasterDialog = true;
    },

    deleteItemMasterAction() {
      this.deleteItem(this.selectedItem.id!).finally(
        () => (this.deleteItemMasterDialog = false)
      );
    },

    itemSelected(instance: Item) {
      return this.$router.push({
        name: 'item-detail',
        params: { itemNumber: String(instance.id) }
      });
    },

    /**
     * open Image dialog
     *
     *
     */
    openImageDialog(item: Item) {
      this.selectedImage = item.media;
      this.imageDialog = true;
    },

    /**
     * open Category Item dialog
     *
     *
     */
    // openSelectedCategoryDialog(item: Category) {
    //   (this as any).$refs.categoryItemDialog.open(item);
    // },

    /**
     * open Item Master dialog
     *
     *
     */
    openItemMasterFormDialog(isEdit = false, instance: Item) {
      (this as any).$refs.itemMasterDialog.open(isEdit, instance);
    },

    async setItemMasterTable() {
      this.loading = true;
      this.fetchItems()
        .then(() => (this.loading = false))
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    }
  },
  mounted() {
    this.setItemMasterTable();
  }
});
