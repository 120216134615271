import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { MovingItem } from '@/models/movingItem';
import { MovingItemService } from '@/services/movingItem/index';

const movingItemService = new MovingItemService();

export const state = {
  movingItem: [] as MovingItem[]
};

export type MovingItemState = typeof state;

export const mutations: MutationTree<MovingItemState> = {
  SET_MOVING_ITEM(state: any, payload: MovingItem[]) {
    state.movingItem = payload;
  },

  ADD_MOVING_ITEM(state: any, payload: MovingItem) {
    state.movingItem.push(payload);
  },

  UPDATE_MOVING_ITEM(state: any, payload: MovingItem) {
    const index = state.movingItem.findIndex(
      (model: MovingItem) => model.id === payload.id
    );
    state.movingItem.splice(index, 1, payload);
  },

  REMOVE_MOVING_ITEM(state: any, payload: number) {
    const index = state.movingItem.findIndex(
      (model: MovingItem) => model.id === payload
    );
    state.movingItem.splice(index, 1);
  },

  RESET_MOVING_ITEM(states: any) {
    const state = {
      movingItem: [] as MovingItem[]
    };
    Object.assign(states, state);
  }
};

export const actions: ActionTree<MovingItemState, MovingItemState> = {
  resetState({ commit }: any) {
    commit('RESET_MOVING_ITEM');
  },

  createMovingItem: async (
    { commit }: any,
    payload: { inventory: number; data: MovingItem }
  ) =>
    movingItemService
      .createMovingItem(payload)
      .then((model: MovingItem) => commit('ADD_MOVING_ITEM', model)),

  fetchMovingItems: async ({ state, commit }: any, payload: number) =>
    movingItemService
      .fetchMovingItems(payload)
      .then((movingItem: MovingItem[]) => {
        commit('SET_MOVING_ITEM', movingItem);
      }),

  fetchMovingItem: async ({ state, commit }: any, payload: MovingItem) =>
    movingItemService.fetchMovingItem(payload).then((model: MovingItem) => {
      const index = state.movingItem.findIndex(
        (v: MovingItem) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_MOVING_ITEM', model);
      } else {
        commit('UPDATE_MOVING_ITEM', model);
      }
    }),

  updateMovingItem: async (
    { state, commit }: any,
    payload: { inventory: number; id: number; data: MovingItem }
  ) =>
    movingItemService.updateMovingItem(payload).then((model: MovingItem) => {
      const index = state.movingItem.findIndex(
        (v: MovingItem) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_MOVING_ITEM', model);
      } else {
        commit('UPDATE_MOVING_ITEM', model);
      }
    }),

  deleteMovingItem: async ({ state, commit }: any, payload: MovingItem) =>
    movingItemService.deleteMovingItem(payload).then(() => {
      commit('REMOVE_MOVING_ITEM', payload);
    }),

  fetchSharedMovingItems: async ({ state, commit }: any, payload: number) =>
    movingItemService
      .fetchSharedMovingItems(payload)
      .then((movingItem: MovingItem[]) => {
        commit('SET_MOVING_ITEM', movingItem);
      })
};

export const getters: GetterTree<MovingItemState, MovingItemState> = {
  getMovingItemById: (state: MovingItemState) => (id: number) => {
    return state.movingItem.find((model) => model.id === id);
  }
};
