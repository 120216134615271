import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IContactNoteState } from './contactnote.types';
import { IContactNoteData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IContactNoteState = {
  version: 1,
  contactNotes: [] as IContactNoteData[]
};

const namespaced = true;

export const contactNote: Module<IContactNoteState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
