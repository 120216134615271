import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { MovingInventory } from '@/models/movingInventory';
import { MovingInventoryService } from '@/services/movingInventory/index';

const movingInventoryService = new MovingInventoryService();

export const state = {
  movingInventory: [] as MovingInventory[]
};

export type MovingInventoryState = typeof state;

export const mutations: MutationTree<MovingInventoryState> = {
  SET_MOVING_INVENTORY(state: any, payload: MovingInventory[]) {
    state.movingInventory = payload;
  },

  ADD_MOVING_INVENTORY(state: any, payload: MovingInventory) {
    state.movingInventory.push(payload);
  },

  UPDATE_MOVING_INVENTORY(state: any, payload: MovingInventory) {
    const index = state.movingInventory.findIndex(
      (model: MovingInventory) => model.id === payload.id
    );
    state.movingInventory.splice(index, 1, payload);
  },

  REMOVE_MOVING_INVENTORY(state: any, payload: number) {
    const index = state.movingInventory.findIndex(
      (model: MovingInventory) => model.id === payload
    );
    state.movingInventory.splice(index, 1);
  }
};

export const actions: ActionTree<MovingInventoryState, MovingInventoryState> = {
  createMovingInventory: async ({ commit }: any, payload: MovingInventory) =>
    movingInventoryService
      .createMovingInventory(payload)
      .then((model: MovingInventory) => commit('ADD_MOVING_INVENTORY', model)),

  fetchMovingInventories: async ({ commit }: any) =>
    movingInventoryService
      .fetchMovingInventories()
      .then((movingInventory: MovingInventory[]) => {
        commit('SET_MOVING_INVENTORY', movingInventory);
      }),

  fetchMovingInventory: async ({ commit }: any, payload: number) =>
    movingInventoryService
      .fetchMovingInventory(payload)
      .then((model: MovingInventory) => {
        const index = state.movingInventory.findIndex(
          (v: MovingInventory) => v.id === model.id
        );
        if (index === -1) {
          commit('ADD_MOVING_INVENTORY', model);
        } else {
          commit('UPDATE_MOVING_INVENTORY', model);
        }
      }),

  updateMovingInventory: async (
    { state, commit }: any,
    payload: MovingInventory
  ) =>
    movingInventoryService
      .updateMovingInventory(payload)
      .then((model: MovingInventory) => {
        const index = state.movingInventory.findIndex(
          (v: MovingInventory) => v.id === model.id
        );
        if (index === -1) {
          commit('ADD_MOVING_INVENTORY', model);
        } else {
          commit('UPDATE_MOVING_INVENTORY', model);
        }
      }),

  deleteMovingInventory: async ({ commit }: any, payload: number) =>
    movingInventoryService.deleteMovingInventory(payload).then(() => {
      commit('REMOVE_MOVING_INVENTORY', payload);
    }),

  fetchSharedMovingInventory: async ({ commit }: any, payload: number) =>
    movingInventoryService
      .fetchSharedMovingInventory(payload)
      .then((model: MovingInventory) => {
        commit('SET_MOVING_INVENTORY', model);
      })
};

export const getters: GetterTree<MovingInventoryState, MovingInventoryState> = {
  getMovingInventoryById: (state: MovingInventoryState) => (id: number) => {
    return state.movingInventory.find((model) => model.id === id);
  }
};
