
import dateFormat from '@/utils/dateFormat';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    value: {
      type: [Date, String]
    },
    showDefaultDate: {
      type: Boolean as PropType<boolean>,
      default: () => true
    }
  },
  data() {
    return {
      open: false,
      lazyValue: new Date(this.value) as Date | null | string
    };
  },
  computed: {
    formatedDate(): string {
      if (this.lazyValue == null) {
        return '';
      }
      return dateFormat(this.lazyValue, 'DD MMM YYYY', true);
    }
  },
  watch: {
    value: {
      handler(val: any) {
        if (!val) {
          this.lazyValue = null;
        } else {
          this.lazyValue = val;
        }
      },
      immediate: true
    }
  },
  methods: {
    selectedDay(date: string) {
      this.$emit('input', date);
      this.open = false;
    }
  }
});
