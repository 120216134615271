<template>
  <!-- <router-link
    :to="{
      name: route,
      params: {}
    }"
    aria-label="StaffNet Home Page"
    class="d-flex align-center"
    title="StaffNet Home Page"
    @click.native="$vuetify.goTo(0)"
  > -->
  <v-img
    alt="StaffNet Logo"
    :contain="contain"
    :src="
      useMini
        ? miniCompanyImage
        : useDark
        ? darkCompanyImage
        : lightCompanyImage
    "
    transition="scroll-y-transition"
    :size="size"
    :width="size ? size : useMini ? 45 : 100"
    :height="size"
  />

  <!-- <v-img
      alt="StaffNet Name"
      class="shrink mt-1 hidden-sm-and-down"
      contain
      min-width="100"
      src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
      width="100"
    />-->
  <!-- </router-link> -->
</template>

<script>
import { get } from 'vuex-pathify';
export default {
  name: 'BaseVuetifyLogo',
  computed: {
    miniCompanyImage: get('app/miniCompanyImage'),
    darkCompanyImage: get('app/darkCompanyImage'),
    lightCompanyImage: get('app/lightCompanyImage')
  },
  props: {
    route: {
      type: String,
      default: 'Home'
    },
    size: {
      type: [String, Number],
      default: null
    },
    useDark: {
      type: Boolean,
      default: false
    },
    contain: {
      type: Boolean,
      default: false
    },
    useMini: {
      type: Boolean,
      default: false
    }
  }
};
</script>
