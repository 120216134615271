
import Vue from 'vue';

enum StatusChoices {
  LEAD,
  FOLLOW_UP,
  SOLD,
  ACTIVE_LEAD,
  RETIRED_LEAD
}

type StatusTransition = {
  display_name: string;
  color: string;
  value: StatusChoices;
};

export default Vue.extend({
  name: 'CRMStatusFilter',

  props: {
    activeView: {
      required: true,
      type: Number,
      default: () => -1
    }
  },
  data() {
    return {
      lazyValue: this.activeView
    };
  },
  watch: {
    value: {
      handler(val: any) {
        this.lazyValue = val ? val : -1;
      },
      immediate: true
    }
  },
  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
        this.$emit('update:activeView', val);
      }
    },
    availableTransitions(): StatusTransition[] {
      return [
        {
          display_name: 'All',
          color: 'white',
          value: -1
        },
        {
          display_name: this.getStatusText(StatusChoices.LEAD),
          color: this.getStatusColor(StatusChoices.LEAD),
          value: StatusChoices.LEAD
        },
        {
          display_name: this.getStatusText(StatusChoices.FOLLOW_UP),
          color: this.getStatusColor(StatusChoices.FOLLOW_UP),
          value: StatusChoices.FOLLOW_UP
        },
        {
          display_name: this.getStatusText(StatusChoices.SOLD),
          color: this.getStatusColor(StatusChoices.SOLD),
          value: StatusChoices.SOLD
        },
        {
          display_name: this.getStatusText(StatusChoices.ACTIVE_LEAD),
          color: this.getStatusColor(StatusChoices.ACTIVE_LEAD),
          value: StatusChoices.ACTIVE_LEAD
        },
        {
          display_name: this.getStatusText(StatusChoices.RETIRED_LEAD),
          color: this.getStatusColor(StatusChoices.RETIRED_LEAD),
          value: StatusChoices.RETIRED_LEAD
        }
      ];
    }
  },
  methods: {
    getStatusColor(state: number): string {
      switch (state) {
        case StatusChoices.LEAD:
          return 'grey';
        case StatusChoices.FOLLOW_UP:
          return 'yellow darken-1';
        case StatusChoices.SOLD:
          return 'success';
        case StatusChoices.ACTIVE_LEAD:
          return 'teal';
        case StatusChoices.RETIRED_LEAD:
          return 'red';
        default:
          return 'white';
      }
    },

    getStatusText(state: number): string {
      switch (state) {
        case StatusChoices.LEAD:
          return 'Lead';
        case StatusChoices.FOLLOW_UP:
          return 'Follow Up';
        case StatusChoices.SOLD:
          return 'Sold';
        case StatusChoices.ACTIVE_LEAD:
          return 'Active Lead';
        case StatusChoices.RETIRED_LEAD:
          return 'Retired Lead';
        default:
          return 'Invalid';
      }
    }
  }
});
