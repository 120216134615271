
import { mapGetters, mapActions } from 'vuex';

//types
import {
  IFetchTimesheetsActionPayload,
  Timesheet,
  TimesheetState
} from '@/models/timesheet';

//functions
import dateFormat from '@/utils/dateFormat';
import { between } from '@/utils/time';
import auth from '@/mixins/auth';
import functions from '@/mixins/functions';
import { getHtmlELement, scrollToTargetOfHtmlElement } from '@/utils/helpers';
import mixins from '@/utils/mixins';
import { accountsProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { ErrorManager } from '@/models/error';
import { Account } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/account';
import moment from 'moment';
import { cloneDeep } from 'lodash';

type TimesheetHour = Timesheet & {
  hours: string;
  break_hours: string;
  timesheet_status: TimesheetState;
};

let scrollTop = 0;
enum StatusViews {
  ALL = -1,
  PENDING = 0,
  APPROVED = 1,
  IN_PROGRESS = 2
}

export default mixins(auth, functions).extend({
  data() {
    return {
      loading: false,
      btnLoading: false,
      searchHolder: '', //this holds the search value so it can be used on filter when nothing happens on search watch

      startFilterDate: moment(new Date())
        .subtract(2, 'weeks')
        .startOf('day')
        .utc(true)
        .toDate(),

      endFilterDate: moment(new Date()).endOf('day').utc(true).toDate(),

      title: 'Timesheet',
      search: '',
      errorMessage: '',

      statusViews: [
        { text: 'All', value: StatusViews.ALL },
        { text: 'Approved', value: StatusViews.APPROVED },
        { text: 'Pending', value: StatusViews.PENDING },
        { text: 'In Progress', value: StatusViews.IN_PROGRESS }
      ],
      activeView: StatusViews.ALL,
      employeeActiveView: -1,
      page: 1,
      itemsPerPage: 25,

      timesheetScrollTop: 0
    };
  },

  computed: {
    ...mapGetters('timesheets', {
      timesheets: 'getTimesheets',
      itemCount: 'getTimesheetCount'
    }),

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Job',
          value: 'job.title'
        },
        {
          text: 'Date',
          value: 'job.date.start'
        },
        {
          text: 'Employee',
          value: 'employee'
        },
        {
          text: 'Punch In',
          // sortable: false,
          value: 'punch_in'
        },
        {
          text: 'Punch Out',
          sortable: false,
          value: 'punch_out'
        },
        {
          text: 'Work Hours',
          sortable: false,
          value: 'hours'
        },
        {
          text: 'Break Hours',
          sortable: false,
          value: 'break_hours'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'timesheet_status.display_name'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        },
        {
          text: 'Approve',
          sortable: false,
          value: 'approve'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //removes approve column
        headerList.pop(); //removes actions column
      }
      return headerList;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    accountsProvider(): any {
      return accountsProvider(this.$store);
    },

    canFetchAllUsers(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    employeeViews(): any {
      const allViewsOption = [{ text: 'All', value: -1 }];

      (this as any).accountsProvider.accounts.map((account: Account) => {
        const option = {
          text: `${account.first_name} ${account.last_name}`,
          value: account.id
        };
        allViewsOption.push(option);
      });

      return allViewsOption;
    },

    stateFilteredTimesheets(): Timesheet[] {
      return this.timesheets.filter((timesheet: Timesheet) => {
        switch (this.activeView) {
          case StatusViews.PENDING:
            return timesheet.state?.value == StatusViews.PENDING;
          case StatusViews.APPROVED:
            return timesheet.state?.value == StatusViews.APPROVED;
          case StatusViews.IN_PROGRESS:
            return (
              timesheet.state?.value !== StatusViews.APPROVED &&
              timesheet.state?.value !== StatusViews.PENDING
            );
          default:
          case StatusViews.ALL:
            return timesheet;
        }
      });
    },

    timesheetsWithHours(): TimesheetHour[] {
      return this.stateFilteredTimesheets.map((timesheet: Timesheet) => {
        let breakHours = 0.0;

        for (let i = 0; timesheet.break_times!.length > i; i++) {
          if (timesheet.break_times![i].break_end !== null) {
            const expectedDuration = between(
              'hour',
              new Date(timesheet.break_times![i].break_end!),
              new Date(timesheet.break_times![i].break_start!)
            );
            breakHours = breakHours + expectedDuration;
          }
        }

        return {
          ...timesheet,
          hours: timesheet.punch_out
            ? this.getTimesheetHours(
                new Date(timesheet.punch_in!),
                new Date(timesheet.punch_out)
              ).toFixed(1)
            : '',
          break_hours: breakHours.toFixed(1),
          timesheet_status:
            timesheet.punch_out != null
              ? new TimesheetState({
                  display_name: timesheet.state?.display_name,
                  value: timesheet.state?.value ?? 0 //pending value is read as undefined so i had to set its value
                })
              : new TimesheetState({ display_name: 'In Progress', value: 2 })
        };
      });
    },

    totalHours(): number {
      let hours = 0.0;

      for (let i = 0; this.timesheetsWithHours.length > i; i++) {
        if (this.timesheetsWithHours[i].punch_out! != null) {
          const expectedDuration = between(
            'hour',
            new Date(this.timesheetsWithHours[i].punch_out!),
            new Date(this.timesheetsWithHours[i].punch_in!)
          );
          hours = hours + expectedDuration;
        }
      }

      return parseFloat(hours.toFixed(1));
    }
  },

  // these watchers were implemented to act as an onChange Listener for our date picker component
  watch: {
    page: {
      handler(val) {
        this.genFilteredTimesheetList(val);
      }
    },
    itemsPerPage: {
      handler() {
        this.genFilteredTimesheetList();
      }
    },
    search(newSearch, prevSearch) {
      this.checkJobReportSearch(newSearch, prevSearch);
    }
  },

  methods: {
    ...mapActions({
      fetchTimesheets: 'timesheets/fetchTimesheets',
      deleteTimesheet: 'timesheets/deleteTimesheet',
      fetchAccounts: 'accounts/fetchAccounts',
      fetchAccount: 'accounts/fetchAccount',
      setTimesheetStatus: 'timesheets/setTimesheetStatus',
      exportTimesheet: 'timesheets/exportTimesheet'
    }),

    dateFormat,

    async checkJobReportSearch(newSearch: string, prevSearch: string) {
      if (newSearch !== prevSearch) {
        this.searchHolder = newSearch;
        await this.debounce(this.genFilteredTimesheetList, 1000)();
      }
    },

    async genFilterParams(): Promise<IFetchTimesheetsActionPayload> {
      const params: IFetchTimesheetsActionPayload =
        {} as IFetchTimesheetsActionPayload;

      if (this.employeeActiveView != -1) {
        params.employee = this.employeeActiveView;
      }
      if (this.activeView == StatusViews.IN_PROGRESS) {
        params.in_progress = true;
      } else if (this.activeView != StatusViews.ALL) {
        params.state = this.activeView;
      }

      params.page_size = this.itemsPerPage != -1 ? this.itemsPerPage : 1000; //get max page size on server if user selects all;
      params.occurrence_after = this.startFilterDate;
      params.occurrence_before = this.endFilterDate;

      return params;
    },

    async setParamsFromRoute() {
      const routeQuery = this.$route.query;
      return new Promise<void>((resolve) => {
        if (routeQuery['occurrence_after']) {
          this.startFilterDate = new Date(
            routeQuery['occurrence_after'] as string
          );
        }
        if (routeQuery['occurrence_before']) {
          this.endFilterDate = new Date(
            routeQuery['occurrence_before'] as string
          );
        }
        if (routeQuery['page']) {
          this.page = Number(routeQuery['page']);
        }
        if (routeQuery['page_size']) {
          this.itemsPerPage = Number(routeQuery['page_size']);
        }
        if (routeQuery['employee']) {
          this.employeeActiveView = Number(routeQuery['employee']);
        }

        if (routeQuery['state'] != null) {
          this.activeView = Number(routeQuery['state']);
        }
        if (routeQuery['in_progress']) {
          this.activeView = StatusViews.IN_PROGRESS;
        }

        if (routeQuery['search']) {
          // cast value's type as string
          this.search = routeQuery['search'] as string;
        }

        resolve();
      });
    },

    async genFilteredTimesheetList(page = 1) {
      this.loading = true;

      // update type to match route query
      const params: Record<string, any> = await this.genFilterParams();

      params.page = page;

      // goes back to first page if user is on another page and does a filter
      if (page == 1) {
        this.page = 1;
      }

      params.search = this.searchHolder == '' ? undefined : this.searchHolder;

      const queryParams = cloneDeep(params);

      if (queryParams.occurrence_after) {
        queryParams.occurrence_after = (
          queryParams.occurrence_after as Date
        ).toISOString();
      }
      if (queryParams.occurrence_before) {
        queryParams.occurrence_before = (
          queryParams.occurrence_before as Date
        ).toISOString();
      }

      /* this will update your URL query based on toggled values. */
      this.$router
        .replace({ name: 'timesheets-list', query: queryParams })
        .catch((e) => e);

      return this.fetchTimesheets(params)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    },

    async exportTimesheetTable() {
      this.btnLoading = true;

      const params = await this.genFilterParams();

      this.exportTimesheet(params)
        .then((data) => {
          this.createDataFile(data);
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.btnLoading = false));
    },

    getTimesheetHours(punch_in: Date, punch_out: Date): number {
      return between('hour', punch_out, punch_in);
    },

    async deleteTimesheetById(id: number) {
      if (
        await (this as any).$refs.deleteConfirmDialog.open(
          'Are you sure you want to delete this timesheet?',
          {
            left: {
              text: 'No'
            },
            right: {
              color: 'primary',
              text: 'yes'
            }
          }
        )
      ) {
        return this.deleteTimesheet(id);
      } else {
        return;
      }
    },

    async openTimesheetFormDialog(timesheet?: Timesheet) {
      const action = timesheet ? 'Edit-Timesheet' : 'Create-Timesheet';
      const timesheetId = timesheet ? (timesheet.id as number) : 0;

      this.$router.push({
        name: 'timesheet-form',
        params: {
          action: action,
          timesheetId: String(timesheetId)
        }
      });
    },

    getColor(hours: number, start: string, end: string) {
      const expectedDuration = between('hour', new Date(end), new Date(start));
      if (hours - expectedDuration > 0.5) return 'red';
      else if (hours - expectedDuration > 0) return 'orange';
      else return 'green';
    },

    setApprovalStatus(item: Timesheet) {
      const status = item.state!.value == 1 ? 0 : 1;
      this.setTimesheetStatus({ id: item.id, status }).catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
    },

    convertUTCDateToLocalDate(date: any) {
      const _date = new Date(date);
      const newDate = new Date(
        _date.getTime() - _date.getTimezoneOffset() * 60 * 1000
      );
      return newDate;
    },

    createDataFile(data: Blob) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      const linkName = 'Timesheet List.xlsx';
      link.href = url;
      link.setAttribute('download', linkName); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    goToTimesheetDetail(timesheet: Timesheet) {
      return this.$router.push({
        name: 'timesheet-detail',
        params: { timesheetId: String(timesheet.id) }
      });
    },

    async getTimesheetDataTableElement() {
      return new Promise<HTMLElement | null>((resolve) =>
        resolve(
          getHtmlELement(
            '#scrollableTimesheetList.v-data-table--fixed-header>.v-data-table__wrapper'
          )
        )
      );
    }
  },

  async beforeDestroy() {
    const timesheetDataTable = await this.getTimesheetDataTableElement();
    if (timesheetDataTable) {
      // clean up of event listeners
      timesheetDataTable.removeEventListener('scroll', () => {});
    }
  },

  async mounted() {
    const timesheetDataTable = await this.getTimesheetDataTableElement();

    if (timesheetDataTable) {
      await scrollToTargetOfHtmlElement(timesheetDataTable, scrollTop, 250);

      timesheetDataTable.addEventListener(
        'scroll',
        () => {
          scrollTop = timesheetDataTable.scrollTop;
        },
        { passive: true }
      );
    }

    this.loading = true;

    if (this.canFetchAllUsers) {
      this.accountsProvider.fetchAccounts(true).catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
    }
    await this.setParamsFromRoute();
    this.genFilteredTimesheetList();
  }
});
