import { CalendarOccurrence } from './calendar';
import { Company } from './company';
import { Contact } from './contact';

export class MovingInventory {
  id?: number;
  customer?: Customer;
  order_number?: string;
  job?: CalendarOccurrence | any;
  occurrence_date?: Date | string;
  inventory_taker?: string;
  quantity?: number;

  delivered_time?: Date;
  received_time?: Date;
  vendor_shipped?: Customer;
  vendor_received?: Customer;

  constructor() {
    this.customer = new Customer();
    this.vendor_received = new Customer();
    this.vendor_shipped = new Customer();
    this.job = new CalendarOccurrence();
  }
}

export class Customer {
  id?: number;
  contact?: Contact | number | null;
  company?: Company | number | null;

  constructor() {
    this.company = new Company();
    this.contact = new Contact();
  }
}
