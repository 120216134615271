import {
  ICountry,
  ICountryData,
  ICountryFieldError,
  ICountryFieldErrorData,
  COUNTRY_CODE_LENGTH
} from './country.types';

export class Country implements ICountry {
  readonly code?: string;
  readonly name?: string;

  constructor(data?: ICountryData) {
    if (data) {
      if (data.code) {
        this.code = data.code;
      }
      if (data.name) {
        this.name = data.name;
      }
    }
  }

  validate(): boolean {
    if (this.code && this.code.length != COUNTRY_CODE_LENGTH) {
      return false;
    }
    return true;
  }
}

export class CountryFieldError implements ICountryFieldError {
  readonly code: string[] = [];
  readonly name: string[] = [];

  constructor(data?: ICountryFieldErrorData) {
    if (data) {
      if (data.code) {
        this.code = data.code;
      }
      if (data.name) {
        this.name = data.name;
      }
    }
  }

  clearErrors(): void {
    this.code.splice(0, this.code.length);
    this.name.splice(0, this.name.length);
  }

  static isCountryFieldError(data: ICountryFieldErrorData): boolean {
    if (data.code && Array.isArray(data.code)) return true;
    if (data.name && Array.isArray(data.name)) return true;
    return false;
  }
}
