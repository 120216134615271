import Vue from 'vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'snackbar-callable',
  computed: {
    snack: {
      get: function () {
        return this.$store.state.snackbar.value;
      },
      set: function (val) {
        this.setValue(val);
      }
    },
    snackbar: {
      get: function () {
        return this.$store.state.snackbar.snackbar;
      },
      set: function (val) {
        this.setSnackbar(val);
      }
    },
    computedIcon() {
      switch (this.snackbar.type) {
        case 'edit':
          return 'mdi-content-save';
        case 'delete':
          return 'mdi-delete';
        case 'primary':
          return 'mdi-account-circle';
        case 'store':
          return 'mdi-cart';
        case 'success':
          return 'mdi-check';
        case 'info':
          return 'mdi-information';
        case 'warning':
          return 'mdi-alert';
        case 'error':
          return 'mdi-alert-circle';
        default:
          return 'mdi-account-circle';
      }
    }
  },
  methods: {
    ...mapMutations({
      setSnackbar: 'snackbar/setSnackbar',
      setValue: 'snackbar/setValue'
    })
  }
});
