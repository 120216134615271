
import { isArray } from '@/utils/helpers';
import Vue, { PropType } from 'vue';
import { Attendee, Participant } from '@/models/calendar';
import { mapActions } from 'vuex';
import { Logger, setLogLevel } from '@/utils/logger';
import { ErrorManager } from '@/models/error';

const logger = new Logger('Participant Section');
logger.setLogLevel('debug');

export default Vue.extend({
  props: {
    value: {
      type: [Array] as PropType<Participant[]>,
      default: () => []
    }
  },
  data() {
    return {
      emailDialog: false,
      searchParticipantsQuery: '',
      errorMessage: '' as string,
      participantsIsLoading: false,
      searchParticipantList: [],
      lazyValue: this.value
    };
  },

  computed: {
    internalValue: {
      get(): Participant[] {
        return this.lazyValue;
      },
      set(val: Participant[]) {
        this.lazyValue = val;
      }
    }
  },

  watch: {
    value: {
      handler(val: any) {
        (this as any).internalValue = val;
      },
      immediate: true
    },
    searchParticipantsQuery: {
      async handler(participantsQuery, oldClients) {
        // Items have already been loaded
        if (this.searchParticipantList.length > 0) return;

        // Items have already been requested
        if (this.participantsIsLoading) return;

        this.participantsIsLoading = true;

        // Lazily load input items
        this.fetchAccounts(true)
          .then((res: any) => {
            this.searchParticipantList = res;
          })
          .catch((err: any) => {
            this.errorMessage = ErrorManager.extractApiError(err);
          })
          .finally(() => (this.participantsIsLoading = false));
      }
    }
  },
  methods: {
    ...mapActions('accounts', ['fetchAccounts']),

    getRsvpIcon(rsvp: number): string {
      switch (rsvp) {
        case 0:
          return 'mdi-close';
        case 1:
          return 'mdi-check';
        case 2:
          return 'mdi-help';
        default:
          return 'mdi-help';
      }
    },
    getRsvpColor(rsvp: number): string {
      switch (rsvp) {
        case 0:
          return 'red lighten-2';
        case 1:
          return 'success';
        case 2:
          return 'grey';
        default:
          return 'grey';
      }
    },
    addSelectedAttendee(attendee: Attendee) {
      if (isArray(this.internalValue)) {
        const index: number = (this as any).internalValue.findIndex(
          (participant: Participant) => participant.attendee.id === attendee.id
        );
        if (index === -1) {
          (this as any).internalValue?.push({ attendee });
        }
        this.searchParticipantsQuery = '';
      }
    },
    removeParticipant(participant: Participant) {
      const index = this.internalValue.findIndex(
        (setParticipant) => setParticipant == participant
      );

      this.internalValue.splice(index, 1);
    }
  }
});
