/**
 * Do a deep-copy of basic JavaScript Objects or Arrays.
 */
export function deepCopy<T>(value: T): T {
  return deepExtend(undefined, value) as T;
}

/**
 * Copy properties from source to target (recursively allows extension
 * of Objects and Arrays).  Scalar values in the target are over-written.
 * If target is undefined, an object of the appropriate type will be created
 * (and returned).
 *
 * We recursively copy all child properties of plain Objects in the source- so
 * that namespace- like dictionaries are merged.
 *
 * Note that the target can be a function, in which case the properties in
 * the source Object are copied onto it as static properties of the Function.
 */
export function deepExtend(target: unknown, source: unknown): unknown {
  if (!(source instanceof Object)) {
    return source;
  }

  switch (source.constructor) {
    case Date:
      // Treat Dates like scalars; if the target date object had any child
      // properties - they will be lost!
      // const dateValue = source as Date;
      return new Date((source as Date).getTime());

    case Object:
      if (target === undefined) {
        target = {};
      }
      break;
    case Array:
      // Always copy the array source and overwrite the target.
      target = [];
      break;

    default:
      // Not a plain Object - treat it as a scalar.
      return source;
  }

  for (const prop in source) {
    // if (!(source as Record<string, any>).hasOwnProperty(prop)) {
    if (!Object.prototype.hasOwnProperty.call(source, prop)) {
      continue;
    }
    (target as { [key: string]: unknown })[prop] = deepExtend(
      (target as { [key: string]: unknown })[prop],
      (source as { [key: string]: unknown })[prop]
    );
  }

  return target;
}
