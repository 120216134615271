
// Utilities
import { waitForReadystate } from '@/utils/componentHelpers';
import accounts from '@/mixins/accounts';
import mixins from '@/utils/mixins';

export default mixins(accounts).extend({
  metaInfo: {
    titleTemplate: '%s StaffNet',
    htmlAttrs: {
      lang: 'en'
    }
  },
  name: 'App',

  async mounted() {
    if (!this.$route.hash) return;

    await this.$nextTick();
    await waitForReadystate();

    this.$vuetify.goTo(this.$route.hash);
  }
});
