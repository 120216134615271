
import { mapActions, mapGetters } from 'vuex';
import Proxyable from '@/mixins/proxyable';

export default Proxyable.extend({
  data() {
    return {
      selectedVendorShipped: '' as any,
      selectedVendorReceived: '' as any
    };
  },

  computed: {
    ...mapGetters({
      contacts: 'contactV2/getContacts',
      companies: 'companyV2/getCompanies'
    }),

    vendorList(): any {
      const vendorList = [];

      vendorList.push({ header: 'Contacts' });
      for (let i = 0; this.contacts.length > i; i++) {
        const contactObject = {
          id: this.contacts[i].id,
          name: `${this.contacts[i].name.first} ${this.contacts[i].name.last}`,
          type: 'Contact'
        };
        vendorList.push(contactObject);
      }

      vendorList.push({ divider: true });

      vendorList.push({ header: 'Companies' });
      for (let i = 0; this.companies.length > i; i++) {
        const companyObject = {
          id: this.companies[i].id,
          name: this.companies[i].name,
          type: 'Company'
        };
        vendorList.push(companyObject);
      }

      return vendorList;
    }
  },

  methods: {
    ...mapActions({
      fetchContacts: 'contactV2/fetchContacts',
      fetchCompanies: 'companyV2/fetchCompanies'
    }),

    filterVendorObject(item: any, queryText: string, itemText: any) {
      return (
        item.name?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.type?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },

    setVendorShipped() {
      if (this.selectedVendorShipped !== null) {
        if (this.selectedVendorShipped.type == 'Contact') {
          this.internalValue.vendor_shipped = {
            contact: this.selectedVendorShipped.id,
            company: null
          };
        }

        if (this.selectedVendorShipped.type == 'Company') {
          this.internalValue.vendor_shipped = {
            contact: null,
            company: this.selectedVendorShipped.id
          };
        }
      } else {
        this.internalValue.vendor_shipped = {
          contact: null,
          company: null
        };
      }
    },

    setVendorReceived() {
      if (this.selectedVendorReceived !== null) {
        if (this.selectedVendorReceived.type == 'Contact') {
          this.internalValue.vendor_received = {
            contact: this.selectedVendorReceived.id,
            company: null
          };
        }

        if (this.selectedVendorReceived.type == 'Company') {
          this.internalValue.vendor_received = {
            contact: null,
            company: this.selectedVendorReceived.id
          };
        }
      } else {
        this.internalValue.vendor_received = {
          contact: null,
          company: null
        };
      }
    }
  },

  mounted() {
    this.fetchCompanies();
    this.fetchContacts();

    if (this.$route.params.movingInventoryId !== '0') {
      if (this.internalValue.vendor_shipped.company !== null)
        this.selectedVendorShipped = {
          id: this.internalValue.vendor_shipped.company.id,
          name: this.internalValue.vendor_shipped.company.name,
          type: 'Company'
        };

      if (this.internalValue.vendor_shipped.contact !== null)
        this.selectedVendorShipped = {
          id: this.internalValue.vendor_shipped.contact.id,
          name: `${this.internalValue.vendor_shipped.contact.name.first} ${this.internalValue.vendor_shipped.contact.name.last}`,
          type: 'Contact'
        };

      if (this.internalValue.vendor_received.company !== null)
        this.selectedVendorReceived = {
          id: this.internalValue.vendor_received.company.id,
          name: this.internalValue.vendor_received.company.name,
          type: 'Company'
        };

      if (this.internalValue.vendor_received.contact !== null)
        this.selectedVendorReceived = {
          id: this.internalValue.vendor_received.contact.id,
          name: `${this.internalValue.vendor_received.contact.name.first} ${this.internalValue.vendor_received.contact.name.last}`,
          type: 'Contact'
        };
    }
  }
});
