import moment from 'moment';
import { CalendarService } from '@/services/calendar';

import { CalendarOccurrence } from '@/models/calendar';

import Vue from 'vue';

import { CalendarOccurrenceState, Time } from '../types/CalendarOccurrence';

const calendarService = new CalendarService();

export const state = {
  timeRange: {
    start: null,
    end: null
  },
  occurrences: [],
  occurrencesByCalendarId: {}
} as CalendarOccurrenceState;
export const mutations = {
  updateTimeRangeStart(state: CalendarOccurrenceState, time: Time) {
    // state.timeRange.start = time;
    Vue.set(state.timeRange, 'start', time);
  },
  updateTimeRangeEnd(state: CalendarOccurrenceState, time: Time) {
    // state.timeRange.end = time;
    Vue.set(state.timeRange, 'end', time);
  },

  updateTimeRange(
    state: CalendarOccurrenceState,
    { start, end }: { start: Time; end: Time }
  ) {
    // state.timeRange.start = time;
    // state.timeRange.end = time;

    Vue.set(state.timeRange, 'start', start);
    Vue.set(state.timeRange, 'end', end);
  },

  addCalendarOccurrence(
    state: CalendarOccurrenceState,
    occurrence: CalendarOccurrence
  ) {
    // const object = getDefaultCalendarObject(calendar);

    const parseCalendarOccurrenceObject = (occurrence: CalendarOccurrence) => {
      occurrence.date!.start = new Date(occurrence.date?.start!);
      occurrence.date!.end = new Date(occurrence.date?.end!);
      return occurrence;
    };

    const object = parseCalendarOccurrenceObject(occurrence);

    Vue.set(
      state.occurrencesByCalendarId,
      new Date(object.original_start!).getTime(),
      occurrence
    );
  }
};
export const actions = {
  async updateEventOccurrence(
    context: any,
    {
      calendarId,
      eventId,
      recurrenceId,
      occurrence
    }: {
      calendarId: number;
      eventId: number;
      recurrenceId: number;
      occurrence: CalendarOccurrence;
    }
  ) {
    return await new Promise((resolve, reject) => {
      const recurrenceDate = moment(Number(recurrenceId)).utc();

      calendarService
        .updateCalendarOccurrence(
          {
            calendarId: calendarId,
            eventId: eventId
          },
          {
            year: recurrenceDate.year(),
            month: recurrenceDate.month() + 1,
            day: recurrenceDate.date(),
            hour: recurrenceDate.hour(),
            minute: recurrenceDate.minute(),
            second: recurrenceDate.seconds()
          },
          occurrence
        )
        // Do we want to update current occurrence?
        .then((occurrence: CalendarOccurrence | any) => resolve(occurrence))
        .catch((error: Error) => reject(error));
    });
  },
  async deleteCalendarOccurrence(
    context: any,
    {
      calendarId,
      eventId,
      recurrenceId
    }: { calendarId: number; eventId: number; recurrenceId: number }
  ) {
    return await new Promise((resolve, reject) => {
      const recurrenceDate = moment(recurrenceId).utc();

      calendarService
        .deleteCalendarOccurrence(
          {
            calendarId: calendarId,
            eventId: eventId
          },
          {
            year: recurrenceDate.year(),
            month: recurrenceDate.month() + 1,
            day: recurrenceDate.date(),
            hour: recurrenceDate.hour(),
            minute: recurrenceDate.minute(),
            second: recurrenceDate.seconds()
          }
        )
        // Do we want to update current occurrence?
        .then((occurrence: CalendarOccurrence | any) => resolve(occurrence))
        .catch((error: Error) => reject(error));
    });
  },

  fetchCalendarOccurrence: async (
    { commit, dispatch }: any,
    {
      calendarId,
      eventId,
      recurrenceId
    }: { calendarId: number; eventId: number; recurrenceId: number }
  ) => {
    const recurrenceDate = moment(recurrenceId).utc();

    return await new Promise((resolve, reject) => {
      calendarService
        .fetchCalendarOccurrence(
          {
            calendarId: calendarId,
            eventId: eventId
          },
          {
            year: recurrenceDate.year(),
            month: recurrenceDate.month() + 1,
            day: recurrenceDate.date(),
            hour: recurrenceDate.hour(),
            minute: recurrenceDate.minute(),
            second: recurrenceDate.seconds()
          }
        )
        .then((occurrence) => {
          commit('addCalendarOccurrence', occurrence);
          resolve(occurrence);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {});
    });
  },

  fetchCalendarOccurrenceReport: async (
    { commit, dispatch }: any,
    { eventId, recurrenceId }: { eventId: number; recurrenceId: number }
  ) => {
    const recurrenceDate = moment(recurrenceId).utc();

    return calendarService.fetchCalendarOccurrenceReport(
      {
        eventId: eventId
      },
      {
        year: recurrenceDate.year(),
        month: recurrenceDate.month() + 1,
        day: recurrenceDate.date(),
        hour: recurrenceDate.hour(),
        minute: recurrenceDate.minute(),
        second: recurrenceDate.seconds()
      }
    );
  },

  fetchCalendarOccurrences: async (
    { commit }: any,
    {
      calendarId,
      start,
      end
    }: { calendarId: number; start: Date | string; end: Date | string }
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .fetchCalendarOccurrences(
          {
            calendarId: calendarId
          },
          {
            start,
            end
          }
        )
        .then((occurrences) => {
          // commit('SET_CALENDAR_OCCURRENCES', occurrences);
          resolve(occurrences);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {});
    });
  },

  sendCalendarOccurrenceInvite: async (
    { commit, dispatch }: any,
    {
      calendarId,
      eventId,
      recurrenceId
    }: { calendarId: number; eventId: number; recurrenceId: number }
  ) => {
    const recurrenceDate = moment(recurrenceId).utc();

    return await new Promise((resolve, reject) => {
      calendarService
        .sendCalendarOccurrenceInvite(
          {
            calendarId,
            eventId
          },
          {
            year: recurrenceDate.year(),
            month: recurrenceDate.month() + 1,
            day: recurrenceDate.date(),
            hour: recurrenceDate.hour(),
            minute: recurrenceDate.minute(),
            second: recurrenceDate.seconds()
          }
        )
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {});
    });
  },

  getCalendarOccurrenceInvite: async (
    { commit, dispatch }: any,
    {
      calendarId,
      eventId,
      occurrenceId,
      token,
      rsvp
    }: {
      calendarId: number;
      eventId: number;
      occurrenceId: number;
      token: string;
      rsvp: number;
    }
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .getCalendarOccurrenceInvite({
          calendarId,
          eventId,
          occurrenceId,
          token,
          rsvp
        })
        .then((result: Partial<CalendarOccurrence & { rsvp: number }>) => {
          resolve(result);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }
};

export const getters = {
  getOccurrencebyRecurrenceTime:
    (state: CalendarOccurrenceState) => (recurrenceEpoch: number) => {
      return state.occurrences.filter(
        (calendar: CalendarOccurrence) =>
          new Date(calendar.original_start!).getTime() == recurrenceEpoch
      );
    }
};
