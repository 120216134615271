
import Vue from 'vue';
import { kebabCase } from 'lodash';

export default Vue.extend({
  name: 'CommonGoto',
  computed: {
    href(): string {
      const id = this.$attrs && (this as any).$attrs.id! ? this.$attrs.id : '';
      return `#${kebabCase(id)}`;
    }
  },

  methods: {
    onClick(e: MouseEvent) {
      e.stopPropagation();

      this.$router.push(this.href).catch(() => null);
    }
  }
});
