
import Vue from 'vue';
import { RouteRecord } from 'vue-router';
export default Vue.extend({
  name: 'Board',
  // props: {
  //   large: Boolean,
  //   addClasses: [String, Array, Object],
  //   addLinkClasses: [String, Array, Object],
  //   addLastItemClasses: [String, Array, Object]
  // },
  computed: {
    // classes(): object {
    //   return {
    //     'v-breadcrumbs--large': this.$attrs.large
    //     // ...this.themeClasses,
    //   };
    // },
    items(): any[] {
      const routes: RouteRecord[] = this.$route.matched.filter((route) => {
        return route.name || (route.meta && route.meta.label);
      });
      return routes.map((route: RouteRecord) => {
        const meta = route.meta || {};
        return {
          to: route,
          text: meta.label || route.name,
          exact: true,
          link: true,
          ripple: true
        };
      });
    }
  }
});
