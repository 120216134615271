
import Vue from 'vue';
import PhoneNumber from 'awesome-phonenumber';
import { mapActions, mapGetters } from 'vuex';
import { IEmployee, IEmployeeUser } from '@/models';
import { ErrorManager } from '@/models/error';

import EmployeeDetailFormDialog from './EmployeeDetailFormDialog.vue';
import { ExtractVue } from '@/utils/mixins';
import moment from 'moment';

export default Vue.extend({
  components: { EmployeeDetailFormDialog },
  name: 'EmployeeList',
  props: ['id'],

  data() {
    return {
      loading: false,
      search: '',
      errorMessage: '',
      tab: null
    };
  },
  computed: {
    ...mapGetters({ getEmployeeById: 'employee/getEmployeeById' }),

    employeeId(): number {
      return Number(this.id || this.$route.params.employeeId);
    },

    employee(): IEmployee {
      return this.getEmployeeById(this.employeeId);
    },

    employeeFullName(): string {
      if (this.employee && this.employee.user) {
        return (this.employee.user as IEmployeeUser).fullName;
      }
      return '';
    },

    employeeInitials(): string {
      if (this.employee && this.employee.user) {
        return (this.employee.user as IEmployeeUser).initials;
      }
      return '';
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      fetchEmployee: 'employee/fetchEmployee',
      deleteEmployee: 'employee/deleteEmployee'
    }),

    openEmployeeDetailForm(): void {
      const employeeFormDialog = this.$refs.employeeFormDialog as ExtractVue<
        typeof EmployeeDetailFormDialog
      >;
      employeeFormDialog.open(this.employee, true);
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    formatPhone(phone: string) {
      const phoneFormat = new PhoneNumber(phone).getNumber('international');
      // can potentially be undefined if input is bad
      if (phoneFormat) return phoneFormat;
      return phone;
    },

    loadableImage(fileName: string): boolean {
      if (!fileName) return false;
      // if file ends with any of these regex expresions
      return !!fileName.match(/.(jpg|jpeg|png|gif)$/i)?.length;
    },

    getFilename(url: string, extension: boolean) {
      const filename = decodeURIComponent(
        new URL(url).pathname.split('/').pop()!
      );
      if (!filename) return 'file'; // some default filename

      if (extension) return filename.replace(/^(.+?)(?:\.[^.]*)?$/, '$1');

      return filename;
    },

    dateFormat(
      date: Date | string | number | (number | string)[],
      format?: string
    ) {
      const fmt = format || 'YYYY-MM-DD';
      return moment(date).utc().format(fmt).toString();
    }
  },

  mounted() {
    this.loading = true;
    this.fetchEmployee(this.employeeId)
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  }
});
