import { IProductState } from '@/store/product';
import { IProductMutations } from './mutations.types';
import { IProductData } from '@/models';

export const mutations: IProductMutations = {
  SET_PRODUCTS(state: IProductState, payload: IProductData[]): void {
    state.products = payload;
  },

  ADD_PRODUCT(state: IProductState, payload: IProductData): void {
    state.products.push(payload);
  },

  UPDATE_PRODUCT(state: IProductState, payload: IProductData): void {
    const index = state.products.findIndex(
      (product: IProductData) => product.id === payload.id
    );

    state.products.splice(index, 1, payload);
  },

  REMOVE_PRODUCT(state: IProductState, payload: number): void {
    const index = state.products.findIndex(
      (product: IProductData) => product.id === payload
    );

    state.products.splice(index, 1);
  }
};
