
import { mapGetters, mapActions } from 'vuex';

//functions
import { Inventory } from '@/models/inventory';
import { ErrorManager } from '@/models/error';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';

export default mixins(auth).extend({
  props: {
    warehouseId: {
      type: Number
    },
    showHeaderTitle: {
      type: Boolean,
      default: true
    },
    search: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      deleteInventoryDialog: false,
      selectedInventory: new Inventory(),
      errorMessage: '',
      errorFlag: false
    };
  },

  computed: {
    ...mapGetters({
      inventories: 'inventoryStore/getInventories'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    canCreateInventory(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    headers(): object[] {
      const headerList = [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Item #',
          value: 'item.identifier'
        },
        {
          text: 'Item Name',
          value: 'item.name'
        },
        {
          text: 'Description',
          width: '100px',
          sortable: false,
          value: 'item.description'
        },
        {
          text: 'Warehouse #',
          value: 'warehouse.identifier'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Quantity Stock',
          sortable: false,
          width: '190px',
          value: 'quantity'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ];

      if (this.isUserEmployee) {
        headerList.pop(); //removes actions column
      }
      return headerList;
    }
  },

  methods: {
    ...mapActions({
      fetchInventories: 'inventoryStore/fetchInventories',
      deleteInventory: 'inventoryStore/deleteInventory',
      fetchWarehouses: 'warehouseStore/fetchWarehouses',
      fetchItems: 'itemStore/fetchItems',
      updateQuantity: 'inventoryStore/updateQuantity'
    }),

    openInventoryDeleteDialog(inventory: Inventory) {
      this.deleteInventoryDialog = true;
      this.selectedInventory = inventory;
    },

    changeQuantity(inventory: Inventory) {
      const qty = String(inventory.quantity) == '' ? 0 : inventory.quantity;

      inventory.quantity = qty; //<-- update textfield with new information

      const payload = {
        id: inventory.id,
        warehouse: inventory.warehouse?.id,
        item: inventory.item?.id,
        quantity: qty,
        location: inventory.location
      };
      this.updateQuantity(payload);
    },

    deleteInventoryAction() {
      this.deleteInventory(this.selectedInventory.id).finally(
        () => (this.deleteInventoryDialog = false)
      );
    },

    inventorySelected(instance: Inventory) {
      return this.$router.push({
        name: 'inventory-detail',
        params: { itemNumber: String(instance.id) }
      });
    },

    /**
     * open  dialog
     *
     *
     */
    openInventoryTableDialog(isEdit = false, instance?: Inventory) {
      (this as any).$refs.inventoryTableDialog.open(isEdit, instance);
    },

    async setData() {
      this.loading = true;
      this.fetchInventories(this.warehouseId)
        .then(() => {
          this.loading = false;
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
      await this.fetchWarehouses();
      await this.fetchItems();
    }
  },

  async mounted() {
    this.setData();
  }
});
