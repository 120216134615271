
import Vue, { PropType } from 'vue';
import {
  Calendar,
  Identifier,
  Functions as fn,
  CalendarDay,
  Day,
  TimeInput,
  DaySpan,
  Parse
} from 'dayspan';

import RRule, { rrulestr, Frequency } from 'rrule';
import { isArray, isString } from '@/utils/helpers';
const prop = 'value',
  event = 'input';
export default Vue.extend({
  model: { prop, event },

  props: {
    value: null as any as PropType<any>,
    useCustom: Boolean,
    start: {
      required: true,
      type: [Date] as PropType<Date>
    }
  },
  data() {
    return {
      lazyValue: this.value,
      hasMouseDown: false,
      open: false
    };
  },
  computed: {
    internalValue: {
      get(): string[] {
        return this.lazyValue as any;
      },
      set(val: string[]) {
        this.lazyValue = val;
        console.count('Called update on Rule');
        this.$emit(event, val);
      }
    } as any,
    formatedRule() {
      return (this as any).internalValue.length
        ? (this as any).internalValue
            .map((rule: string) => RRule.fromString(rule).toText())
            .join(', ')
        : "Doesn't Repeat";
    },
    recurrenceDefaultListOptions() {
      return [
        null,
        {
          freq: Frequency.DAILY,
          dtstart: new Day(this.start).toDate(),
          byweekday: new Day(this.start).dayOfWeek
        },
        {
          freq: Frequency.WEEKLY,
          dtstart: new Day(this.start).toDate(),
          byweekday: new Day(this.start).dayOfWeek
        },
        {
          freq: Frequency.MONTHLY,
          dtstart: new Day(this.start).toDate(),
          byweekday: new Day(this.start).dayOfWeek
        }
      ].map((item: any) => {
        item = item ? new RRule(item) : null;
        return {
          text: item ? item.toText() : 'Doesnt Repeat',
          value: item ? [item.toString()] : []
        };
      });
    }
  },
  watch: {
    value: {
      handler(val: any) {
        (this as any).lazyValue = val;
      },
      immediate: true
    }
  },
  methods: {
    cancel() {
      (this as any).open = false;
    },
    selectRecurrenceOption(recurrence: any) {
      this.internalValue = recurrence.value;
      // this.$emit(event, recurrence.value);
    }
  }
});
