import { axios } from '@/plugins/axios';
import {
  Timesheet,
  IFetchTimesheetsActionPayload,
  ITimesheetPaginatedListData
} from '@/models/timesheet';
export class TimesheetService {
  endpoint: string;

  constructor() {
    this.endpoint = 'v2/timesheets/';
  }

  async fetchTimesheets(payload?: IFetchTimesheetsActionPayload) {
    return axios
      .get<ITimesheetPaginatedListData>(`${this.endpoint}`, {
        timeout: 30 * 1000, // 30 seconds
        params: payload
          ? {
              page: payload.page,
              page_size: payload.page_size,
              search: payload.search,
              employee: payload.employee,
              state: payload.state,
              occurrence_before: payload.occurrence_before,
              occurrence_after: payload.occurrence_after,
              in_progress: payload.in_progress
            }
          : undefined
      })
      .then(({ data }) => data);
  }

  async fetchTimesheet(id: number) {
    return axios
      .get<Timesheet>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  async createTimesheet(payload: object) {
    return axios
      .post<Timesheet>(`${this.endpoint}`, payload)
      .then(({ data }) => data);
  }

  async updateTimesheet(id: number, payload: object) {
    return axios
      .patch<Timesheet>(`${this.endpoint}${id}/`, payload)
      .then(({ data }) => data);
  }

  async deleteTimesheet(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }

  async setStatus(id: number, status: number) {
    return axios
      .put<Timesheet>(`${this.endpoint}${id}/set_state/${status}/`)
      .then(({ data }) => data);
  }

  async exportTimesheet(
    employee?: number,
    state?: number,
    occurrence_before?: Date | string,
    occurrence_after?: Date | string,
    in_progress?: boolean
  ) {
    return axios
      .get(`v1/timesheets/export/`, {
        responseType: 'blob',
        timeout: 60 * 1000,
        params: {
          employee,
          state,
          occurrence_before,
          occurrence_after,
          in_progress
        }
      })
      .then(({ data }) => data);
  }
}
