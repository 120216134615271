
import Proxyable from '@/mixins/proxyable';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import { IJobData, IJobStateParams } from '@/models/job/job.types';
import { mapActions, mapGetters } from 'vuex';
import { JobStateType } from '@/models/job/job';
import moment from 'moment';
import dateFormat from '@/utils/dateFormat';
import { isSameDay } from '@/utils/dateHelpers';
import ChoiceDialog from '@/components/core/ChoiceDialog.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import { getObjectValueByPath } from '@/utils/helpers';

export default Proxyable.extend({
  components: { ErrorAlert, ChoiceDialog, ConfirmationDialog },

  data() {
    return {
      selectedEvents: [] as IJobData[],
      errorMessage: '' as string,
      selectedJob: null as null | IJobData,
      sendInviteDialog: false,
      inviteInProgress: false,

      loading: false,

      selectedEventsIndex: 0
    };
  },

  computed: {
    ...mapGetters({
      getDraftEventsList: 'calendar/getDraftEventsList'
    }),

    draftEvents(): IJobData[] {
      return this.getDraftEventsList;
    },

    everyItemSelected(): boolean {
      return this.everyGroupItemSelected(this.draftEvents);
    },

    someItemsSelected(): boolean {
      return this.someGroupItemsSelected(this.draftEvents);
    },

    groupedJobs(): Record<string, IJobData[]> {
      return this.groupJobsByDate(this.draftEvents as IJobData[], 'date.start');
    }
  },

  watch: {
    draftEvents: {
      handler(value) {
        //resets eveents list on getter rebuild
        this.selectedEvents = [];
      }
    }
  },

  methods: {
    ...mapActions('job', ['updateJobState']),

    ...mapActions('snackbar', ['snackMessage', 'snackError']),

    ...mapActions('calendar', ['sendCalendarEventInvite']),

    ...mapActions('calendarOccurrence', ['sendCalendarOccurrenceInvite']),

    dateFormat,
    isSameDay,

    everyGroupItemSelected(jobs: IJobData[]): boolean {
      return jobs.every((job) => this.selectedEvents.includes(job));
    },
    someGroupItemsSelected(jobs: IJobData[]): boolean {
      return jobs.some((job) => this.selectedEvents.includes(job));
    },
    selectAllGroupJobs(jobs: IJobData[], value: boolean) {
      for (let i = 0; i < jobs.length; i++) {
        const item = jobs[i];

        const index = this.selectedEvents.indexOf(item);
        if (value) {
          if (index == -1) {
            this.selectedEvents.push(item);
          }
        } else {
          this.selectedEvents.splice(index, 1);
        }
      }
    },

    groupJobsByDate<T extends any = any>(
      items: T[],
      groupBy: string
    ): Record<string, T[]> {
      const key = groupBy;
      return items.reduce((rv: any, x: any) => {
        const currentDay = getObjectValueByPath(x, key) as string | Date;

        const currentMonth = moment(currentDay).startOf('month').toString();

        (rv[currentMonth] = rv[currentMonth] || []).push(x);

        return rv;
      }, {} as Record<string, T[]>);
    },

    isSelected(job: IJobData) {
      return this.selectedEvents.includes(job);
    },

    selectEvent(job: IJobData) {
      if (this.selectedEvents.includes(job)) {
        const index = this.selectedEvents.indexOf(job);
        this.selectedEvents.splice(index, 1);
      } else {
        this.selectedEvents.push(job);
      }
    },

    selectAllEvents(value: boolean) {
      const selection = [];

      for (let i = 0; i < this.draftEvents.length; i++) {
        const item = this.draftEvents[i];

        if (value) {
          selection.push(item);
        } else {
          selection.splice(i, 1);
        }
      }
      this.selectedEvents = selection;
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    async publishSelectedEvents(jobs: IJobData[]) {
      this.loading = true;
      for (let index = 0; index < jobs.length; index++) {
        const job = jobs[index];
        await this.updateJobState(this.convertToJobStateParams(job)).catch(
          (error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
            this.loading = false;
          }
        );
      }
      this.loading = false;
      // if any of the jobs in the list have jobs then show list
      const anyJobsHasParticipant = jobs.every(
        (job) => job.participants.length !== 0
      );
      if (anyJobsHasParticipant) {
        this.openInviteDialog();
      } else {
        this.closePublishDrawer();
        this.$emit('rebuild');
      }
    },

    async sendInviteToJobsEmployees(jobs: IJobData[]) {
      // filter jobs to only check jobs with multiple participants
      const filteredJobList = jobs.filter(
        (job) => job.participants.length !== 0
      );

      for (let index = 0; index < filteredJobList.length; index++) {
        const job = filteredJobList[index];
        if (job.recurrence.enabled) {
          await this.sendOccurrenceInvite(job);
        } else {
          await this.sendEventInvite(job);
        }
      }
      this.closeInviteDialog();
    },

    closePublishDrawer() {
      this.selectedEvents = [];
      this.internalValue = false;
    },

    openInviteDialog() {
      this.sendInviteDialog = true;
    },

    closeInviteDialog() {
      this.sendInviteDialog = false;
      this.closePublishDrawer();
      this.$emit('rebuild');
    },

    convertToJobStateParams(event: IJobData): IJobStateParams {
      const occurrence_date = moment(event.original_start).utc(false);
      return {
        event: event.event,
        original_occurrence: {
          second: occurrence_date.seconds(),
          minute: occurrence_date.minutes(),
          hour: occurrence_date.hours(),
          day: occurrence_date.date(),
          month: occurrence_date.month() + 1,
          year: occurrence_date.year()
        },
        state: JobStateType.PUBLISHED
      } as IJobStateParams;
    },

    async sendEventInvite(result: IJobData) {
      this.sendCalendarEventInvite({
        calendarId: result.calendar,
        eventId: result.id ?? result.event,
        start: result.date?.start,
        end: result.date?.end,
        create: true
      })
        .then(() => {
          this.snackMessage({
            msg: `Sent invite to employees`,
            timeout: 6 * 1000
          });
        })
        .catch((error: any) => {
          this.snackError({
            msg: `Error sending invite to employees: ${ErrorManager.extractApiError(
              error
            )}`,
            timeout: 6 * 1000
          });
        });
    },

    async sendOccurrenceInvite(result: IJobData) {
      const recurrenceId = new Date(result.original_start!).getTime();
      this.sendCalendarOccurrenceInvite({
        calendarId: result.calendar,
        eventId: result.event,
        recurrenceId
      })
        .then(() => {
          this.snackMessage({
            msg: `Sent invite to employees`,
            timeout: 6 * 1000
          });
        })
        .catch((error: any) => {
          this.snackError({
            msg: `Error sending invite to employees: ${ErrorManager.extractApiError(
              error
            )}`,
            timeout: 6 * 1000
          });
        });
    }
  }
});
