
import { JobReport } from '@/models/jobReport';
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    dialog: false,
    imageIndex: 0 as number,
    jobReport: new JobReport()
  }),

  methods: {
    /**
     * open image modal view
     *
     * @param index index number of the selected image
     * @param jobReport job report Object
     *
     *
     */
    open(index: number, jobReport: JobReport) {
      this.imageIndex = index;
      this.jobReport = jobReport;
      this.dialog = true;
    },

    /**
     * closes image modal view
     *
     *
     */
    close() {
      this.dialog = false;
    }
  }
});
