import {
  IJobDateOptions,
  IJobStateParams,
  IJobTimelineOccurrencesData
} from '@/models/job/index';
import { IJobService } from './job.types';
import { AxiosInstance } from 'axios';

export class JobService implements IJobService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/jobs';
  }

  fetchJobTimelineOccurrences(
    date: IJobDateOptions
  ): Promise<IJobTimelineOccurrencesData[]> {
    return this.axios
      .get<IJobTimelineOccurrencesData[]>(
        `${this.endpoint}/schedule/?start=${date.start}&end=${date.end}`
      )
      .then(({ data }) => data);
  }

  updateJobState(params: IJobStateParams): Promise<void> {
    return this.axios
      .post(
        `${this.endpoint}/${params.event}/occurrences/${params.original_occurrence.year}/${params.original_occurrence.month}/${params.original_occurrence.day}/${params.original_occurrence.hour}/${params.original_occurrence.minute}/${params.original_occurrence.second}/set_state/${params.state}/`
      )
      .then(({ data }) => data);
  }
}
