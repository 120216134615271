import { IOrganizationState } from '@/store/organization';
import { IOrganizationGetters } from './getters.types';
import { IOrganization, Organization } from '@/models';
import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';

export const getters: IOrganizationGetters = {
  getOrganizations(state: IOrganizationState): IOrganization[] {
    return state.organizations.map((data) => new Organization(data));
  },
  getOrganizationUsers(state: IOrganizationState): User[] {
    //TODO: should return as class with new store architecture
    return state.organizationUsers;
  },

  getOrganizationById:
    (state: IOrganizationState) =>
    (id: number): IOrganization | undefined => {
      const data = state.organizations.find(
        (organization) => organization.id === id
      );
      if (!data) {
        return undefined;
      }

      return new Organization(data);
    }
};
