import {
  ICompany,
  ICompanyData,
  ICompanyNote,
  ICompanyNoteData
} from '../company.types';
import { Company, CompanyNote } from '../company';

export const mockCompaniesData = (): ICompanyData[] => [
  {
    id: 20,
    name: 'adawd',
    phone: '',
    email: '',
    website: '',
    status: {
      value: 0,
      display_name: 'Lead'
    },
    address: {
      id: 21,
      address1: 'fse',
      address2: 'fsef',
      city: 'sef',
      region: 'gdrg',
      postal_code: 'drg',
      country: {
        code: 'DZ',
        name: 'Algeria'
      }
    },
    logo: null,
    owner: 'Test Last',
    created_at: '2022-08-17T13:45:59.688183Z',
    updated_at: '2022-08-17T13:45:59.688183Z'
  },
  {
    id: 31,
    name: 'sefsef',
    phone: '',
    email: '',
    website: '',
    status: {
      value: 0,
      display_name: 'Lead'
    },
    address: {
      id: 32,
      address1: 'srsef',
      address2: 'sefsef',
      city: 'sef',
      region: 'sef',
      postal_code: 'sefsef',
      country: {
        code: 'AF',
        name: 'Afghanistan'
      }
    },
    logo: null,
    owner: 'Test Last',
    created_at: '2022-08-17T15:14:38.378320Z',
    updated_at: '2022-08-17T15:14:38.378320Z'
  }
];

export const mockCompanyNotesData = (): ICompanyNoteData[] => [
  {
    id: 1,
    text: 'note 1',
    owner: 1,
    company: 2
  },
  {
    id: 2,
    text: 'note 3',
    owner: 2,
    company: 3
  }
];

export const mockCompanyNotes = (
  data: ICompanyNoteData[] = mockCompanyNotesData()
): ICompanyNote[] => data.map((item) => new CompanyNote(item));

export const mockCompanies = (
  data: ICompanyData[] = mockCompaniesData()
): ICompany[] => data.map((item) => new Company(item));
