// Utilities
import { make } from 'vuex-pathify';
import { toggle } from '../utils';
const DEFAULT_RIGHTNAVDATA = Object.freeze({
  color: 'primary',
  href: false,
  msg: '',
  text: 'Close',
  to: false,
  timeout: 6000,
  action: function () {}
});

export const state = {
  currentVersion: process.env.VUE_APP_VERSION,
  companyName: 'StaffNet',
  miniCompanyImage: require('@/assets/logos/staffnet_spotlights.png'),
  lightCompanyImage: require('@/assets/logos/staffnet_logo_light.png'),
  darkCompanyImage: require('@/assets/logos/staffnet_logo_dark.png'),
  tabs: require('@/data/tabItems.json'),
  links: require('@/data/drawerItems.json'),
  drawer: true as boolean,
  mini: false as boolean,
  loading: false as boolean,
  rightDrawer: false as boolean,
  isLoading: false as boolean,
  appProductsDialog: false as boolean,
  releases: [],
  rightNavData: {
    ...DEFAULT_RIGHTNAVDATA
  },
  eventDialog: false as boolean
};

export const mutations = {
  ...make.mutations(state),
  TOGGLE_EVENT_DIALOG: toggle('eventDialog'),
  setRightNavData: (state: any, payload: object) => {
    state.rightNavData = Object.assign({}, DEFAULT_RIGHTNAVDATA, payload);
  }
};

export const actions = {
  setAppProductsDialog: async ({ commit }: any, payload: boolean) => {
    commit('SET_APP_PRODUCTS_DIALOG', payload);
  },

  setDrawer: async ({ commit }: any, payload: boolean) => {
    commit('SET_DRAWER', payload);
  },

  setRightDrawer: async ({ commit }: any, payload: boolean) => {
    commit('SET_RIGHT_DRAWER', payload);
  }
};

export const getters = { ...make.getters(state) };
