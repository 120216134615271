var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-card',{staticClass:"mx-auto text-center",attrs:{"shaped":"","max-width":"500"}},[(_vm.error)?[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.error))])])],1)]:[_c('div',{staticClass:"mx-auto"},[_c('v-img',{staticClass:"mt-12",attrs:{"contain":"","height":"100","src":_vm.darkCompanyImage}})],1),_c('div',{staticClass:"text-canter float-center title class mb-0"},[_vm._v(" "+_vm._s(_vm.details.title)+" ")]),(
            _vm.details.date &&
            new _vm.Day(new Date(_vm.details.date.start)).sameDay(
              new _vm.Day(new Date(_vm.details.date.end))
            )
          )?_c('v-card-subtitle',{staticClass:"text-capitalize grey--text text--darken-1 my-0"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.details.date.start, 'dddd, DD MMM', false))),_c('span',[_vm._v("  •  ")]),_vm._v(_vm._s(_vm.dateFormat(_vm.details.date.start, 'h:mma', false))+" - "+_vm._s(_vm.dateFormat(_vm.details.date.end, 'h:mma', false))+" ")]):_c('v-card-subtitle',{staticClass:"text-capitalize grey--text text--darken-1 mb-0"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.details.date.start, 'MMM DD, YYYY h:mma', false))+" - "+_vm._s(_vm.dateFormat(_vm.details.date.end, 'MMM DD, YYYY h:mma', false))+" ")]),(_vm.details.recurrence.enabled)?_c('v-card-subtitle',{staticClass:"text-capitalize grey--text text--darken-1 pt-0 caption mb-auto",domProps:{"textContent":_vm._s(
            _vm.rrulestr(_vm.details.recurrence.rules[1]).toText() ||
            _vm.details.recurrence
          )}}):_vm._e(),_c('v-card-text',{staticClass:"mt-auto"},[(_vm.details.description)?_c('div',{staticClass:"mt-bottom"},[_vm._v(" "+_vm._s(_vm.details.description)+" ")]):_vm._e(),(_vm.details.location)?_c('div',[_vm._v("Location: "+_vm._s(_vm.details.location))]):_vm._e(),_c('div',[_vm._v(" Reponse: "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":""}},on),[_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.rsvp))])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":{
                    name: 'calendar-event-page',
                    params: {
                      ..._vm.$router.currentRoute.params,
                      rsvp: item.rsvp
                    }
                  },"exact":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)]),_c('v-card-actions')]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }