
import { JobReport } from '@/models/jobReport';
import { mapActions } from 'vuex';
import { CalendarOccurrence } from '@/models/calendar';
import Vue from 'vue';
import { ErrorManager } from '@/models/error';

enum Step {
  JOBOCCURRENCE = 1,
  CHECKLIST = 2,
  JOBDETAILS = 3
}

export default Vue.extend({
  data() {
    return {
      Step,
      value: Step.JOBOCCURRENCE,

      selectedJobOccurrence: {} as CalendarOccurrence,
      jobReport: new JobReport(),
      errorMessage: '',

      dialog: false
    };
  },

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      createJobReport: 'jobReports/createJobReport',
      updateJobReportChecklist: 'jobReports/updateJobReportChecklist',
      addJobReportImage: 'jobReports/addJobReportImage'
    }),

    jobReportCheckist() {
      const list: { id: any; item: any; response: boolean }[] = [];

      this.selectedJobOccurrence.checklist!.items!.map(
        (checklistItems: any) => {
          const checklistObject = {
            id: checklistItems.id,
            item: checklistItems.item_text,
            response: false
          };

          list.push(checklistObject);
        }
      );

      this.jobReport.checklist = list;
    },

    addJobReport() {
      const payload = {
        job: this.selectedJobOccurrence.event,
        occurrence_date: this.selectedJobOccurrence.original_start,
        description: this.jobReport.description,
        media: []
      };

      this.createJobReport(payload)
        .then((createdJobReport) => {
          //update job with checklist
          for (let i = 0; this.jobReport.checklist!.length > i; i++) {
            this.updateJobReportChecklist({
              jobReportId: createdJobReport.id,
              data: this.jobReport.checklist![i]
            }).catch((error: any) => {
              this.errorMessage = ErrorManager.extractApiError(error);
            });
          }

          //update job with images
          for (let i = 0; this.jobReport.media!.length > i; i++) {
            const formData = new FormData();
            formData.append('image', this.jobReport.media![i]);
            this.addJobReportImage({
              jobReportId: createdJobReport.id,
              data: formData
            }).catch((error: any) => {
              this.errorMessage = ErrorManager.extractApiError(error);
            });
          }
        })

        .then(() => this.close())

        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    changeDetector() {
      this.dialog =
        JSON.stringify(this.selectedJobOccurrence) !== JSON.stringify({});
      if (!this.dialog) {
        this.close();
      }
    },

    close() {
      (this as any).$refs.jobdetailForm.clearJobReportDetailFormData();
      this.$router.push({
        name: 'jobReports-list'
      });
    }
  },

  mounted() {
    this.jobReport = { description: '', checklist: [], media: [] } as JobReport;
  }
});
