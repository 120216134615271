
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    edit: false,
    opacity: 0.3,
    //detail page props
    utility: false,
    direction: 'bottom',
    transition: 'slide-y-reverse-transition',
    editData: {}
  }),
  props: {
    photo: {
      type: String,
      default: null
    },
    title: {
      type: [String, Function],
      default: null
    },
    subtitle: {
      type: [String, Function],
      default: null
    },
    third_title: {
      type: [String, Function],
      default: null
    },
    subthird_title: {
      type: [String, Function],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object,
      default: () => {}
    },
    social: {
      type: Object,
      default: () => {}
    },
    editFunction: {
      type: Function,
      default: () => ({})
    },
    deleteFunction: {
      type: Function,
      default: () => ({})
    }
  },
  methods: {
    onEditClick(data: string): any {
      return this.editFunction;
    },
    onDeleteClick(): any {
      return this.deleteFunction;
    }
    // dateFormat
  }
});
