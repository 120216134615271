var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dateTimeWrapper"},[_c('div',{staticClass:"containerWrapper"},[_c('div',{staticClass:"dateContainer"},[_c('v-date-picker',{attrs:{"color":"primary","allowed-dates":(val) => _vm.dateEvents.indexOf(val) !== -1,"picker-date":_vm.dateChange,"no-title":"","show-adjacent-months":"","disabled":_vm.disabled},on:{"click:date":_vm.showJobs,"update:pickerDate":function($event){_vm.dateChange=$event},"update:picker-date":function($event){_vm.dateChange=$event}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('div',{staticClass:"timeContainer"},[_c('div',{staticClass:"startTime timeRow"},[_c('span',{staticClass:"subTitle"},[_vm._v("Jobs")]),_c('div',[(_vm.todayJobOccurrences)?_c('v-list',{staticClass:"job-list",attrs:{"disabled":_vm.disabled,"nav":"","two-line":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"job-group",class:`elevation-${hover ? 3 : 1}`},[_vm._l((_vm.todayJobOccurrences),function(item,index){return [_c('v-list-item',{key:`${item.date.start}__${index}`,style:(_vm.selectedJobOccurrence ==
                        `${item.date.start}__${index}`
                          ? 'background: #00aeef4a;'
                          : ''),on:{"click":function($event){return _vm.selectJobOccurrence(item, index)}}},[[_c('v-list-item-content',[_c('v-list-item-title',{style:(_vm.selectedJobOccurrence ==
                              `${item.date.start}__${index}`
                                ? 'color: #00aeef'
                                : ''),domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',[_vm._v("Start Date: "+_vm._s(_vm.localDateFormat( item.date.start, 'MMM D, YYYY hh:mma', item.date.timezone, false )))])],1)]],2),(index < _vm.todayJobOccurrences.length - 1)?_c('v-divider',{key:`${index}__divider`}):_vm._e()]})],2)]}}],null,false,4105067730)})],1):_vm._e()],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }