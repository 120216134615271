
import Vue from 'vue';
export default Vue.extend({
  name: 'ErrorAlert',

  props: {
    errorMessage: {
      type: String,
      default: ''
    },
    detailErrorMessage: {
      type: String,
      default: ''
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showDetailError: false
  }),

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    detailErrorOccurred(): boolean {
      return this.detailErrorMessage.length > 0;
    }
  },

  methods: {
    toggleDetailError() {
      this.showDetailError = !this.showDetailError;
    },

    clearAllErrors(): void {
      this.showDetailError = false;
      this.$emit('clearErrors');
    }
  }
});
