import { cloneDeepWith, isEqual, transform } from 'lodash';
import Vue from 'vue';
import { VNode, VNodeDirective } from 'vue/types';
type Dictionary<T> = { [key: string]: T };

export function getNameInitials(first_name: string, last_name: string): string {
  const owner = first_name + ' ' + last_name;
  let initials: string | RegExpMatchArray | [] = '';
  initials = owner.match(/\b\w/g) || [];
  initials = (
    ((initials as RegExpMatchArray).shift() || '') +
    ((initials as RegExpMatchArray).pop() || '')
  ).toUpperCase();
  return initials;
}

export function createSimpleFunctional(c: string, el = 'div', name?: string) {
  return Vue.extend({
    name: name || c.replace(/__/g, '-'),

    functional: true,

    render(h, { data, children }): VNode {
      data.staticClass = `${c} ${data.staticClass || ''}`.trim();

      return h(el, data, children);
    }
  });
}

export type BindingConfig = Pick<VNodeDirective, 'arg' | 'modifiers' | 'value'>;
export function directiveConfig(
  binding: BindingConfig,
  defaults = {}
): VNodeDirective {
  return {
    ...defaults,
    ...binding.modifiers,
    value: binding.arg,
    ...(binding.value || {})
  };
}

export function addOnceEventListener(
  el: EventTarget,
  eventName: string,
  cb: (event: Event) => void,
  options: boolean | AddEventListenerOptions = false
): void {
  const once = (event: Event) => {
    cb(event);
    el.removeEventListener(eventName, once, options);
  };

  el.addEventListener(eventName, once, options);
}

export function getNestedValue(
  obj: any,
  path: (string | number)[],
  fallback?: any
): any {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function deepEqual(a: any, b: any): boolean {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date) {
    // If the values are Date, they were convert to timestamp with getTime and compare it
    if (a.getTime() !== b.getTime()) return false;
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false;
  }

  const props = Object.keys(a);

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false;
  }

  return props.every((p) => deepEqual(a[p], b[p]));
}

export function getObjectValueByPath(
  obj: any,
  path: string,
  fallback?: any
): any {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback);
}

export function getPropertyFromItem(
  item: object,
  property: any,
  fallback?: any
): any {
  if (property == null) return item === undefined ? fallback : item;

  if (item !== Object(item)) return fallback === undefined ? item : fallback;

  if (typeof property === 'string')
    return getObjectValueByPath(item, property, fallback);

  if (Array.isArray(property)) return getNestedValue(item, property, fallback);

  if (typeof property !== 'function') return fallback;

  const value = property(item, fallback);

  return typeof value === 'undefined' ? fallback : value;
}

export function createRange(length: number): number[] {
  return Array.from({ length }, (v, k) => k);
}

export function getZIndex(el?: Element | null): number {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return 0;

  const index = +window.getComputedStyle(el).getPropertyValue('z-index');

  if (!index) return getZIndex(el.parentNode as Element);
  return index;
}

const tagsToReplace = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;'
} as any;

export function escapeHTML(str: string): string {
  return str.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
}

export function filterObjectOnKeys<T, K extends keyof T>(
  obj: T,
  keys: K[]
): { [N in K]: T[N] } {
  const filtered = {} as { [N in K]: T[N] };

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (typeof obj[key] !== 'undefined') {
      filtered[key] = obj[key];
    }
  }

  return filtered;
}

export function convertToUnit(
  str: string | number | null | undefined,
  unit = 'px'
): string | undefined {
  if (str == null || str === '') {
    return undefined;
  } else if (isNaN(+str!)) {
    return String(str);
  } else {
    return `${Number(str)}${unit}`;
  }
}

export function kebabCase(str: string): string {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function isObject<T extends object>(input: any): input is T {
  return input !== null && !isArray(input) && typeof input === 'object';
}
export function isString<T extends string>(input: any): input is T {
  return input !== null && typeof input === 'string';
}
export function isEmptyObject(obj: any) {
  return (
    toString.call(obj) === '[object Object]' && Object.keys(obj).length === 0
  );
}
export const isArray = (arr: any) => arr !== null && Array.isArray(arr);

// KeyboardEvent.keyCode aliases
export const keyCodes = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34
});

// This remaps internal names like '$cancel' or '$vuetify.icons.cancel'
// to the current name or component for that icon.
// export function remapInternalIcon(vm: Vue, iconName: string): VuetifyIcon {
//   if (!iconName.startsWith('$')) {
//     return iconName;
//   }

//   // Get the target icon name
//   const iconPath = `$vuetify.icons.values.${iconName
//     .split('$')
//     .pop()!
//     .split('.')
//     .pop()}`;

//   // Now look up icon indirection name,
//   // e.g. '$vuetify.icons.values.cancel'
//   return getObjectValueByPath(vm, iconPath, iconName);
// }

export function keys<O extends Record<any, any>>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

/**
 * Camelize a hyphen-delimited string.
 */
const camelizeRE = /-(\w)/g;
export const camelize = (str: string): string => {
  return str.replace(camelizeRE, (_, c) => (c ? c.toUpperCase() : ''));
};

/**
 * Returns the set difference of B and A, i.e. the set of elements in B but not in A
 */
export function arrayDiff(a: any[], b: any[]): any[] {
  const diff: any[] = [];
  for (let i = 0; i < b.length; i++) {
    if (a.indexOf(b[i]) < 0) diff.push(b[i]);
  }
  return diff;
}

/**
 * Makes the first character of a string uppercase
 */
export function upperFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function groupItems<T extends any = any>(
  items: T[],
  groupBy: string[]
): Record<string, T[]> {
  const key = groupBy[0];
  return items.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {} as Record<string, T[]>);
}

export function wrapInArray<T>(v: T | T[] | null | undefined): T[] {
  return v != null ? (Array.isArray(v) ? v : [v]) : [];
}

export function sortItems<T extends any = any>(
  items: T[],
  sortBy: string[],
  sortDesc: boolean[],
  locale: string,
  customSorters?: any
): T[] {
  if (sortBy === null || !sortBy.length) return items;
  const stringCollator = new Intl.Collator(locale, {
    sensitivity: 'accent',
    usage: 'sort'
  });

  return items.sort((a, b) => {
    for (let i = 0; i < sortBy.length; i++) {
      const sortKey = sortBy[i];

      let sortA = getObjectValueByPath(a, sortKey);
      let sortB = getObjectValueByPath(b, sortKey);

      if (sortDesc[i]) {
        [sortA, sortB] = [sortB, sortA];
      }

      if (customSorters && customSorters[sortKey]) {
        const customResult = customSorters[sortKey](sortA, sortB);

        if (!customResult) continue;

        return customResult;
      }

      // Check if both cannot be evaluated
      if (sortA === null && sortB === null) {
        continue;
      }

      [sortA, sortB] = [sortA, sortB].map((s) =>
        (s || '').toString().toLocaleLowerCase()
      );

      if (sortA !== sortB) {
        if (!isNaN(sortA) && !isNaN(sortB))
          return Number(sortA) - Number(sortB);
        return stringCollator.compare(sortA, sortB);
      }
    }

    return 0;
  });
}

export function defaultFilter(value: any, search: string | null, item?: any) {
  item;
  return (
    value != null &&
    search != null &&
    typeof value !== 'boolean' &&
    value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
      -1
  );
}

export function searchItems<T extends any = any>(
  items: T[],
  search: string
): T[] {
  if (!search) return items;
  search = search.toString().toLowerCase();
  if (search.trim() === '') return items;

  return items.filter((item: any) =>
    Object.keys(item).some((key) =>
      defaultFilter(getObjectValueByPath(item, key), search, item)
    )
  );
}

/**
 * Returns:
 *  - 'normal' for old style slots - `<template slot="default">`
 *  - 'scoped' for old style scoped slots (`<template slot="default" slot-scope="data">`) or bound v-slot (`#default="data"`)
 *  - 'v-slot' for unbound v-slot (`#default`) - only if the third param is true, otherwise counts as scoped
 */
export function getSlotType<T extends boolean = false>(
  vm: Vue,
  name: string,
  split?: T
): (T extends true ? 'v-slot' : never) | 'normal' | 'scoped' | void {
  if (
    vm.$slots[name] &&
    vm.$scopedSlots[name] &&
    (vm.$scopedSlots[name] as any).name
  ) {
    return split ? ('v-slot' as any) : 'scoped';
  }
  if (vm.$slots[name]) return 'normal';
  if (vm.$scopedSlots[name]) return 'scoped';
}

export function debounce(fn: Function, delay: number) {
  let timeoutId = 0 as any;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
}

export function getPrefixedScopedSlots(prefix: string, scopedSlots: any) {
  return Object.keys(scopedSlots)
    .filter((k) => k.startsWith(prefix))
    .reduce((obj: any, k: string) => {
      obj[k.replace(prefix, '')] = scopedSlots[k];
      return obj;
    }, {});
}

export function getSlot(
  vm: Vue,
  name = 'default',
  data?: object | (() => object),
  optional = false
) {
  if (vm.$scopedSlots[name]) {
    return vm.$scopedSlots[name]!(data instanceof Function ? data() : data);
  } else if (vm.$slots[name] && (!data || optional)) {
    return vm.$slots[name];
  }
  return undefined;
}

export function clamp(value: number, min = 0, max = 1) {
  return Math.max(min, Math.min(max, value));
}

export function padEnd(str: string, length: number, char = '0') {
  return str + char.repeat(Math.max(0, length - str.length));
}

export function chunk(str: string, size = 1) {
  const chunked: string[] = [];
  let index = 0;
  while (index < str.length) {
    chunked.push(str.substr(index, size));
    index += size;
  }
  return chunked;
}

export function humanReadableFileSize(bytes: number, binary = false): string {
  const base = binary ? 1024 : 1000;
  if (bytes < base) {
    return `${bytes} B`;
  }

  const prefix = binary ? ['Ki', 'Mi', 'Gi'] : ['k', 'M', 'G'];
  let unit = -1;
  while (Math.abs(bytes) >= base && unit < prefix.length - 1) {
    bytes /= base;
    ++unit;
  }
  return `${bytes.toFixed(1)} ${prefix[unit]}B`;
}

export function camelizeObjectKeys(
  obj: Record<string, any> | null | undefined
) {
  if (!obj) return {};

  return Object.keys(obj).reduce((o: any, key: string) => {
    o[camelize(key)] = obj[key];
    return o;
  }, {});
}

export function mergeDeep(
  source: Dictionary<any> = {},
  target: Dictionary<any> = {}
) {
  for (const key in target) {
    const sourceProperty = source[key];
    const targetProperty = target[key];

    // Only continue deep merging if
    // both properties are objects
    if (isObject(sourceProperty) && isObject(targetProperty)) {
      source[key] = mergeDeep(sourceProperty, targetProperty);

      continue;
    }

    source[key] = targetProperty;
  }

  return source;
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

//  export function isDeepEmpty(input: any) {
//     if (isEmpty(input)) {
//       return true;
//     }
//     if (typeof input === 'object') {
//       for (const item of Object.values(input)) {
//         if (
//           (item !== undefined && typeof item !== 'object') ||
//           !this.isDeepEmpty(item)
//         ) {
//           return false;
//         }
//       }
//       return true;
//     }
//     return isEmpty(input);
//   }

export function checkEmpty(obj: any) {
  for (const key in obj) {
    if (obj[key] instanceof Object === true) {
      if (checkEmpty(obj[key]) === false) return false;
    } else {
      if (obj[key].length !== 0) return false;
    }
  }
  return true;
}

export const findExistingIndex = (
  arr: any[],
  item: object,
  property: any,
  fallback?: any
) => {
  const itemValue = getPropertyFromItem(item, property, fallback);
  return (arr || []).findIndex((i) =>
    deepEqual(getPropertyFromItem(i, property, fallback), itemValue)
  );
};

export const filterDuplicates = (arr: any[], property: any, fallback?: any) => {
  const uniqueValues = new Map();

  for (let index = 0; index < arr.length; ++index) {
    const item = arr[index];
    const val = getPropertyFromItem(item, property, fallback); // TODO: comparator

    !uniqueValues.has(val) && uniqueValues.set(val, item);
  }

  return Array.from(uniqueValues.values());
};

/** determine if color processed is avaible for css*/
export function isCssColor(color?: string | false): boolean {
  return !!color && !!color.match(/^(#|var\(--|(rgb|hsl)a?\()/);
}

interface Color {
  r: number;
  g: number;
  b: number;
}
export function parseColor(color: string | object): any {
  if (isObject(color)) {
    return color as Color;
  }

  const match = /#(\w\w)(\w\w)(\w\w)/.exec(color);

  if (!match) {
    // throw 'Colors must be in the format of #rrggbb';
    console.warn('Colors must be in the format of #rrggbb');
    return false;
    // throw new Error("Colors must be in the format of #rrggbb");
  }

  return {
    r: parseInt(match[1], 16),
    g: parseInt(match[2], 16),
    b: parseInt(match[3], 16)
  };
}

export function omitDeep<T>(collection: T, excludeKeys: any[]) {
  function omitFn(value: { [x: string]: any }) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach((key) => {
        delete value[key];
      });
    }
  }

  return cloneDeepWith<T>(collection, omitFn) as T;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {Boolean} includeId flag to control including ID with difference
 * @return {Object}        Return a new object who represent the diff
 */
export function diffDeep<T>(object: T, base: any, includeId = false): T {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        if (Array.isArray(value) && Array.isArray(base[key])) {
          result[key] = [];

          // loop through each entry of array
          for (let i = 0; i < value.length; i++) {
            if (isObject(value[i])) {
              const base_entry = base[key].find(
                (data: { id?: number | string }) => data?.id === value[i]?.id
              );

              if (base_entry) {
                if (!isEqual(value[i], base_entry)) {
                  // get the differences
                  result[key].push(changes(value[i], base_entry));
                } else {
                  // We always need to include ID for array entries
                  // even if there was no change - otherwise item
                  // gets deleted when data written to database
                  result[key].push({ id: value[i].id });
                }
              } else {
                result[key].push(value[i]);
              }
            } else {
              result[key].push(value[i]);
            }
          }
        } else {
          result[key] =
            isObject(value) && isObject(base[key])
              ? changes(value, base[key])
              : value;

          // include ID field if applicable
          if (includeId) {
            result['id'] = base['id'];
          }
        }
      } else {
        // save id regardless even if equal
        if (includeId) {
          result['id'] = base['id'];
        }
      }
    });
  }
  return changes(object, base);
}

/**
 * Validates that a string is a valid web URL.
 *
 * @param {any} urlStr The string to validate.
 * @return {boolean} Whether the string is valid web URL or not.
 */
export function isURL(urlStr: any): boolean {
  if (typeof urlStr !== 'string') {
    return false;
  }
  // Lookup illegal characters.
  const re = /[^a-z0-9:/?#[\]@!$&'()*+,;=.\-_~%]/i;
  if (re.test(urlStr)) {
    return false;
  }
  try {
    const uri = new URL(urlStr);
    const scheme = uri.protocol;
    // const slashes = uri.slashes;
    const hostname = uri.hostname;
    const pathname = uri.pathname;
    if (scheme !== 'http:' && scheme !== 'https:') {
      //|| !slashes
      return false;
    }
    // Validate hostname: Can contain letters, numbers, underscore and dashes separated by a dot.
    // Each zone must not start with a hyphen or underscore.
    if (
      !hostname ||
      !/^[a-zA-Z0-9]+[\w-]*([.]?[a-zA-Z0-9]+[\w-]*)*$/.test(hostname)
    ) {
      return false;
    }
    // Allow for pathnames: (/chars+)*/?
    // Where chars can be a combination of: a-z A-Z 0-9 - _ . ~ ! $ & ' ( ) * + , ; = : @ %
    const pathnameRe = /^(\/[\w\-.~!$'()*+,;=:@%]+)*\/?$/;
    // Validate pathname.
    if (pathname && pathname !== '/' && !pathnameRe.test(pathname)) {
      return false;
    }
    // Allow any query string and hash as long as no invalid character is used.
  } catch (e) {
    return false;
  }
  return true;
}

// this function waits for element to be loaded into the dom
export function waitForElm(selector: string) {
  return new Promise((resolve: any) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export function getHtmlELement(el: any): HTMLElement | null {
  if (typeof el === 'string') {
    return document.querySelector<HTMLElement>(el);
  } else if (el && el._isVue) {
    return (el as Vue).$el as HTMLElement;
  } else if (el instanceof HTMLElement) {
    return el;
  } else {
    return null;
  }
}

export function scrollToTargetOfHtmlElement(
  element?: string | HTMLElement,
  target?: number,
  duration = 1000 // 1 second
) {
  const scroll = getHtmlELement(element);
  const targetLocation =
    target ?? scroll?.scrollHeight! ?? scroll?.clientHeight;

  const startTime = performance.now();
  const startLocation = scroll?.scrollTop!;

  // if targetlocation is the same startlocation
  if (targetLocation === startLocation) return Promise.resolve(targetLocation);

  // Scroll behaviour for easing animation
  /** @see import * as easings from 'vuetify/lib/services/goto/easing-patterns' */
  const easeInOutCubic = (t: number) =>
    t < 0.5 ? 4 * t ** 3 : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

  return new Promise((resolve) =>
    requestAnimationFrame(function step(currentTime: number) {
      const timeElapsed = currentTime - startTime;

      // decrementing progress as end condition for scroll
      const progress = Math.abs(
        duration ? Math.min(timeElapsed / duration, 1) : 1
      );

      // set new height to be the difference between location multiplied by animation offset
      const top = Math.floor(
        startLocation +
          (targetLocation - startLocation) * easeInOutCubic(progress)
      );

      //set the scroll top to be the new top value per frame
      scroll?.scrollTo({
        top
      });

      //if element scroll top has not fully loaded then use the document client height
      const clientHeight =
        scroll?.clientHeight! ?? document.documentElement.clientHeight;

      if (
        progress === 1 ||
        clientHeight + scroll?.scrollTop! === scroll?.scrollHeight
      ) {
        return resolve(targetLocation);
      }
      // run requestAnimationFrame if functions has not returned
      requestAnimationFrame(step);
    })
  );
}

export function compareOrder<T extends { id?: number; order: number }>(
  otherArray: T[],
  callback?: (other: T, current: T) => boolean
) {
  return function (current: T) {
    return (
      otherArray.filter((other) =>
        callback
          ? callback(other, current)
          : function (other: T) {
              return other.id == current.id && other.order == current.order;
            }
      ).length == 0
    );
  };
}
