
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { Item } from '@/models/inventory';
import { ErrorManager } from '@/models/error';
import auth from '@/mixins/auth';
import mixins from '@/utils/mixins';

export default mixins(auth).extend({
  name: 'item-detail',
  data() {
    return {
      loading: false,
      deleteItemMasterDialog: false,

      errorMessage: '',
      errorFlag: false,

      item: [] as Item[],
      itemheaders: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          class: 'blue lighten-3',
          value: 'data-table-space'
        },
        {
          text: 'Description',
          class: 'blue lighten-3',
          align: 'center',
          value: 'description'
        },
        {
          text: 'Unit of Measure',
          class: 'blue lighten-3',
          align: 'center',
          sortable: false,
          value: 'unit'
        },
        {
          text: 'Last Purchase Price',
          class: 'blue lighten-3',
          // sortable: false,
          value: 'price'
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      getItemById: 'itemStore/getItemById'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    canModifyItem(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    }
  },

  methods: {
    ...mapActions({
      fetchItem: 'itemStore/fetchItem',
      deleteItem: 'itemStore/deleteItem'
    }),

    async tryGetOrFetchInventory(itemId: number) {
      try {
        if (this.getItemById(itemId)) {
          return this.getItemById(itemId);
        } else {
          return this.fetchItem(itemId).then(() => {
            return this.getItemById(itemId);
          });
        }
      } catch (e) {
        console.log('NO INVENTORY FOUND', e);
      }
    },

    async setData() {
      this.item = [];
      const result = await this.tryGetOrFetchInventory(
        Number(this.$route.params.itemNumber)
      );
      this.item.push(result);
    },

    deleteItemMaster(itemId: number) {
      this.deleteItem(itemId)
        .then(() => {
          this.deleteItemMasterDialog = false;
          this.$router.push({
            name: 'item-master'
          });
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.deleteItemMasterDialog = false;
        });
    },

    /**
     * open Item Master dialog
     *
     *
     */
    openItemMasterFormDialog(isEdit = false, instance: Item) {
      (this as any).$refs.itemMasterDialog.open(isEdit, instance);
    }
  },

  /**
   * fetches user data from server and displays it
   *
   */
  async beforeMount() {
    this.setData();
  }
});
