import { ICompanyNoteState } from '..';
import { ICompanyNoteMutations } from './mutations.types';
import { ICompanyNoteData } from '@/models';

export const mutations: ICompanyNoteMutations = {
  SET_COMPANY_NOTES(
    state: ICompanyNoteState,
    payload: ICompanyNoteData[]
  ): void {
    state.companyNotes = payload;
  },

  ADD_COMPANY_NOTE(state: ICompanyNoteState, payload: ICompanyNoteData): void {
    state.companyNotes.unshift(payload);
  },

  UPDATE_COMPANY_NOTE(
    state: ICompanyNoteState,
    payload: ICompanyNoteData
  ): void {
    const index = state.companyNotes.findIndex(
      (companynote: ICompanyNoteData) => companynote.id === payload.id
    );

    state.companyNotes.splice(index, 1, payload);
  },

  REMOVE_COMPANY_NOTE(state: ICompanyNoteState, payload: number): void {
    const index = state.companyNotes.findIndex(
      (companynote: ICompanyNoteData) => companynote.id === payload
    );

    state.companyNotes.splice(index, 1);
  }
};
