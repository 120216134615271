
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    avatar: {
      type: String,
      default: undefined
    },

    avatarColor: {
      type: String,
      default: undefined
    },

    text: {
      type: String,
      default: ''
    },

    href: {
      type: String,
      default: undefined
    },

    name: {
      type: String,
      default: ''
    },

    icon: {
      type: [Boolean, String],
      default: false
    },

    chip: {
      type: String,
      default: ''
    },

    noMarkdown: {
      type: Boolean,
      default: false
    },

    subtext: {
      type: String,
      default: undefined
    },

    namespace: {
      type: String,
      default: undefined
    },

    to: {
      type: String,
      default: undefined
    },

    params: {
      type: String,
      default: undefined
    },

    disabled: {
      type: Boolean,
      default: false
    },

    event: {
      type: Boolean,
      default: true
    },

    action: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    chipColor() {
      switch (this.chip) {
        case 'new':
          return 'primary';
        case 'updated':
          return 'warning';
        case 'deprecated':
          return 'black';
        case 'help':
          return 'error';
        default:
          return 'primary';
      }
    },

    customAttrs() {
      const attrs = {
        ...this.$attrs
      };

      if (this.href) {
        attrs.target = '_blank';
        attrs.rel = 'noopener';
        attrs.href = String(this.href);
      }
      return attrs;
    }
  }
});
