import { axios } from '@/plugins/axios';

export class StateService {
  endpoint: string;

  constructor() {
    this.endpoint = 'v1/todos/states/';
  }

  async fetchStates() {
    return axios.get(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchState(id: number) {
    return axios.get(`${this.endpoint}${id}/`).then(({ data }) => data);
  }

  async createState(payload: object) {
    return axios.post(`${this.endpoint}`, payload).then(({ data }) => data);
  }

  async updateState(id: number, payload: object) {
    return axios
      .patch(`${this.endpoint}${id}/`, payload)
      .then(({ data }) => data);
  }

  async deleteState(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }
}
