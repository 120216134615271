
import Vue from 'vue';
import { merge } from 'lodash';
import { IJobData } from '@/models/job/job.types';
import dateFormat from '@/utils/dateFormat';
export default Vue.extend({
  data() {
    return {
      dialog: false,
      title: 'Choice Dialog',
      resolve: null as any,
      reject: null as any,
      job: {} as undefined | IJobData,
      // recurrence: 1,
      options: {
        'max-width': 400,
        zIndex: 200
      },
      buttonOptions: {
        text: false,
        default: {
          text: 'Cancel'
        },
        left: {
          text: 'Cancel'
        },
        right: {
          color: 'primary',
          text: 'Ok',
          icon: null,
          iconOnly: false
        }
      },
      confirm: false
    };
  },
  methods: {
    dateFormat,

    open(
      title?: string,
      buttonOptions?: {
        left?: {
          text?: string;
        };
        right?: {
          color?: string;
          text?: string;
          icon?: string;
          iconOnly?: boolean;
        };
      },
      job?: IJobData,
      confirm = false
    ) {
      this.dialog = true;
      this.job = job;
      title ? (this.title = title) : this.title;
      confirm ? (this.confirm = confirm) : this.confirm;
      buttonOptions
        ? (this.buttonOptions = merge(this.buttonOptions, buttonOptions))
        : this.buttonOptions;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.confirm = false;
      this.title = '';
    },
    agree() {
      this.dialog = false;
      return this.resolve(true);
    },
    cancel() {
      this.dialog = false;
      return this.resolve(false);
    }
  },
  watch: {
    dialog: {
      handler(val) {
        val || this.close();
      }
    }
  },
  beforeDestroy() {
    this.confirm = false;
  }
});
