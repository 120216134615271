// API Data
export interface IProductPriceData {
  id?: number;
  frequency: number;
  price: number;
}
export interface IProductPlanData {
  id?: number;
  max_active_users?: number;
  is_crm_supported?: boolean;
  is_inventory_management_supported?: boolean;
}

export interface IProductData {
  id?: number;
  name: string;
  description?: string;
  prices: IProductPriceData[];
  plan: IProductPlanData | null;
  created_at?: Date | string;
  updated_at?: Date | string;
}

export interface IProductPriceFieldErrorData {
  frequency?: string[];
  price?: string[];
}

export interface IProductFieldErrorData {
  name?: string[];
  description?: string[];
  prices?: IProductPriceFieldErrorData[];
}

// Business Logic Interface
export interface IProductPlan extends IProductPlanData {
  validate(): boolean;
}

export interface IProductPrice extends IProductPriceData {
  validate(): boolean;
}

export interface IProduct extends IProductData {
  prices: IProductPrice[];
  plan: IProductPlan | null;

  getMonthlyPrice(): number | undefined;
  getYearlyPrice(): number | undefined;
  validate(): boolean;
}

export interface IProductPriceFieldError extends IProductPriceFieldErrorData {
  clearErrors(): void;
}

export interface IProductFieldError extends IProductFieldErrorData {
  prices?: IProductPriceFieldError[];

  clearErrors(): void;
}

export enum ProductPriceFrequencyOptions {
  Monthly = 0,
  Yearly
}
