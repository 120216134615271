import { IProductState } from '@/store/product';
import { IProductGetters } from './getters.types';
import { IProduct, Product } from '@/models';

export const getters: IProductGetters = {
  getProducts(state: IProductState): IProduct[] {
    return state.products.map((data) => new Product(data));
  },

  getProductById:
    (state: IProductState) =>
    (id: number): IProduct | undefined => {
      const data = state.products.find((product) => product.id === id);
      if (!data) {
        return undefined;
      }

      return new Product(data);
    }
};
