import { ICompanyNoteState } from '..';
import { ICompanyNoteGetters } from './getters.types';
import { ICompanyNote, CompanyNote } from '@/models';

export const getters: ICompanyNoteGetters = {
  getCompanyNotes(state: ICompanyNoteState): ICompanyNote[] {
    return state.companyNotes.map((data) => new CompanyNote(data));
  },

  getCompanyNoteById:
    (state: ICompanyNoteState) =>
    (id: number): ICompanyNote | undefined => {
      const data = state.companyNotes.find(
        (companynote) => companynote.id === id
      );
      if (!data) {
        return undefined;
      }

      return new CompanyNote(data);
    }
};
