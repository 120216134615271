
import Proxyable from '@/mixins/proxyable';
import { Category } from '@/models/inventory';
import { mapActions, mapState } from 'vuex';

export default Proxyable.extend({
  data() {
    return {
      emptyRule: [(v: any) => !!v || ''],

      menuDialog: false,
      loading: false,
      valid: true,
      showSaveBtn: true,
      deleteLoading: false,
      deleteSuccess: false,
      editSuccess: false,

      //default color
      types: ['hex'],
      type: 'hex',
      hex: '#D3D3D3'
    };
  },

  computed: {
    ...mapState('itemCategoryStore', ['categories']),

    categoryItems(): Category[] {
      const categoryList = [];
      for (let i = 0; this.categories.length > i; i++) {
        categoryList.push(this.categories[i].category_name);
      }
      return categoryList;
    },

    color: {
      get(): any {
        return (this as any)[(this as any).type];
      },
      set(v) {
        (this as any)[(this as any).type] = v;
      }
    },

    showColor(): string {
      this.internalValue.category.color = this.color;

      if (typeof this.color === 'string') return this.color;

      return JSON.stringify(
        Object.keys(this.color as object).reduce((color: any, key: any) => {
          color[key] = Number((this.color as any)[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    }
  },

  methods: {
    ...mapActions({
      createCategory: 'itemCategoryStore/createCategory',
      deleteCategory: 'itemCategoryStore/deleteCategory',
      updateCategory: 'itemCategoryStore/updateCategory'
    }),

    setIconColor() {
      for (let i = 0; this.categories.length > i; i++) {
        if (
          this.categories[i].category_name ==
          this.internalValue.category.category_name
        ) {
          this.color = this.categories[i].color!;
          this.internalValue.category.color = this.categories[i].color!;
          this.internalValue.category.id = this.categories[i].id;
          this.showSaveBtn = false;
          this.editSuccess = false;
          this.deleteSuccess = false;
          break;
        } else {
          this.showSaveBtn = true;
        }
      }
    },

    async saveCategory() {
      const category = {
        category_name: this.internalValue.category.category_name,
        color: this.color
      };

      this.createCategory(category).then((response) => {
        this.showSaveBtn = false;
        this.internalValue.category!.id = response.id;
        this.internalValue.category!.color = response.color;
      });
    },

    editCategoryColor() {
      this.loading = true;
      this.updateCategory(this.internalValue.category).then((response) => {
        this.internalValue.category!.id = response.id;
        this.internalValue.category!.color = response.color;

        this.showSaveBtn = false;
        this.loading = false;
        this.editSuccess = true;
      });
    },

    removeCategory() {
      this.deleteCategory(this.internalValue.category.id).then(() => {
        this.deleteLoading = false;
        this.deleteSuccess = true;
        this.setCategoryData();
      });
    },

    setCategoryData(
      category = {
        id: 0,
        category_name: '',
        color: '#D3D3D3'
      }
    ) {
      this.internalValue.category! = category;
      this.color = category.color;
      this.setIconColor();
    }
  }
});
