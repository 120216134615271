
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import dateFormat from '@/utils/dateFormat';
import moment from 'moment-timezone';
import {
  localDateFormat,
  setPartsToUTCDate,
  setUTCPartsToDate
} from '@/utils/dateHelpers';

import {
  upperFirst,
  getPropertyFromItem,
  escapeHTML,
  deepEqual,
  omitDeep
} from '@/utils/helpers';

import { diffMinutes } from './common/timestamp';

import { CalendarTimestamp, CalendarFormatter } from './common/types';
import { createNativeLocaleFormatter } from './common/utils';
import mixins from '@/utils/mixins';
import vcalendar from '@/mixins/calendar';

import { cloneDeep, isEmpty, pick } from 'lodash';
import {
  CalendarEvent,
  CalendarOccurrence,
  Participant,
  RecurrenceEditType
} from '@/models/calendar';

import RRule from 'rrule';
import { Logger } from '@/utils/logger';

import {
  accountsProvider,
  authProvider
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import auth from '@/mixins/auth';
import StackedParticipantAvatarList from '@/components/data/calendar/StackedParticipantAvatarList.vue';
import { Job } from '@/models/job';
import RecurrenceSaveDialog, {
  RecurrenceSaveOptions
} from './new/RecurrenceSaveDialog.vue';
import { ErrorManager } from '@/models/error';
import {
  CalendarDayBodySlotScope,
  CalendarDaySlotScope,
  CalendarEventParsed
} from 'vuetify';

const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6];
const weekdaysWork = [1, 2, 3, 4, 5];

const intervalsDefault = {
  first: 0,
  minutes: 60,
  count: 24,
  height: 48
};

type CalendarOccurrenceVisual = {
  start_time: string;
  end_time: string;
  timed: boolean;
  category: string | string[];
};

export type VCalendar = Vue & {
  prev: () => void;
  next: () => void;
  getFormatter: (format: any) => any;
};

enum DeleteOptions {
  DELETE_SINGLE_OCCURRENCE = 0,
  DELETE_FUTURE_OCCURRENCES = 1,
  DELETE_ENTIRE_EVENT_SERIES = 2
}

function formatTime(
  withTime: { hour: number; minute: number },
  ampm: boolean
): string {
  const suffix = ampm ? (withTime.hour < 12 ? 'a' : 'p') : '';
  const hour = withTime.hour % 12 || 12;
  const minute = withTime.minute;
  return minute > 0
    ? minute < 10
      ? `${hour}:0${minute}${suffix}`
      : `${hour}:${minute}${suffix}`
    : `${hour}${suffix}`;
}
const MINUTES_IN_DAY = 1440;
const MINUMUM_EVENT_HEIGHT = 20; // same from vuetify event height prop
const logger = new Logger('Calendar');
logger.setLogLevel('debug');

type AltoleapEvent = CalendarOccurrence | CalendarEvent;

export default mixins(vcalendar, auth).extend({
  components: { StackedParticipantAvatarList, RecurrenceSaveDialog },
  props: {
    provider: {
      type: Object
    }
  },
  data: () => ({
    focus: '' as string | Date,
    tab: null,
    settingsDialog: false,
    dark: false,
    startMenu: false,
    'event-overlap-threshold': 60,
    endMenu: false,
    nowMenu: false,
    minWeeks: 1,
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1'
    ],

    labels: {
      today: 'today',
      todayIcon: 'mdi-calendar'
    },
    typeOptions: [
      {
        id: 'D',
        label: 'Day',
        shortcut: 'D',
        type: 'day',
        size: 1,
        focus: 0.4999,
        repeat: true,
        listTimes: true,
        updateRows: true,
        schedule: false
      },
      {
        id: 'W',
        label: 'Week',
        shortcut: 'W',
        type: 'week',
        size: 1,
        focus: 0.4999,
        repeat: true,
        listTimes: true,
        updateRows: true,
        schedule: false
      },
      {
        id: 'M',
        label: 'Month',
        shortcut: 'M',
        type: 'month',
        size: 1,
        focus: 0.4999,
        repeat: true,
        listTimes: false,
        updateRows: true,
        schedule: false
      },
      {
        id: 'S',
        label: 'Schedule',
        shortcut: 'S',
        type: 'category',
        size: 92,
        focus: 0.0,
        repeat: false,
        listTimes: false,
        updateRows: false,
        schedule: true
      }
    ],
    mode: 'column',
    modeOptions: [
      { text: 'Stack', value: 'stack' },
      { text: 'Column', value: 'column' }
    ],
    weekdays: weekdaysDefault,
    weekdaysDefault,
    weekdaysWork,
    weekdaysOptions: [
      { text: 'Sunday - Saturday', value: weekdaysDefault },
      { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] }
    ],
    intervals: intervalsDefault,
    intervalsOptions: [
      { text: 'Default', value: intervalsDefault },
      {
        text: 'Workday',
        value: { first: 16, minutes: 30, count: 20, height: 48 }
      }
    ],
    maxDays: 7,
    maxDaysOptions: [
      { text: '7 days', value: 7 },
      { text: '5 days', value: 5 },
      { text: '4 days', value: 4 },
      { text: '3 days', value: 3 }
    ],
    styleInterval: 'default',
    styleIntervalOptions: [
      { text: 'Default', value: 'default' },
      { text: 'Workday', value: 'workday' },
      { text: 'Past', value: 'past' }
    ],
    color: 'primary',
    colorOptions: [
      { text: 'Primary', value: 'primary' },
      { text: 'Secondary', value: 'secondary' },
      { text: 'Accent', value: 'accent' },
      { text: 'Red', value: 'red' },
      { text: 'Pink', value: 'pink' },
      { text: 'Purple', value: 'purple' },
      { text: 'Deep Purple', value: 'deep-purple' },
      { text: 'Indigo', value: 'indigo' },
      { text: 'Blue', value: 'blue' },
      { text: 'Light Blue', value: 'light-blue' },
      { text: 'Cyan', value: 'cyan' },
      { text: 'Teal', value: 'teal' },
      { text: 'Green', value: 'green' },
      { text: 'Light Green', value: 'light-green' },
      { text: 'Lime', value: 'lime' },
      { text: 'Yellow', value: 'yellow' },
      { text: 'Amber', value: 'amber' },
      { text: 'Orange', value: 'orange' },
      { text: 'Deep Orange', value: 'deep-orange' },
      { text: 'Brown', value: 'brown' },
      { text: 'Blue Gray', value: 'blue-gray' },
      { text: 'Gray', value: 'gray' },
      { text: 'Black', value: 'black' }
    ],
    minimumEventHeight: MINUMUM_EVENT_HEIGHT,

    shortIntervals: true,
    shortMonths: true,
    shortWeekdays: false,
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      category: 'Schedule'
    },

    calendarStart: {} as CalendarTimestamp,
    calendarEnd: {} as CalendarTimestamp,
    selectedEvent: null,
    selectedElement: null as HTMLElement | null,
    selectedOpen: false,
    searchMode: false,
    recurrence: 0,
    calendarDrawer: false,
    categories: [] as any[],
    selectedCategories: [] as any[],
    originalDragEvent: null as any,
    dragEvent: null as any,
    dragStart: null as any,
    dragTime: null as number | null,
    eventPlaceholder: null as any,
    eventPlaceholderStart: null as any,
    extendOriginal: null as any,
    detailOptions: {
      reminder: {
        minutes: 0,
        minutesList: [
          { text: 'minutes', value: 0 },
          { text: 'hours', value: 1 },
          { text: 'days', value: 2 }
        ]
      }
    },

    timezone: moment.tz.guess(),
    ready: false,

    // when updated, value will always be greater than 0
    currentTimeMarker: -1
  }),

  computed: {
    cal(): any {
      return this.ready ? this.$refs.calendar : null;
    },

    authProvider(): any {
      return authProvider(this.$store);
    },

    accountsProvider(): any {
      return accountsProvider(this.$store);
    },

    ...mapState('calendar', [
      'calendars',
      'events',
      'loading',
      'occurrences',
      'initialCalendarsLoaded'
    ]),

    canMoveEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },

    canCreateEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },
    canDeleteEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },

    start: {
      get(): CalendarTimestamp {
        return this.startTime;
      },
      set(val: any): void {
        this.$store.commit('calendar/updateStart', val);
      }
    },
    end: {
      get(): CalendarTimestamp {
        return this.endTime;
      },
      set(val: any): void {
        this.$store.commit('calendar/updateEnd', val);
      }
    },
    ...mapState({
      startTime: (state: any) => state.calendar.start,
      endTime: (state: any) => state.calendar.end
    }),
    ...mapGetters({
      getAccountsByName: 'accounts/getAccountsByName',
      enabledCalendars: 'calendar/enabledCalendars',
      getCalendarEventById: 'calendar/getCalendarEventById'
    }),

    isViewMonth(): boolean {
      return this.type == 'month';
    },

    todayDate: (): string =>
      dateFormat(new Date(), 'MMM DD, YYYY hh:mm:ssa', false),

    calendarOccurrences(): CalendarOccurrence[] {
      const getCategory = (participant: Participant): string => {
        return `${participant.attendee.first_name} ${participant.attendee.last_name}`;
      };

      let parsedOccurrences = [];

      if (this.type === 'category') {
        this.categories.map((category) => {
          const categoryText = category;

          parsedOccurrences.push(
            ...this.occurrences.map((occurrence: CalendarOccurrence) => {
              const occurrenceExtension: any = {};
              occurrenceExtension.start_time = localDateFormat(
                occurrence.date!.start,
                'YYYY-MM-DD HH:mm',
                occurrence.date!.timezone
              );

              occurrenceExtension.end_time = localDateFormat(
                occurrence.date!.end,
                'YYYY-MM-DD HH:mm',
                occurrence.date!.timezone
              );

              occurrenceExtension.timed = !!occurrenceExtension.end_time;

              if (
                Array.isArray(occurrence.participants) &&
                occurrence.participants.length
              ) {
                const categories: string[] = [];
                occurrence.participants.forEach((participant) => {
                  categories.push(getCategory(participant));
                });

                if (categories.includes(category)) {
                  occurrenceExtension.category = categoryText;
                }
              }

              return { ...occurrence, ...occurrenceExtension };
            })
          );
        });
      } else {
        parsedOccurrences = this.occurrences.map(
          (occurrence: CalendarOccurrence & CalendarOccurrenceVisual) => {
            occurrence.start_time = localDateFormat(
              occurrence.date!.start,
              'YYYY-MM-DD HH:mm',
              occurrence.date!.timezone
            );

            occurrence.end_time = localDateFormat(
              occurrence.date!.end,
              'YYYY-MM-DD HH:mm',
              occurrence.date!.timezone
            );

            occurrence.timed = !!occurrence.end_time;

            return occurrence;
          }
        );
      }
      return parsedOccurrences;
    },
    settingsPage: {
      get() {
        return this.settingsDialog;
      },
      set(val: boolean) {
        return this.setSettingsPage(val);
      }
    } as any,
    monthFormatter(): CalendarFormatter {
      return this.getFormatter({
        timeZone: 'UTC',
        month: 'long'
      });
    },
    title(): string {
      const { calendarStart, calendarEnd } = this;
      if (!calendarStart || !calendarEnd) {
        return '';
      }

      const startMonth: any = (this as any).monthFormatter(calendarStart);
      const endMonth: any = (this as any).monthFormatter(calendarEnd);
      const suffixMonth = startMonth === endMonth ? '' : endMonth;

      const startYear = calendarStart.year;
      const endYear = calendarEnd.year;
      const suffixYear = startYear === endYear ? '' : startYear;

      const startDay = calendarStart.day + this.nth(calendarStart.day);
      const endDay = calendarEnd.day + this.nth(calendarEnd.day);

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`;

        case 'week':
        case 'schedule':
          return `${startMonth} ${startDay} ${suffixYear} - ${suffixMonth} ${endDay}, ${endYear}`;

        case 'day':
        case 'category':
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return '';
    },

    /**
     * Event Placeholder for adding an event
     *
     * - uses timed as a value because VCalendar wants timed key for displayed events
     * @returns {Job}
     */
    getEventPlaceholderForAdd(): Job {
      return {
        title: '',
        color: this.$vuetify.theme.currentTheme.primary as string,
        date: {
          timezone: moment.tz.guess() ?? this.calendars[0].timezone,
          start: new Date(),
          end: new Date()
        },
        description: '',
        location: '',
        recurrence: {
          enabled: false,
          rules: []
        },
        calendar: this.calendars[0].id,
        participants: [],
        reminder: {
          enabled: false,
          minutes: 10
        },
        conference: {
          enabled: false,
          platform: 0
        }
      };
    }
  },

  watch: {
    selectedCategories(val: number[]) {
      this.setSelectedItems();
    },
    selectedOpen(value: boolean) {
      value || this.closeDialog(value);
    }
  },

  methods: {
    ...mapActions('calendarOccurrence', [
      // these actions ahve a much simplier implmentation
      'updateEventOccurrence',
      'sendCalendarOccurrenceInvite'
    ]),

    ...mapActions('calendar', [
      'getOccurrences',
      'fetchCalendars',
      'fetchCalendarOccurrence',
      'fetchCalendarOccurrences',
      'fetchCalendarEvent',
      'fetchCalendarEvents',
      'createCalendarEvent',
      'updateCalendarEvent',
      'deleteCalendarEvent',
      'updateCalendarOccurrence',
      'moveCalendarOccurrence',
      'deleteCalendarOccurrence',
      'sendCalendarEventInvite'
    ]),

    ...mapActions('snackbar', [
      'snackWarning',
      'snackMessage',
      'createSnack',
      'snackSuccess',
      'snackError'
    ]),

    ...mapActions('accounts', ['fetchAccounts']),

    loadingCalendars(): Promise<void> {
      if (this.initialCalendarsLoaded) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const watcher = this.$watch('initialCalendarsLoaded', () => {
          resolve();
          watcher();
        });
      });
    },

    async updatedCalendarList() {
      await this.rebuild();
    },

    async rebuild() {
      await this.getOccurrences();
    },

    async openRecurrenceSave(options: RecurrenceSaveOptions) {
      const recurrenceSaveDialog = this.$refs
        .recurrenceSaveDialog as InstanceType<typeof RecurrenceSaveDialog>;
      return await recurrenceSaveDialog.open(options);
    },

    async updateRange(value: any) {
      this.calendarStart = value.start;
      this.calendarEnd = value.end;
      // store

      this.start = moment(value.start.date)
        .utc(true)
        .startOf('day')
        .format('YYYY-MM-DD[T]HH:mm:ss');

      this.end = moment(value.end.date)
        .utc(true)
        .endOf('day')
        .format('YYYY-MM-DD[T]HH:mm:ss');

      await this.loadingCalendars();

      await this.updatedCalendarList();
    },

    closeDialog(value: boolean) {
      this.selectedOpen = value;

      this.selectedElement = null;
      this.selectedEvent = null;
    },

    showIntervalLabel(interval: any) {
      return interval.minute === 0;
    },

    viewDay(val: CalendarDaySlotScope) {
      this.focus = val.date;
      this.type = 'day';
    },

    setSettingsPage(value: any) {
      this.settingsDialog = value;
    },

    prev(): any {
      (this.cal as VCalendar).prev();
    },

    next() {
      (this.cal as VCalendar).next();
    },

    async addFocusDate() {
      this.focus = new Date(this.focus);

      // checking validity of focus to be an instance of a Date class
      if (!(this.focus instanceof Date && !isNaN(this.focus.getTime()))) {
        this.focus = new Date();
      }

      this.add({
        start: moment(this.focus).toDate(),
        end: moment(this.focus).add(1, 'hour').toDate()
      });
    },

    async add(date?: Partial<CalendarEvent['date']>) {
      const dtstart = moment(date ? date!.start! : new Date())
        .valueOf()
        .toString();
      const dtend = moment(date ? date!.end! : new Date())
        .valueOf()
        .toString();
      this.$router.push({
        name: 'SchedulerNewJobView',
        params: {
          view: this.type as string,
          calendarId: String(this.calendars[0].id) ?? String(1),
          dtstart,
          dtend
        }
      });
    },

    async edit(occurrence: CalendarOccurrence) {
      const recurrenceId = moment(occurrence.original_start!)
        .valueOf()
        .toString();

      await this.$router.push({
        name: 'SchedulerEditJobView',
        params: {
          view: this.type as string,
          calendarId: String(occurrence.calendar),
          eventId: String(occurrence.event),
          recurrenceId
        }
      });
    },

    getEventColor(event: { color: string }): string {
      if (event.color === '#FFFFFF') {
        return 'primary';
      } else {
        return event.color ?? 'primary';
      }
    },

    eventInfo(
      event: any | CalendarTimestamp,
      timedEvent: boolean,
      eventName: string,
      fallback: any
    ) {
      const name = escapeHTML(
        getPropertyFromItem(event.input, eventName, fallback)
      );
      if (event.start.hasTime) {
        if (timedEvent) {
          const showStart = event.start.hour < 12 && event.start.hour >= 12;
          const start = formatTime(event.start, showStart);
          const end = formatTime(event.end, true);
          const singline = diffMinutes(event.start, event.end) <= 45;
          const separator = singline ? ', ' : '<br>';
          return `<strong>${upperFirst(
            name
          )}</strong>${separator}${start} - ${end}`;
        } else {
          const time = formatTime(event.start, true);
          return `<strong>${time}</strong> ${name}`;
        }
      }
      return name;
    },

    nth(d: number): string {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
    },

    rnd(a: number, b: number): number {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    getFormatter(options: object): CalendarFormatter {
      return createNativeLocaleFormatter('en', (_tms, _short) => options);
    },

    async deleteEventDialog(ev: any) {
      await this.openRecurrenceSave({
        occurrence: ev,
        originalOccurrence: ev,
        isDelete: true
      }).then((isConfirmed) => {
        if (!isConfirmed) {
          this.$nextTick(() => {
            this.rebuild();
          });
        }
      });
    },

    async sendEventInvite(result: Job, create = true) {
      if (
        await this.openChoiceDialog('Send schedule to employees?', {
          left: { text: 'Dont Send' },
          right: { text: 'Send', color: 'primary' }
        })
      ) {
        this.sendCalendarEventInvite({
          calendarId: result.calendar,
          eventId: result.id,
          start: result.date?.start,
          end: result.date?.end,
          create: create
        })
          .then(() => {
            this.snackMessage({
              msg: `Sent invite to employees`,
              timeout: 6 * 1000
            });
          })
          .catch((error: any) => {
            this.snackError({
              msg: `Error sending invite to employees: ${ErrorManager.extractApiError(
                error
              )}`,
              timeout: 6 * 1000
            });
          });
      }
    },

    async sendOccurrenceInvite(result: Job) {
      if (
        await this.openChoiceDialog('Send schedule to employees?', {
          left: { text: 'Dont Send' },
          right: { text: 'Send', color: 'primary' }
        })
      ) {
        const recurrenceId = new Date(result.original_start!).getTime();
        this.sendCalendarOccurrenceInvite({
          calendarId: result.calendar,
          eventId: result.event,
          recurrenceId
        })
          .then(() => {
            this.snackMessage({
              msg: `Sent invite to employees`,
              timeout: 6 * 1000
            });
          })
          .catch((error: any) => {
            this.snackError({
              msg: `Error sending invite to employees: ${ErrorManager.extractApiError(
                error
              )}`,
              timeout: 6 * 1000
            });
          });
      }
    },

    async tryGetOrFetchCalendarEvent(calendarId: number, eventId: number) {
      if (!this.getCalendarEventById(eventId)) {
        await this.fetchCalendarEvent({
          calendarId: calendarId,
          eventId: eventId
        });
      }
      return this.getCalendarEventById(eventId);
    },

    findExistingIndex(item: string) {
      return (this.selectedCategories || []).findIndex((i: object) =>
        deepEqual(i, item)
      );
    },

    selectCategory(data: string) {
      const internalValue = (this.selectedCategories || []).slice();
      const i = this.findExistingIndex(data);
      i !== -1 ? internalValue.splice(i, 1) : internalValue.push(data);
      this.setValue(
        internalValue.map((i: object) => {
          return i;
        })
      );
      return;
    },

    setValue(value: any) {
      const oldValue = this.selectedCategories;
      this.selectedCategories = value;
      value !== oldValue && this.$emit('change', value);
    },

    setSelectedItems() {
      const selectedItems = [];
      const values = !Array.isArray(this.selectedCategories)
        ? [this.selectedCategories]
        : this.selectedCategories;

      for (const value of values) {
        const index = this.getAccountsByName.findIndex((v: string) =>
          deepEqual(v, value)
        );

        if (index > -1) {
          selectedItems.push(this.getAccountsByName[index]);
        }
      }

      this.categories = selectedItems;
    },

    computeEventDurationInPixels(
      day: CalendarDayBodySlotScope,
      eventParsed: CalendarEventParsed,
      start: boolean,
      end: boolean
    ) {
      return Math.max(
        this.minimumEventHeight,
        (end ? day.timeToY(MINUTES_IN_DAY) : day.timeToY(eventParsed.end)) -
          (start ? day.timeToY(eventParsed.start) : 0)
      );
    },

    convertReminderTime(check: boolean, cond: number, time: number): number {
      const DAY = MINUTES_IN_DAY;
      const HOUR = 60;

      if (check) {
        switch (cond) {
          case 0:
            break;
          case 1:
            time = time * HOUR;
            break;
          case 2:
            time = time * DAY; //(60 * 24);
            break;
          default:
            break;
        }
      }
      return time;
    },

    parseEvent(event: AltoleapEvent, options: any): Partial<AltoleapEvent> {
      if (!isEmpty(event)) {
        const parseEventParticipants = (event: AltoleapEvent) => {
          if (event.participants!.length) {
            event.participants =
              event.participants.map((participant: Participant) => {
                return (
                  { id: participant.id, attendee: participant.attendee.id } ||
                  []
                );
              }) || [];
          }

          return event;
        };
        event.reminder!.minutes = this.convertReminderTime(
          event.reminder!.enabled,
          options.reminder.minutes,
          event.reminder!.minutes
        );

        event = parseEventParticipants(event);

        return event;
      } else return event;
    },

    async openChoiceDialog(
      title?: string,
      buttonOptions?: {
        left?: {
          text: string;
        };
        right?: {
          color?: string;
          text?: string;
          icon?: string;
          iconOnly?: boolean;
        };
      }
    ): Promise<boolean> {
      return await (this as any).$refs.choiceDialog
        .open(title, buttonOptions)
        .then((choice?: boolean) => choice);
    },

    startDrag(element: any, mouseEvent: MouseEvent) {
      const leftButton = mouseEvent.buttons === 1;

      if (leftButton) {
        if (this.canMoveEvent) {
          if (element.event && element.timed) {
            this.originalDragEvent = cloneDeep(element.event);

            this.dragEvent = element.event;
            this.dragTime = null;
            this.extendOriginal = null; //not used
          }
        }
      }
    },

    startDragTime(event: CalendarDayBodySlotScope, mouseEvent: MouseEvent) {
      const leftButton = mouseEvent.buttons === 1;
      if (leftButton) {
        this.selectedElement = null;
        const mouseYTimestamp = this.toTime(event);
        // edit
        if (this.dragEvent && !this.dragTime) {
          if (this.canMoveEvent) {
            const start = new Date(this.dragEvent.date.start).getTime();

            this.dragTime = mouseYTimestamp - start;
          }
        }
        // create
        else if (!this.selectedOpen) {
          if (this.canCreateEvent) {
            this.eventPlaceholderStart = this.roundTime(mouseYTimestamp);

            this.eventPlaceholder = null;
            this.$nextTick(() => {
              this.eventPlaceholder = cloneDeep(this.getEventPlaceholderForAdd);

              this.eventPlaceholder.date.start = new Date(
                this.eventPlaceholderStart
              );
              this.eventPlaceholder.date.end = new Date(
                this.roundTime(this.eventPlaceholderStart, 30, false)
              );
              this.eventPlaceholder.title = '(No title)';

              this.occurrences.push(this.eventPlaceholder);
            });
          }
        }
      }
    },

    extendBottom(event: any) {
      this.eventPlaceholder = event;
      this.eventPlaceholderStart = event.date.start;
      this.extendOriginal = event.date.end;
    },

    mouseMove(event: CalendarDayBodySlotScope, mouseEvent: MouseEvent) {
      const leftButton = mouseEvent.buttons === 1;

      if (leftButton) {
        const mouseYTimestamp = this.toTime(event);

        // EDITING EVENT
        if (this.dragEvent && this.dragTime !== null) {
          this.selectedElement = null;
          if (this.canMoveEvent) {
            const start = new Date(this.dragEvent.date.start).getTime();
            const end = new Date(this.dragEvent.date.end).getTime();

            const duration = end - start;

            const newStartTime = mouseYTimestamp - this.dragTime;
            const newStart = this.roundTime(newStartTime);
            const newEnd = newStart + duration;
            this.dragEvent.date.start = new Date(newStart);
            this.dragEvent.date.end = new Date(newEnd);
          }
        }
        //  CREATING EVENT
        else if (this.eventPlaceholder && this.eventPlaceholderStart !== null) {
          if (this.canCreateEvent) {
            const mouseRounded = this.roundTime(mouseYTimestamp, 15, false);
            const min = Math.min(mouseRounded, this.eventPlaceholderStart);
            const max = Math.max(mouseRounded, this.eventPlaceholderStart);

            this.eventPlaceholder.date.start = new Date(min);
            this.eventPlaceholder.date.end = new Date(max);
          }
        }
      }
    },

    resetDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.originalDragEvent = null;
      this.eventPlaceholder = null;
      this.eventPlaceholderStart = null;
      this.extendOriginal = null;
    },

    async endDrag(event: CalendarDayBodySlotScope, mouseEvent: MouseEvent) {
      if (this.dragEvent && this.dragTime) {
        this.dragTime = null; // clear flag to prevent movement while updating backend

        this.setOriginalDragEventDate();

        if (
          this.dragEvent &&
          !deepEqual(this.dragEvent.date, this.originalDragEvent.date)
        ) {
          await this.openRecurrenceSave({
            occurrence: this.dragEvent,
            originalOccurrence: this.originalDragEvent,
            isDelete: false
          })
            .then((result) => {
              if (!result) {
                this.$nextTick(() => {
                  this.dragEvent.date = this.originalDragEvent.date;
                });
              }
            })
            .catch(() => {
              this.dragEvent.date = this.originalDragEvent.date;
            })
            .finally(() => {
              this.$nextTick(() => {
                this.resetDrag();
              });
            });
        } else {
          this.resetDrag();
        }
      } else if (this.eventPlaceholder && this.eventPlaceholderStart !== null) {
        this.eventPlaceholderStart = null; // clear flag to prevent movement whie updating backend

        this.$nextTick(() => {
          const copy = cloneDeep(this.eventPlaceholder);

          setTimeout(
            () =>
              this.add({
                start: setUTCPartsToDate(copy.date.start),
                end: setUTCPartsToDate(copy.date.end)
              }),
            10
          );
        });

        setTimeout(() => {
          const i = this.occurrences.indexOf(this.eventPlaceholder);
          if (i !== -1) {
            this.occurrences.splice(i, 1);
          }

          this.eventPlaceholder = null;
        }, 200);
      }
    },

    async cancelDrag() {
      if (this.eventPlaceholder) {
        if (this.extendOriginal) {
          this.eventPlaceholder.date.end = this.extendOriginal;
        } else {
          const i = this.occurrences.indexOf(this.eventPlaceholder);
          if (i !== -1) {
            this.occurrences.splice(i, 1);
          }
        }
      }
    },

    setOriginalDragEventDate() {
      if (!(this.originalDragEvent.date.start instanceof Date))
        this.originalDragEvent.date.start = new Date(
          this.originalDragEvent.date.start
        );
      if (!(this.originalDragEvent.date.end instanceof Date))
        this.originalDragEvent.date.end = new Date(
          this.originalDragEvent.date.end
        );
      if (!(this.dragEvent.date.start instanceof Date))
        this.dragEvent.date.start = new Date(this.dragEvent.date.start);
      if (!(this.dragEvent.date.end instanceof Date))
        this.dragEvent.date.end = new Date(this.dragEvent.date.end);
    },

    roundTime(time: number, roundTo = 30, down = true) {
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },

    toTime(tms: CalendarTimestamp): number {
      return setPartsToUTCDate(
        new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute)
      ).getTime();
    },

    rndElement(arr: any[]) {
      return arr[this.rnd(0, arr.length - 1)];
    },

    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    setCurrentTimeMarker() {
      this.currentTimeMarker = this.cal.timeToY(moment().format('HH:mm'));
    },

    updateCurrentTimeMarkerPeriodically() {
      // callback to set first to enforce intial callback
      // https://stackoverflow.com/questions/6685396/execute-the-setinterval-function-without-delay-the-first-time
      this.setCurrentTimeMarker();
      setInterval(this.setCurrentTimeMarker, 60 * 1000);
    }
  },

  async mounted() {
    await this.loadingCalendars();
    await this.accountsProvider.fetchAccount(
      this.authProvider.getCurrentUser.id
    );
    // flag for setting the vuetify calendar to be available to this component
    this.ready = true;
    this.$nextTick(() => {
      this.updateCurrentTimeMarkerPeriodically();
      this.scrollToTime();
      this.updateTime();
    });

    if (this.timezone === 'UTC') {
      this.snackWarning({
        msg: 'The automatic timezone detection determined your timezone to be UTC.\
          \nThis is most likely the result of security measures of your web browser.\
          \nPlease set your timezone manually in the calendar settings.',
        timeout: 30 * 1000
      });
    }
    await this.fetchCalendarEvents();

    await this.updatedCalendarList();
  },

  async beforeMount() {
    await this.fetchCalendars();
    await this.fetchAccounts(true).then(() => {
      this.categories = this.getAccountsByName;
      this.selectedCategories = this.categories;
    });

    this.focus = this.today;
  }
});
