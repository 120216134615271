
import { MovingItem } from '@/models/movingItem';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateFormat from '@/utils/dateFormat';
import Vue from 'vue';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  data() {
    return {
      imageDialog: false,
      loading: false,

      search: '',
      selectedImage: '' as any,
      errorMessage: '',

      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Thumbnail',
          sortable: false,
          value: 'media'
        },
        {
          text: 'Item #',
          value: 'identifier'
        },
        {
          text: 'Description',
          sortable: false,
          width: '100px',
          value: 'description'
        },
        {
          text: 'Dimensions',
          sortable: false,
          value: 'dimensions'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location'
        },
        {
          text: 'Out Date',
          value: 'out_date'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        }
      ]
    };
  },

  computed: {
    ...mapState('movingItem', ['movingItem']),
    ...mapState('movingInventory', ['movingInventory']),

    ...mapGetters({
      getMovingInventoryById: 'movingInventory/getMovingInventoryById'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      fetchSharedMovingInventory: 'movingInventory/fetchSharedMovingInventory',
      fetchSharedMovingItems: 'movingItem/fetchSharedMovingItems'
    }),

    dateFormat,

    /**
     * open Image dialog
     *
     *
     */
    openImageDialog(item: MovingItem) {
      this.selectedImage = item.media;
      this.imageDialog = true;
    }
  },

  async mounted() {
    this.loading = true;

    this.fetchSharedMovingItems(this.$route.params.movingItemId)
      .then(() => (this.loading = false))
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });

    this.fetchSharedMovingInventory(this.$route.params.movingItemId)
      .then(() => (this.loading = false))
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
  }
});
