import {
  IContactNoteActions,
  ContactNoteContext,
  IContactNoteActionPayload,
  IContactNoteFetchActionPayload
} from './actions.types';
import { IContactNoteData } from '@/models';

export const actions: IContactNoteActions = {
  fetchContactNotes(
    context: ContactNoteContext,
    payload: number
  ): Promise<void> {
    return this.$services.contact
      .fetchContactNotes(payload)
      .then((contactnotes: IContactNoteData[]) => {
        context.commit('SET_CONTACT_NOTES', contactnotes);
      });
  },

  fetchContactNote(
    context: ContactNoteContext,
    payload: IContactNoteFetchActionPayload
  ): Promise<void> {
    return this.$services.contact
      .fetchContactNote(payload.contactId, payload.noteId)
      .then((contactnote: IContactNoteData) => {
        const index = context.state.contactNotes.findIndex(
          (v: IContactNoteData) => v.id === contactnote.id
        );
        if (index === -1) {
          context.commit('ADD_CONTACT_NOTE', contactnote);
        } else {
          context.commit('UPDATE_CONTACT_NOTE', contactnote);
        }
      });
  },

  createContactNote(
    context: ContactNoteContext,
    payload: IContactNoteActionPayload
  ): Promise<void> {
    return this.$services.contact
      .createContactNote(payload.contactId, payload.note)
      .then((contactnote: IContactNoteData) =>
        context.commit('ADD_CONTACT_NOTE', contactnote)
      );
  },

  updateContactNote(
    context: ContactNoteContext,
    payload: IContactNoteActionPayload
  ): Promise<void> {
    return this.$services.contact
      .updateContactNote(payload.contactId, payload.note)
      .then((contactnote: IContactNoteData) => {
        const index = context.state.contactNotes.findIndex(
          (v: IContactNoteData) => v.id === contactnote.id
        );
        if (index === -1) {
          context.commit('ADD_CONTACT_NOTE', contactnote);
        } else {
          context.commit('UPDATE_CONTACT_NOTE', contactnote);
        }
      });
  },

  deleteContactNote(
    context: ContactNoteContext,
    payload: IContactNoteFetchActionPayload
  ): Promise<void> {
    return this.$services.contact
      .deleteContactNote(payload.contactId, payload.noteId)
      .then(() => {
        context.commit('REMOVE_CONTACT_NOTE', payload);
      });
  }
};
