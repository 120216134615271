
import { MovingInventory } from '@/models/movingInventory';
import { mapActions, mapGetters } from 'vuex';
import { CalendarOccurrence } from '@/models/calendar';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { ErrorManager } from '@/models/error';

enum Step {
  JOBOCCURRENCE = 1,
  DETAILS = 2
}

export default Vue.extend({
  data() {
    return {
      Step,
      value: Step.JOBOCCURRENCE,

      selectedJobOccurrence: new CalendarOccurrence(),
      copiedJobOccurrence: new CalendarOccurrence(),

      movingInventory: new MovingInventory(),
      copiedInventory: new MovingInventory(),

      errorMessage: '',
      fieldError: [] as string[],

      dialog: false,
      editFlag: false,
      valid: false,
      loadingFlag: false
    };
  },

  computed: {
    ...mapGetters({
      getMovingInventoryById: 'movingInventory/getMovingInventoryById'
    }),

    isObject(): boolean {
      return this.movingInventory.customer instanceof Object;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      createMovingInventory: 'movingInventory/createMovingInventory',
      updateMovingInventory: 'movingInventory/updateMovingInventory',
      fetchMovingInventory: 'movingInventory/fetchMovingInventory'
    }),

    addMovingInventory() {
      this.movingInventory.job = this.selectedJobOccurrence.event!;
      this.movingInventory.occurrence_date =
        this.selectedJobOccurrence.original_start!;

      if (
        this.movingInventory.vendor_shipped!.contact! instanceof Object &&
        this.movingInventory.vendor_shipped!.company! instanceof Object
      ) {
        this.movingInventory.vendor_shipped = {
          contact: null,
          company: null
        };
      }

      if (
        this.movingInventory.vendor_received!.contact! instanceof Object &&
        this.movingInventory.vendor_received!.company! instanceof Object
      ) {
        this.movingInventory.vendor_received = {
          contact: this.movingInventory.vendor_received!.contact!.id,
          company: null
        };
      }

      this.createMovingInventory(this.movingInventory)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.order_number) {
            this.fieldError = error.response.data.order_number;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    editMovingInventory() {
      this.movingInventory.job =
        this.selectedJobOccurrence.event ?? this.selectedJobOccurrence.id!;
      this.movingInventory.occurrence_date =
        this.selectedJobOccurrence.original_start!;

      if (this.movingInventory.customer!.contact! instanceof Object) {
        this.movingInventory.customer = {
          contact: this.movingInventory.customer!.contact!.id,
          company: null
        };
      }

      if (this.movingInventory.customer!.company! instanceof Object) {
        this.movingInventory.customer = {
          contact: null,
          company: this.movingInventory.customer!.company!.id
        };
      }

      if (this.movingInventory.vendor_shipped!.contact! instanceof Object) {
        this.movingInventory.vendor_shipped = {
          contact: this.movingInventory.vendor_shipped!.contact!.id,
          company: null
        };
      }

      if (this.movingInventory.vendor_shipped!.company! instanceof Object) {
        this.movingInventory.vendor_shipped = {
          contact: null,
          company: this.movingInventory.vendor_shipped!.company!.id
        };
      }

      if (this.movingInventory.vendor_received!.contact! instanceof Object) {
        this.movingInventory.vendor_received = {
          contact: this.movingInventory.vendor_received!.contact!.id,
          company: null
        };
      }

      if (this.movingInventory.vendor_received!.company! instanceof Object) {
        this.movingInventory.vendor_received = {
          contact: null,
          company: this.movingInventory.vendor_received!.company!.id
        };
      }

      this.updateMovingInventory(this.movingInventory)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.order_number) {
            this.fieldError = error.response.data.order_number;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    changeDetector() {
      this.dialog =
        JSON.stringify(this.movingInventory) !==
          JSON.stringify(this.copiedInventory) ||
        JSON.stringify(this.selectedJobOccurrence) !==
          JSON.stringify(this.copiedJobOccurrence);
      if (!this.dialog) {
        this.close();
      }
    },

    close() {
      this.$router.push({
        name: 'moving-inventory-management'
      });
    },

    validData(isValid: boolean) {
      this.valid = isValid;
    },

    async tryGetOrFetchMovingInventory(movingInventoryId: string) {
      try {
        if (this.getMovingInventoryById(movingInventoryId)) {
          return this.getMovingInventoryById(movingInventoryId);
        } else {
          return this.fetchMovingInventory(movingInventoryId)
            .then(() => this.getMovingInventoryById(movingInventoryId))
            .catch((error: any) => {
              if (error.response.data.order_number) {
                this.fieldError = error.response.data.order_number;
              }
              this.errorMessage = ErrorManager.extractApiError(error);
            });
        }
      } catch (error) {
        if (error.response.data.order_number) {
          this.fieldError = error.response.data.order_number;
        }
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    }
  },

  async mounted() {
    this.loadingFlag = false;
    this.editFlag =
      this.$route.params.action == 'Edit-Inventory' ? true : false;

    if (this.editFlag) {
      const result = await this.tryGetOrFetchMovingInventory(
        this.$route.params.movingInventoryId
      );

      this.movingInventory = result;

      this.selectedJobOccurrence = result.job;
      this.selectedJobOccurrence.original_start = result.occurrence_date;
      this.copiedJobOccurrence = cloneDeep(this.selectedJobOccurrence);
      this.copiedInventory = cloneDeep(this.movingInventory);
    }
    this.loadingFlag = true;
  }
});
