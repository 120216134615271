import { Task, States } from '@/models/task';
import { TaskService } from '@/services/tasks';
import { GetterTree } from 'vuex/types/index';
import { IRootState } from '../store.types';
import { errorCatch } from '../utils';

const taskService = new TaskService();

export const state = {
  tasks: [] as Task[],
  states: [] as States[],
  loading: false,
  error: {}
};

export type TaskState = typeof state;

export const mutations = {
  START_LOADING(state: any) {
    state.loading = true;
  },
  STOP_LOADING(state: any) {
    state.loading = false;
  },
  SET_LOADING(state: any, payload: boolean) {
    state.loading = payload;
  },

  ADD_STATE(state: any, payload: Task) {
    state.states.push(payload);
  },
  SET_STATES(state: any, payload: Task[]) {
    state.states = payload;
  },

  UPDATE_STATE(state: any, payload: Task) {
    const index = state.states.findIndex(
      (task: Task) => task.id === payload.id
    );
    state.states.splice(index, 1, payload);
  },

  REMOVE_STATE(state: any, payload: number) {
    const index = state.states.findIndex((task: Task) => task.id === payload);
    state.states.splice(index, 1);
  },

  ADD_TASK(state: any, payload: Task) {
    state.tasks.push(payload);
  },
  SET_TASKS(state: any, payload: Task[]) {
    state.tasks = payload;
  },

  UPDATE_TASK(state: any, payload: Task) {
    const index = state.tasks.findIndex((task: Task) => task.id === payload.id);
    state.tasks.splice(index, 1, payload);
  },

  REMOVE_TASK(state: any, payload: number) {
    const index = state.tasks.findIndex((task: Task) => task.id === payload);
    state.tasks.splice(index, 1);
  },
  SET_ERROR(state: any, error: any) {
    errorCatch(state, error);
  },
  CLEAR_ERROR(state: any) {
    state.error = {};
  }
};

export const actions = {
  clearError: ({ commit }: any) => commit('CLEAR_ERROR'),

  updateTaskElement: async ({ commit, dispatch }: any, payload: Task) => {
    return taskService.moveTask(payload.id!, payload);
  },

  createTask: async ({ commit, dispatch }: any, payload: Task) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      taskService
        .createTask(payload)
        .then((task) => {
          commit('ADD_TASK', task);
          resolve(task);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchTasks: async ({ commit, dispatch }: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      taskService
        .fetchTasks()
        .then((tasks) => {
          commit('SET_TASKS', tasks);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          //TODO: LF - 2020-03-08: Finalize how to handle error
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchTask: ({ commit, state }: any, payload: number) => {
    return taskService.fetchTask(payload).then((task) => {
      const index = state.tasks.findIndex((v: Task) => v.id === task.id);
      if (index === -1) {
        commit('ADD_TASK', task);
      } else {
        commit('UPDATE_TASK', task);
      }
    });
  },

  updateTask: async ({ commit }: any, payload: Task) => {
    return taskService.updateTask(payload.id!, payload).then((task: any) => {
      commit('UPDATE_TASK', task);
    });
  },

  deleteTask: async ({ commit, dispatch }: any, payload: number) => {
    return taskService.deleteTask(payload).then(() => {
      commit('REMOVE_TASK', payload);
    });
  }
};

export const getters: GetterTree<TaskState, IRootState> = {
  getTasks: (state) => {
    return state.tasks;
  },

  getTaskById: (state: any) => (id: number) => {
    return state.tasks.find((task: Task) => task.id === id) ?? null;
  }
};
