import { IContactState } from '@/store/v2/contact';
import { IContactMutations } from './mutations.types';
import { IContactData } from '@/models';

export const mutations: IContactMutations = {
  SET_CONTACTS(state: IContactState, payload: IContactData[]): void {
    state.contacts = payload;
  },

  ADD_CONTACT(state: IContactState, payload: IContactData): void {
    state.contacts.push(payload);
  },

  UPDATE_CONTACT(state: IContactState, payload: IContactData): void {
    const index = state.contacts.findIndex(
      (contact: IContactData) => contact.id === payload.id
    );

    state.contacts.splice(index, 1, payload);
  },

  REMOVE_CONTACT(state: IContactState, payload: number): void {
    const index = state.contacts.findIndex(
      (contact: IContactData) => contact.id === payload
    );

    state.contacts.splice(index, 1);
  }
};
