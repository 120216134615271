
import Vue from 'vue';
import { mapActions } from 'vuex';

// components
import ErrorAlert from '../../common/ErrorAlert.vue';
import VDragDropAvatarFileInput from '../../common/VDragDropAvatarFileInput.vue';
import CountryPicker from '@/components/core/CountryPicker/Index.vue';
import PhoneInputPicker from '@/components/core/PhoneInputPicker/Index.vue';

// models
import { Company, CompanyFieldError, ICompany } from '@/models';
import { ErrorManager } from '@/models/error';
import { diffDeep } from '@/utils/helpers';

//mixins
import { FormRules } from '@/mixins/FormRules';

export default Vue.extend({
  name: 'CompanyDetailFormDialog',

  components: {
    ErrorAlert,
    CountryPicker,
    PhoneInputPicker,
    VDragDropAvatarFileInput
  },

  mixins: [FormRules],

  data() {
    return {
      isEdit: false,
      valid: false,
      dialog: false,
      loading: false,

      errorMessage: '',

      company: new Company(),
      companyOriginalCopy: null as null | ICompany,

      fieldError: new CompanyFieldError()
    };
  },

  methods: {
    ...mapActions({
      createCompany: 'companyV2/createCompany',
      updateCompany: 'companyV2/updateCompany'
    }),

    open(company: ICompany = new Company(), isEdit = false) {
      this.companyOriginalCopy = company;
      this.company = new Company(this.companyOriginalCopy);

      this.isEdit = isEdit;

      this.clearAllErrors();
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    clearAllErrors() {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    onPhoneNumberInput(
      _fromattedNumber: string,
      { number }: { number: { international: string } }
    ) {
      this.company.phone = number.international;
      this.fieldError.phone.splice(0, this.fieldError.phone.length);
    },

    async saveCompany() {
      this.clearAllErrors();
      this.loading = true;

      try {
        if (this.isEdit) {
          const companyDelta = diffDeep(
            this.company,
            this.companyOriginalCopy,
            true
          );
          await this.updateCompany(companyDelta);
        } else {
          await this.createCompany(this.company);
        }

        this.loading = false;
        this.close();
      } catch (error) {
        this.loading = false;

        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (CompanyFieldError.isCompanyFieldError(error.response.data)) {
            this.fieldError = new CompanyFieldError(error.response.data);
          }
        }

        this.errorMessage = ErrorManager.extractApiError(error);
      }
    }
  }
});
