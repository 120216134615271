
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      dateRange: [] as string[],
      tab: null
    };
  },

  methods: {
    /**
     * set the date range from calendar
     *
     * @param dateRange total amount of hours done by user
     *
     *
     */
    setDateRange(dateRange: string[]) {
      if (dateRange[0] > dateRange[1]) {
        const date = dateRange[0];
        dateRange[0] = dateRange[1];
        dateRange[1] = date;
      }
      this.dateRange = dateRange;

      (this as any).$refs.employeeScheduleTab.setEmployeeWorkTime(
        this.dateRange
      );
    }
  },

  async beforeMount() {
    this.dateRange = [new Date().toISOString(), new Date().toISOString()];
  }
});
