import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Category } from '@/models/inventory';
import { CategoryService } from '@/services/inventory/category-service';

const categoryService = new CategoryService();

export const state = {
  categories: [] as Category[]
};

export type CategoryState = typeof state;

export const mutations: MutationTree<CategoryState> = {
  SET_CATEGORY(state: any, payload: Category[]) {
    state.categories = payload;
  },

  ADD_CATEGORY(state: any, payload: Category) {
    state.categories.push(payload);
  },

  UPDATE_CATEGORY(state: any, payload: Category) {
    const index = state.categories.findIndex(
      (cateogory: Category) => cateogory.id === payload.id
    );
    state.categories.splice(index, 1, payload);
  },

  REMOVE_CATEGORY(state: any, payload: number) {
    const index = state.categories.findIndex(
      (model: Category) => model.id === payload
    );
    state.categories.splice(index, 1);
  }
};

export const actions: ActionTree<CategoryState, CategoryState> = {
  createCategory: async ({ commit }: any, payload: Category) => {
    return categoryService.createCategory(payload).then((model: Category) => {
      commit('ADD_CATEGORY', model);
      return model;
    });
  },

  fetchCategories: async ({ commit }: any) =>
    categoryService.fetchCategories().then((category: Category[]) => {
      commit('SET_CATEGORY', category);
    }),

  fetchCategory: async ({ commit }: any, payload: number) => {
    return categoryService.fetchCategory(payload).then((model: Category) => {
      const index = state.categories.findIndex(
        (v: Category) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_CATEGORY', model);
      } else {
        commit('UPDATE_CATEGORY', model);
      }
    });
  },

  updateCategory: async ({ state, commit }: any, payload: Category) => {
    return categoryService.updateCategory(payload).then((model: Category) => {
      const index = state.categories.findIndex(
        (v: Category) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_CATEGORY', model);
      } else {
        commit('UPDATE_CATEGORY', model);
      }
      return model;
    });
  },

  deleteCategory: async ({ commit }: any, payload: number) =>
    categoryService.deleteCategory(payload).then(() => {
      commit('REMOVE_CATEGORY', payload);
    })
};

export const getters: GetterTree<CategoryState, CategoryState> = {
  getCategoryById: (state: CategoryState) => (id: number) => {
    return state.categories.find((model) => model.id === id);
  }
};
