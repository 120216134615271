import allCountries from './all-countries';
// import 'whatwg-fetch';
import axios from 'axios';
import { cloneDeep } from 'lodash';
// to avoid using axios instances with other api clients
// const axiosCopy = cloneDeep(axios).create({
//   url: 'https://ip2c.org/s',
//   headers: null,
// });

// axiosCopy.defaults.headers = null;

export async function getCountry() {
  const response = await fetch('https://ip2c.org/s');
  const response_1 = await response.text();
  const result = (response_1 || '').toString();
  if (!result || result[0] !== '1') {
    throw new Error('unable to fetch the country');
  }
  return result.substr(2, 2);
  // const { data } = await axiosCopy.get('https://ip2c.org/s');
  // const result = (data || '').toString();
  // if (!result || result[0] !== '1') {
  //   throw new Error('unable to fetch the country');
  // }
  // return result.substr(2, 2);
}

// Credits: http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
export function setCaretPosition(ctrl: any, pos: any) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}

export const defaultOptions = {
  placeholder: 'Enter a phone number',
  label: 'Enter a Phone Number',
  disabledFetchingCountry: false,
  disabled: false,
  disabledFormatting: false,
  mode: '',
  invalidMsg: '',
  required: false,
  allCountries,
  defaultCountry: '',
  enabledCountryCode: false,
  enabledFlags: true,
  preferredCountries: [],
  onlyCountries: [],
  ignoredCountries: [],
  autofocus: false,
  autocomplete: 'on',
  name: 'telephone',
  wrapperClasses: '',
  inputClasses: '',
  inputId: '',
  dropdownOptions: {},
  inputOptions: {},
  maxLen: 25,
  validCharactersOnly: false,
  dynamicPlaceholder: false
};

export default {
  options: { ...defaultOptions }
};
