
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { Inventory } from '@/models/inventory';
import { ErrorManager } from '@/models/error';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';

export default mixins(auth).extend({
  name: 'inventory-detail',
  data() {
    return {
      showBackBtn: false,
      loading: false,
      deleteInventoryDialog: false,

      errorMessage: '',
      errorFlag: false,

      inventory: [] as Inventory[],
      warehouseHeaders: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          class: 'blue lighten-3',
          value: 'data-table-space'
        },
        {
          text: 'Warehouse #',
          class: 'blue lighten-3',
          value: 'warehouse.identifier'
        },
        {
          text: 'Name',
          class: 'blue lighten-3',
          value: 'warehouse.name'
        }
      ],
      itemheaders: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          class: 'blue lighten-3',
          value: 'data-table-space'
        },
        {
          text: 'Description',
          class: 'blue lighten-3',
          value: 'item.description'
        },
        {
          text: 'Unit of Measure',
          class: 'blue lighten-3',
          sortable: false,
          value: 'item.unit'
        },
        {
          text: 'Quantity',
          class: 'blue lighten-3',
          sortable: false,
          value: 'quantity'
        },
        {
          text: 'Last Purchase Price',
          class: 'blue lighten-3',
          // sortable: false,
          value: 'item.price'
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      getInventoryById: 'inventoryStore/getInventoryById'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    canModifyInventoryDetail(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    }
  },
  methods: {
    ...mapActions({
      fetchInventory: 'inventoryStore/fetchInventory',
      deleteInventory: 'inventoryStore/deleteInventory'
    }),

    async tryGetOrFetchInventory(inventoryId: number) {
      try {
        if (this.getInventoryById(inventoryId)) {
          return this.getInventoryById(inventoryId);
        } else {
          return this.fetchInventory(inventoryId)
            .then(() => this.getInventoryById(inventoryId))
            .catch((error: any) => {
              this.errorMessage = ErrorManager.extractApiError(error);
            });
        }
      } catch (e) {
        console.log('NO INVENTORY FOUND', e);
      }
    },

    /**
     * open  dialog
     *
     *
     */
    openInventoryTableDialog(isEdit = false, instance: Inventory) {
      (this as any).$refs.inventoryTableDialog.open(isEdit, instance);
    },

    async setData() {
      this.inventory = [];
      const result = await this.tryGetOrFetchInventory(
        Number(this.$route.params.itemNumber)
      );
      this.inventory.push(result);
    },

    deleteInventoryAction(inventoryId: number) {
      this.deleteInventory(inventoryId)
        .then(() => {
          this.deleteInventoryDialog = false;
          this.$router.push({
            name: 'inventoryTable'
          });
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    }
  },

  /**
   * fetches user data from server and displays it
   *
   */
  async beforeMount() {
    this.setData();
  }
});
