import { VBtn, VCalendarDaily, VCalendarWeekly } from 'vuetify/lib/components';
import {
  MouseHandler,
  MouseEvents,
  MouseEventsMap
} from 'vuetify/src/components/VCalendar/mixins/mouse';
import vcalendar from '@/mixins/calendar';
import { VNode, VNodeChildren } from 'vue';
import { convertToUnit, getSlot } from '@/utils/helpers';
import { CalendarFormatter, CalendarTimestamp } from 'vuetify';
import mixins from '@/utils/mixins';
import { ScopedSlot } from 'vue/types/vnode';
import { CalendarType } from '@/models/job/job';
import moment from 'moment';
import { TimelineTimestamp } from '@/models/calendar';

const baseMixins = mixins(VCalendarWeekly, vcalendar);
interface Options extends InstanceType<typeof baseMixins> {
  days: CalendarTimestamp[];
  color: string;
  showMonthOnFirst: boolean;
  shortMonths: boolean;
  weekdays: number[];

  weekdayFormatter: CalendarFormatter;
  monthFormatter(
    day: CalendarTimestamp,
    shortMonths: boolean
  ): CalendarFormatter;
  isOutside(day: CalendarTimestamp): boolean;
  setTextColor(color: string, object: object): object;
  dayFormatter(
    day: CalendarTimestamp,
    bool: boolean
  ): VNodeChildren | [ScopedSlot];
  getMouseEventHandlers(
    events: MouseEvents,
    getEvent: MouseHandler
  ): MouseEventsMap;
  getRelativeClasses(day: CalendarTimestamp, bool: boolean): object;
}

export default baseMixins
  .extend<Options>({
    functional: false
  })
  .extend({
    name: 'timelineWeekIntervalMixin',
    methods: {
      genHeadDay(day: CalendarTimestamp, index: number): VNode {
        const outside = this.isOutside(this.days[index]);
        const color = day.present ? this.color : undefined;

        return this.$createElement(
          'div',
          this.setTextColor(color!, {
            key: day.date,
            staticClass: 'v-calendar-daily_head-weekday',
            class: this.getRelativeClasses(day, outside)
          }),
          this.weekdayFormatter(day, false) //second parameter asks for short weekdays
        );
      },

      genDayLabel(day: CalendarTimestamp): VNode {
        return this.$createElement(
          'div',
          {
            staticClass: 'v-calendar-daily_head-day-label'
          },
          getSlot(this, 'day-label', day) || [this.genDayLabelButton(day)]
        );
      },

      genDayLabelButton(day: CalendarTimestamp): VNode {
        const color = day.present ? 'primary' : 'transparent';
        const hasMonth = day.day === 1 && this.showMonthOnFirst;

        return this.$createElement(
          VBtn,
          {
            props: {
              color,
              fab: true,
              depressed: true,
              small: false
            },
            on: { click: () => this.openTimelineDay(day) }
          },
          hasMonth
            ? this.monthFormatter(day, this.shortMonths) +
                ' ' +
                this.dayFormatter(day, false)
            : this.dayFormatter(day, false)
        );
      },

      openTimelineDay(day: TimelineTimestamp) {
        this.timelineDays = this.timelineDays.filter(
          (timeline) => timeline.date == day.date
        );

        this.timelineDays[0].showtimelineWeekDate = true;

        this.setFocus(this.timelineDays[0].date);
        this.type = CalendarType.TIMELINE_DAY;
      }
    }
  });
