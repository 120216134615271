
import Vue from 'vue';
import { cloneDeep } from 'lodash';

import { Job } from '@/models/job';
import {
  IJobOccurrenceData,
  IJobTimelineOccurrencesData,
  IParticipant,
  JobDeleteType,
  JobTimelineOccurrences
} from '@/models/job/index';

export default Vue.extend({
  name: 'TimelineDeleteDialog',

  data: () => ({
    recurrenceFormValid: false,
    isActive: false,
    isDelete: false,

    title: 'Delete Timeline Event',
    message: 'Which information do you want to delete?',
    timelineJob: new JobTimelineOccurrences(),
    occurrence: new Job(),
    originalOccurrence: new Job(),

    JobDeleteType,
    choice: JobDeleteType.SINGLE_PARTICIPANT,
    labels: {
      cancel: 'Cancel',
      ok: 'Ok'
    },
    width: 300,

    resolve: (
      value:
        | { job: IJobOccurrenceData; choice: JobDeleteType }
        | PromiseLike<{
            job: IJobOccurrenceData;
            choice: JobDeleteType;
          }>
    ) => {}
  }),

  computed: {
    occurrenceHasRecurrence(): boolean {
      return !!(
        this.occurrence.recurrence && this.occurrence.recurrence.enabled
      );
    }
  },

  methods: {
    async open(timelineOccurrence: JobTimelineOccurrences) {
      this.isActive = true;
      this.isDelete = true;

      this.timelineJob = cloneDeep(timelineOccurrence);

      return new Promise<{
        job: IJobOccurrenceData;
        choice: JobDeleteType;
      }>((resolve, reject) => {
        this.resolve = resolve;
      });
    },

    close(resolveData?: { job: IJobOccurrenceData; choice: JobDeleteType }) {
      this.isActive = false;
      return this.resolve(resolveData!);
    },

    async agree() {
      switch (this.choice) {
        case JobDeleteType.SINGLE_PARTICIPANT: {
          const removedAttendeeFromJob = this.removeAttendeeFromJob();
          this.close({
            job: removedAttendeeFromJob.jobs![0],
            choice: JobDeleteType.SINGLE_PARTICIPANT
          });
          break;
        }
        case JobDeleteType.EVENT: {
          this.close({
            job: this.timelineJob.jobs![0],
            choice: JobDeleteType.EVENT
          });
          break;
        }
      }
    },

    removeAttendeeFromJob(): IJobTimelineOccurrencesData {
      this.timelineJob.jobs![0].participants =
        this.timelineJob.jobs![0].participants?.filter(
          (participant: IParticipant) =>
            participant.attendee?.id !== this.timelineJob.user?.id
        );
      return this.timelineJob!;
    }
  },

  beforeDestroy() {
    this.choice = JobDeleteType.SINGLE_PARTICIPANT;
  }
});
