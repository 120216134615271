
import Vue, { PropType } from 'vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment-timezone';
import mixins from '@/utils/mixins';
import vcalendar from '@/mixins/calendar';
import { CalendarEvent } from '@/models/calendar';
import auth from '@/mixins/auth';
import CalendarView from './calendar/Calendar.vue';
import TimelineView from './timeline/Timeline.vue';
import { Attendee } from '@/models';
import { CalendarType } from '@/models/job/index';

const baseMixins = mixins(vcalendar, auth);

interface Options extends InstanceType<typeof baseMixins> {
  $refs: {
    files: HTMLInputElement;
    calendar: InstanceType<typeof CalendarView>;
    timeline: InstanceType<typeof TimelineView>;
  };
}

export default baseMixins.extend<Options>({ functional: false }).extend({
  components: { CalendarView, TimelineView },
  data() {
    return {
      CalendarType
    };
  },
  name: 'CalendarController',
  props: {
    canMoveEvent: {
      type: Boolean,
      default: () => false
    },
    canUpdateEvent: {
      type: Boolean,
      default: () => false
    },
    canCreateEvent: {
      type: Boolean,
      default: () => false
    },
    canDeleteEvent: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    ...mapState('calendar', ['calendars']),

    isViewTimelineType(): boolean {
      return [CalendarType.TIMELINE_DAY, CalendarType.TIMELINE_WEEK].includes(
        this.type as CalendarType
      );
    },

    isViewCalendarType(): boolean {
      return [
        CalendarType.CALENDAR_MONTH,
        CalendarType.CALENDAR_WEEK,
        CalendarType.CALENDAR_DAY
      ].includes(this.type as CalendarType);
    }
  },

  methods: {
    ...mapActions('calendar', ['fetchCalendars']),

    prev(): any {
      if (this.isViewCalendarType) {
        this.$refs.calendar.prev();
      } else if (this.isViewTimelineType) {
        this.$refs.timeline.prev();
      }
    },

    next() {
      if (this.isViewCalendarType) {
        this.$refs.calendar.next();
      } else if (this.isViewTimelineType) {
        this.$refs.timeline.next();
      }
    },

    genCalendarDateTime() {
      const currentTime = moment(new Date()).format('HH:mm:ss');

      //adds current time to focus date
      const calendarDate =
        moment(this.focus).format('YYYY-MM-DD') + 'T' + currentTime;

      this.add({
        start: moment(calendarDate).toDate(),
        end: moment(calendarDate).add(1, 'hour').toDate()
      });
    },

    async add(date?: Partial<CalendarEvent['date']> & { attendee?: Attendee }) {
      const dtstart = moment(date ? date!.start! : new Date())
        .valueOf()
        .toString();
      const dtend = moment(date ? date!.end! : new Date())
        .valueOf()
        .toString();

      const queryParams = date?.attendee?.id
        ? {
            attendee: String(date?.attendee?.id)
          }
        : undefined;

      this.$router.push({
        name: 'SchedulerNewJobView',
        params: {
          view: this.type as string,
          calendarId: String(this.calendars[0].id) ?? String(1),
          dtstart,
          dtend
        },
        query: queryParams
      });
    },

    genOccurrences() {
      if (this.isViewCalendarType) {
        this.$refs.calendar.rebuild();
      } else if (this.isViewTimelineType) {
        this.$nextTick(() => {
          this.$refs.timeline.genJobOccurrences();
        });
      }
    }
  },
  async beforeMount() {
    await this.fetchCalendars();
  },
  async mounted() {
    (this.$root as any).$recurrenceSaveDialog = this.$refs.recurrenceSaveDialog;
  }
});
