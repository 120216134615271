
import { accountsProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { Account } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/account';
import Proxyable from '@/mixins/proxyable';

export default Proxyable.extend({
  data: () => ({
    search: null
  }),

  watch: {
    search(val) {
      // Items have already been loaded
      this.searchEmployee(val);
    }
  },

  computed: {
    accountsProvider(): any {
      return accountsProvider(this.$store);
    },
    users(): Account[] {
      return (this as any).accountsProvider.accounts.map((account: Account) => {
        return {
          full_name: account.first_name + ' ' + account.last_name,
          email: account.email,
          id: account.id
        };
      });
    }
  },

  methods: {
    /**
     * Gets called when the user searches for name
     *
     *
     * @param searchinput text string to be searched
     *
     *
     */
    searchEmployee(searchinput: string) {
      this.users.filter((item: any) => {
        return (
          (item.full_name || '')
            .toLowerCase()
            .indexOf((searchinput || '').toLowerCase()) > -1
        );
      });
    }
  },
  async mounted() {
    await this.accountsProvider.fetchAccounts(true);
  }
});
