import { IOrganizationState } from '@/store/organization';
import { IOrganizationMutations } from './mutations.types';
import { IOrganizationData } from '@/models';
import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';

export const mutations: IOrganizationMutations = {
  SET_ORGANIZATIONS(
    state: IOrganizationState,
    payload: IOrganizationData[]
  ): void {
    state.organizations = payload;
  },

  ADD_ORGANIZATION(
    state: IOrganizationState,
    payload: IOrganizationData
  ): void {
    state.organizations.push(payload);
  },

  UPDATE_ORGANIZATION(
    state: IOrganizationState,
    payload: IOrganizationData
  ): void {
    const index = state.organizations.findIndex(
      (organization: IOrganizationData) => organization.id === payload.id
    );

    state.organizations.splice(index, 1, payload);
  },

  REMOVE_ORGANIZATION(state: IOrganizationState, payload: number): void {
    const index = state.organizations.findIndex(
      (organization: IOrganizationData) => organization.id === payload
    );

    state.organizations.splice(index, 1);
  },

  SET_ORGANIZATION_USERS(state: IOrganizationState, payload: User[]): void {
    state.organizationUsers = payload;
  }
};
