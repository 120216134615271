import Vue, { VNodeData } from 'vue';
import { isCssColor, parseColor, isString } from '@/utils/helpers';
import { classToHex } from '@/utils/colorUtils';
import colors from '@/utils/colors';
// import colors from 'vuetify/lib/util/colors';
export default Vue.extend({
  name: 'colorable',

  props: {
    color: String
  },

  methods: {
    setBackgroundColor(
      color?: string | false,
      data: VNodeData = {}
    ): VNodeData {
      if (typeof data.style === 'string') {
        // istanbul ignore next
        console.error('style must be an object');
        // istanbul ignore next
        return data;
      }
      if (typeof data.class === 'string') {
        // istanbul ignore next
        console.error('class must be an object');
        // istanbul ignore next
        return data;
      }
      if (isCssColor(color)) {
        data.style = {
          ...(data.style as object),
          'background-color': `${color}`,
          'border-color': `${color}`
        };
      } else if (color) {
        data.class = {
          ...data.class,
          [color]: true
        };
      }

      return data;
    },

    setTextColor(color?: string | false, data: VNodeData = {}): VNodeData {
      if (typeof data.style === 'string') {
        // istanbul ignore next
        console.error('style must be an object');
        // istanbul ignore next
        return data;
      }
      if (typeof data.class === 'string') {
        // istanbul ignore next
        console.error('class must be an object');
        // istanbul ignore next
        return data;
      }
      if (isCssColor(color)) {
        data.style = {
          ...(data.style as object),
          color: `${color}`,
          'caret-color': `${color}`
        };
      } else if (color) {
        const [colorName, colorModifier] = color
          .toString()
          .trim()
          .split(' ', 2) as (string | undefined)[];
        data.class = {
          ...data.class,
          [colorName + '--text']: true
        };
        if (colorModifier) {
          data.class['text--' + colorModifier] = true;
        }
      }
      return data;
    },

    // generateFontColor(color: string) {
    //   const COLOR_BLACK = 'black';
    //   const COLOR_WHITE = 'white';
    //   if (isString<string>(color)) {
    //     const { r, g, b } = parseColor(color);
    //     const luminance = Math.sqrt(
    //       0.241 * Math.pow(r, 2) +
    //       0.691 * Math.pow(g, 2) +
    //       0.068 * Math.pow(b, 2)
    //     );
    //     if (luminance >= 130) {
    //       return COLOR_BLACK;
    //     } else {
    //       return COLOR_WHITE;
    //     }
    //   } else {
    //     console.warn('Color was not a string');
    //     return COLOR_BLACK;
    //   }
    // }
    generateFontColor(color = 'primary', treshold = 130, useDark = false) {
      const COLOR_BLACK = 'black';
      const COLOR_WHITE = 'white';
      let luminance = 0;
      const getLuminance = (color: string) => {
        const { r, g, b } = parseColor(color);
        luminance = Math.sqrt(
          0.241 * Math.pow(r, 2) +
            0.691 * Math.pow(g, 2) +
            0.068 * Math.pow(b, 2)
        );
        return luminance;
      };
      const match = /#(\w\w)(\w\w)(\w\w)/.exec(color);

      if (isString<string>(color)) {
        if (!match) {
          getLuminance(
            classToHex(color, colors, this.$vuetify.theme.currentTheme)
          );
        } else if (isCssColor(color)) {
          getLuminance(color);
        }
      } else {
        console.warn('Color was not a string');
        return COLOR_BLACK;
      }
      if (luminance >= treshold) {
        return COLOR_BLACK;
      } else {
        if (useDark) {
          return false;
        }
        return COLOR_WHITE;
      }
    }
  }
});
