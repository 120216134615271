import { IProductActions, ProductContext } from './actions.types';
import { IProductData, IStripeSessionData } from '@/models';
import { ICheckoutProductActionPayload } from '.';

export const actions: IProductActions = {
  fetchProducts(context: ProductContext): Promise<void> {
    return this.$services.product
      .fetchProducts()
      .then((products: IProductData[]) => {
        context.commit('SET_PRODUCTS', products);
      });
  },

  fetchProduct(context: ProductContext, payload: number): Promise<void> {
    return this.$services.product
      .fetchProduct(payload)
      .then((product: IProductData) => {
        const index = context.state.products.findIndex(
          (v: IProductData) => v.id === product.id
        );
        if (index === -1) {
          context.commit('ADD_PRODUCT', product);
        } else {
          context.commit('UPDATE_PRODUCT', product);
        }
      });
  },

  createProduct(context: ProductContext, payload: IProductData): Promise<void> {
    return this.$services.product
      .createProduct(payload)
      .then((product: IProductData) => context.commit('ADD_PRODUCT', product));
  },

  updateProduct(context: ProductContext, payload: IProductData): Promise<void> {
    return this.$services.product
      .updateProduct(payload)
      .then((product: IProductData) => {
        const index = context.state.products.findIndex(
          (v: IProductData) => v.id === product.id
        );
        if (index === -1) {
          context.commit('ADD_PRODUCT', product);
        } else {
          context.commit('UPDATE_PRODUCT', product);
        }
      });
  },

  deleteProduct(context: ProductContext, payload: number): Promise<void> {
    return this.$services.product.deleteProduct(payload).then(() => {
      context.commit('REMOVE_PRODUCT', payload);
    });
  },

  buyProduct(
    _context: ProductContext,
    payload: ICheckoutProductActionPayload
  ): Promise<IStripeSessionData> {
    return this.$services.product.buyProduct(
      payload.product,
      payload.frequency
    );
  }
};
