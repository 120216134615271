import { IFeedbackData, IFeedbackFieldErrorData } from './feedback.types';

export class Feedback {
  readonly name: string;
  readonly comment: string;
  readonly email: string;

  constructor(data: IFeedbackData) {
    this.name = data.name ?? '';
    this.comment = data.comment ?? '';
    this.email = data.email ?? '';
  }
}

export class FeedbackFieldError implements IFeedbackFieldErrorData {
  name: string[] = [];
  comment: string[] = [];
  email: string[] = [];

  constructor(data?: IFeedbackFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
      if (data.comment) {
        this.comment = data.comment;
      }
      if (data.email) {
        this.email = data.email;
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
    this.email.splice(0, this.email.length);
    this.comment.splice(0, this.comment.length);
  }

  static isFeedbackFieldError(data: IFeedbackFieldErrorData): boolean {
    if (data.name && Array.isArray(data.name)) return true;
    if (data.email && Array.isArray(data.email)) return true;
    if (data.comment && Array.isArray(data.comment)) return true;
    return false;
  }
}
