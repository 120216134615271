
import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import { timeAgo } from '@vue-altoleap-libraries/vue-altoleap-accounts-ui/src/functions';

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState('vendorStore', ['vendors']),
    ...mapState('inventoryStore', ['inventory', 'activityFeed']),
    ...mapState('itemStore', ['item']),

    ...mapState('warehouseStore', ['warehouse'])
  },

  methods: {
    ...mapActions({
      fetchWarehouses: 'warehouseStore/fetchWarehouses',

      fetchItems: 'itemStore/fetchItems',
      fetchInventories: 'inventoryStore/fetchInventories',
      fetchActivityFeeds: 'inventoryStore/fetchActivityFeeds'
    }),
    timeAgo,

    navigateTo(routeName: string, param = null as any) {
      if (param == null) {
        this.$router.push({
          name: routeName
        });
      } else {
        this.$router.push({
          name: routeName,
          params: { itemNumber: String(param) }
        });
      }
    }
  },

  mounted() {
    this.fetchWarehouses();
    this.fetchItems();
    this.fetchInventories();
    this.fetchActivityFeeds();
  }
});
