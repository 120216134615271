
// utils
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { deepEqual, diffDeep, getPropertyFromItem } from '@/utils/helpers';

// mixins
import mixins from '@/utils/mixins';
import Auth from '@/mixins/auth';
import Proxyable from '@/mixins/proxyable';
import Colorable from '@/mixins/colorable';
import {
  accountsProvider,
  Account
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';

// models
import { ErrorManager } from '@/models/error';
import { Assignees, Task, TaskFieldError } from '@/models/task';

// components
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import dateFormat from '@/utils/dateFormat';
import BoardTaskDueDate from './BoardTaskDueDate.vue';

export default mixins(Auth, Proxyable, Colorable).extend({
  name: 'BoardDrawer',

  components: {
    ErrorAlert,
    ConfirmationDialog,
    BoardTaskDueDate
  },

  data: () => ({
    isLoading: false,
    isEditing: false,
    task: new Task(),
    fieldError: new TaskFieldError(),

    errorDeleteDialogMessage: '',

    deleteInProgress: false,
    deleteTaskDialog: false,

    nameRules: [(v: string) => !!v || 'Name is required'],

    errorMessage: '',
    errorMessageDetail: ''
  }),

  computed: {
    ...mapGetters({
      getTaskById: 'tasks/getTaskById',
      getStateById: 'states/getStateById'
    }),

    accountsProvider(): any {
      return accountsProvider(this.$store);
    },

    users(): Account[] {
      return this.accountsProvider.accounts.map((account: Account) => {
        return {
          full_name: account.first_name + ' ' + account.last_name,
          email: account.email,
          id: account.id
        };
      });
    }
  },

  methods: {
    ...mapActions({
      fetchTasks: 'tasks/fetchTasks',
      fetchTask: 'tasks/fetchTask',
      updateTask: 'tasks/updateTask',
      deleteTask: 'tasks/deleteTask'
    }),

    dateFormat,

    async open(taskId: number) {
      this.internalValue = true;
      this.isLoading = true;

      await this.fetchTasks().finally(() => {
        this.isLoading = false;
      });
      this.isEditing = false;
      this.clearAllErrors();
      const task = cloneDeep(await this.tryGetOrFetchTask(taskId));
      if (task) {
        this.task = task;
      }
    },

    async tryGetOrFetchTask(taskId: number) {
      if (!this.getTaskById(taskId)) {
        await this.loadTaskById(taskId);
      }
      return this.getTaskById(taskId);
    },

    loadTaskById(taskId: number) {
      this.isLoading = true;

      return this.fetchTask(taskId)
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.errorMessageDetail = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
          this.closeBoardDrawer();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getStatusText(status: number | string) {
      return this.getStateById(Number(status))?.name ?? '';
    },

    getStatusColor(status: number) {
      return this.getStateById(Number(status))?.color ?? '#FFFFFF';
    },

    getAssigneeFullName(assignee: Assignees) {
      const fullName = `${assignee.first_name} ${assignee.last_name}`.trim();
      return !fullName ? 'No Name' : fullName;
    },

    closeBoardDrawer() {
      this.internalValue = false;
    },

    startEdit() {
      this.isEditing = true;
    },

    cancelEdit() {
      this.isEditing = false;
      this.task = this.getTaskById(this.task.id);
    },

    saveTask(task: Task) {
      const taskDelta = diffDeep(task, this.getTaskById(this.task.id), true);
      taskDelta.assignees = task.assignees.map(this.getValue);

      this.isLoading = true;
      return this.updateTask(taskDelta)
        .then(() => this.closeBoardDrawer())
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.errorMessageDetail = error.response.data.detail;
            }
            // client received an error response that falls out of range 2xx
            if (TaskFieldError.isTaskFieldError(error.response.data)) {
              this.fieldError = new TaskFieldError(error.response.data);
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getValue(item: any) {
      return getPropertyFromItem(item, 'id', item);
    },

    findExistingAssigneeIndex(item: Assignees) {
      const itemValue = this.getValue(item);

      return this.task.assignees.findIndex((i: object) =>
        deepEqual(this.getValue(i), itemValue)
      );
    },

    clearAllErrors() {
      this.fieldError.clearErrors();
      this.errorMessage = '';
      this.errorMessageDetail = '';
    },

    clearAllDialogErrors() {
      this.errorDeleteDialogMessage = '';
    },

    closeDeleteDialog() {
      this.deleteTaskDialog = false;
    },

    openDeleteTaskDialog() {
      this.deleteTaskDialog = true;
    },

    destroyTask(deleteId: number) {
      this.deleteTask(deleteId)
        .then(() => {
          this.closeDeleteDialog();
          this.closeBoardDrawer();
        })
        .catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    }
  }
});
