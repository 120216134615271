import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IContactState } from './contact.types';
import { IContactData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IContactState = {
  version: 1,
  contacts: [] as IContactData[]
};

const namespaced = true;

export const contact: Module<IContactState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
