
import { setPartsToUTCDate, setUTCPartsToDate } from '@/utils/dateHelpers';
import { DaySpan, Calendar, Day, CalendarDay, Functions as fn } from 'dayspan';
import Vue, { PropType } from 'vue';
const prop = 'value',
  event = 'input';
export default Vue.extend({
  name: 'DatePicker',
  model: { prop, event },

  props: {
    value: {
      type: [Date, String] as PropType<Date | string>,
      default: () => new Date()
    },
    span: {
      required: false,
      type: DaySpan,
      default: () =>
        new DaySpan(
          new Day(new Date()).startOf('month'),
          new Day(new Date()).endOf('month')
        )
    },

    highlightSpan: {
      type: Boolean,
      default: false
    },

    weekdays: {
      type: Array,
      default() {
        return [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];
      }
    },

    labels: {
      default() {
        return {
          prevMonth: 'Previous month',
          nextMonth: 'Next month'
        };
      }
    }
  },

  data() {
    return {
      month: Calendar.months() as Calendar<any, any>,
      lazyValue: new Date(this.value),
      open: false
    };
  },

  mounted() {
    this.month = this.getMonth();
  },

  computed: {
    Day: () => Day,
    summary(): string {
      return this.month ? this.month.summary(false, false, false, false) : '';
    },
    days(): CalendarDay<any, any>[] {
      return this.month && this.month.days;
    },

    rows(): number {
      return Math.floor(this.month && this.month.days.length / 7);
    },

    formatedDate(): string {
      return new Day(setUTCPartsToDate(this.lazyValue)).format('DD MMM YYYY');
    }
  },

  watch: {
    value: {
      handler(val: any) {
        // validatetion to check if value is parsable to a date
        // e.g string, number or Date
        if (!val) {
          this.lazyValue = new Date();
        } else {
          this.lazyValue = new Date(val);
        }
      },
      immediate: true
    },
    span: {
      deep: true,
      handler() {
        this.resetMonth();
      }
    },
    open: {
      handler() {
        this.resetMonth();
      }
    }
  },

  methods: {
    setPartsToUTCDate,
    setUTCPartsToDate,
    isHighlighted(day: CalendarDay<any, any>) {
      return this.highlightSpan && this.span.matchesDay(day);
    },

    getMonthStart() {
      return this.value ? new Day(this.lazyValue) : Day.today();
    },

    resetMonth() {
      if (!this.span.matchesMonth(this.month.start)) {
        this.month = this.getMonth();
      }
    },

    getMonth() {
      return Calendar.months(1, this.getMonthStart(), 0, {
        fill: true,
        minimumSize: 42
      });
    },

    pick(day: CalendarDay<any, any>) {
      this.$emit('picked', day);
    },

    selectDay(day: CalendarDay<any, any>) {
      const dateString =
        day.format('YYYY-MM-DD ') +
        new Day(setUTCPartsToDate(this.lazyValue)).format('hh:mm:ss');
      this.$emit(event, setPartsToUTCDate(new Date(dateString)));
      this.open = false;
    },

    prev() {
      const ev = this.getEvent('prev', { next: false, prev: true });

      this.$emit('prev', ev);

      if (!ev.handled) {
        ev.month.prev();
        ev.handled = true;
      }

      this.$emit('change', ev);
    },

    next() {
      const ev = this.getEvent('next', { next: true, prev: false });

      this.$emit('next', ev);

      if (!ev.handled) {
        ev.month.next();
        ev.handled = true;
      }

      this.$emit('change', ev);
    },

    daysAtRow(row: number, rowSize: number) {
      const start = (row - 1) * rowSize;

      return this.month.days.slice(start, start + rowSize);
    },

    getEvent(type: string, extra = {}): any {
      return fn.extend(
        {
          type: type,
          span: this.span,
          month: this.month,
          handled: false,
          $vm: this,
          $element: this.$el
        },
        extra
      );
    }
  }
});
