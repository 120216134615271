import { Module } from 'vuex';
import { IRootState } from '@/store';
import { ICompanyNoteState } from './companynote.types';
import { ICompanyNoteData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: ICompanyNoteState = {
  version: 1,
  companyNotes: [] as ICompanyNoteData[]
};

const namespaced = true;

export const companyNote: Module<ICompanyNoteState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
