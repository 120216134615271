import Vue, { VueConstructor } from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);
export function createVuetify(store?: any) {
  return new Vuetify({
    theme: {
      dark: false,
      default: 'light',
      disable: false,
      options: {
        customProperties: true,
        minifyTheme: (css) => {
          return process.env.NODE_ENV === 'production'
            ? css.replace(/[\r\n|\r|\n]/g, '')
            : css;
        }
      },
      themes: {
        light: {
          primary: '#00aeef',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        },
        dark: {
          primary: '#00aeef',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        }
      }
    }
  });
}

export function useVuetify(Vue: VueConstructor) {
  Vue.use(Vuetify);
}
