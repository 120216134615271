
import { Item } from '@/models/inventory';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  data() {
    return {
      loading: false,
      dialog: false,
      enableEdit: false,
      showImage: false,
      valid: false,

      selectedItem: new Item(),
      image: null as any,
      contactId: '' as any,
      companyId: '' as any,

      emptyRule: [(v: any) => !!v || 'This is required'],
      errorMessage: '',
      fieldErrors: { identifier: [], price: [] } as any
    };
  },

  computed: {
    ...mapGetters({
      contacts: 'contactV2/getContacts',
      companies: 'companyV2/getCompanies'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    defaultFieldErrors(): any {
      return { identifier: [], price: [] };
    }
  },

  methods: {
    ...mapActions({
      createItem: 'itemStore/createItem',
      updateItem: 'itemStore/updateItem',
      fetchContacts: 'contactV2/fetchContacts',
      fetchCompanies: 'companyV2/fetchCompanies'
    }),

    open(isEdit = false, selectedItem: Item) {
      this.enableEdit = isEdit;
      this.dialog = true;

      this.clearErrors();

      if (this.enableEdit) {
        this.selectedItem = cloneDeep(selectedItem!);

        this.contactId =
          this.selectedItem.vendor!.contact == null
            ? ''
            : this.selectedItem.vendor!.contact!.id;

        this.companyId =
          selectedItem.vendor!.company == null
            ? ''
            : selectedItem.vendor!.company!.id;
      } else {
        (this.$refs.form as any).reset();
        this.selectedItem = new Item();
      }

      //ITEM FORM
      this.$nextTick(() => {
        if (!this.enableEdit) {
          this.setItemImage();
        } else if (this.enableEdit && this.selectedItem.media! == null) {
          this.setItemImage(false, this.selectedItem.media!);
        } else {
          this.setItemImage(true, this.selectedItem.media!);
        }
      });
    },

    setItemImage(showImage = false, image = null as any) {
      this.showImage = showImage;
      this.image = image;
    },

    close() {
      this.setItemImage();
      this.clearErrors();
      (this.$refs.form as any).reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = new Item();
        this.$emit('fetchUpdatedItems');
      });
    },

    clearErrors(): void {
      this.fieldErrors = this.defaultFieldErrors;
      this.errorMessage = '';
    },

    setUploadedImage(uploadedFile: any) {
      this.showImage = false;
      const media =
        uploadedFile.target.files || uploadedFile.dataTransfer.files;
      this.selectedItem.media = media;

      if (!this.selectedItem.media.length) return;
      this.createImage(this.selectedItem.media[0]);
    },

    createImage(file: any) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.image = e.target.result;
        this.showImage = true;
      };
      reader.readAsDataURL(file);
    },

    setContactName() {
      this.selectedItem.vendor!.contact = this.contactId;
      this.selectedItem.vendor!.company = null;
      this.companyId = '';
    },

    setCompanyName() {
      this.selectedItem.vendor!.company = this.companyId;
      this.selectedItem.vendor!.contact = null;
      this.contactId = '';
    },

    addItemMaster() {
      let itemMasterData;

      this.selectedItem.price =
        this.selectedItem.price == null || String(this.selectedItem.price) == ''
          ? 0
          : this.selectedItem.price;

      if (this.selectedItem.media) {
        itemMasterData = this.ConvertToFormData();
      } else {
        itemMasterData = this.ConvertItemToJSONData();
      }

      this.createItem(itemMasterData)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.identifier) {
            this.fieldErrors.identifier = error.response.data.identifier;
          }
          if (error.response.data.price) {
            this.fieldErrors.price = error.response.data.price;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    editItemMaster() {
      let itemMasterData;

      this.selectedItem.price =
        this.selectedItem.price == null || String(this.selectedItem.price) == ''
          ? 0
          : this.selectedItem.price;

      if (
        typeof this.selectedItem.media == 'string' ||
        this.selectedItem.media == null
      ) {
        itemMasterData = this.ConvertItemToJSONData();
      } else {
        itemMasterData = this.ConvertToFormData();
      }

      this.updateItem({ id: this.selectedItem.id, data: itemMasterData })
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.identifier) {
            this.fieldErrors.identifier = error.response.data.identifier;
          }
          if (error.response.data.price) {
            this.fieldErrors.price = error.response.data.price;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    ConvertItemToJSONData(): object {
      delete this.selectedItem['media'];
      const itemMasterData = this.selectedItem;
      itemMasterData.vendor!.company = this.companyId;
      itemMasterData.vendor!.contact = this.contactId;
      return itemMasterData;
    },

    ConvertToFormData(): FormData {
      const reader = new FileReader();
      const imagefile = this.selectedItem.media;

      const cacheImage = imagefile[0];

      const formData = new FormData();
      formData.append('media', cacheImage);
      formData.append('name', this.selectedItem.name as string);
      formData.append('identifier', this.selectedItem.identifier as string);
      formData.append('price', this.selectedItem.price as any);

      if (this.selectedItem.description) {
        formData.append('description', this.selectedItem.description as any);
      }

      if (this.selectedItem.unit) {
        formData.append('unit', this.selectedItem.unit as string);
      }

      if (this.companyId) {
        formData.append('vendor.company', this.companyId as any);
      } else if (this.contactId) {
        formData.append('vendor.contact', this.contactId as any);
      } else {
        this.selectedItem.vendor!.contact = '';
        this.selectedItem.vendor!.company = '';
        formData.append('vendor.contact', this.selectedItem.vendor!.contact);
        formData.append('vendor.company', this.selectedItem.vendor!.company);
      }

      return formData;
    }
  },
  mounted() {
    this.loading = true;
    try {
      this.fetchCompanies();
      this.fetchContacts();
    } catch (error) {
      this.errorMessage = ErrorManager.extractApiError(error);
    } finally {
      this.loading = false;
    }
  }
});
