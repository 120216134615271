import { axios } from '@/plugins/axios';
import { Job } from '@/models/job';

interface CalendarOptions {
  calendarId?: number;
  eventId?: number;
  occurrenceId?: number;
  data?: object;

  [prop: string]: any;
}

export class JobService {
  endpoint: string;

  constructor() {
    this.endpoint = '/v1/jobs/';
  }

  async fetchJobs() {
    return axios.get(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchJob(eventId: number) {
    return axios.get(`${this.endpoint}${eventId}/`).then(({ data }) => data);
  }

  async createJob(options: CalendarOptions) {
    return axios
      .post(`${this.endpoint}`, options.data)
      .then(({ data }) => data);
  }

  async updateJob(eventId: number, data: Job) {
    return axios
      .patch(`${this.endpoint}${eventId}/`, data)
      .then(({ data }) => data);
  }

  async deleteJob(calId: number) {
    return axios.delete(`${this.endpoint}${calId}/`);
  }
}
