import { axios } from '@/plugins/axios';
import { Category } from '@/models/inventory';

export class CategoryService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'v1/inventory-management/categories/';
  }

  async createCategory(category: Category) {
    return axios
      .post<Category>(`${this.endpoint}`, category)
      .then(({ data }) => data);
  }

  async fetchCategories() {
    return axios.get<Category[]>(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchCategory(id: number) {
    return axios
      .get<Category>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  async updateCategory(category: Category) {
    return axios
      .patch<Category>(`${this.endpoint}${category.id}/`, category)
      .then(({ data }) => data);
  }

  async deleteCategory(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }
}
