import {
  ICountry,
  ICountryData,
  ICountryFieldError,
  ICountryFieldErrorData
} from '../country';

// API Data
export interface ICompanyStatusData {
  display_name?: string;
  value?: number;
}

export interface ICompanyStatus extends ICompanyStatusData {
  validate(): boolean;
}

export interface ICompanyData {
  id?: number;
  name?: string;
  email?: string;
  logo?: string | null;
  owner?: string;
  phone?: string;
  website?: string;

  address?: ICompanyAddressData;
  status?: ICompanyStatusData;

  updated_at?: Date | string;
  created_at?: Date | string;
}

export interface ICompanyAddressData {
  id?: number;
  address1?: string;
  address2?: string;
  city?: string;
  country?: ICountryData;
  postal_code?: string;
  region?: string;
}

export interface ICompanyFieldErrorData {
  name?: string[];
  email?: string[];
  logo?: string[];
  owner?: string[];
  phone?: string[];
  website?: string[];

  address?: ICompanyAddressFieldErrorData;
  status?: ICompanyStatusFieldErrorData;
}

export interface ICompanyStatusFieldErrorData {
  display_name?: string[];
  value?: string[];
}

export interface ICompanyAddressFieldErrorData {
  address1?: string[];
  address2?: string[];
  city?: string[];
  country?: ICountryFieldErrorData;

  postal_code?: string[];
  region?: string[];
}

// Business Logic Interface
export interface ICompanyAddress extends ICompanyAddressData {
  country?: ICountry;

  validate(): boolean;
}

export interface ICompany extends ICompanyData {
  address?: ICompanyAddress;
  status?: ICompanyStatus;

  validate(): boolean;

  initials: string;
}

// Field Errors Business Logic Interface

export interface ICompanyAddressFieldError
  extends ICompanyAddressFieldErrorData {
  country: ICountryFieldError;
  clearErrors(): void;
}
export interface ICompanyFieldError extends ICompanyFieldErrorData {
  address: ICompanyAddressFieldError;
  status: ICompanyStatusFieldError;

  clearErrors(): void;
}
export interface ICompanyStatusFieldError extends ICompanyStatusFieldErrorData {
  clearErrors(): void;
}
// Constants - Used for validation

/** ##### Company Note ##### **/
export interface ICompanyNoteData {
  id?: number;
  text: string;
  owner?: number;
  company?: number;
  created_at?: Date | string;
  updated_at?: Date | string;
}

export interface ICompanyNoteFieldErrorData {
  text?: string[];
  owner?: string[];
  company?: string[];
}

// Business Logic Interface
export interface ICompanyNote extends ICompanyNoteData {
  validate(): boolean;
}

// Field Error Business Logic Interface
export interface ICompanyNoteFieldError extends ICompanyNoteFieldErrorData {
  clearErrors(): void;
}

export enum CompanyStatusChoices {
  LEAD,
  FOLLOW_UP,
  SOLD,
  ACTIVE_LEAD,
  RETIRED_LEAD
}

export type CompanyStatusTransition = {
  display_name: string;
  color: string;
  value: CompanyStatusChoices;
};
