import { IEmployee, IEmployeeData } from '../employee.types';
import { Employee } from '../employee';

export const mockEmployeesData = (): IEmployeeData[] => [
  {
    id: 2,
    user: {
      id: 10,
      first_name: 'LeRon',
      last_name: 'Harris',
      email: 'test@altoleap.com'
    },
    role: 'Client',
    rate: 100.0,
    hire_date: '2021-08-31T19:43:00Z',
    attachments: []
  },
  {
    id: 3,
    user: {
      id: 11,
      first_name: 'LeRon  alter ego',
      last_name: 'Harros',
      email: 'lharris@altoleap.com'
    },
    role: 'Developer',
    rate: 100107490283.0,
    hire_date: '2021-08-02T19:43:00Z',
    attachments: []
  }
];

export const mockEmployees = (
  data: IEmployeeData[] = mockEmployeesData()
): IEmployee[] => data.map((item) => new Employee(item));
