
import Vue from 'vue';
import moment from 'moment';
import RRule from 'rrule';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

import { Job, Participant } from '@/models/job';
import { ErrorManager } from '@/models/error';
import {
  ByWeekday,
  JobFieldError,
  RecurrenceEditType
} from '@/models/calendar';
import { deepEqual, omitDeep } from '@/utils/helpers';
import { setUTCPartsToDate } from '@/utils/dateHelpers';
import { DAYS_IN_WEEK } from '@/components/data/calendar/common/timestamp';

import ErrorAlert from '@/components/common/ErrorAlert.vue';
import { IJobData } from '@/models/job/job.types';
import { JobStateType } from '@/models/job/job';

enum ReminderTimeType {
  Minute = 0,
  Hour,
  Day
}

export interface RecurrenceSaveOptions {
  occurrence: Job;
  originalOccurrence: Job;
  isDelete: boolean;
}

export default Vue.extend({
  components: { ErrorAlert },
  name: 'RecurrenceSaveDialog',

  props: {
    dense: { type: Boolean }
  },

  data: () => ({
    recurrenceFormValid: false,
    dialogLoading: false,
    apiLoading: false,
    isActive: false,
    isDelete: false,
    title: '(No Title)',
    message: '',
    occurrence: new Job(),
    originalOccurrence: new Job(),
    useRecurrenceChoices: false,
    RecurrenceEditType,
    choice: RecurrenceEditType.SINGLE_OCCURRENCE,
    labels: {
      cancel: 'Cancel',
      ok: 'Ok'
    },
    width: 300,
    resolve: (value: Job | boolean | PromiseLike<Job | boolean>) => {},

    errorMessage: '',
    errorMessageDetail: '',

    recurrenceDateList: [] as Date[],
    occurrenceDateFromListIndex: -1,
    occurrenceDateFromList: null as Date | null,
    originalRuleFromEventString: new RRule(),
    occurrenceRuleFromEventString: new RRule()
  }),

  watch: {
    isActive: {
      handler(val) {
        val || this.clearData();
      }
    }
  },

  computed: {
    ...mapGetters({
      getCalendarEventById: 'calendar/getCalendarEventById'
    }),
    onlyThisEventCanAction(): boolean {
      return !this.canEditFutureEvents && !this.canEditAllEvents;
    },

    canEditRuleByWeekDay(): boolean {
      if (this.occurrenceRuleFromEventString.origOptions.byweekday) {
        return (
          (
            this.occurrenceRuleFromEventString.origOptions
              .byweekday as ByWeekday[]
          ).length == 1
        );
      }

      return false;
    },

    canEditFutureEvents(): boolean {
      if (!this.isDelete) {
        if (this.currentOccurrenceStartedInPast) {
          return false;
        }
        if (
          this.occurrenceListStartedInPast &&
          this.currentOccurrenceStartedInPast
        ) {
          return false;
        }

        if (
          this.occurrenceRuleFromEventString.origOptions.byweekday &&
          !this.canEditRuleByWeekDay &&
          !this.isEventSameDay
        ) {
          return false;
        }

        if (this.occurrenceDateFromListIndex == 0 && this.isEventSameDay) {
          return false;
        }
      }

      // check if occurrence is the last occurrence in the rrule list
      if (this.occurrenceRuleFromEventString.origOptions.count) {
        return (
          this.occurrenceDateFromListIndex <
          this.occurrenceRuleFromEventString.origOptions.count - 1
        );
      }

      // check if occurrence is the last occurrence in the rrule list
      if (
        this.occurrenceRuleFromEventString.origOptions.until &&
        this.occurrenceDateFromList
      ) {
        return !moment(this.occurrenceDateFromList).isSame(
          this.occurrenceRuleFromEventString.origOptions.until as Date
        );
      }

      return true;
    },

    canEditAllEvents(): boolean {
      if (this.isDelete) {
        return true;
      }
      if (
        this.currentOccurrenceStartedInPast ||
        this.occurrenceListStartedInPast
      ) {
        return false;
      }
      return this.isEventSameDay;
    },

    isEventSameDay(): boolean {
      try {
        return moment(
          setUTCPartsToDate(this.originalOccurrence.date?.start as Date)
        ).isSame(setUTCPartsToDate(this.occurrence.date?.start as Date), 'day');
      } catch {
        return false;
      }
    },

    occurrenceHasRecurrence(): boolean {
      return !!(
        this.occurrence.recurrence && this.occurrence.recurrence.enabled
      );
    },

    currentOccurrenceStartedInPast(): boolean {
      if (this.occurrenceDateFromList) {
        return setUTCPartsToDate(this.occurrenceDateFromList) < new Date();
      }
      return false;
    },

    occurrenceListStartedInPast(): boolean {
      if (this.recurrenceDateList.length) {
        return setUTCPartsToDate(this.recurrenceDateList[0]) < new Date();
      }
      return false;
    }
  },

  methods: {
    ...mapActions('calendar', [
      'fetchCalendarEvent',
      'deleteCalendarEvent',
      'deleteCalendarOccurrence',
      'updateCalendarEvent',
      'createCalendarEvent',
      'updateThisAndFutureOccurrences',
      'deleteThisAndFutureOccurrences'
    ]),

    ...mapActions('calendarOccurrence', [
      // these actions have a much simplier implmentation
      'updateEventOccurrence',
      'sendCalendarOccurrenceInvite'
    ]),

    clearAllErrors() {
      this.errorMessage = '';
      this.errorMessageDetail = '';
    },

    async open({
      occurrence,
      originalOccurrence,
      isDelete
    }: RecurrenceSaveOptions) {
      this.clearAllErrors();
      this.dialogLoading = true;
      this.isActive = true;
      this.isDelete = isDelete;

      this.occurrence = cloneDeep(occurrence);
      this.occurrence.date!.start =
        typeof this.occurrence.date?.start == 'string'
          ? new Date(this.occurrence.date?.start)
          : this.occurrence.date?.start;

      //bug fix - the occurrence end date was inaccurate when updating event or this and following
      this.occurrence.date!.end =
        typeof this.occurrence.date?.end == 'string'
          ? moment(this.occurrence.date?.end).utc(true).toDate()
          : this.occurrence.date?.end;

      this.originalOccurrence = cloneDeep(originalOccurrence);
      this.originalOccurrence.date!.start =
        typeof this.originalOccurrence.date?.start == 'string'
          ? new Date(this.originalOccurrence.date?.start)
          : this.originalOccurrence.date?.start;

      if (this.occurrenceHasRecurrence) {
        // in the occurrence where this calendar event for this occurrence has not been loaded
        const originalCalendarEvent = await this.tryGetOrFetchCalendarEvent(
          occurrence.calendar!,
          occurrence.event!
        );

        // Use original because we want to compare the dtstart of the orignal start time
        // to find the index of this occurrence in the occurrence list
        const rules = (
          originalCalendarEvent.recurrence?.rules as string[]
        ).toString();
        this.originalRuleFromEventString = RRule.fromString(rules);

        // use occurrence rules to compare and set
        const occurrenceRules = (
          occurrence.recurrence?.rules as string[]
        ).toString();
        this.occurrenceRuleFromEventString = RRule.fromString(occurrenceRules);

        this.recurrenceDateList = this.originalRuleFromEventString.between(
          this.occurrenceRuleFromEventString.origOptions.dtstart as Date,
          new Date(this.occurrence.original_start as string),
          true
        ); //List of dates this occurrence will generate

        this.occurrenceDateFromList = this.recurrenceDateList.find(
          (date) =>
            date.getTime() ==
            new Date(occurrence.original_start as Date).getTime()
        )!;

        if (this.occurrenceDateFromList)
          this.occurrenceDateFromListIndex = this.recurrenceDateList.indexOf(
            this.occurrenceDateFromList
          );
      }

      this.useRecurrenceChoices = this.occurrenceHasRecurrence;
      if (this.onlyThisEventCanAction) {
        this.useRecurrenceChoices = false;
      }

      if (this.isDelete) {
        this.useRecurrenceChoices = !!this.occurrenceHasRecurrence;
      }

      this.setNonRecurrenceLabelStrings();

      this.title = this.useRecurrenceChoices
        ? `${this.isDelete ? 'Delete' : 'Edit'} Recurring Job`
        : `Are you sure you want to ${
            this.isDelete ? 'delete' : 'edit'
          } this job?`;

      this.width = this.useRecurrenceChoices ? 300 : 370;
      this.dialogLoading = false;

      return new Promise<Job | boolean>((resolve, reject) => {
        this.resolve = resolve;
      });
    },

    /**
     * @summary Update label string representations
     * If occurence has no recurrence then change labels to be
     * Cancel - 'No'
     * Ok - 'Yes'
     */
    setNonRecurrenceLabelStrings() {
      if (!this.occurrenceHasRecurrence) {
        this.labels.cancel = 'No';
        this.labels.ok = 'Yes';
      }
    },

    /**
     * @summary Update the occurrence rrule if we are updating the occurrence only
     * If recurrence
     */
    async updateOccurrenceRecurrenceRRule(
      occurrence: Job,
      updateEventStartDay = false
    ) {
      const originalCalendarEvent = await this.tryGetOrFetchCalendarEvent(
        occurrence.calendar!,
        occurrence.event!
      );

      try {
        if (!occurrence.recurrence?.rules!.length) {
          // if recurrence is not a list then revert occurrence
          // back to what it was
          occurrence = cloneDeep(this.originalOccurrence);
          return;
        }

        const rules = (occurrence.recurrence?.rules as string[]).join('\n');

        const ruleFromDragEvent = RRule.fromString(rules);

        //* Only update the time and not the day
        //* setUTCHours mutates the dtStart value
        //* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setUTCHours
        if (updateEventStartDay) {
          ruleFromDragEvent.origOptions.dtstart = originalCalendarEvent.date!
            .start as Date;
        }

        ruleFromDragEvent.origOptions.dtstart!.setUTCHours(
          (occurrence.date?.start as Date).getUTCHours(),
          (occurrence.date?.start as Date).getUTCMinutes()
        );

        const ruleString = RRule.optionsToString(ruleFromDragEvent.origOptions);

        // split string by rrule option in string
        // and use that value
        // const [DSTART, RULES] = ruleString.split(/^RRULE:/gim);
        occurrence.recurrence = {
          enabled: true,
          rules: [ruleString]
        };
      } catch (e) {
        occurrence = cloneDeep(this.originalOccurrence!);
      }
    },

    async updateCalendarEventRecurrenceFromOriginalEvent(job: Job) {
      const originalCalendarEvent = await this.tryGetOrFetchCalendarEvent(
        job.calendar!,
        job.event!
      );
      if (job.recurrence && job.recurrence.enabled) {
        try {
          let rules = (
            originalCalendarEvent.recurrence?.rules as string[]
          ).join('\n');
          // if case we are adding recurrence for the first time to the event
          // then we want to use the new edited recurrence ruleset
          if (
            !deepEqual(rules, (job.recurrence.rules as string[]).join('\n'))
          ) {
            rules = (job.recurrence.rules as string[]).join('\n');
          }

          const ruleFromDragEvent = RRule.fromString(rules);
          //* Only update the time and not the day
          //* setUTCHours mutates the dtStart value
          //* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setUTCHours

          ruleFromDragEvent.origOptions.dtstart = originalCalendarEvent.date!
            .start as Date;

          ruleFromDragEvent.origOptions.dtstart = new Date(
            ruleFromDragEvent.origOptions.dtstart as Date
          );

          ruleFromDragEvent.origOptions.dtstart!.setUTCHours(
            (job.date?.start as Date).getUTCHours(),
            (job.date?.start as Date).getUTCMinutes()
          );

          if (ruleFromDragEvent.origOptions.until)
            ruleFromDragEvent.origOptions.until!.setUTCHours(
              (job.date?.start as Date).getUTCHours(),
              (job.date?.start as Date).getUTCMinutes()
            );

          const ruleString = RRule.optionsToString(
            ruleFromDragEvent.origOptions
          );

          // split string by rrule option in string
          // and use that value
          job.recurrence = {
            enabled: true,
            rules: [ruleString]
          };

          //Set year month and date to the original event calendar event
          job.date!.start = new Date(job.date?.start!);
          job.date?.start.setUTCFullYear(
            new Date(originalCalendarEvent.date?.start!).getUTCFullYear(),
            new Date(originalCalendarEvent.date?.start!).getUTCMonth(),
            new Date(originalCalendarEvent.date?.start!).getUTCDate()
          );

          job.date!.end = new Date(job.date?.end!);
          job.date!.end.setUTCFullYear(
            new Date(originalCalendarEvent.date?.end!).getUTCFullYear(),
            new Date(originalCalendarEvent.date?.end!).getUTCMonth(),
            new Date(originalCalendarEvent.date?.end!).getUTCDate()
          );
        } catch (e) {
          job = cloneDeep(originalCalendarEvent);
        }
      } else {
        job.recurrence = {
          enabled: false,
          rules: []
        };
      }
      return job;
    },

    cancel() {
      this.clearData();
      this.$emit('rebuild');
      this.dialogLoading = false;
      this.apiLoading = false;
      this.isActive = false;
      return this.resolve(false);
    },

    clearData() {
      this.title = '';
      this.message = '';
      this.width = 320;

      this.occurrence = new Job();
      this.originalOccurrence = new Job();

      this.errorMessage = '';
      this.errorMessageDetail = '';

      this.recurrenceDateList = [];
      this.occurrenceDateFromListIndex = -1;
      this.occurrenceDateFromList = null;
      this.originalRuleFromEventString = new RRule();
      this.occurrenceRuleFromEventString = new RRule();
    },

    async agree() {
      this.clearAllErrors();
      await this.saveChoiceAction()
        .then((result) => {
          this.isActive = false;
          return this.resolve(result);
        })
        .catch((err) => {
          if (err.response) {
            if (JobFieldError.isJobFieldError(err.response.data)) {
              //close dialog before moving to field error in CalendarEdit
              this.isActive = false;
              this.$emit('setFieldError', err.response.data);
            }
          }

          this.errorMessageDetail = err.response && err.response.data.detail;

          this.errorMessage = ErrorManager.extractApiError(err);
          return this.resolve(false);
        });
    },

    async tryGetOrFetchCalendarEvent(
      calendarId: number,
      eventId: number
    ): Promise<Job> {
      if (!this.getCalendarEventById(eventId)) {
        await this.fetchCalendarEvent({
          calendarId: calendarId,
          eventId: eventId
        });
      }
      return this.getCalendarEventById(eventId);
    },

    convertReminderTime(
      check: boolean,
      cond: ReminderTimeType,
      time: number
    ): number {
      const MinutesInDay = 1440;
      const MinutesInHour = 60;
      if (check) {
        switch (cond) {
          case ReminderTimeType.Minute:
            break;
          case ReminderTimeType.Hour:
            time = time * MinutesInHour;
            break;
          case ReminderTimeType.Day:
            time = time * MinutesInDay; //(60 * 24);
            break;
          default:
            break;
        }
      }
      return time;
    },

    parseEventForSave(event: Job) {
      let newEventData = cloneDeep(event);
      const parseEventParticipants = (event: Job) => {
        if (event.participants!.length) {
          event.participants =
            event.participants.map((participant: Participant) => {
              return (
                { id: participant.id, attendee: participant.attendee?.id } || []
              );
            }) || [];
        }

        return event;
      };

      newEventData.reminder!.minutes = this.convertReminderTime(
        newEventData.reminder?.enabled!,
        ReminderTimeType.Minute,
        newEventData.reminder!.minutes!
      );

      newEventData.recurrence!.enabled =
        !!newEventData.recurrence?.rules!.length;

      newEventData = parseEventParticipants(newEventData);

      return newEventData;
    },

    async saveChoiceAction() {
      const event = this.parseEventForSave(this.occurrence);
      this.apiLoading = true;
      // in the case where this occurrence has no occurrence
      if (!this.useRecurrenceChoices) {
        this.choice = RecurrenceEditType.ALL_OCCURRENCE;
      }
      // in the case where we should only move this occurrence
      if (this.onlyThisEventCanAction) {
        this.choice = RecurrenceEditType.SINGLE_OCCURRENCE;
      }
      switch (this.choice) {
        case RecurrenceEditType.SINGLE_OCCURRENCE:
          try {
            if (this.isDelete) {
              return this.deleteOccurrence(event);
            } else {
              // update rule's dtstart to only update the utc hour and minute
              await this.updateOccurrenceRecurrenceRRule(event);

              return this.editOccurrence(event);
            }
          } finally {
            this.apiLoading = false;
          }

        case RecurrenceEditType.FUTURE_OCCURRENCES:
          try {
            if (this.isDelete) {
              return this.deleteThisandFutureOccurrences(event);
            } else {
              return this.editThisandFutureOccurrences(event);
            }
          } finally {
            this.apiLoading = false;
          }

        case RecurrenceEditType.ALL_OCCURRENCE:
          try {
            if (this.isDelete) {
              return this.deleteEvent(event);
            } else {
              return this.editEvent(event);
            }
          } finally {
            this.apiLoading = false;
          }
      }
    },

    // EVENT API CALLBACKS
    async editOccurrence(occurrence: Job) {
      const recurrenceId = moment(this.originalOccurrence.original_start)
        .valueOf()
        .toString();
      return await this.updateEventOccurrence({
        calendarId: this.originalOccurrence.calendar,
        eventId: this.originalOccurrence.event,
        recurrenceId: recurrenceId,
        occurrence: occurrence
      }).then((jobOccurrence: IJobData) => {
        if (
          jobOccurrence.participants.length &&
          jobOccurrence.state == JobStateType.PUBLISHED
        ) {
          this.$emit('send-invite:occurrence', jobOccurrence);
        } else {
          this.$emit('rebuild');
        }
        return jobOccurrence;
      });
    },

    async editThisandFutureOccurrences(occurrence: Job) {
      const oldEventRRuleOptions = cloneDeep(
        this.originalRuleFromEventString.origOptions
      ); // to not copy by reference

      const occurrenceRRuleOptions = cloneDeep(
        this.occurrenceRuleFromEventString.origOptions
      ); // to not copy by reference

      // set the new event date to be created from thois occurrence's start date
      occurrenceRRuleOptions.dtstart = cloneDeep(
        occurrence.date?.start as Date
      ); // to not copy by reference

      // check if this occurrence index is in series list
      if (this.occurrenceDateFromListIndex > -1) {
        // set old event until to be previous index
        if (oldEventRRuleOptions.until) {
          // check if occurrence isn't the first in the list before updating old event with prev occurrence

          const delta = moment(occurrence.date?.start).diff(
            moment(this.originalOccurrence.date!.start),
            'minutes'
          );

          // set new rrule options with updated time
          occurrenceRRuleOptions.until = moment(
            occurrenceRRuleOptions.until as Date
          )
            .add(delta, 'minutes')
            .toDate();
        }

        if (occurrenceRRuleOptions.count) {
          occurrenceRRuleOptions.count = cloneDeep(
            occurrenceRRuleOptions.count - this.occurrenceDateFromListIndex
          );
        }

        if (this.canEditRuleByWeekDay) {
          const weekDay: number = (occurrence.date?.start as Date).getUTCDay();
          const byweekDay = (DAYS_IN_WEEK - 1 + weekDay) % DAYS_IN_WEEK;

          occurrenceRRuleOptions.byweekday = [byweekDay];
        }
      }

      const RRuleFromNewEventOptions = new RRule(occurrenceRRuleOptions);

      //state should be in draft when doing this and following
      occurrence.state = JobStateType.DRAFT;

      const newEvent: Job = {
        ...occurrence,
        recurrence: {
          enabled: true,
          rules: [RRuleFromNewEventOptions.toString()]
        }
      };

      const payload = omitDeep(newEvent, ['id']);

      return await this.updateThisAndFutureOccurrences(payload).then(() => {
        this.$emit('rebuild');
        return true;
      });
    },

    async editEvent(occurrence: Job) {
      occurrence = await this.updateCalendarEventRecurrenceFromOriginalEvent(
        occurrence
      );

      if (!occurrence.conference) {
        delete occurrence.conference;
      }

      //state should be in draft when doing ALL OCCURRENCE
      occurrence.state = JobStateType.DRAFT;

      return await this.updateCalendarEvent({
        calendarId: this.originalOccurrence.calendar,
        eventId: this.originalOccurrence.event,
        data: occurrence
      }).then((job: IJobData) => {
        if (job.participants.length && job.state == JobStateType.PUBLISHED) {
          this.$emit('send-invite:event', job, false);
        } else {
          this.$emit('rebuild');
        }
        return job;
      });
    },

    async deleteEvent(occurrence: Job) {
      return this.deleteCalendarEvent({
        calendarId: occurrence.calendar,
        eventId: occurrence.event
      }).then(() => {
        this.$emit('rebuild');
        return true;
      });
    },

    async deleteOccurrence(occurrence: Job) {
      const originalStartDate = new Date(occurrence.original_start!);

      return this.deleteCalendarOccurrence({
        calendarId: occurrence.calendar,
        eventId: occurrence.event,
        year: originalStartDate.getUTCFullYear(),
        month: originalStartDate.getUTCMonth() + 1,
        day: originalStartDate.getUTCDate(),
        hour: originalStartDate.getUTCHours(),
        minute: originalStartDate.getUTCMinutes(),
        second: originalStartDate.getUTCSeconds()
      }).then(() => {
        this.$emit('rebuild');
        return true;
      });
    },

    async deleteThisandFutureOccurrences(occurrence: Job) {
      return await this.deleteThisAndFutureOccurrences(
        this.parseEventForSave(occurrence)
      ).then(() => {
        this.$emit('rebuild');
        return true;
      });
    }
  },

  beforeDestroy() {
    this.clearData();
    this.choice = RecurrenceEditType.SINGLE_OCCURRENCE;
  }
});
