import { VBtn, VCalendarDaily } from 'vuetify/lib/components';
import {
  MouseHandler,
  MouseEvents,
  MouseEventsMap
} from 'vuetify/src/components/VCalendar/mixins/mouse';
import { VNode, VNodeChildren } from 'vue';
import { convertToUnit, getSlot } from '@/utils/helpers';
import { CalendarTimestamp } from 'vuetify';
import mixins from '@/utils/mixins';
import { ScopedSlot } from 'vue/types/vnode';

const baseMixins = mixins(VCalendarDaily);
interface Options extends InstanceType<typeof baseMixins> {
  parsedIntervalCount: number;
  intervalWidth: number | string;
  shortIntervals: boolean;

  dayFormatter(
    day: CalendarTimestamp,
    bool: boolean
  ): VNodeChildren | [ScopedSlot];
  getMouseEventHandlers(
    events: MouseEvents,
    getEvent: MouseHandler
  ): MouseEventsMap;
  intervalFormatter(
    interval: CalendarTimestamp,
    short: boolean
  ): VNodeChildren | [ScopedSlot];
  getRelativeClasses(day: CalendarTimestamp): object;
  genHeadWeekday(day: CalendarTimestamp): VNode;
  genDayHeader(day: CalendarTimestamp, index: number): VNode[];
}

export default baseMixins
  .extend<Options>({
    functional: false
  })
  .extend({
    name: 'timelineDayIntervalMixin',
    methods: {
      genHeadDay(day: CalendarTimestamp, index: number): VNode {
        return this.$createElement(
          'div',
          {
            key: day.date,
            staticClass: 'v-calendar-daily_head-day',
            class: this.getRelativeClasses(day)
          },
          [
            this.genHeadWeekday(day),
            this.genHeadDayLabel(day),
            ...this.genDayHeader(day, index)
          ]
        );
      },

      genIntervalLabel(
        interval: CalendarTimestamp,
        widthOffset: number
      ): VNode {
        const width: string | undefined = convertToUnit(
          Number(this.intervalWidth!) + widthOffset
        );
        const short: boolean = this.shortIntervals;
        const label: VNodeChildren | [ScopedSlot] = this.intervalFormatter(
          interval,
          short
        );

        return this.$createElement(
          'div',
          {
            key: interval.time,
            staticClass:
              'v-calendar-timeline__interval border-top border-right borer-bottom',
            style: {
              width
            }
          },
          [
            this.$createElement(
              'div',
              {
                staticClass: 'v-calendar-timelime__interval-text'
              },
              label
            )
          ]
        );
      },

      genHeadDayLabel(day: CalendarTimestamp): VNode {
        return this.$createElement(
          'div',
          {
            staticClass: 'v-calendar-daily_head-day-label'
          },
          getSlot(this, 'day-label-header', day) || [this.genHeadDayButton(day)]
        );
      },

      genHeadDayButton(day: CalendarTimestamp): VNode {
        const color = day.present ? 'primary' : 'transparent';

        return this.$createElement(
          VBtn,
          {
            props: {
              color,
              fab: true,
              depressed: true
            },
            on: this.getMouseEventHandlers(
              {
                'click:date': { event: 'click', stop: true },
                'contextmenu:date': {
                  event: 'contextmenu',
                  stop: true,
                  prevent: true,
                  result: false
                }
              },
              (nativeEvent: any) => {
                return { nativeEvent, ...day };
              }
            )
          },
          this.dayFormatter(day, false)
        );
      }
    }
  });
