
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { Organization, IOrganization } from '@/models';

export default Vue.extend({
  name: 'OrganizationDetailFormDialog',
  data: () => ({
    dialog: false,
    organizationOriginalCopy: null as null | IOrganization,
    organization: new Organization() as IOrganization
  }),

  methods: {
    open(organization: IOrganization = new Organization()) {
      this.organizationOriginalCopy = organization;
      this.organization = cloneDeep(this.organizationOriginalCopy);

      this.dialog = true;
    },

    close() {
      this.dialog = false;
    }
  }
});
