// API Data
export interface IContactStatusData {
  display_name?: string;
  value?: number;
}

export interface IContactStatus extends IContactStatusData {
  validate(): boolean;
}

export interface IContactNameData {
  id?: number;
  title?: string;
  first: string;
  last: string;
}

export interface IContactName extends IContactNameData {
  validate(): boolean;

  fullName: string;
  initials: string;
}

export interface IContactData {
  id?: number;
  name: IContactNameData;
  avatar?: string | File | null;
  title?: string;
  email?: string;
  phone?: string;
  website?: string;
  status?: IContactStatusData;
  company?: IContactCompanyData | null;
  owner?: string;
  created_at?: Date | string;
  updated_at?: Date | string;
}

export interface IContactCompanyData {
  id?: number;
  name?: string;
  logo?: string | null;
}

export interface IContactNameFieldErrorData {
  title?: string[];
  first: string[];
  last: string[];
}
export interface IContactStatusFieldErrorData {
  display_name?: string[];
  value?: string[];
}
export interface IContactCompanyFieldErrorData {
  name?: string[];
  logo?: string[];
}

export interface IContactFieldErrorData {
  name?: IContactNameFieldErrorData;
  avatar?: string[];
  title?: string[];
  email?: string[];
  phone?: string[];
  website?: string[];
  status?: IContactStatusFieldErrorData;
  company?: IContactCompanyFieldErrorData;
  owner?: string[];
}

// Business Logic Interface

export interface IContactCompany extends IContactCompanyData {
  validate(): boolean;
}

export interface IContact extends IContactData {
  name: IContactName;
  validate(): boolean;
}

export interface IContactCompanyFieldError
  extends IContactCompanyFieldErrorData {
  clearErrors(): void;
}
export interface IContactNameFieldError extends IContactNameFieldErrorData {
  clearErrors(): void;
}
export interface IContactFieldError extends IContactFieldErrorData {
  clearErrors(): void;
}
export interface IContactStatusFieldError extends IContactStatusFieldErrorData {
  clearErrors(): void;
}
// Constants - Used for validation

/** ##### Contact Note ##### **/
export interface IContactNoteData {
  id?: number;
  text: string;
  owner?: number;
  contact?: number;
  created_at?: Date | string;
  updated_at?: Date | string;
}

export interface IContactNoteFieldErrorData {
  text?: string[];
  contact?: string[];
}

// Business Logic Interface
export interface IContactNote extends IContactNoteData {
  validate(): boolean;
}

export interface IContactNoteFieldError extends IContactNoteFieldErrorData {
  clearErrors(): void;
}

export enum ContactStatusChoices {
  LEAD,
  FOLLOW_UP,
  SOLD,
  ACTIVE_LEAD,
  RETIRED_LEAD
}

export type ContactStatusTransition = {
  display_name: string;
  color: string;
  value: ContactStatusChoices;
};
