
import { EmployeeAttachment } from '@/models';
import { isURL } from '@/utils/helpers';
import Vue from 'vue';
export default Vue.extend({
  name: 'EmployeeAttachmentListItem',
  props: {
    attachment: { type: EmployeeAttachment, required: true }
  },

  data() {
    return {
      lazyAttachment: this.attachment,
      itemImage: null as string | ArrayBuffer | null | undefined
    };
  },

  mounted() {
    this.loadableImageSrc(this.attachment.file!);
  },

  methods: {
    loadableImage(file: string | File): boolean {
      if (!file) return false;

      const fileName = typeof file === 'string' ? file : file.name;
      if (!fileName) return false;
      // if fileName ends with any of these regex expresions
      return !!fileName.match(/.(jpg|jpeg|png|gif)$/i)?.length;
    },

    loadableImageSrc(file: string | File) {
      if (file instanceof File && this.loadableImage(file)) {
        const reader = new FileReader();
        // on the initial load of the file
        // set the image source to be the target result which is a readable format
        reader.readAsDataURL(file);
        reader.onload = (e: ProgressEvent<FileReader>) => {
          this.itemImage = e.target?.result;
        };
      } else {
        this.itemImage = file as string;
      }

      //   return this.itemImage;
    },

    getFilename(file: string | File, extension: boolean) {
      const fileName = typeof file === 'string' ? file : file.name;

      let fileUrlName = fileName;

      if (isURL(fileUrlName)) {
        fileUrlName = decodeURIComponent(
          new URL(fileName).pathname.split('/').pop()!
        );
      }

      if (!fileUrlName) return fileName; // some default fileUrlName

      if (extension) return fileUrlName.replace(/^(.+?)(?:\.[^.]*)?$/, '$1');

      return fileUrlName + `${file instanceof File ? '*' : ''}`;
    },

    removeEmployeeAttachment() {
      this.$emit('remove:attachment', this.attachment);
    }
  }
});
