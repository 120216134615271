import { Module } from 'vuex';
import { IRootState } from '@/store';
import { ICompanyState } from './company.types';
import { ICompanyData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: ICompanyState = {
  version: 1,
  companies: [] as ICompanyData[]
};

const namespaced = true;

export const company: Module<ICompanyState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
