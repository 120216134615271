import { ICompanyState } from '@/store/v2/company';
import { ICompanyMutations } from './mutations.types';
import { ICompanyData } from '@/models';

export const mutations: ICompanyMutations = {
  SET_COMPANIES(state: ICompanyState, payload: ICompanyData[]): void {
    state.companies = payload;
  },

  ADD_COMPANY(state: ICompanyState, payload: ICompanyData): void {
    state.companies.push(payload);
  },

  UPDATE_COMPANY(state: ICompanyState, payload: ICompanyData): void {
    const index = state.companies.findIndex(
      (company: ICompanyData) => company.id === payload.id
    );

    state.companies.splice(index, 1, payload);
  },

  REMOVE_COMPANY(state: ICompanyState, payload: number): void {
    const index = state.companies.findIndex(
      (company: ICompanyData) => company.id === payload
    );

    state.companies.splice(index, 1);
  }
};
