export function setForm(payload: any) {
  const data = new FormData();

  for (const key in payload) {
    const value = payload[key];

    if (value != null) {
      data.append(key, value);
    }
  }
  return data;
}

import { DAYS_IN_WEEK } from './timestamp';

export function validateWeekdays(input: any[] | string) {
  if (typeof input === 'string') {
    input = input.split(',');
  }

  if (Array.isArray(input)) {
    const ints = input.map((x) => parseInt(x));

    if (ints.length > DAYS_IN_WEEK || ints.length === 0) {
      return false;
    }

    const visited = {} as any;
    let wrapped = false;

    for (let i = 0; i < ints.length; i++) {
      const x = ints[i];

      if (!isFinite(x) || x < 0 || x >= DAYS_IN_WEEK) {
        return false;
      }

      if (i > 0) {
        const d = x - ints[i - 1];

        if (d < 0) {
          if (wrapped) {
            return false;
          }

          wrapped = true;
        } else if (d === 0) {
          return false;
        }
      }

      if (visited[x]) {
        return false;
      }

      visited[x] = true;
    }

    return true;
  }

  return false;
}

// FORMATTER FOR DATES
import {
  // CalendarTimestamp,
  CalendarFormatter,
  CalendarTimestampFormatOptions
} from './types';

export function createNativeLocaleFormatter(
  locale: string,
  getOptions: CalendarTimestampFormatOptions
): CalendarFormatter {
  const emptyFormatter: CalendarFormatter = (_t, _s) => '';

  if (
    typeof Intl === 'undefined' ||
    typeof Intl.DateTimeFormat === 'undefined'
  ) {
    return emptyFormatter;
  }

  return (timestamp, short) => {
    try {
      const intlFormatter = new Intl.DateTimeFormat(
        locale || undefined,
        getOptions(timestamp, short)
      );
      const time = `${padNumber(timestamp.hour, 2)}:${padNumber(
        timestamp.minute,
        2
      )}`;
      const date = timestamp.date;
      return intlFormatter.format(new Date(`${date}T${time}:00+00:00`));
    } catch (e) {
      return '';
    }
  };
}

function padNumber(x: number, length: number): string {
  let padded = String(x);
  while (padded.length < length) {
    padded = '0' + padded;
  }

  return padded;
}
