import AltoleapAccountsUI from '@vue-altoleap-libraries/vue-altoleap-accounts-ui';
import AltoleapAccountsLib from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';

import AltoleapNotificationsUI from '@vue-altoleap-libraries/vue-altoleap-notifications-ui';
import AltoleapNotificationsLib from '@vue-altoleap-libraries/vue-altoleap-notifications-lib';

import '@vue-altoleap-libraries/vue-altoleap-notifications-ui/dist/vue-altoleap-notifications-ui.css';
import '@vue-altoleap-libraries/vue-altoleap-accounts-ui/dist/vue-altoleap-accounts-ui.css';

import { VueConstructor } from 'vue/types/umd';

export function installAltoleap(Vue: VueConstructor, store: any) {
  Vue.use(AltoleapAccountsUI, {
    data: {
      enableModifyAdminGroup: false,
      extendedProfile: true,
      showOrganization: true,
      showResetPassword: false,
      showResetPassation: false,
      disableRegistration: false,
      setInitialPassword: false,
      enableBilling: true,

      config: {
        companyName: 'StaffNet',
        logo: require('@/assets/logos/staffnet_logo_dark.png'),
        logoHeight: 100
      },

      routes: {
        privacyRoute: { name: 'privacy' },
        termsRoute: { name: 'terms' }
      }
    },

    computed: {
      authProvider() {
        return AltoleapAccountsLib.authProvider(store);
      },

      accountsProvider() {
        return AltoleapAccountsLib.accountsProvider(store);
      },

      groupsProvider() {
        return AltoleapAccountsLib.groupsProvider(store);
      },

      subscriptionProvider() {
        return AltoleapAccountsLib.subscriptionProvider(store);
      },

      snackbarProvider() {
        return store;
      }
    }
  });

  Vue.use(AltoleapNotificationsUI, {
    data: {
      config: {
        disableScheduling: true,
        disableImage: true
      }
    },

    computed: {
      provider() {
        return AltoleapNotificationsLib.provider(store);
      }
    }
  });
}
