/**
 * @summary Get local date and return is as utc date
 * @param date
 * @returns number
 * @url https://github.com/jakubroztocil/rrule/issues/336#issuecomment-548589766
 */
export function setPartsToUTCDate(date: Date): Date {
  const d = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0
    )
  );
  return d;
}

/**
 * @summary Get utc local date and return is as utc number
 * @param date
 * @returns number
 * @url https://github.com/jakubroztocil/rrule/issues/336#issuecomment-548589766
 */
export function setUTCPartsToDate(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

import moment, { MomentInput } from 'moment-timezone';

export function localDateFormat(
  date?: Date | string | number | (number | string)[],
  format?: string,
  time_zone?: string,
  utc_local = false
) {
  const fmt = format || 'YYYY-MM-DD';

  return moment(date || new Date())
    .tz(time_zone || 'UTC', true)
    .utc(utc_local)
    .format(fmt)
    .toString();
}

export function formatTime(
  withTime: { hour: number; minute: number },
  ampm: boolean
): string {
  const suffix = ampm ? (withTime.hour < 12 ? 'AM' : 'PM') : '';
  const hour = withTime.hour % 12 || 12;
  const minute = withTime.minute;
  return minute > 0
    ? minute < 10
      ? `${hour}:0${minute}${suffix}`
      : `${hour}:${minute}${suffix}`
    : `${hour}${suffix}`;
}

// gen start and end date in accurrate timezone
export function genStartEndTime(job: any) {
  const jobStartTime = {
    hour: moment(job.date.start)
      .tz(job.date.timezone || 'UTC', true)
      .utc()
      .hours(),
    minute: moment(job.date.start)
      .tz(job.date.timezone || 'UTC', true)
      .utc()
      .minutes()
  };
  const jobEndTime = {
    hour: moment(job.date.end)
      .tz(job.date.timezone || 'UTC', true)
      .utc()
      .hours(),
    minute: moment(job.date.end)
      .tz(job.date.timezone || 'UTC', true)
      .utc()
      .minutes()
  };

  const start = formatTime(jobStartTime, false);
  const end = formatTime(jobEndTime, true);

  return `${start} - ${end}`;
}

export function roundTime(time: number, roundTo = 30, down = true) {
  const roundDownTime = roundTo * 60 * 1000;

  return down
    ? time - (time % roundDownTime)
    : time + (roundDownTime - (time % roundDownTime));
}

export function dateFromNow(
  date: Date | string | number | (number | string)[],
  utc_local: boolean
) {
  return moment(date).utc(utc_local).fromNow();
}

export function isSameDay(dateA: MomentInput, dateB: MomentInput) {
  return moment(dateA).isSame(dateB, 'day');
}
