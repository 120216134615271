import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IOrganizationState } from './organization.types';
import { IOrganizationData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';

export const state: IOrganizationState = {
  version: 1,
  organizations: [] as IOrganizationData[],
  organizationUsers: [] as User[]
};

const namespaced = true;

export const organization: Module<IOrganizationState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
