import { axios } from '@/plugins/axios';

export class TaskService {
  endpoint: string;

  constructor() {
    this.endpoint = 'v1/todos/tasks/';
  }

  async fetchTasks() {
    return axios.get(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchTask(id: number) {
    return axios.get(`${this.endpoint}${id}/`).then(({ data }) => data);
  }

  async createTask(payload: object) {
    return axios.post(`${this.endpoint}`, payload).then(({ data }) => data);
  }

  async moveTask(id: number, payload: object) {
    return axios
      .post(`${this.endpoint}${id}/move_task/`, payload)
      .then(({ data }) => data);
  }

  async updateTask(id: number, payload: object) {
    return axios
      .patch(`${this.endpoint}${id}/`, payload)
      .then(({ data }) => data);
  }

  async deleteTask(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }
}
