export class JobDate {
  start?: Date | string;
  end?: Date | string;
  timezone?: string;
}

export class Recurrence {
  enabled?: boolean;
  rules?: any[];
}

export class Attendee {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export class Participant {
  id?: number;
  attendee?: Attendee;
  rsvp_status?: number;
}

export class Reminder {
  enabled?: boolean;
  minutes?: number;
}

export class Session {
  key?: string;
  password?: string;
  start_url?: string;
  join_url?: string;
}

export class Conference {
  enabled?: boolean;
  platform?: number;
  session?: Session;
}

export class Item {
  id?: number;
  item_text?: string;
}

export class Checklist {
  id?: number;
  items?: Item[];
}

export class Job {
  id?: number;
  title?: string;
  color?: string;
  description?: string;
  date?: JobDate;
  recurrence?: Recurrence;
  location?: string;
  calendar?: number;
  participants: Participant[] | Attendee[] | { attendee: number }[] | any;
  reminder?: Reminder;
  conference?: Conference;
  checklist?: Checklist;
  original_start?: Date | string;
  event?: number;
  state?: number;
}
