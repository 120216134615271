
import { Category } from '@/models/inventory';
import { mapActions } from 'vuex';
import Vue from 'vue';
import { cloneDeep } from 'lodash';

export default Vue.extend({
  data() {
    return {
      openCategoryDialog: false,
      menuDialog: false,
      selectedCategory: new Category()
    };
  },

  computed: {
    'selectedCategory.category.color': {
      get(): any {
        return (this as any)[(this as any).type];
      },
      set(v) {
        (this as any)[(this as any).type] = v;
      }
    },

    showColor(): string {
      if (typeof this.selectedCategory.color === 'string')
        return this.selectedCategory.color;

      return JSON.stringify(
        Object.keys(this.selectedCategory.color as unknown as object).reduce(
          (color: any, key: any) => {
            color[key] = Number(
              (this.selectedCategory.color as any)[key].toFixed(2)
            );
            return color;
          },
          {}
        ),
        null,
        2
      );
    }
  },

  methods: {
    ...mapActions({
      deleteCategory: 'itemCategoryStore/deleteCategory',
      updateCategory: 'itemCategoryStore/updateCategory'
    }),

    open(category: Category) {
      this.openCategoryDialog = true;
      this.selectedCategory = cloneDeep(category);
    },

    editSelectedCategory() {
      this.updateCategory(this.selectedCategory)
        .catch((error: any) => {
          this.$emit('setErrorAlert', error);
        })
        .finally(() => {
          this.$emit('fetchUpdatedItems');
          this.openCategoryDialog = false;
        });
    },

    deleteSelectedCategory() {
      this.deleteCategory(this.selectedCategory.id)
        .catch((error: any) => {
          this.$emit('setErrorAlert', error);
        })
        .finally(() => {
          this.$emit('fetchUpdatedItems');
          this.openCategoryDialog = false;
        });
    }
  }
});
