
import mixins from '@/utils/mixins';
import vcalendar from '@/mixins/calendar';
import moment from 'moment';
import { CalendarOccurrence } from '@/models/calendar';
import TimelineDay from './TimelineDay.vue';
import { mapActions, mapGetters } from 'vuex';
import { VCalendar, VCalendarDaily } from 'vuetify/lib/components';
import {
  CalendarType,
  IAttendee,
  IJobOptions,
  JobDeleteType,
  IJobDragData,
  JobTimelineOccurrences,
  JobOptionType,
  IParticipant,
  IJobData,
  IJobOccurrenceData
} from '@/models/job/index';
import TimelineDeleteDialog from '../../new/TimelineDeleteDialog.vue';
import RecurrenceSaveDialog, {
  RecurrenceSaveOptions
} from '../../new/RecurrenceSaveDialog.vue';
import TimelineWeek from './TimelineWeek.vue';
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import { ErrorManager } from '@/models/error';
import { JobDate } from '@/models';
import TimelineDragOptions from '../../new/TimelineDragOptions.vue';
import ChoiceDialog from '@/components/core/ChoiceDialog.vue';
import functions from '@/mixins/functions';

export type OccurrencePayload = {
  start: string;
  end: string;
};

export type TimelineCalendarEditData = {
  attendee: IAttendee;
  date: JobDate;
};

const DELAY_TIME_IN_MILLISECONDS = 600;

const baseMixins = mixins(vcalendar, VCalendarDaily, functions);

interface Options extends InstanceType<typeof baseMixins> {
  $refs: {
    files: HTMLInputElement;
    choiceDialog: InstanceType<typeof ChoiceDialog>;
    timelineweek: InstanceType<typeof TimelineWeek>;
    timelineday: InstanceType<typeof TimelineDay>;
  };
}

export default baseMixins.extend<Options>({ functional: false }).extend({
  components: {
    TimelineDay,
    TimelineDeleteDialog,
    RecurrenceSaveDialog,
    TimelineWeek,
    ErrorAlert,
    TimelineDragOptions,
    ChoiceDialog
  },

  name: 'Timeline',

  props: {
    canCreateEvent: {
      type: Boolean
    },
    canUpdateEvent: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      CalendarType,
      loading: false,
      errorMessage: '' as string,
      errorMessageDetail: '',
      originalJobOccurrence: {} as IJobOccurrenceData
    };
  },

  computed: {
    ...mapGetters('job', {
      timeline: 'getJobTimelineOccurrences'
    }),
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      getOccurrences: 'calendar/getOccurrences',
      fetchJobTimelineOccurrences: 'job/fetchJobTimelineOccurrences'
    }),

    ...mapActions('calendarOccurrence', ['sendCalendarOccurrenceInvite']),

    ...mapActions('calendar', ['sendCalendarEventInvite', 'getOccurrences']),

    ...mapActions('snackbar', ['snackMessage', 'snackError']),

    prev() {
      if (this.type == CalendarType.TIMELINE_DAY) {
        this.$refs.timelineday.prev();
      }
      if (this.type == CalendarType.TIMELINE_WEEK) {
        this.$refs.timelineweek.prev();
      }
    },

    next() {
      if (this.type == CalendarType.TIMELINE_DAY) {
        this.$refs.timelineday.next();
      }
      if (this.type == CalendarType.TIMELINE_WEEK) {
        this.$refs.timelineweek.next();
      }
    },

    async genJobOccurrences() {
      this.loading = true;
      const payload: OccurrencePayload = this.genOccurrencePayload();

      this.debounce(
        this.jobOccurrencesAPICalls,
        DELAY_TIME_IN_MILLISECONDS
      )(payload);
    },

    async jobOccurrencesAPICalls(payload: OccurrencePayload) {
      try {
        await this.getOccurrences(payload);
        await this.fetchJobTimelineOccurrences(payload);
      } catch (error) {
        if (error.response) {
          if (error.response.data.detail) {
            this.errorMessageDetail = error.response.data.detail;
          }
        }
        this.errorMessage = ErrorManager.extractApiError(error);
      } finally {
        this.loading = false;
      }
    },

    genOccurrencePayload(): OccurrencePayload {
      let startDate: string;
      let endDate: string;

      if (this.type == CalendarType.TIMELINE_WEEK) {
        startDate = moment(this.focus)
          .startOf('week')
          .format('YYYY-MM-DDTHH:mm:ss');
        endDate = moment(this.focus)
          .endOf('week')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else {
        (startDate = moment(this.focus)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')),
          (endDate = moment(this.focus)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'));
      }
      return { start: startDate, end: endDate };
    },

    add(timelineData: TimelineCalendarEditData) {
      this.focus = moment(timelineData.date.start!).utc().format('YYYY-MM-DD');

      const startDate = moment(timelineData.date.start!)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');

      this.$emit('add', {
        start: moment(startDate).toDate(),
        end:
          !timelineData.date.end || timelineData.date.end == ''
            ? moment(startDate).add(1, 'hour').toDate()
            : moment(
                moment(timelineData.date.end!)
                  .utc()
                  .format('YYYY-MM-DDTHH:mm:ss')
              ).toDate(),
        attendee: timelineData.attendee
      });
    },

    async edit(occurrence: CalendarOccurrence) {
      const recurrenceId = moment(occurrence.original_start!)
        .valueOf()
        .toString();

      await this.$router.push({
        name: 'SchedulerEditJobView',
        params: {
          view: this.type as string,
          calendarId: String(occurrence.calendar),
          eventId: String(occurrence.event),
          recurrenceId
        }
      });
    },

    async openTimelineOptionsDialog(dragObject: IJobDragData) {
      const timelineOptionsDialog = this.$refs
        .timelineOptionsDialog as InstanceType<typeof TimelineDragOptions>;

      const isMoveOnly = dragObject.job.participants.some(
        (participant: IParticipant) =>
          participant.attendee?.id == dragObject.attendee.id
      );

      return await timelineOptionsDialog.open({
        dragObject: dragObject,
        moveEvent: isMoveOnly
      });
    },

    async timelineDragOptionDialog(
      dragObject: IJobDragData,
      originalDragEvent: IJobDragData
    ) {
      this.openTimelineOptionsDialog(dragObject).then((data) =>
        this.$nextTick(() => {
          if (data.choice == JobOptionType.ADD_EVENT) {
            this.eventDialog(data, originalDragEvent.job);
          } else if (data.choice == JobOptionType.MOVE_EVENT) {
            this.eventDialog(data, originalDragEvent.job);
          } else {
            return;
          }
        })
      );
    },

    async openRecurrenceSave(options: RecurrenceSaveOptions) {
      const recurrenceSaveDialog = this.$refs
        .recurrenceSaveDialog as InstanceType<typeof RecurrenceSaveDialog>;
      return await recurrenceSaveDialog.open(options);
    },

    async eventDialog(
      options: IJobOptions,
      originalDragEvent?: IJobOccurrenceData
    ) {
      this.openRecurrenceSave({
        occurrence: options.job,
        originalOccurrence: originalDragEvent ? originalDragEvent : options.job,
        isDelete: options.choice == JobDeleteType.EVENT
      });
    },

    async openTimelineDeleteDialog(timelineOccurrence: JobTimelineOccurrences) {
      this.originalJobOccurrence = timelineOccurrence.jobs![0];
      const timelinedeletedialog = this.$refs
        .timelinedeletedialog as InstanceType<typeof TimelineDeleteDialog>;
      return await timelinedeletedialog.open(timelineOccurrence);
    },

    async timelineDeleteOptionDialog(
      timelineOccurrence: JobTimelineOccurrences
    ) {
      this.openTimelineDeleteDialog(timelineOccurrence).then((data) =>
        this.$nextTick(() => {
          if (data.choice == JobDeleteType.SINGLE_PARTICIPANT) {
            data.job.date.end = moment(data.job.date.end)
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss');
            this.eventDialog(data);
          } else if (data.choice == JobDeleteType.EVENT) {
            this.eventDialog(data);
          } else {
            return;
          }
        })
      );
    },

    async openChoiceDialog(
      title?: string,
      buttonOptions?: {
        left?: {
          text: string;
        };
        right?: {
          color?: string;
          text?: string;
          icon?: string;
          iconOnly?: boolean;
        };
      }
    ) {
      return await this.$refs.choiceDialog.open(title, buttonOptions);
    },

    async sendEventInvite(result: IJobData, create = true) {
      if (
        await this.openChoiceDialog('Send schedule to employees?', {
          left: { text: 'Dont Send' },
          right: { text: 'Send', color: 'primary' }
        })
      ) {
        this.sendCalendarEventInvite({
          calendarId: result.calendar,
          eventId: result.id ?? result.event,
          start: result.date?.start,
          end: result.date?.end,
          create: create
        })
          .then(() => {
            this.snackMessage({
              msg: `Sent invite to employees`
            });
          })
          .catch((error: any) => {
            this.snackError({
              msg: `Error sending invite to employees: ${ErrorManager.extractApiError(
                error
              )}`
            });
          });
      }
      this.genJobOccurrences();
    },

    async sendOccurrenceInvite(result: IJobData) {
      if (
        await this.openChoiceDialog('Send schedule to employees?', {
          left: { text: 'Dont Send' },
          right: { text: 'Send', color: 'primary' }
        })
      ) {
        const recurrenceId = new Date(result.original_start!).getTime();
        this.sendCalendarOccurrenceInvite({
          calendarId: result.calendar,
          eventId: result.event,
          recurrenceId
        })
          .then(() => {
            this.snackMessage({
              msg: `Sent invite to employees`
            });
          })
          .catch((error: any) => {
            this.snackError({
              msg: `Error sending invite to employees: ${ErrorManager.extractApiError(
                error
              )}`
            });
          });
      }
      this.genJobOccurrences();
    }
  }
});
