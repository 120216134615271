
import mixins from '@/utils/mixins';
// mixins
import Proxyable from '@/mixins/proxyable';
import { Checklist, Item } from '@/models/job/job';

export default mixins(Proxyable).extend({
  data: () => ({
    checkListItemText: ''
  }),

  methods: {
    createChecklistItem() {
      const item = new Item({ item_text: this.checkListItemText });

      if (this.internalValue && Array.isArray(this.internalValue.items)) {
        this.internalValue.items!.push(item);
      } else {
        const checklist = new Checklist({
          items: [item]
        });
        this.internalValue = checklist;
      }
      this.checkListItemText = '';
    },
    removeChecklistItem(index: number) {
      this.internalValue.items!.splice(index, 1);
    }
  }
});
