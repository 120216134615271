import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IEmployeeState } from './employee.types';
import { IEmployeeData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IEmployeeState = {
  version: 1,
  employees: [] as IEmployeeData[]
};

const namespaced = true;

export const employee: Module<IEmployeeState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
