
import Vue from 'vue';
import { mapActions } from 'vuex';
import { JobReport } from '@/models/jobReport';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  data: () => ({
    dialog: false,
    jobReport: new JobReport(),
    fileconverted: '' as any,
    errorMessage: ''
  }),

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      updateJobReport: 'jobReports/updateJobReport'
    }),

    /**
     * gets job report data and set it to text area
     *
     * @param jobReport job Report ogbject data
     *
     *
     */
    open(jobReport: JobReport) {
      Object.assign(this.jobReport, jobReport);
      this.dialog = true;
    },

    /**
     * closes dialog
     *
     *
     */
    close() {
      this.dialog = false;
    },

    /**
     * saves new job report detail information and update view
     *
     *
     */
    async save() {
      //updates job report view

      const formData = new FormData();
      formData.append('id', String(this.jobReport.id!));
      formData.append('description', String(this.jobReport.description!));
      formData.append(
        'occurrence_date',
        String(this.jobReport.occurrence_date!)
      );

      await this.updateJobReport({
        jobReportId: this.jobReport.id,
        data: formData
      })
        .then((res: JobReport) => {
          this.$emit('updatedJobReport', res);
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    }
  }
});
