export interface IFetchJobReportActionPayload {
  page?: number;
  page_size?: number;
  search?: string;
  employee?: number;
  occurrence_after?: Date;
  occurrence_before?: Date;
}

export interface IJobReportPaginatedListData {
  count: number;
  next?: string;
  previous?: string;
  results: JobReport[];
}

export class Item {
  item_text?: string;
  category: any;
}

export class CheckList {
  id?: number;
  item?: string;
  response?: boolean;
  job?: number;
  items?: Item[] = [];
}

export class JobReport {
  id?: number;
  job?:
    | number
    | {
        id?: number;
        title?: string;
        description?: string;
        date?:
          | Date
          | {
              start?: Date | string;
              end?: Date | string;
              timezone?: string;
            };
        location?: string;
        participants?: {
          id?: number;
          first_name?: string;
          last_name?: string;
          email?: string;
        }[];
      }
    | any;
  owner?: string;
  occurrence_date?: Date;
  description?: string;
  checklist?: CheckList[];
  created_at?: Date;
  updated_at?: Date;
  media?: any[];
}
