
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  Employee,
  EmployeeAttachment,
  EmployeeEmergencyContact,
  EmployeeFieldError,
  IEmployee,
  IEmployeeEmergencyContactData
} from '@/models';
import { diffDeep, isURL } from '@/utils/helpers';
import { ErrorManager } from '@/models/error';
import AccountPicker from '../accounts/AccountPicker.vue';
import EmployeeAttachmentListItem from './EmployeeAttachmentListItem.vue';
import ConfirmationDialog from '../common/ConfirmationDialog.vue';
import dateFormat from '@/utils/dateFormat';

export default Vue.extend({
  components: { AccountPicker, ConfirmationDialog, EmployeeAttachmentListItem },
  name: 'EmployeeDetailFormDialog',
  data: () => ({
    loading: false,
    isEdit: false,
    dialog: false,
    employeeOriginalCopy: null as null | IEmployee,
    employee: new Employee() as IEmployee,
    fieldError: new EmployeeFieldError(),
    errorMessage: '',
    files: [],
    attachmentFile: null as null | File,

    deleteInProgress: false,
    deleteAttachmentDialog: false,
    errorAttachmentDialogMessage: '',
    selectedAttachment: null as null | EmployeeAttachment,
    showEmergencyContact: false
  }),

  computed: {
    ...mapGetters({ getEmployeeById: 'employee/getEmployeeById' }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    computedEmployee(): IEmployee | null {
      return this.getEmployeeById(this.employee.id);
    }
  },

  methods: {
    ...mapActions({
      createEmployee: 'employee/createEmployee',
      updateEmployee: 'employee/updateEmployee',
      deleteEmployeeAttachment: 'employee/deleteEmployeeAttachment'
    }),

    open(employee: IEmployee = new Employee(), isEdit = false) {
      this.employeeOriginalCopy = employee;
      this.employee = cloneDeep(this.employeeOriginalCopy);
      this.isEdit = isEdit;

      this.clearAllErrors();
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    addEmployeeAttachment(file: File) {
      if (file) {
        this.employee.attachFile(file);
      }
      this.$nextTick(() => {
        // clear the current attachment file after selecting
        this.attachmentFile = null;
      });
    },

    addEmployeeEmergencyContact() {
      const contact = new EmployeeEmergencyContact();
      this.employee.addEmergencyContact(contact);
    },

    removeEmployeeEmergencyContact(contact: IEmployeeEmergencyContactData) {
      this.employee.removeEmergencyContact(contact);
    },

    removeEmployeeAttachment(attachment: EmployeeAttachment) {
      if (this.isEdit && attachment.id) {
        this.openEmployeeAttachmentDeleteDialog(attachment);
      } else {
        this.employee.detachFile(attachment.file!);
      }
    },

    destroyEmployeeAttachment(attachment: EmployeeAttachment) {
      this.deleteInProgress = true;
      this.deleteEmployeeAttachment({
        id: this.employee.id,
        attachmentId: attachment.id
      })
        .then(() => {
          this.closeEmployeeAttachmentDeleteDialog();
          this.employee.detachFile(attachment.file!);
        })
        .catch((error) => {
          this.errorAttachmentDialogMessage =
            ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.deleteInProgress = false;
        });
    },

    clearAllDialogErrors() {
      this.errorAttachmentDialogMessage = '';
    },

    openEmployeeAttachmentDeleteDialog(attachment: EmployeeAttachment) {
      this.selectedAttachment = cloneDeep(attachment);
      this.deleteAttachmentDialog = true;
    },

    closeEmployeeAttachmentDeleteDialog() {
      this.deleteInProgress = false;
      this.deleteAttachmentDialog = false;
      this.clearAllDialogErrors();
    },

    async saveEmployee(): Promise<void> {
      this.clearAllErrors();

      this.loading = true;

      try {
        if (this.isEdit) {
          const employeeDelta = diffDeep(
            this.employee,
            this.employeeOriginalCopy,
            true
          );
          employeeDelta.id = this.employee.id;

          if (employeeDelta.hire_date) {
            employeeDelta.hire_date = dateFormat(
              employeeDelta.hire_date,
              'YYYY-MM-DD'
            );
          }

          if (employeeDelta.dob) {
            employeeDelta.dob = dateFormat(employeeDelta.dob, 'YYYY-MM-DD');
          }
          await this.updateEmployee(employeeDelta);
        } else {
          const employeeData = this.employee;
          if (employeeData.hire_date) {
            employeeData.hire_date = dateFormat(
              employeeData.hire_date,
              'YYYY-MM-DD'
            );
          }
          if (employeeData.dob) {
            employeeData.dob = dateFormat(employeeData.dob, 'YYYY-MM-DD');
          }
          await this.createEmployee(this.employee);
        }

        this.loading = false;

        this.close();
      } catch (error) {
        this.loading = false;

        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (EmployeeFieldError.isEmployeeFieldError(error.response.data)) {
            this.fieldError = new EmployeeFieldError(error.response.data);
          }
        }

        this.errorMessage = ErrorManager.extractApiError(error);
      }
    },

    onPhoneNumberInput(
      _formattedNumber: string,
      { number }: { number: { international: string } }
    ): void {
      this.employee.phone = number.international;
    },

    loadableImage(file: string | File): boolean {
      const fileName = typeof file === 'string' ? file : file.name;
      if (!fileName) return false;
      // if fileName ends with any of these regex expresions
      return !!fileName.match(/.(jpg|jpeg|png|gif)$/i)?.length;
    },

    loadableImageSrc(file: string | File) {
      let imageSource = file as string | ArrayBuffer | null | undefined;
      if (file instanceof File && this.loadableImage(file)) {
        const reader = new FileReader();
        // on the initial load of the file
        // set the image source to be the target result which is a readable format
        reader.readAsDataURL(file);
        reader.onload = (e: ProgressEvent<FileReader>) => {
          imageSource = e.target?.result;
        };
      }
      return imageSource;
    },

    getFilename(file: string | File, extension: boolean) {
      const fileName = typeof file === 'string' ? file : file.name;

      let fileUrlName = fileName;

      if (isURL(fileUrlName)) {
        fileUrlName = decodeURIComponent(
          new URL(fileName).pathname.split('/').pop()!
        );
      }

      if (!fileUrlName) return fileName; // some default fileUrlName

      if (extension) return fileUrlName.replace(/^(.+?)(?:\.[^.]*)?$/, '$1');

      return fileUrlName;
    }
  }
});
