import { isEmptyObject } from '@/utils/helpers';

export const set = (property: string | number) => (store: any, payload: any) =>
  (store[property] = payload);
export const toggle = (property: string | number) => (store: any) =>
  (store[property] = !store[property]);
export const push = (property: string | number) => (store: any, payload: any) =>
  store[property].push(payload);
export const errorCatch = (state: any, error: any) => {
  if (error.response) {
    state.error = {
      code: error.response.status as number | null,
      text: error.response.statusText as string | null,
      ...(error.response.data as object | object[])
    };
  } else if (!isEmptyObject(error)) {
    state.error = {
      detail: 'Cannot establish connection to server',
      error: error
    } as object;
  }
};
