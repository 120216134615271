
import Vue, { PropType } from 'vue';
import { common_timezones } from './timezones';

const prop = 'value',
  event = 'input';
export default Vue.extend({
  model: { prop, event },
  props: {
    value: null as any as PropType<any>,
    string: {
      type: String as PropType<string>,
      default: '',
      required: false
    },
    timezones: {
      type: Array,
      default: () => common_timezones
    },
    label: {
      type: String,
      default: 'Time Zone'
    }
  },

  data() {
    return {
      lazyValue: this.value,
      search: ''
    };
  },

  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
        this.$emit(event, val);
      }
    },
    customAttrs(): object {
      return this.$attrs;
    }
  },

  watch: {
    value(val: string) {
      this.lazyValue = val;
    }
  }
});
