// Must be called in Vue context
// export function goTo(this: any, id: string) {
//   this.$vuetify.goTo(id).then(() => {
//     if (!id) return (document.location.hash = "");

//     if (history.replaceState) {
//       history.replaceState(null, null, id);
//     } else {
//       document.location.hash = id;
//     }
//   });
// }

export async function waitForReadystate() {
  if (
    typeof document !== 'undefined' &&
    typeof window !== 'undefined' &&
    document.readyState !== 'complete'
  ) {
    await new Promise((resolve) => {
      const cb = () => {
        window.requestAnimationFrame(resolve);
        window.removeEventListener('load', cb);
      };

      window.addEventListener('load', cb);
    });
  }
}

export function genChip(item: any) {
  if (item.new) return 'new';
  if (item.updated) return 'updated';
  if (item.deprecated) return 'deprecated';
  if (item.help) return 'help';
  if (item.dev) return 'dev';
}
