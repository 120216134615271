
import Vue from 'vue';
import { cloneDeep } from 'lodash';

import { Job } from '@/models/job';
import {
  IJobDragData,
  IJobOccurrenceData,
  IJobOptions,
  IParticipant,
  JobDragData,
  JobOptionType,
  JobTimelineOccurrences
} from '@/models/job/index';

export default Vue.extend({
  name: 'TimelineDeleteDialog',

  data: () => ({
    recurrenceFormValid: false,
    isActive: false,
    isDelete: false,
    isMoveOnly: false,

    title: 'Timeline Drag Options',
    message: 'Which drag action do you wish to do?',

    dragObject: new JobDragData(),
    timelineJob: new JobTimelineOccurrences(),
    occurrence: new Job(),

    JobOptionType,
    choice: JobOptionType.ADD_EVENT,
    labels: {
      cancel: 'Cancel',
      ok: 'Ok'
    },
    width: 300,

    resolve: (value: IJobOptions | PromiseLike<IJobOptions>) => {}
  }),

  computed: {
    showAddEventOption(): boolean {
      return !(
        this.isMoveOnly ||
        !this.dragObject.attendee.id ||
        !this.dragObject.originalAttendee.id
      );
    },
    occurrenceHasRecurrence(): boolean {
      return !!(
        this.occurrence.recurrence && this.occurrence.recurrence.enabled
      );
    }
  },

  methods: {
    async open(data: { dragObject: IJobDragData; moveEvent: boolean }) {
      this.isActive = true;
      this.isDelete = true;

      this.dragObject = cloneDeep(data.dragObject);
      this.isMoveOnly = data.moveEvent;

      return new Promise<IJobOptions>((resolve, reject) => {
        this.resolve = resolve;
      });
    },

    close(resolveData?: IJobOptions) {
      this.isActive = false;
      return this.resolve(resolveData!);
    },

    async agree() {
      switch (this.choice) {
        case JobOptionType.ADD_EVENT: {
          this.close({
            job: this.addJobAttendee(),
            choice: JobOptionType.ADD_EVENT
          });
          break;
        }
        case JobOptionType.MOVE_EVENT: {
          this.close({
            job: this.replaceJobAttendee(),
            choice: JobOptionType.MOVE_EVENT
          });
          break;
        }
      }
    },

    addJobAttendee(): IJobOccurrenceData {
      const participant = { attendee: this.dragObject.attendee };
      this.dragObject.job.participants.push(participant);
      return this.dragObject.job;
    },

    replaceJobAttendee(): IJobOccurrenceData {
      const participants = this.dragObject.job.participants?.map(
        (participant: IParticipant) => {
          if (this.dragObject.originalAttendee.id == participant.attendee?.id) {
            return { attendee: this.dragObject.attendee };
          } else {
            return participant;
          }
        }
      );
      this.dragObject.job.participants = this.dragObject.job.participants.length
        ? participants
        : [
            {
              attendee: this.dragObject.attendee
            }
          ];

      if (!this.dragObject.attendee.id) {
        this.dragObject.job.participants = [];
      }

      return this.dragObject.job;
    }
  },

  beforeDestroy() {
    this.choice = JobOptionType.ADD_EVENT;
  }
});
