
import { rrulestr } from 'rrule';
import { Day } from 'dayspan';
import { localDateFormat } from '@/utils/dateHelpers';

import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';
import dateFormat from '@/utils/dateFormat';

import { JobStateType } from '@/models/job/job';
import {
  IJobData,
  IJobStateParams,
  IParticipant
} from '@/models/job/job.types';
import { mapActions } from 'vuex';
import { ErrorManager } from '@/models/error';
import moment from 'moment';
import { PropType } from 'vue/types/v3-component-props';

export default mixins(auth).extend({
  props: {
    event: {
      type: Object as PropType<IJobData>
    },
    canUpdateEvent: {
      type: Boolean,
      default: () => false
    },
    loading: Boolean,
    errorMessage: String
  },
  data() {
    return {
      stateloading: false,
      stateErrorMessage: this.errorMessage
    };
  },
  computed: {
    JobStateType: () => JobStateType,
    Day: () => Day,
    rrulestr: () => rrulestr,

    canDeleteEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },
    canCreateEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    }
  },
  methods: {
    ...mapActions('job', ['updateJobState']),
    cancel() {
      this.$emit('close', false);
    },
    deleteOcc(event: any) {
      this.$emit('delete', event);
    },

    setEventState(event: IJobData, state: JobStateType) {
      const occurrence_date = moment(event.original_start).utc(false);

      const params = {
        event: event.event,
        original_occurrence: {
          second: occurrence_date.seconds(),
          minute: occurrence_date.minutes(),
          hour: occurrence_date.hours(),
          day: occurrence_date.date(),
          month: occurrence_date.month() + 1,
          year: occurrence_date.year()
        },
        state: state
      } as IJobStateParams;

      this.stateloading = true;
      this.updateJobState(params)
        .then((job: IJobData) => {
          this.$emit('rebuild');

          if (
            job.participants.length !== 0 &&
            job.state == JobStateType.PUBLISHED
          ) {
            if (job.recurrence.enabled) {
              this.$emit('send-invite:occurrence', job);
            } else {
              this.$emit('send-invite:event', job, true);
            }
          }
        })
        .catch((error: any) => {
          this.stateErrorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.stateloading = false;
          this.$emit('close', false);
        });
    },

    dateFormat,
    getRsvpIcon(rsvp: number): string {
      switch (rsvp) {
        case 0:
          return 'mdi-close';
        case 1:
          return 'mdi-check';
        case 2:
          return 'mdi-help';
        default:
          return 'mdi-help';
      }
    },
    getRsvpColor(rsvp: number): string {
      switch (rsvp) {
        case 0:
          return 'red lighten-2';
        case 1:
          return 'success';
        case 2:
          return 'grey';
        default:
          return 'grey';
      }
    },
    localDateFormat
  }
});
