
import Vue from 'vue';
import mixins from '@/utils/mixins';
import { orderBy, cloneDeep } from 'lodash';
import { States } from '@/models/task';
import { mapState, mapActions, mapMutations } from 'vuex';
import { parseColor, isString } from '@/utils/helpers';
import { ErrorManager } from '@/models/error';

export default mixins(Vue).extend({
  name: 'TaskCreateForm',
  components: {
    draggable: () => import('vuedraggable')
  },
  props: {
    useActiviator: {
      type: Boolean,
      default: false
    },
    useSlot: Boolean
  },

  data: () => ({
    dialog: false,
    valid: false,
    menu: false,
    editable: true,
    isDragging: false,
    delayedDragging: false,
    loading: false,

    errorMessage: '',
    emptyRule: [(v: any) => !!v || 'This is required'],
    orderQuery: 'order',

    labels: {
      title: 'Add Column'
    },
    list: {
      id: 0,
      name: '',
      order: 1,
      color: '#FFFFFF',
      is_done: false
    } as States
  }),

  computed: {
    ...mapState({
      taskLoading: (state: any) => state.tasks.loading,
      tasks: (state: any) => state.tasks.tasks,
      states: (state: any): States[] => state.states.states
    }),

    computedStates(): any[] {
      return orderBy<States>(this.states, [this.orderQuery], 'asc');
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    dragOptions(): object {
      return {
        animation: 1,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      };
    }
  },

  methods: {
    ...mapActions({
      fetchStates: 'states/fetchStates'
    }),

    ...mapMutations(['states/SET_STATES']),

    updateColumn(event: any) {
      const states: States[] = this.computedStates.map(function (
        state: States,
        index: number
      ) {
        state.order = index + 1;
        return state;
      });

      this.$store.commit('states/SET_STATES', states);
    },

    cancel() {
      this.loading = true;
      this.fetchStates()
        .then(() => {
          this.reset();
          this.resetValidation();
          this.loading = false;
          this.dialog = false;
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    save() {
      this.$emit('save', this.list);
      window.requestAnimationFrame(() => {
        this.$nextTick(() => {
          this.list = {
            id: 0,
            name: '',
            order: 1,
            color: '#FFFFFF',
            is_done: false
          };
        });
        this.cancel();
      });
    },

    reset() {
      (this as any).$refs.createForm.reset();
    },

    resetValidation() {
      (this as any).$refs.createForm.resetValidation();
    },

    getFontColor(color: string) {
      const COLOR_BLACK = 'black';
      const COLOR_WHITE = 'white';
      if (isString<string>(color)) {
        const { r, g, b } = parseColor(color);
        const luminance = Math.sqrt(
          0.241 * Math.pow(r, 2) +
            0.691 * Math.pow(g, 2) +
            0.068 * Math.pow(b, 2)
        );
        if (luminance >= 130) {
          return COLOR_BLACK;
        } else {
          return COLOR_WHITE;
        }
      } else {
        console.warn('Color was not a string');
        return COLOR_BLACK;
      }
    }
  }
});
