
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import { rrulestr } from 'rrule';
import { Day } from 'dayspan';
import dateFormat from '@/utils/dateFormat';
import { sync } from 'vuex-pathify';
import { CalendarOccurrence } from '../../../models/calendar';
import { CalendarEvent } from 'vuetify';

export default Vue.extend({
  data: () => ({
    pathMatch: 'occurrences',
    details: {} as (CalendarOccurrence | CalendarEvent) & { rsvp: string },
    error: null,
    items: [
      { title: 'No', rsvp: 0 },
      { title: 'Yes', rsvp: 1 },
      { title: 'Maybe', rsvp: 2 }
    ]
  }),
  computed: {
    Day: () => Day,
    rrulestr: () => rrulestr,
    darkCompanyImage: sync('app/darkCompanyImage'),
    rsvp(): string {
      switch (parseInt(this.details.rsvp, 10)) {
        case 0:
          return 'No';
        case 1:
          return 'Yes';
        case 2:
          return 'Maybe';
        default:
          return 'Undecided';
      }
    }
  },
  watch: {
    '$route.path': 'setDetails'
  },
  methods: {
    ...mapActions('calendar', [
      'getCalendarEventInvite',
      'getCalendarOccurrenceInvite'
    ]),
    dateFormat,
    async setDetails() {
      if (this.$route.params.pathMatch !== this.pathMatch) {
        await this.getCalendarEventInvite({
          calendarId: this.$route.params.calendarId,
          eventId: this.$route.params.eventId,
          token: this.$route.params.token,
          rsvp: this.$route.params.rsvp
        })
          .then((result) => {
            this.details = result;
          })
          .catch((err) => {
            this.error = err.toString();
          });
      } else {
        await this.getCalendarOccurrenceInvite({
          calendarId: this.$route.params.calendarId,
          eventId: this.$route.params.eventId,
          occurrenceId: this.$route.params.occurrenceId,
          token: this.$route.params.token,
          rsvp: this.$route.params.rsvp
        })
          .then((result) => {
            this.details = result;
          })
          .catch((err) => {
            this.error = err.toString();
          });
      }
    }
  },
  async mounted() {
    await this.setDetails();
  }
});
