
import Vue from 'vue';
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';

// import { quillEditor } from 'vue-quill-editor';
export default Vue.extend({
  name: 'InfoSectionCard',
  components: {
    // quillEditor
  },
  props: {
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    }
  }
});
