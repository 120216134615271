import { IContactNoteState } from '..';
import { IContactNoteGetters } from './getters.types';
import { IContactNote, ContactNote } from '@/models';

export const getters: IContactNoteGetters = {
  getContactNotes(state: IContactNoteState): IContactNote[] {
    return state.contactNotes.map((data) => new ContactNote(data));
  },

  getContactNoteById:
    (state: IContactNoteState) =>
    (id: number): IContactNote | undefined => {
      const data = state.contactNotes.find(
        (contactnote) => contactnote.id === id
      );
      if (!data) {
        return undefined;
      }

      return new ContactNote(data);
    }
};
