import { axios, AxiosResponse } from '@/plugins/axios';
import { errorCatch } from '../error';
export class RegistrationService {
  async register(payload?: any): Promise<AxiosResponse> {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          `/v1/accounts/registration/`,
          {
            email: payload.email,
            first_name: payload.firstName,
            last_name: payload.lastName,
            password1: payload.password1,
            password2: payload.password2
          },
          {
            headers: {
              Authorization: null
            }
          }
        )
        .then((response: any) => {
          return resolve(response.data);
        })
        .catch((error: any) => {
          return reject(errorCatch(error));
        });
    });
  }
}
