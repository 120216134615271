import { axios } from '@/plugins/axios';

export class TwitterService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'v1/twitter/';
  }

  async notify(payload: any) {
    return axios
      .post(`${this.endpoint}notify/`, {
        tweet: payload
      })
      .then((response) => response);
  }
}
