
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { MovingItem } from '@/models/movingItem';
import dateFormat from '@/utils/dateFormat';

import Vue from 'vue';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  data() {
    return {
      dialog: false,
      enableEdit: false,
      showImage: false,
      valid: true,

      unitList: [
        { id: 0, name: 'METER (m)' },
        { id: 1, name: 'FEET (ft)' }
      ],
      selectedMovingItem: new MovingItem(),
      warehouse_identifier: '' as any,

      image: null as any,

      rules: {
        required: (v: any) => !!v || 'This is required',
        noneZero: (value: string) => {
          const pattern = /^[1-9]\d*(\.\d+)?$/;
          if (value) return pattern.test(value) || 'quantity cannot be 0.';
        }
      },
      errorMessage: '',
      fieldError: [] as string[]
    };
  },

  computed: {
    ...mapState('warehouseStore', ['warehouse']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      createMovingItem: 'movingItem/createMovingItem',
      updateMovingItem: 'movingItem/updateMovingItem',
      fetchWarehouses: 'warehouseStore/fetchWarehouses'
    }),

    open(isEdit = false, selectedMovingItem: MovingItem) {
      this.enableEdit = isEdit;
      this.dialog = true;

      this.clearErrors();
      this.$nextTick(() => {
        (this.$refs.form as any).reset();
        this.selectedMovingItem.out_date = new Date();

        if (this.enableEdit) {
          this.selectedMovingItem = cloneDeep(selectedMovingItem!);

          //MG 11/6/2021 -Allow user to pass empty date
          this.selectedMovingItem.out_date = this.selectedMovingItem.out_date
            ? new Date(selectedMovingItem.out_date!)
            : new Date();

          this.warehouse_identifier = this.selectedMovingItem.warehouse
            ? this.selectedMovingItem.warehouse!.id!
            : '';
        } else {
          this.selectedMovingItem = new MovingItem();
          this.warehouse_identifier = '';

          //display default unit
          this.selectedMovingItem.dimensions!.unit = 0;
        }

        this.selectedMovingItem.inventory = this.$route.params.movingItemId;

        if (!this.enableEdit) {
          this.setItemImage();
        } else if (this.enableEdit && this.selectedMovingItem.media! == null) {
          this.setItemImage(false, this.selectedMovingItem.media!);
        } else {
          this.setItemImage(true, this.selectedMovingItem.media!);
        }
      });
    },

    filterWarehouseObject(
      item: { name: string; identifier: string },
      queryText: string,
      itemText: any
    ) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.identifier
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    setItemImage(showImage = false, image = null as any) {
      this.showImage = showImage;
      this.image = image;
    },

    close() {
      this.setItemImage();
      this.clearErrors();
      this.$emit('setMovingInventoryTable');
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedMovingItem = new MovingItem();
      });
    },

    clearErrors(): void {
      this.fieldError = [];
      this.errorMessage = '';
    },

    setUploadedImage(uploadedFile: any) {
      this.showImage = false;
      const media =
        uploadedFile.target.files || uploadedFile.dataTransfer.files;
      this.selectedMovingItem.media = media;

      if (!this.selectedMovingItem.media.length) return;
      this.createImage(this.selectedMovingItem.media[0]);
    },

    createImage(file: any) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.image = e.target.result;
        this.showImage = true;
      };
      reader.readAsDataURL(file);
    },

    addMovingItem() {
      let movingItemData;

      if (this.selectedMovingItem.media) {
        movingItemData = this.ConvertToFormData();
      } else {
        movingItemData = this.ConvertItemToJSONData();
      }

      this.createMovingItem({
        inventory: this.selectedMovingItem.inventory,
        data: movingItemData
      })
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.identifier) {
            this.fieldError = error.response.data.identifier;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    editMovingItem() {
      let movingItemData;

      if (
        typeof this.selectedMovingItem.media == 'string' ||
        this.selectedMovingItem.media == null
      ) {
        movingItemData = this.ConvertItemToJSONData();
      } else {
        movingItemData = this.ConvertToFormData();
      }

      this.updateMovingItem({
        inventory: this.selectedMovingItem.inventory,
        id: this.selectedMovingItem.id,
        data: movingItemData
      })
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.identifier) {
            this.fieldError = error.response.data.identifier;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    ConvertItemToJSONData(): object {
      delete this.selectedMovingItem['media'];

      this.selectedMovingItem.out_date = dateFormat(
        this.selectedMovingItem.out_date!,
        'YYYY-MM-DD',
        true
      );

      this.selectedMovingItem.quantity =
        this.selectedMovingItem.quantity == null ||
        String(this.selectedMovingItem.quantity) == ''
          ? 1
          : this.selectedMovingItem.quantity;

      this.selectedMovingItem.dimensions!.length =
        this.selectedMovingItem.dimensions!.length == null ||
        String(this.selectedMovingItem.dimensions!.length) == ''
          ? 0
          : this.selectedMovingItem.dimensions!.length;

      this.selectedMovingItem.dimensions!.width =
        this.selectedMovingItem.dimensions!.width == null ||
        String(this.selectedMovingItem.dimensions!.width) == ''
          ? 0
          : this.selectedMovingItem.dimensions!.width;

      this.selectedMovingItem.dimensions!.height =
        this.selectedMovingItem.dimensions!.height == null ||
        String(this.selectedMovingItem.dimensions!.height) == ''
          ? 0
          : this.selectedMovingItem.dimensions!.height;

      if (
        this.selectedMovingItem.warehouse !== '' &&
        this.warehouse_identifier !== null
      ) {
        this.selectedMovingItem.warehouse =
          this.warehouse_identifier.id ?? this.warehouse_identifier;
      } else {
        this.selectedMovingItem.warehouse = null;
      }

      const movingItemData = this.selectedMovingItem;

      return movingItemData;
    },

    ConvertToFormData(): FormData {
      const reader = new FileReader();
      const imagefile = this.selectedMovingItem.media;

      reader.readAsDataURL(imagefile[0]);
      const cacheImage = imagefile[0];

      const formData = new FormData();

      this.selectedMovingItem.out_date = dateFormat(
        this.selectedMovingItem.out_date!,
        'YYYY-MM-DD',
        true
      );

      this.selectedMovingItem.quantity =
        this.selectedMovingItem.quantity == null ||
        String(this.selectedMovingItem.quantity) == ''
          ? 1
          : this.selectedMovingItem.quantity;

      this.selectedMovingItem.dimensions!.length =
        this.selectedMovingItem.dimensions!.length == null ||
        String(this.selectedMovingItem.dimensions!.length) == ''
          ? 0
          : this.selectedMovingItem.dimensions!.length;

      this.selectedMovingItem.dimensions!.width =
        this.selectedMovingItem.dimensions!.width == null ||
        String(this.selectedMovingItem.dimensions!.width) == ''
          ? 0
          : this.selectedMovingItem.dimensions!.width;

      this.selectedMovingItem.dimensions!.height =
        this.selectedMovingItem.dimensions!.height == null ||
        String(this.selectedMovingItem.dimensions!.height) == ''
          ? 0
          : this.selectedMovingItem.dimensions!.height;

      formData.append('media', cacheImage);
      formData.append(
        'identifier',
        this.selectedMovingItem.identifier as string
      );

      formData.append('inventory', this.selectedMovingItem.inventory! as any);

      formData.append('out_date', this.selectedMovingItem.out_date as any);

      formData.append(
        'dimensions.length',
        this.selectedMovingItem.dimensions!.length as any
      );

      formData.append(
        'dimensions.width',
        this.selectedMovingItem.dimensions!.width as any
      );

      formData.append(
        'dimensions.height',
        this.selectedMovingItem.dimensions!.height as any
      );

      formData.append(
        'dimensions.unit',
        this.selectedMovingItem.dimensions!.unit as any
      );

      if (
        this.selectedMovingItem.warehouse !== '' &&
        this.warehouse_identifier !== null
      ) {
        formData.append(
          'warehouse',
          this.warehouse_identifier.id ?? (this.warehouse_identifier as any)
        );
      } else {
        formData.append('warehouse', '' as any);
      }

      if (this.selectedMovingItem.quantity) {
        formData.append('quantity', this.selectedMovingItem.quantity as any);
      }

      if (this.selectedMovingItem.description) {
        formData.append(
          'description',
          this.selectedMovingItem.description as any
        );
      }

      if (this.selectedMovingItem.location!.id) {
        formData.append(
          'location.id',
          this.selectedMovingItem.location!.id as any
        );
      }

      formData.append(
        'location.aisle',
        this.selectedMovingItem.location!.aisle ?? ('' as any)
      );
      formData.append(
        'location.rack',
        this.selectedMovingItem.location!.rack ?? ('' as any)
      );

      formData.append(
        'location.bay',
        this.selectedMovingItem.location!.bay ?? ('' as any)
      );

      formData.append(
        'location.shelf',
        this.selectedMovingItem.location!.shelf ?? ('' as any)
      );

      formData.append(
        'location.bin',
        this.selectedMovingItem.location!.bin ?? ('' as any)
      );

      return formData;
    }
  },
  mounted() {
    this.fetchWarehouses().catch((error: any) => {
      this.errorMessage = ErrorManager.extractApiError(error);
    });
  }
});
