import { make } from 'vuex-pathify';
import { RegistrationService } from '@/services/registration';
import { errorCatch } from '../utils';

export const state = {
  user: {},
  service: new RegistrationService(),
  loading: false,
  error: {}
};

export const mutations = {
  ...make.mutations(state),
  SET_ERROR(state: any, error: any) {
    errorCatch(state, error);
  },
  CLEAR_ERROR(state: any) {
    state.error = {};
  }
};

export const actions = {
  clearError: ({ commit }: any) => commit('CLEAR_ERROR'),
  register: async ({ commit, dispatch }: any, ...payload: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);
    await new Promise<void>((resolve, reject) => {
      new RegistrationService()
        .register(...payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  }
};

export const getters = {
  getLoading: (state: any) => state.loading
};
