import { IJobData } from '@/models/job/index';
import { axios } from '@/plugins/axios';
import { setUTCPartsToDate } from '@/utils/dateHelpers';
import moment from 'moment';

interface CalendarOptions {
  calendarId?: number;
  eventId?: number;
  occurrenceId?: number;
  data?: object;
  originalOccurrence?: IJobData;

  [prop: string]: any;
}
interface CalendarDateOptions {
  start?: string | Date;
  end?: string | Date;
  data?: object;
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
}

interface CalendarOccurrenceInviteOptions {
  calendarId: number;
  eventId: number;

  occurrenceId?: number;

  token: string;

  rsvp: number;
}

export class CalendarService {
  private endpoint: string;

  private occurrenceEndpoint = 'occurrences/';
  constructor() {
    this.endpoint = '/v1/jobs/';
  }

  async fetchCalendars() {
    return axios.get(`/v1/schedule/calendars/`).then(({ data }) => data);
  }

  async fetchCalendar(options: CalendarOptions) {
    return axios
      .get(`/v1/schedule/calendars/${options.calendarId}`)
      .then(({ data }) => data);
  }

  async createCalendar(payload: object) {
    return axios
      .post(`/v1/schedule/calendars/`, payload)
      .then(({ data }) => data);
  }

  async updateCalendar(calId: number, payload: object) {
    return axios
      .patch(`/v1/schedule/calendars/${calId}`, payload)
      .then(({ data }) => data);
  }

  async deleteCalendar(calId: number) {
    return axios.delete(`/v1/schedule/calendars/${calId}/`);
  }

  async optionsCalendar(calId: number) {
    return axios({
      method: 'options',
      url: `/v1/schedule/calendars/${calId}/`
    }).then(({ data }) => data);
  }

  async optionsCalendars() {
    return axios({ method: 'options', url: `/v1/schedule/calendars/` }).then(
      ({ data }) => data
    );
  }

  // TODO: LF-2022-03-18 - We need to replace below with the job endpoints
  async fetchCalendarEvents(calId: number) {
    return axios.get(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchCalendarEvent(calId: number, eventId: number) {
    return axios.get(`${this.endpoint}${eventId}/`).then(({ data }) => data);
  }

  async createCalendarEvent(options: CalendarOptions) {
    const queryParams = options.originalOccurrence
      ? {
          'start-series': true,
          'original-event': options.originalOccurrence?.event,
          'original-start':
            options.originalOccurrence?.original_start ??
            options.originalOccurrence.date.start
        }
      : undefined;

    return axios
      .post(`${this.endpoint}`, options.data, {
        params: queryParams
      })
      .then(({ data }) => data);
  }

  async updateCalendarEvent(calId: number, eventId: number, data: object) {
    return axios
      .patch(`${this.endpoint}${eventId}/`, data)
      .then(({ data }) => data);
  }

  async sendCalendarEventInvite(options: CalendarOptions, create: boolean) {
    return axios
      .post(`${this.endpoint}${options.eventId}/invite/`, undefined, {
        params: { create }
      })
      .then(({ data }) => data);
  }

  async getCalendarEventInvite(options: CalendarOccurrenceInviteOptions) {
    return axios
      .get(
        `${this.endpoint}${options.eventId}/token/${options.token}/rsvp/${options.rsvp}/`,
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(({ data }) => data);
  }

  async deleteCalendarEvent(options: CalendarOptions) {
    return axios
      .delete(`${this.endpoint}${options.eventId}/`)
      .then(({ data }) => data);
  }
  async fetchCalendarOccurrences(
    options: CalendarOptions,
    date: CalendarDateOptions
  ) {
    return axios
      .get(`${this.endpoint}occurrences/?start=${date.start}&end=${date.end}`)
      .then(({ data }) => data);
  }
  async sendCalendarOccurrenceInvite(
    options: CalendarOptions,
    date: CalendarDateOptions
  ) {
    return axios
      .post(
        `${this.endpoint}${options.eventId}/occurrences/${date.year}/${date.month}/${date.day}/${date.hour}/${date.minute}/${date.second}/invite/`
      )
      .then(({ data }) => data);
  }
  async getCalendarOccurrenceInvite(options: CalendarOccurrenceInviteOptions) {
    // TODO: inquire about url path inconsistency
    return axios
      .get(
        `${this.endpoint}${options.eventId}/occurrences/${options.occurrenceId}/token/${options.token}/rsvp/${options.rsvp}/`,
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(({ data }) => data);
  }
  async fetchCalendarOccurrence(
    options: CalendarOptions,
    date: CalendarDateOptions
  ) {
    return axios
      .get(
        `${this.endpoint}${options.eventId}/occurrences/${date.year}/${date.month}/${date.day}/${date.hour}/${date.minute}/${date.second}/`
      )
      .then(({ data }) => data);
  }

  async fetchCalendarOccurrenceReport(
    options: CalendarOptions,
    date: CalendarDateOptions
  ) {
    return axios
      .get(
        `${this.endpoint}${options.eventId}/occurrences/${date.year}/${date.month}/${date.day}/${date.hour}/${date.minute}/${date.second}/report/`
      )
      .then(({ data }) => data);
  }

  async updateCalendarOccurrence(
    options: CalendarOptions,
    date: CalendarDateOptions,
    data: object
  ) {
    return axios
      .put(
        `${this.endpoint}${options.eventId}/occurrences/${date.year}/${date.month}/${date.day}/${date.hour}/${date.minute}/${date.second}/`,
        data
      )
      .then(({ data }) => data);
  }

  updateThisAndFutureOccurrences(data: IJobData) {
    const occurrenceDate = setUTCPartsToDate(new Date(data.original_start!));

    return axios
      .patch(
        `${this.endpoint}${
          data.event
        }/occurrences/${occurrenceDate.getFullYear()}/${occurrenceDate.getMonth() +
          1}/${occurrenceDate.getDate()}/${occurrenceDate.getHours()}/${occurrenceDate.getMinutes()}/${occurrenceDate.getSeconds()}/this-and-following/`,
        data
      )
      .then(({ data }) => data);
  }

  deleteThisAndFutureOccurrences(data: IJobData) {
    const occurrenceDate = setUTCPartsToDate(new Date(data.original_start!));

    return axios
      .delete(
        `${this.endpoint}${
          data.event
        }/occurrences/${occurrenceDate.getFullYear()}/${occurrenceDate.getMonth() +
          1}/${occurrenceDate.getDate()}/${occurrenceDate.getHours()}/${occurrenceDate.getMinutes()}/${occurrenceDate.getSeconds()}/this-and-following/`
      )
      .then(({ data }) => data);
  }

  async deleteCalendarOccurrence(
    options: CalendarOptions,
    date: CalendarDateOptions
  ) {
    return axios
      .delete(
        `${this.endpoint}${options.eventId}/occurrences/${date.year}/${date.month}/${date.day}/${date.hour}/${date.minute}/${date.second}/`
      )
      .then(({ data }) => data);
  }

  async fetchEmployeesWorkTime(start: string, end: string) {
    return axios
      .get(`${this.endpoint}participant-hours/?start=${start}&end=${end}`)
      .then(({ data }) => data);
  }
}
