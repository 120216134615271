
import { setPartsToUTCDate, setUTCPartsToDate } from '@/utils/dateHelpers';
import { DaySpan, Calendar, Day, CalendarDay, Functions as fn } from 'dayspan';
import Vue, { PropType } from 'vue';
const prop = 'value',
  event = 'input';
export default Vue.extend({
  name: 'dsDayPicker',
  model: { prop, event },

  props: {
    fieldError: {
      type: [Array, String],
      required: false
    },
    value: {
      type: [Date, String] as PropType<Date | string>,
      default: () => new Date(),
      required: true
    },
    span: {
      required: false,
      type: DaySpan,
      default: () =>
        new DaySpan(
          new Day(new Date()).startOf('month'),
          new Day(new Date()).endOf('month')
        )
    },

    highlightSpan: {
      type: Boolean,
      default: false
    },

    weekdays: {
      type: Array,
      default() {
        return [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];
      }
    },

    labels: {
      default() {
        return {
          prevMonth: 'Previous month',
          nextMonth: 'Next month'
        };
      }
    },

    format: {
      type: String,
      default: 'DD MMM YYYY'
    }
  },

  data: (vm: any) =>
    ({
      month: vm.getMonth(),
      open: false
    } as any),

  computed: {
    Day: () => Day,
    summary(): string {
      return this.month ? this.month.summary(false, false, false, false) : '';
    },
    rows() {
      return Math.floor(this.month.days.length / 7);
    },

    internalValue: {
      get(): Date {
        if (this.lazyValue instanceof Date) {
          return this.lazyValue;
        } else {
          return new Date(this.lazyValue);
        }
      },
      set(val: any) {
        this.lazyValue = val;
      }
    } as any,

    formatedDate() {
      return new Day(setUTCPartsToDate(this.value)).format(this.format);
    }
  },

  watch: {
    value: {
      handler(val: any) {
        // validatetion to check if value is parsable to a date
        // e.g string, number or Date
        this.lazyValue = val;
      },
      immediate: true
    },
    span: {
      deep: true,
      handler() {
        this.resetMonth();
      }
    },
    open: {
      handler() {
        this.resetMonth();
      }
    }
  },

  methods: {
    setPartsToUTCDate,
    setUTCPartsToDate,
    isHighlighted(day: CalendarDay<any, any>) {
      return this.highlightSpan && this.span.matchesDay(day);
    },

    getMonthStart() {
      return this.value ? new Day(this.value) : this.$altoleapCalendar.today;
    },

    resetMonth() {
      if (!this.span.matchesMonth(this.month.start)) {
        this.month = this.getMonth();
      }
    },

    getMonth() {
      return Calendar.months(1, this.getMonthStart(), 0, {
        fill: true,
        minimumSize: 42
      });
    },

    pick(day: CalendarDay<any, any>) {
      this.$emit('picked', day);
    },

    selectDay(day: CalendarDay<any, any>) {
      const dateString =
        day.format('YYYY-MM-DDT') +
        new Day(setUTCPartsToDate(this.value)).format('HH:mm:ss');

      this.$emit(event, setPartsToUTCDate(new Date(dateString)));
      this.open = false;
    },

    prev() {
      const ev = this.getEvent('prev', { next: false, prev: true });

      this.$emit('prev', ev);

      if (!ev.handled) {
        ev.month.prev();
        ev.handled = true;
      }

      this.$emit('change', ev);
    },

    next() {
      const ev = this.getEvent('next', { next: true, prev: false });

      this.$emit('next', ev);

      if (!ev.handled) {
        ev.month.next();
        ev.handled = true;
      }

      this.$emit('change', ev);
    },

    daysAtRow(row: number, rowSize: number) {
      const start = (row - 1) * rowSize;

      return this.month.days.slice(start, start + rowSize);
    },

    getEvent(type: string, extra = {}) {
      return fn.extend(
        {
          type: type,
          span: this.span,
          month: this.month,
          handled: false,
          $vm: this,
          $element: this.$el
        },
        extra
      );
    }
  }
});
