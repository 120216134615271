
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';

import { States, Task } from '@/models/task';

import BoardList from './BoardList.vue';

export default Vue.extend({
  name: 'BoardColumn',

  components: {
    BoardList
  },

  props: {
    column: Object as PropType<States>
  },

  computed: {
    ...mapGetters({
      getTasks: 'tasks/getTasks'
    }),

    columnTaskList() {
      return this.getTasks.filter((task: Task) => {
        return (task.status as States).id! === this.column.id;
      });
    }
  },

  methods: {
    selectTask(taskId: number) {
      this.$emit('select-task', taskId);
    },
    addTask(taskId: number) {
      this.$emit('add-task', taskId);
    }
  }
});
