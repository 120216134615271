import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Item } from '@/models/inventory';
import { ItemMasterService } from '@/services/inventory/item-service';

const itemMasterService = new ItemMasterService();

export const state = {
  item: [] as Item[]
};

export type ItemState = typeof state;

export const mutations: MutationTree<ItemState> = {
  SET_ITEM(state: any, payload: Item[]) {
    state.item = payload;
  },

  ADD_ITEM(state: any, payload: Item) {
    state.item.push(payload);
  },

  UPDATE_ITEM(state: any, payload: Item) {
    const index = state.item.findIndex(
      (model: Item) => model.identifier === payload.identifier
    );
    state.item.splice(index, 1, payload);
  },

  REMOVE_ITEM(state: any, payload: string) {
    const index = state.item.findIndex(
      (model: Item) => model.identifier === payload
    );
    state.item.splice(index, 1);
  }
};

export const actions: ActionTree<ItemState, ItemState> = {
  createItem: async ({ commit }: any, payload: Item) =>
    itemMasterService
      .createItem(payload)
      .then((model: Item) => commit('ADD_ITEM', model)),

  fetchItems: async ({ commit }: any) =>
    itemMasterService.fetchItems().then((item: Item[]) => {
      commit('SET_ITEM', item);
    }),

  fetchItem: async ({ commit }: any, payload: number) =>
    itemMasterService.fetchItem(payload).then((model: Item) => {
      const index = state.item.findIndex(
        (v: Item) => v.identifier === model.identifier
      );
      if (index === -1) {
        commit('ADD_ITEM', model);
      } else {
        commit('UPDATE_ITEM', model);
      }
    }),

  updateItem: async (
    { state, commit }: any,
    payload: { identifier: number; data: Item }
  ) =>
    itemMasterService.updateItem(payload).then((model: Item) => {
      const index = state.item.findIndex(
        (v: Item) => v.identifier === model.identifier
      );
      if (index === -1) {
        commit('ADD_ITEM', model);
      } else {
        commit('UPDATE_ITEM', model);
      }
    }),

  deleteItem: async ({ commit }: any, payload: number) =>
    itemMasterService.deleteItem(payload).then(() => {
      commit('REMOVE_ITEM', payload);
    })
};

export const getters: GetterTree<ItemState, ItemState> = {
  getItemById: (state: ItemState) => (id: number) => {
    return state.item.find((model) => model.id === id);
  }
};
