
import Vue from 'vue';
import { mapActions } from 'vuex';

// components
import ErrorAlert from '../../common/ErrorAlert.vue';
import CompanyPicker from '../company/CompanyPicker.vue';
import VDragDropAvatarFileInput from '../../common/VDragDropAvatarFileInput.vue';

// models
import { Contact, ContactFieldError, IContact } from '@/models';
import { ErrorManager } from '@/models/error';
import { diffDeep } from '@/utils/helpers';

//mixins
import { FormRules } from '@/mixins/FormRules';

export default Vue.extend({
  name: 'ContactDetailFormDialog',

  components: { ErrorAlert, CompanyPicker, VDragDropAvatarFileInput },

  mixins: [FormRules],

  data() {
    return {
      isEdit: false,
      valid: false,
      dialog: false,
      loading: false,

      errorMessage: '',

      contact: new Contact(),
      contactOriginalCopy: null as null | IContact,

      fieldError: new ContactFieldError(),

      contactTitlesList: [
        { display_name: 'Mr.', value: 'Mr.' },
        { display_name: 'Mrs.', value: 'Mrs.' },
        { display_name: 'Miss', value: 'Miss' },
        { display_name: 'Ms.', value: 'Ms.' },
        { display_name: 'Dr.', value: 'Dr.' },
        { display_name: 'Rev.', value: 'Rev.' }
      ]
    };
  },
  methods: {
    ...mapActions({
      createContact: 'contactV2/createContact',
      updateContact: 'contactV2/updateContact'
    }),

    open(contact: IContact = new Contact(), isEdit = false) {
      this.contactOriginalCopy = contact;
      this.contact = new Contact(this.contactOriginalCopy);

      this.isEdit = isEdit;

      this.clearAllErrors();
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    clearAllErrors() {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    onPhoneNumberInput(
      _fromattedNumber: string,
      { number }: { number: { international: string } }
    ) {
      this.contact.phone = number.international;
      this.fieldError.phone.splice(0, this.fieldError.phone.length);
    },

    async saveContact() {
      this.clearAllErrors();
      this.loading = true;

      try {
        if (this.isEdit) {
          const contactDelta = diffDeep(
            this.contact,
            this.contactOriginalCopy,
            true
          );
          await this.updateContact(contactDelta);
        } else {
          await this.createContact(this.contact);
        }

        this.loading = false;
        this.close();
      } catch (error) {
        this.loading = false;

        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (ContactFieldError.isContactFieldError(error.response.data)) {
            this.fieldError = new ContactFieldError(error.response.data);
          }
        }

        this.errorMessage = ErrorManager.extractApiError(error);
      }
    }
  }
});
