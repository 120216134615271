import {
  IChecklist,
  IChecklistFieldError,
  IConference,
  IConferenceFieldError,
  IConferenceFieldErrorData,
  IJobData,
  IJobDate,
  IJobDateFieldError,
  IJobDateFieldErrorData,
  IJobFieldErrorData,
  IParticipant,
  IParticipantFieldError,
  IRecurrence,
  IRecurrenceFieldError,
  IRecurrenceFieldErrorData,
  IReminder,
  IReminderFieldError,
  IReminderFieldErrorData,
  ISessionFieldError,
  ISessionFieldErrorData,
  IChecklistFieldErrorData,
  IItem,
  IItemFieldErrorData,
  IItemFieldError,
  IAttendeeFieldErrorData,
  IParticipantFieldErrorData,
  IAttendee,
  IAttendeeFieldError,
  IJobTimelineOccurrencesData,
  IJobOccurrenceFieldErrorData,
  IJobTimelineStats,
  IJobDragData,
  ISession,
  IJobContactData,
  IJobContact,
  IJobOccurrenceData
} from './';

// Constants
export enum JobDeleteType {
  SINGLE_PARTICIPANT = 0,
  EVENT = 1
}

export enum JobStateType {
  DRAFT = 0,
  PUBLISHED = 1
}

export enum JobOptionType {
  ADD_EVENT = 2,
  MOVE_EVENT = 3
}
export interface IJobOptions {
  job: IJobOccurrenceData;
  choice: JobDeleteType | JobOptionType;
}

// calendar types
export enum CalendarType {
  CALENDAR_DAY = 'day',
  CALENDAR_WEEK = 'week',
  CALENDAR_MONTH = 'month',
  TIMELINE_DAY = 'timeline-day',
  TIMELINE_WEEK = 'timeline-week'
}

export class JobDragData implements IJobDragData {
  readonly job: IJobOccurrenceData = new JobOccurrence();
  readonly attendee: IAttendee = new Attendee();
  readonly originalAttendee: IAttendee = new Attendee();
  readonly date: string = '';

  constructor(data?: IJobDragData) {
    if (data) {
      this.job = data.job;
      this.attendee = data.attendee;
      this.originalAttendee = data.originalAttendee;
      this.date = data.date;
    }
  }
}
// TODO: LF-2022-05-26 - A class should never inherit from the data
export class Job implements IJobData {
  readonly id?: number;
  readonly title: string = '';
  readonly color: string = '';
  readonly description: string = '';
  readonly date: JobDate = new JobDate();
  readonly recurrence: Recurrence = new Recurrence();
  readonly location: string = '';
  readonly calendar?: number;
  readonly participants: Participant[] | any = [];
  readonly reminder: Reminder = new Reminder();
  readonly conference: Conference = new Conference();
  readonly checklist: Checklist = new Checklist();
  readonly contact: null | IJobContact = null;
  readonly original_start: string = '';
  readonly event?: number;
  readonly state: number = JobStateType.DRAFT;
  readonly owner?: string;

  constructor(data?: IJobData) {
    if (data) {
      this.title = data.title ?? '';
      this.color = data.color ?? '';
      this.description = data.description ?? '';
      this.location = data.location ?? '';
      this.original_start = data.original_start ?? '';
      this.state = data.state ?? 0;
      if (data.date) {
        this.date = new JobDate(data.date);
      }
      if (data.recurrence) {
        this.recurrence = new Recurrence(data.recurrence);
      }
      if (data.participants) {
        this.participants = data.participants.map(
          (participant: IParticipant) => new Participant(participant)
        );
      }
      if (data.recurrence) {
        this.recurrence = new Recurrence(data.recurrence);
      }
      if (data.conference) {
        this.conference = new Conference(data.conference);
      }
      if (data.checklist) {
        this.checklist = new Checklist(data.checklist);
      }
      if (data.contact) {
        this.contact = new JobContact(data.contact);
      }
      if (data.event) {
        this.event = data.event;
      }
      if (data.calendar) {
        this.calendar = data.calendar;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class JobTimelineStats implements IJobTimelineStats {
  readonly time_scheduled: number = 0;

  constructor(data?: IJobTimelineStats) {
    if (data) {
      this.time_scheduled = data.time_scheduled ?? 0;
    }
  }
}

export class JobOccurrence extends Job {
  readonly cancelled?: boolean;
  readonly task_id?: string;
  readonly original_end: string = '';
  readonly owner: string = '';

  constructor(data?: IJobOccurrenceData) {
    super(data);
    if (data) {
      this.cancelled = data.cancelled ?? false;
      this.task_id = data.task_id ?? '';
      if (data.original_end) {
        this.original_end = data.original_end;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
    }
  }
}

export class JobTimelineOccurrences implements IJobTimelineOccurrencesData {
  readonly user: Attendee = new Attendee();
  readonly stats: IJobTimelineStats = new JobTimelineStats();
  readonly jobs: IJobOccurrenceData[] = [];

  constructor(data?: IJobTimelineOccurrencesData) {
    if (data) {
      this.user = new Attendee(data.user);
      this.stats = new JobTimelineStats(data.stats);
      this.jobs = data.jobs!.map((job) => new JobOccurrence(job));
    }
  }
}

export class JobDate implements IJobDate {
  readonly start: string = '';
  readonly end: string = '';
  readonly timezone: string = '';

  constructor(data?: IJobDate) {
    if (data) {
      this.start = data.start;
      this.end = data.end;
      this.timezone = data.timezone;
    }
  }
}

export class Recurrence implements IRecurrence {
  readonly enabled?: boolean;
  readonly rules?: any[];

  constructor(data?: IRecurrence) {
    if (data) {
      this.enabled = data.enabled;
      this.rules = data.rules;
    }
  }
}

export class Reminder implements IReminder {
  readonly enabled?: boolean;
  readonly minutes?: number;

  constructor(data?: IReminder) {
    if (data) {
      this.enabled = data.enabled ?? false;
      this.minutes = data.minutes ?? 0;
    }
  }
}

export class Item implements IItem {
  id?: number;
  item_text?: string;

  constructor(data?: IItem) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.item_text) {
        this.item_text = data.item_text;
      }
    }
  }
}

export class JobContact implements IJobContact {
  id?: number;
  first_name = '';
  last_name = '';
  email?: string;
  phone?: string;

  constructor(data?: IJobContactData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
    }
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  hasValidField(): boolean {
    if (this.first_name || this.last_name || this.email || this.phone) {
      return true;
    }

    return false;
  }
}

export class Checklist implements IChecklist {
  id?: number;
  items?: Item[];

  constructor(data?: IChecklist) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.items) {
        this.items = data.items.map((item: Item) => new Item(item));
      }
    }
  }
}

export class Participant implements IParticipant {
  readonly id?: number;
  readonly attendee: Attendee = new Attendee();
  readonly rsvp_status?: number;

  constructor(data?: IParticipant) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.attendee) {
        this.attendee = new Attendee(data.attendee);
      }
      if (data.rsvp_status) {
        this.rsvp_status = data.rsvp_status;
      }
    }
  }
}

export class Attendee implements IAttendee {
  readonly id?: number;
  readonly first_name?: string = '';
  readonly last_name?: string = '';
  readonly email?: string = '';
  readonly is_active?: boolean = false;

  constructor(data?: IAttendee) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.is_active) {
        this.is_active = data.is_active;
      }
    }
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`.trim();
  }
}

export class Conference implements IConference {
  readonly enabled?: boolean;
  readonly platform?: number;
  readonly session?: Session;

  constructor(data?: IConference) {
    if (data) {
      this.enabled = data.enabled ?? false;
      if (data.platform) {
        this.platform = data.platform;
      }
      if (data.session) {
        this.session = new Session(data.session);
      }
    }
  }
}

export class Session implements ISession {
  readonly key?: string;
  readonly password?: string;
  readonly start_url?: string;
  readonly join_url?: string;

  constructor(data?: ISession) {
    if (data) {
      if (data.key) {
        this.key = data.key;
      }
      if (data.password) {
        this.password = data.password;
      }
      if (data.start_url) {
        this.start_url = data.start_url;
      }
      if (data.join_url) {
        this.join_url = data.join_url;
      }
    }
  }
}

export class JobDateFieldError implements IJobDateFieldError {
  start: string[] = [];
  end: string[] = [];
  timezone: string[] = [];

  constructor(data?: IJobDateFieldErrorData) {
    if (data) {
      if (data.start) {
        this.start = data.start;
      }
      if (data.end) {
        this.end = data.end;
      }
      if (data.timezone) {
        this.timezone = data.timezone;
      }
    }
  }

  clearErrors(): void {
    this.start.splice(0, this.start!.length);
    this.end.splice(0, this.end!.length);
    this.timezone.splice(0, this.timezone!.length);
  }
}

export class RecurrenceFieldError implements IRecurrenceFieldError {
  enabled?: string[] | undefined;
  rules?: string[] | undefined;

  constructor(data?: IRecurrenceFieldErrorData) {
    if (data) {
      if (data.enabled) {
        this.enabled = data.enabled;
      }
      if (data.rules) {
        this.rules = data.rules;
      }
    }
  }
  clearErrors(): void {
    this.enabled!.splice(0, this.enabled!.length);
    this.rules!.splice(0, this.rules!.length);
  }
}

export class ReminderFieldError implements IReminderFieldError {
  enabled?: string[] | undefined;
  minutes?: string[] | undefined;

  constructor(data?: IReminderFieldErrorData) {
    if (data) {
      if (data.enabled) {
        this.enabled = data.enabled;
      }
      if (data.minutes) {
        this.minutes = data.minutes;
      }
    }
  }
  clearErrors(): void {
    this.enabled!.splice(0, this.enabled!.length);
    this.minutes!.splice(0, this.minutes!.length);
  }
}

export class SessionFieldError implements ISessionFieldError {
  key?: string[];
  password?: string[];
  start_url?: string[];
  join_url?: string[];

  constructor(data?: ISessionFieldErrorData) {
    if (data) {
      if (data.key) {
        this.key = data.key;
      }
      if (data.password) {
        this.password = data.password;
      }
      if (data.start_url) {
        this.start_url = data.start_url;
      }
      if (data.join_url) {
        this.join_url = data.join_url;
      }
    }
  }
  clearErrors(): void {
    this.key!.splice(0, this.key!.length);
    this.password!.splice(0, this.password!.length);
    this.start_url!.splice(0, this.start_url!.length);
    this.join_url!.splice(0, this.join_url!.length);
  }
}

export class ConferenceFieldError implements IConferenceFieldError {
  enabled?: string[] | undefined;
  platform?: string[] | undefined;
  session?: ISessionFieldError;

  constructor(data?: IConferenceFieldErrorData) {
    if (data) {
      if (data.enabled) {
        this.enabled = data.enabled;
      }
      if (data.platform) {
        this.platform = data.platform;
      }
      if (data.session) {
        this.session = new SessionFieldError(data.session);
      }
    }
  }
  clearErrors(): void {
    this.enabled!.splice(0, this.enabled!.length);
    this.platform!.splice(0, this.platform!.length);
  }
}

export class ChecklistFieldError implements IChecklistFieldError {
  id?: string[] | undefined;
  items?: string[] | IItemFieldErrorData[];

  constructor(data?: IChecklistFieldErrorData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.items) {
        this.items = data.items;
      }
    }
  }

  clearErrors(): void {
    this.items!.splice(0, this.items!.length);
  }
}

export class ItemFieldError implements IItemFieldError {
  id?: string[] | undefined;
  item_text?: string[] | undefined;

  constructor(data?: IItemFieldErrorData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.item_text) {
        this.item_text = data.item_text;
      }
    }
  }

  clearErrors(): void {
    this.id!.splice(0, this.id!.length);
    this.item_text!.splice(0, this.item_text!.length);
  }
}

export class ParticipantFieldError implements IParticipantFieldError {
  attendee?: string[] | IAttendeeFieldErrorData[];
  rsvp_status?: string[] | undefined;

  constructor(data?: IParticipantFieldErrorData) {
    if (data) {
      if (data.attendee) {
        this.attendee = data.attendee;
      }
      if (data.rsvp_status) {
        this.rsvp_status = data.rsvp_status;
      }
    }
  }

  clearErrors(): void {
    this.attendee!.splice(0, this.attendee!.length);
    this.rsvp_status!.splice(0, this.rsvp_status!.length);
  }
}

export class AttendeeFieldError implements IAttendeeFieldError {
  first_name?: string[] | undefined;
  last_name?: string[] | undefined;
  email?: string[] | undefined;

  constructor(data?: IAttendeeFieldErrorData) {
    if (data) {
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
    }
  }

  clearErrors(): void {
    this.first_name!.splice(0, this.first_name!.length);
    this.last_name!.splice(0, this.last_name!.length);
    this.email!.splice(0, this.email!.length);
  }
}

export class JobFieldError implements IJobFieldErrorData {
  title: string[] = [];
  color: string[] = [];
  description: string[] = [];
  original_start: string[] = [];
  date: IJobDateFieldErrorData[] | string[] = [];
  recurrence: IRecurrenceFieldErrorData[] | string[] = [];
  location: string[] = [];
  calendar: string[] = [];
  participants: IParticipantFieldErrorData[] | string[] = [];
  reminder: IReminderFieldErrorData[] | string[] = [];
  conference: IConferenceFieldErrorData[] | string[] = [];
  checklist: IChecklistFieldErrorData[] | string[] = [];
  event: string[] = [];

  constructor(data?: IJobFieldErrorData) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }
      if (data.color) {
        this.color = data.color;
      }
      if (data.description) {
        this.description = data.description;
      }
      if (data.original_start) {
        this.original_start = data.original_start;
      }
      if (data.date) {
        this.date = data.date;
      }
      if (data.recurrence) {
        this.recurrence = data.recurrence;
      }
      if (data.location) {
        this.location = data.location;
      }
      if (data.calendar) {
        this.calendar = data.calendar;
      }
      if (data.participants) {
        this.participants = data.participants;
      }
      if (data.reminder) {
        this.reminder = data.reminder;
      }
      if (data.conference) {
        this.conference = data.conference;
      }
      if (data.checklist) {
        this.checklist = data.checklist;
      }
      if (data.event) {
        this.event = data.event;
      }
    }
  }

  clearErrors(): void {
    this.title.splice(0, this.title.length);
    this.description.splice(0, this.description.length);
    this.color.splice(0, this.color.length);
  }

  static isJobFieldError(data: IJobFieldErrorData): boolean {
    if (data.title && Array.isArray(data.title)) return true;
    if (data.description && Array.isArray(data.description)) return true;
    if (data.color && Array.isArray(data.color)) return true;
    //Add more field errors
    return false;
  }
}

export class JobOccurrenceFieldError extends JobFieldError {
  cancelled?: string[] = [];
  task_id?: string[] = [];
  original_end: string[] = [];
  owner: string[] = [];

  constructor(data?: IJobOccurrenceFieldErrorData) {
    super();
    if (data) {
      if (data.cancelled) {
        this.cancelled = data.cancelled;
      }
      if (data.task_id) {
        this.task_id = data.task_id;
      }
      if (data.original_end) {
        this.original_end = data.original_end;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
    }
  }
}
