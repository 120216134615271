import { IOrganization, IOrganizationData } from '../organization.types';
import { Organization } from '../organization';

export const mockOrganizationsData = (): IOrganizationData[] => [
  {
    name: 'Org 1'
  },
  {
    name: 'Org 2'
  }
];

export const mockOrganizations = (
  data: IOrganizationData[] = mockOrganizationsData()
): IOrganization[] => data.map((item) => new Organization(item));
