export interface IFetchTimesheetsActionPayload {
  page?: number;
  page_size?: number;
  search?: string;
  employee?: number;
  state?: number;
  occurrence_after?: Date | string;
  occurrence_before?: Date | string;
  in_progress?: boolean;
}

export interface ITimesheetPaginatedListData {
  count: number;
  next?: string;
  previous?: string;
  results: Timesheet[];
}
export class Timesheet {
  id?: number;
  job?: TimesheetJob | any;
  employee?: Employee | any;
  break_times?: BreakTime[] = [];
  punch_in?: Date | string;
  punch_out?: Date | null | string;
  occurrence_date?: Date | string;
  state?: TimesheetState;

  constructor(data?: Timesheet) {
    this.employee = new Employee();
    this.job = new TimesheetJob();

    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.job) {
        this.job = data.job;
      }
      if (data.employee) {
        // TODO: should construct new employee
        this.employee = data.employee;
      }
      if (data.break_times) {
        data.break_times.forEach((breakTime, index) => {
          this.break_times?.push(new BreakTime(breakTime));
        });
      }
      if (data.punch_in) {
        this.punch_in = new Date(data.punch_in);
      }
      if (data.punch_out) {
        this.punch_out = new Date(data.punch_out);
      }
      if (data.occurrence_date) {
        this.occurrence_date = new Date(data.occurrence_date);
      }
      if (data.state) {
        this.state = new TimesheetState(data.state);
      }
    }
  }
}

export class TimesheetJob {
  id?: number;
  title?: string;
  date?: { start: string; end: string; timezone: string };
  event?: number;
}

export class Employee {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;

  constructor(data?: Employee) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
    }
  }
}

export class BreakTime {
  id?: number;
  break_start?: Date | string;
  break_end?: Date | string;

  constructor(data?: BreakTime) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.break_start) {
        this.break_start = data.break_start;
      }
      if (data.break_end) {
        this.break_end = data.break_end;
      }
    }
  }
}

export class TimesheetState {
  display_name?: string;
  value?: number;

  constructor(data?: TimesheetState) {
    if (data) {
      if (data.display_name) {
        this.display_name = data.display_name;
      }

      this.value = data.value ?? 0;
    }
  }
}
