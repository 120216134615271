import { EmployeeWorkTime } from '@/models/employeeWorkTime';
import { Job } from '@/models/job';
import { JobService } from '@/services/jobs';
import { errorCatch } from '../utils';

const jobService = new JobService();

export const state = {
  calendarIds: [] as number[],
  events: [] as Job[],
  loading: false,
  error: {},
  calendarsById: {}
};

export const mutations = {
  // Calendar Category mutations
  ADD_CALENDAR_JOB(state: any, payload: Job) {
    state.events.push(payload);
  },

  SET_CALENDAR_JOBS(state: any, payload: Job[]) {
    state.events = payload;
  },

  UPDATE_CALENDAR_JOB(state: any, payload: Job) {
    const index = state.events.findIndex(
      (category: Job) => category.id === payload.id
    );
    state.events.splice(index, 1, payload);
  },

  REMOVE_CALENDAR_JOB(state: any, payload: number) {
    const index = state.events.findIndex(
      (category: Job) => category.id === payload
    );
    state.events.splice(index, 1);
  },
  SET_LOADING(state: any, payload: boolean) {
    state.loading = payload;
  },

  SET_ERROR(state: any, error: any) {
    errorCatch(state, error);
  },

  CLEAR_ERROR(state: any) {
    state.error = {};
  }
};

export const actions = {
  clearError: ({ commit }: any) => commit('CLEAR_ERROR'),

  fetchJobs: async ({ commit, dispatch }: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      jobService
        .fetchJobs()
        .then((events) => {
          commit('SET_CALENDAR_JOBS', events);
          resolve(events);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchJob: async ({ commit, dispatch }: any, payload: { eventId: number }) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      jobService.fetchJob(payload.eventId).then((event: Job) => {
        const index = state.events.findIndex(
          (s: Job) => s.id === payload.eventId
        );
        if (index === -1) {
          commit('ADD_CALENDAR_JOB', event);
        } else {
          commit('UPDATE_CALENDAR_JOB', event);
        }
        resolve(event);
      });
    });
  },

  updateJob: async (
    { commit, dispatch }: any,
    payload: { eventId: number; data: Job }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      jobService
        .updateJob(payload.eventId, payload.data)
        .then((event: Job) => {
          const index = state.events.findIndex(
            (s: Job) => s.id === payload.eventId
          );
          if (index === -1) {
            commit('ADD_CALENDAR_JOB', event);
          } else {
            commit('UPDATE_CALENDAR_JOB', event);
          }
          resolve(event);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  deleteJob: async (
    { commit, dispatch }: any,
    payload: { eventId: number }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      jobService
        .deleteJob(payload.eventId)
        .then((event) => {
          const index = state.events.findIndex(
            (s: Job) => s.id === payload.eventId
          );
          if (index === -1) {
            commit('ADD_CALENDAR_JOB', event);
          } else {
            commit('UPDATE_CALENDAR_JOB', event);
          }
          resolve(event);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  }
};
