import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { VueConstructor } from 'vue/types/umd';

export function registerComponents(Vue: VueConstructor) {
  const requireComponent = require.context(
    '@/components',
    true,
    /(index.ts)$|\.vue$/
  );

  Vue.component('v-paper', {
    render(h) {
      return h('v-card', this.$slots.default);
    }
  });

  // Dynamically load all components
  // and lazily load them
  // https://vuejs.org/v2/guide/components-dynamic-async
  requireComponent.keys().forEach((fileName) => {
    if (fileName !== './index.ts') {
      const componentName = upperFirst(
        camelCase(
          fileName
            .replace(/^\.\//, '')
            .replace(/\.\w+$/, '')
            .replace(/(\.\/|Index)/gi, '')
        )
      );

      Vue.component(componentName, (resolve) => {
        const componentConfig = requireComponent(fileName);

        resolve(componentConfig.default || componentConfig);
      });
    }
  });
}
