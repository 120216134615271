import Vue from 'vue';
import pathify from 'vuex-pathify';
import router from '@/router';
import data from './data';
import Vuex, { Store } from 'vuex';
import { IRootState } from './store.types';
import { axios } from '@/plugins/axios';

// altoleap libraries modules
import AltoleapAccounts from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import AltoleapNotificationLib from '@vue-altoleap-libraries/vue-altoleap-notifications-lib';

// store modules
import modules from './modules';
import { employee } from './employee';
import { product } from './product';
import { feedback } from './feedback';
import { job } from './job';
import { organization } from './organization';

// V2 CRM
import { contact } from './v2/contact';
import { contactNote } from './v2/contactnote';
import { company } from './v2/company';
import { companyNote } from './v2/companynote';

//plugins
import { mixpanelStorePlugin } from './plugins/mixpanelPlugin';
import { authStorePlugin } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import AxiosStorePlugin from './plugins/axios';

Vue.use(Vuex);

export const makeStore = (): Store<IRootState> =>
  new Vuex.Store<IRootState>({
    state: {
      version: 1
    },
    mutations: {},
    actions: {},
    modules: {
      ...modules,
      ...data,
      ...AltoleapAccounts.storeModules(axios),
      ...AltoleapNotificationLib.storeModules(axios),
      employee,
      product,
      feedback,
      job,
      organization,

      // CRM
      contactV2: contact,
      contactNoteV2: contactNote,
      companyV2: company,
      companyNoteV2: companyNote
    },
    plugins: [
      pathify.plugin,
      authStorePlugin(axios, router),
      AxiosStorePlugin,
      mixpanelStorePlugin
    ]
  });
