
import Vue, { PropType } from 'vue';
const prop = 'value',
  event = 'input';
export default Vue.extend({
  model: { prop, event },
  props: {
    value: null as any as PropType<any>
  },
  data() {
    return {
      lazyValue: this.value,
      menu: false
    };
  },
  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
        this.$emit(event, val);
      }
    },
    customAttrs(): object {
      return this.$attrs;
    }
  }
});
