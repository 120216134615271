import { axios } from '@/plugins/axios';
import { ActivityFeed, Inventory } from '@/models/inventory';

export class InventoryService {
  private endpoint: string;
  private activityFeedEndpoint: string;

  constructor() {
    this.endpoint = 'v1/inventory-management/inventories/';
    this.activityFeedEndpoint = 'v1/inventory-management/activities/';
  }

  async createInventory(inventory: Inventory) {
    return axios
      .post<Inventory>(`${this.endpoint}`, inventory)
      .then(({ data }) => data);
  }

  async fetchInventories(warehouse?: number) {
    return axios
      .get<Inventory[]>(`${this.endpoint}`, {
        params: { warehouse }
      })
      .then(({ data }) => data);
  }

  async fetchInventory(id: number) {
    return axios
      .get<Inventory>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  async updateInventory(inventory: Inventory) {
    return axios
      .patch<Inventory>(`${this.endpoint}${inventory.id}/`, inventory)
      .then(({ data }) => data);
  }

  async deleteInventory(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }

  async updateQuantity(inventory: Inventory) {
    return axios
      .put<Inventory>(
        `${this.endpoint}${inventory.id}/adjust_quantity/`,
        inventory
      )
      .then(({ data }) => data);
  }

  //Activity Feed
  async fetchActivityFeeds() {
    return axios
      .get<Inventory[]>(`${this.activityFeedEndpoint}`)
      .then(({ data }) => data);
  }
}
