
import { mapGetters, mapActions } from 'vuex';

//types
import { Timesheet } from '@/models/timesheet';

//functions
import dateFormat from '@/utils/dateFormat';
import auth from '@/mixins/auth';
import mixins from '@/utils/mixins';
import { ErrorManager } from '@/models/error';
import moment from 'moment';
import { JobReport } from '@/models/jobReport';

import { setPartsToUTCDate, localDateFormat } from '@/utils/dateHelpers';

export default mixins(auth).extend({
  props: {
    timesheetId: {
      type: Number
    }
  },

  data() {
    return {
      loading: false,

      title: 'Timesheet',
      errorMessage: '',
      jobReportErrorMessage: '',
      jobReportId: undefined as undefined | number
    };
  },

  computed: {
    ...mapGetters('timesheets', {
      getTimesheetById: 'getTimesheetById'
    }),

    timesheet(): Timesheet {
      return this.getTimesheetById(this.timesheetId);
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    recurrenceId(): number {
      return moment(this.timesheet.occurrence_date).valueOf();
    }
  },

  methods: {
    ...mapActions({
      fetchCalendarOccurrenceReport:
        'calendarOccurrence/fetchCalendarOccurrenceReport'
    }),
    setPartsToUTCDate,
    localDateFormat,
    dateFormat,

    getDateRangeFormat(startDate: Date, endDate: Date): any {
      const start = moment(startDate).utc();
      const end = moment(endDate).utc();
      const sameDay = start.isSame(end, 'day');

      const sa = start.format('a');
      const ea = end.format('a');

      let sh = start.format('h');
      let eh = end.format('h');

      if (start.get('minute') !== 0) {
        sh += start.format(':mm');
      }

      if (end.get('minute') !== 0) {
        eh += end.format(':mm');
      }

      return sa === ea ? sh + ' - ' + eh + ea : sh + sa + ' - ' + eh + ea;
    }
  },

  async mounted() {
    this.loading = true;

    await this.fetchCalendarOccurrenceReport({
      eventId: this.timesheet.job.event ?? this.timesheet.job.id,
      recurrenceId: this.recurrenceId
    })
      .then((report: JobReport) => {
        this.jobReportId = report.id;
      })
      .catch((error: any) => {
        this.jobReportErrorMessage = ErrorManager.extractApiError(error);
      });

    this.loading = false;
  }
});
