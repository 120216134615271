
import { cloneDeep, orderBy } from 'lodash';
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';

import { States, Task } from '@/models/task';

import dateFormat from '@/utils/dateFormat';
import { compareOrder, deepEqual } from '@/utils/helpers';

export const DRAG_CLASS = 'is-dragging';

export const defaultSortableOptions = {
  animation: 200,
  forceFallback: true,
  fallbackClass: DRAG_CLASS,
  fallbackOnBody: true,
  ghostClass: 'is-ghost',
  fallbackTolerance: 1
};

interface ActionContext {
  element: Task;
  newIndex?: number;
  oldIndex?: number;
}

interface ActionType {
  moved?: ActionContext;
  added?: ActionContext;
  removed?: ActionContext;
}

export default Vue.extend({
  name: 'BoardList',

  components: {
    Draggable
  },

  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    column: {
      type: Object as PropType<States>,
      required: true
    },
    listItems: {
      type: Array as PropType<Task[]>,
      required: true
    }
  },

  data() {
    return {
      loading: false,

      items: cloneDeep(this.listItems),

      errorMessage: ''
    };
  },

  watch: {
    listItems: {
      handler(tasks: Task[]) {
        if (!deepEqual(tasks, this.items)) {
          this.items = cloneDeep(tasks);
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      getTaskById: 'tasks/getTaskById'
    }),

    orderedItems: {
      get(): Task[] {
        return orderBy(this.items, 'order');
      },
      set(tasks: Task[]) {
        this.items = tasks;
      }
    },

    columnRootOptions(): Record<string, string | boolean | any> {
      const options = {
        ...defaultSortableOptions,
        fallbackOnBody: false,
        group: 'board-list',
        tag: 'ul',
        'ghost-class': 'board-card-drag-active',
        'data-list-id': this.column.id
      };

      return options;
    }
  },

  methods: {
    ...mapActions({
      updateTaskElement: 'tasks/updateTaskElement'
    }),

    dateFormat,

    clearAllErrors() {
      this.errorMessage = '';
    },

    getOwnerInitials(first_name: string, last_name: string) {
      const owner = first_name + ' ' + last_name;
      let initials: string | RegExpMatchArray = '';
      initials = owner.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || '') + (initials.pop() || '')
      ).toUpperCase();
      return initials;
    },

    selectTask(taskId: number) {
      this.$emit('select-task', taskId);
    },

    async updateList(_event: ActionType) {
      this.clearAllErrors();

      function getValueOfState(state?: number | States) {
        return (state as States).id ?? state;
      }
      const itemsClone = cloneDeep(this.items);
      const columnId = this.column.id;
      const tasks: Task[] = itemsClone.map(function (
        task: Task,
        index: number
      ) {
        return {
          order: index + 1,
          id: task.id,
          status: columnId
        };
      });
      const difference = (tasks as Required<Task>[]).filter(
        compareOrder(itemsClone as Required<Task>[], (other, current) => {
          return (
            other.id == current.id &&
            other.order == current.order &&
            getValueOfState(other.status) == getValueOfState(current.status)
          );
        })
      );
      Promise.all(difference.map(this.updateTaskElement));
    }
  }
});
