
import Vue from 'vue';

// Helpers
import { ErrorManager } from '@/models/error';
import { mapActions, mapGetters } from 'vuex';

// Models
import { Contact, IContact } from '@/models';

// Components
import ErrorAlert from '../../common/ErrorAlert.vue';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import ContactDetailFormDialog from './ContactDetailFormDialog.vue';
import ContactStatusDisplay from './ContactStatusDisplay.vue';
import CRMStatusFilter from '../common/CRMStatusFilter.vue';

interface Options {
  $refs: {
    contactFormDialog: InstanceType<typeof ContactDetailFormDialog>;
  };
}

export default Vue.extend<Options>({ functional: false }).extend({
  name: 'ContactDataTable',

  components: {
    ErrorAlert,
    ConfirmationDialog,
    ContactDetailFormDialog,
    ContactStatusDisplay,
    CRMStatusFilter
  },

  data() {
    return {
      search: '',
      errorMessage: '',
      errorContactDialogMessage: '',

      deleteInProgress: false,
      deleteContactDialog: false,
      loading: false,

      selectedContact: new Contact(),

      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'First Name',
          value: 'name.first'
        },
        {
          text: 'Last Name',
          value: 'name.last'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Phone Number',
          sortable: false,
          value: 'phone'
        },
        {
          text: 'Company',
          value: 'company.name'
        },
        {
          text: 'Owner',
          value: 'owner'
        },
        {
          text: 'Status',
          value: 'status',
          width: '200px'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      activeView: -1 as number
    };
  },

  computed: {
    ...mapGetters({ contacts: 'contactV2/getContacts' }),

    filterContacts(): IContact[] {
      return this.contacts.filter((contact: IContact) => {
        if (this.activeView == -1) {
          return -1; // true
        }
        return contact.status?.value == this.activeView;
      });
    }
  },

  methods: {
    ...mapActions({
      fetchContacts: 'contactV2/fetchContacts',
      deleteContact: 'contactV2/deleteContact'
    }),

    clearAllErrors() {
      this.errorMessage = '';
    },

    clearAllDialogErrors() {
      this.errorContactDialogMessage = '';
    },

    closeContactDeleteDialog() {
      this.deleteContactDialog = false;
    },

    /**
     * @public
     * Opens detail dialog
     */
    openContactDetailDialog(instance?: IContact, isEdit = false) {
      this.$refs.contactFormDialog.open(instance, isEdit);
    },

    openDeleteContactDialog(contact: IContact) {
      // To avoid mutation reference value
      this.selectedContact = new Contact(contact);
      this.deleteContactDialog = true;
    },

    destroyContact(deleteId: number) {
      this.deleteContact(deleteId)
        .then(() => (this.deleteContactDialog = false))
        .catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    goToContactDetail(contact: IContact) {
      return this.$router.push({
        name: 'contact-detail',
        params: { id: String(contact.id) }
      });
    }
  },

  mounted() {
    this.loading = true;
    this.fetchContacts()
      .catch((error) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
});
