
import Vue from 'vue';
import { countries } from './countries';
import { ICountry } from '@/models/country';

const prop = 'value',
  event = 'input';

export default Vue.extend({
  model: { prop, event },

  props: {
    value: [String, Object],
    countries: {
      type: Array as () => ICountry[],
      default: () => countries()
    },

    label: {
      type: String,
      default: 'Countries'
    }
  },
  data() {
    return {
      lazyValue: this.value,
      search: ''
    };
  },
  watch: {
    value: {
      handler(val: any) {
        this.lazyValue = val;
      },
      immediate: true
    }
  },
  computed: {
    internalValue: {
      get(): any {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
        this.$emit(event, val);
      }
    },
    customAttrs(): object {
      return this.$attrs;
    }
  }
});
