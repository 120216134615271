import {
  CheckList,
  IFetchJobReportActionPayload,
  IJobReportPaginatedListData,
  JobReport
} from '@/models/jobReport';
import { JobReportService } from '@/services/jobReportsService';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const jobReportService = new JobReportService();

export const state = {
  count: 0 as number,
  jobReports: [] as JobReport[],
  checklist: [] as CheckList[]
};

type JobReportState = typeof state;

export const mutations: MutationTree<JobReportState> = {
  ADD_JOB_REPORT(state, payload: JobReport) {
    state.jobReports.push(payload);
  },

  ADD_JOB_CHECKLIST(state, payload: CheckList) {
    state.checklist.push(payload);
  },

  SET_JOB_REPORTS(state, payload: JobReport[]) {
    state.jobReports = payload;
  },

  UPDATE_JOB_REPORT(state, payload: JobReport) {
    const index = state.jobReports.findIndex(
      (jobReport: JobReport) => jobReport.id === payload.id
    );
    state.jobReports.splice(index, 1, payload);
  },

  SET_JOB_CHECKLIST(state, payload: CheckList) {
    state.checklist[0] = payload;
  },

  UPDATE_JOB_CHECKLIST(state, payload: CheckList) {
    state.checklist[0] = payload;
  },

  REMOVE_JOB_REPORT(state, payload: number) {
    const index = state.jobReports.findIndex(
      (jobReport: JobReport) => jobReport.id === payload
    );
    state.jobReports.splice(index, 1);
  },

  SET_COUNT(state, payload: number): void {
    state.count = payload;
  },

  INCREMENT_COUNT(state): void {
    state.count += 1;
  },

  DECREMENT_COUNT(state): void {
    state.count -= 1;
  }
};

export const actions: ActionTree<JobReportState, JobReportState> = {
  createJobReport: async ({ commit }: any, payload: JobReport) =>
    jobReportService.createJobReport(payload).then((report: JobReport) => {
      commit('ADD_JOB_REPORT', report);
      commit('INCREMENT_COUNT');
      return report;
    }),

  fetchJobReports: async (
    { commit },
    payload?: IFetchJobReportActionPayload
  ) => {
    return jobReportService
      .fetchJobReports(payload)
      .then((jobReport: IJobReportPaginatedListData) => {
        commit('SET_JOB_REPORTS', jobReport.results);
        commit('SET_COUNT', jobReport.count);
      });
  },

  fetchJobChecklist: async ({ commit }, payload: number) => {
    return jobReportService
      .fetchJobChecklist(payload)
      .then((res: CheckList[]) => {
        commit('SET_JOB_CHECKLIST', res[0]);
        return res;
      });
  },

  updateJobReportChecklist: async (
    { commit },
    payload: { jobReportId: number; data: CheckList }
  ) => {
    return jobReportService
      .updateJobReportChecklist(payload.jobReportId, payload.data)
      .then((res: CheckList[]) => {
        commit('UPDATE_JOB_CHECKLIST', res[0]);
      });
  },

  updateJobReport: async (
    { commit },
    payload: { jobReportId: number; data: JobReport }
  ) => {
    return jobReportService
      .updateJobReport(payload.jobReportId, payload.data)
      .then((res: JobReport) => {
        const index = state.jobReports.findIndex(
          (v: JobReport) => v.id === res.id
        );
        if (index === -1) {
          commit('ADD_JOB_REPORT', res);
          commit('INCREMENT_COUNT');
        } else {
          commit('UPDATE_JOB_REPORT', res);
        }

        return res;
      });
  },

  addJobReportImage: async (
    { commit },
    payload: { jobReportId: number; data: any }
  ) => {
    return jobReportService
      .addJobReportImage(payload.jobReportId, payload.data)
      .then((res: JobReport) => {
        return res;
      });
  },

  fetchJobReportById: async ({ commit }, payload: number) => {
    return jobReportService
      .fetchJobReportById(payload)
      .then((res: JobReport) => {
        const jobReports = [...state.jobReports];
        const index = jobReports.findIndex((t: JobReport) => t.id === payload);
        if (index === -1) {
          commit('ADD_JOB_REPORT', jobReports);
          commit('INCREMENT_COUNT');
        } else {
          commit('UPDATE_JOB_REPORT', jobReports);
        }
        return res;
      });
  },

  deleteJobReport: async ({ commit, dispatch }, payload: number) => {
    return jobReportService.deleteJobReport(payload).then(() => {
      commit('REMOVE_JOB_REPORT', payload);
      commit('DECREMENT_COUNT');
    });
  }
};

export const getters: GetterTree<JobReportState, JobReportState> = {
  getJobReports: (state: JobReportState): JobReport[] => {
    return state.jobReports;
  },
  getJobReportCount(state: JobReportState): number {
    return state.count;
  }
};
