import { Calendar, CalendarEvent, CalendarOccurrence } from '@/models/calendar';
import { CalendarService } from '@/services/calendar';
import { errorCatch } from '../utils';
// import dateFormat from '@/utils/dateFormat';
import { isArray } from '@/utils/helpers';

// test
import Vue from 'vue';
import {
  getDefaultCalendarObject,
  Calendar as CalendarDTO,
  mapCollectionToCalendar
} from '@/models/calendar';
import moment from 'moment';
import { EmployeeWorkTime } from '@/models/employeeWorkTime';
import { JobStateType } from '@/models/job/job';
import { IJobData } from '@/models/job/index';

const calendarService = new CalendarService();

export const state = {
  calendarOptions: {},
  calendars: [] as Calendar[],
  calendarIds: [] as number[],
  events: [] as CalendarEvent[],
  occurrences: [] as CalendarOccurrence[],
  currentOccurrence: {} as CalendarOccurrence,
  selectedCalendars: [],
  loading: false,
  error: {},

  start: moment().format('YYYY-MM-DD'),
  end: moment().add(1, 'week').format('YYYY-MM-DD'),
  calendarsById: {},
  initialCalendarsLoaded: false,

  lastPromise: null as null | Promise<any>
};

export type ICalendarState = typeof state;

export const mutations = {
  // new test implementation
  updateStart(state: any, payload: any) {
    state.start = payload;
  },

  updateEnd(state: any, payload: any) {
    state.end = payload;
  },

  updateTimes(state: any, { start, end }: { start: any; end: any }) {
    state.start = start;
    state.end = end;
  },

  addCalendar(state: any, { calendar }: any) {
    const object = getDefaultCalendarObject(calendar);

    state.calendars.push(object);
    Vue.set(state.calendarsById, object.id, object);
  },
  toggleCalendarEnabled(state: any, { calendar }: any) {
    // state.calendarsById[calendar.id].enabled = calendar.enabled;
    state.calendarsById[calendar.id].enabled =
      !state.calendarsById[calendar.id].enabled;
  },
  /**
   * Marks initial loading of calendars as complete
   *
   * @param {Store} state the store data
   */
  initialCalendarsLoaded(state: any) {
    state.initialCalendarsLoaded = true;
  },

  markCalendarAsLoading(state: any, { calendar }: any) {
    state.calendarsById[calendar.id].loading = true;
  },

  markCalendarAsNotLoading(state: any, { calendar }: any) {
    state.calendarsById[calendar.id].loading = false;
  },
  // new test implementation

  // Calendar mutations
  SET_CALENDAR_IDS: (state: any, payload: any) => {
    state.calendarIds = payload;
  },
  SET_SELECTED_CALENDAR: (state: any, payload: any) => {
    state.selectedCalendars = payload;
  },
  ADD_CALENDAR(state: any, payload: Calendar) {
    state.calendars.push(payload);
  },

  SET_CALENDAR(state: any, payload: Calendar[]) {
    state.calendars = payload;
  },
  SET_OPTIONS_CALENDAR(state: any, payload: object) {
    state.calendarOptions = payload;
  },

  UPDATE_CALENDAR(state: any, payload: Calendar) {
    const index = state.calendars.findIndex(
      (calendar: Calendar) => calendar.id === payload.id
    );
    state.calendars.splice(index, 1, payload);
  },

  REMOVE_CALENDAR(state: any, payload: number) {
    const index = state.calendars.findIndex(
      (calendar: Calendar) => calendar.id === payload
    );
    state.calendars.splice(index, 1);
  },

  // Calendar Category mutations
  ADD_CALENDAR_EVENT(state: any, payload: CalendarEvent) {
    state.events.push(payload);
  },

  SET_CALENDAR_EVENTS(state: any, payload: CalendarEvent[]) {
    state.events = payload;
  },

  UPDATE_CALENDAR_EVENT(state: any, payload: CalendarEvent) {
    const index = state.events.findIndex(
      (category: CalendarEvent) => category.id === payload.id
    );
    state.events.splice(index, 1, payload);
  },

  REMOVE_CALENDAR_EVENT(state: any, payload: number) {
    const index = state.events.findIndex(
      (category: CalendarEvent) => category.id === payload
    );
    state.events.splice(index, 1);
  },

  // occurrences
  ADD_CALENDAR_OCCURRENCE(state: any, payload: CalendarOccurrence) {
    state.occurrences.push(payload);
  },
  SET_CALENDAR_OCCURRENCES(state: any, payload: CalendarOccurrence[]) {
    state.occurrences = payload;
  },
  SET_CALENDAR_OCCURRENCE(state: any, payload: CalendarOccurrence) {
    state.currentOccurrence = payload;
  },

  UPDATE_CALENDAR_OCCURRENCE(state: any, payload: CalendarOccurrence) {
    const index = state.occurrences.findIndex(
      (occurrence: CalendarOccurrence) =>
        occurrence.original_start === payload.original_start
    );
    state.occurrences.splice(index, 1, payload);
  },
  REMOVE_CALENDAR_OCCURRENCE(state: any, payload: CalendarOccurrence) {
    const index = state.occurrences.findIndex(
      (occurrence: CalendarOccurrence) =>
        occurrence.original_start === payload.original_start
    );
    state.occurrences.splice(index, 1);
  },

  SET_LOADING(state: any, payload: boolean) {
    state.loading = payload;
  },

  SET_ERROR(state: any, error: any) {
    errorCatch(state, error);
  },

  CLEAR_ERROR(state: any) {
    state.error = {};
  },

  SET_LAST_PROMISE(state: ICalendarState, payload: Promise<any>) {
    state.lastPromise = payload;
  }
};

export const actions = {
  clearError: ({ commit }: any) => commit('CLEAR_ERROR'),
  // Calendar actions
  createCalendar: async ({ commit, dispatch }: any, payload: Calendar) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .createCalendar(payload)
        .then((calendar) => {
          commit('ADD_CALENDAR', calendar);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchCalendars: async ({ commit, dispatch }: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .fetchCalendars()
        .then((calendars: Calendar[]) => {
          // commit('SET_CALENDAR', calendars);
          calendars
            .map((calendar) => mapCollectionToCalendar(calendar))
            .forEach((calendar) => {
              commit('addCalendar', { calendar });
            });
          commit('initialCalendarsLoaded');

          commit(
            'SET_CALENDAR_IDS',
            calendars.map((calendar: Calendar) => calendar.id)
          );
          // resolve(calendars);
          resolve(state.calendars);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  // test
  async toggleCalendarEnabled(context: any, { calendar }: any) {
    context.commit('markCalendarAsLoading', { calendar });

    // doesn this for api call
    // calendar.enabled = !calendar.enabled;

    try {
      // await calendar.update().then(() => {
      context.commit('toggleCalendarEnabled', { calendar });
      await context.dispatch('getOccurrences');

      context.commit('markCalendarAsNotLoading', { calendar });
      // }).catch((err) => {
      //   context.commit('toggleCalendarEnabled', { calendar });
      //   context.commit('markCalendarAsNotLoading', { calendar });

      // });
    } catch (error) {
      context.commit('markCalendarAsNotLoading', { calendar });
      throw error;
    }
  },
  // test

  fetchCalendar: async ({ commit, state, dispatch }: any, payload: number) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .fetchCalendar({ calendarId: payload })
        .then((calendar) => {
          // const calendars = [...state.calendars];
          const index = state.calendars.findIndex(
            (s: Calendar) => s.id === payload
          );
          if (index === -1) {
            commit('ADD_CALENDAR', calendar);
          } else {
            commit('UPDATE_CALENDAR', calendar);
          }
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  updateCalendar: async ({ commit, dispatch }: any, payload: Calendar) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .updateCalendar(payload.id, payload)
        .then((calendar) => {
          commit('UPDATE_CALENDAR', calendar);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  deleteCalendar: async ({ commit, dispatch }: any, payload: number) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .deleteCalendar(payload)
        .then(() => {
          commit('REMOVE_CALENDAR', payload);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  optionsCalendar: async ({ commit, dispatch }: any, payload: number) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .optionsCalendar(payload)
        .then((res) => {
          commit('SET_OPTIONS_CALENDAR', res.actions.PUT);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  optionsCalendars: async ({ commit, dispatch }: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .optionsCalendars()
        .then((res) => {
          commit('SET_OPTIONS_CALENDAR', res.actions.POST);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  sendCalendarOccurrenceInvite: async (
    { commit, dispatch }: any,
    payload: any
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .sendCalendarOccurrenceInvite(
          {
            calendarId: payload.calendarId,
            eventId: payload.eventId
          },
          {
            year: payload.year,
            month: payload.month,
            day: payload.day,
            hour: payload.hour,
            minute: payload.minute,
            second: payload.second
          }
        )
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch((err) => {
          reject();
        })
        .finally(() => {});
    });
  },

  getCalendarOccurrenceInvite: async (
    { commit, dispatch }: any,
    payload: any
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .getCalendarOccurrenceInvite({
          calendarId: payload.calendarId,
          eventId: payload.eventId,
          occurrenceId: payload.occurrenceId,
          token: payload.token,
          rsvp: payload.rsvp
        })
        .then((result: Partial<CalendarOccurrence & { rsvp: number }>) => {
          resolve(result);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  },

  fetchCalendarOccurrence: async ({ commit, dispatch }: any, payload: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .fetchCalendarOccurrence(
          {
            calendarId: payload.calendarId,
            eventId: payload.eventId
          },
          {
            year: payload.year,
            month: payload.month,
            day: payload.day,
            hour: payload.hour,
            minute: payload.minute,
            second: payload.second
          }
        )
        .then((occurrence) => {
          commit('SET_CALENDAR_OCCURRENCE', occurrence);

          resolve(occurrence);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchCalendarOccurrences: async ({ commit, dispatch }: any, payload: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .fetchCalendarOccurrences(
          {
            calendarId: payload.calendarId
          },
          {
            start: payload.start,
            end: payload.end
          }
        )
        .then((occurrences) => {
          // commit('SET_CALENDAR_OCCURRENCES', occurrences);

          resolve(occurrences);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  sendCalendarEventInvite: async (
    { commit, dispatch }: any,
    payload: { calendarId: number; eventId: number; create: boolean }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    await new Promise<void>((resolve, reject) => {
      calendarService
        .sendCalendarEventInvite(
          {
            calendarId: payload.calendarId,
            eventId: payload.eventId
          },
          payload.create
        )
        .then((event) => {
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  getCalendarEventInvite: async ({ commit, dispatch }: any, payload: any) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .getCalendarEventInvite({
          calendarId: payload.calendarId,
          eventId: payload.eventId,
          token: payload.token,
          rsvp: payload.rsvp
        })
        .then((result: Partial<CalendarOccurrence & { rsvp: number }>) => {
          resolve(result);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  },

  updateCalendarOccurrence: async ({ commit, dispatch }: any, payload: any) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .updateCalendarOccurrence(
          {
            calendarId: payload.calendarId,
            eventId: payload.eventId
          },
          {
            year: payload.year,
            month: payload.month,
            day: payload.day,
            hour: payload.hour,
            minute: payload.minute,
            second: payload.second
          },
          payload.data
        )
        .then((occurrence: CalendarOccurrence | any) => {
          const index = state.occurrences.findIndex(
            (s: CalendarOccurrence) =>
              s.original_start === payload.data.original_start
          );
          if (index === -1) {
            commit('ADD_CALENDAR_OCCURRENCE', occurrence);
          } else {
            commit('UPDATE_CALENDAR_OCCURRENCE', occurrence);
          }

          resolve(occurrence);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  deleteCalendarOccurrence: async ({ commit, dispatch }: any, payload: any) => {
    dispatch('clearError');

    return await new Promise((resolve, reject) => {
      calendarService
        .deleteCalendarOccurrence(
          {
            calendarId: payload.calendarId,
            eventId: payload.eventId
          },
          {
            year: payload.year,
            month: payload.month,
            day: payload.day,
            hour: payload.hour,
            minute: payload.minute,
            second: payload.second
          }
        )
        .then((occurrence: CalendarOccurrence | any) => {
          commit('REMOVE_CALENDAR_OCCURRENCE', occurrence);

          resolve(occurrence);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject(err);
        });
    });
  },

  getOccurrences: async (
    { commit, dispatch, getters, state }: any,
    payload?: {
      calendarId: number | null;
      start: string;
      end: string;
    }
  ): Promise<void> => {
    const fetchEventPromise = dispatch(
      'fetchCalendarOccurrences',
      payload
        ? payload
        : {
            calendarId: null,
            start: state.start,
            end: state.end
          }
    );
    commit('SET_LAST_PROMISE', fetchEventPromise);

    fetchEventPromise.then((jobs: any[]) => {
      if (fetchEventPromise == state.lastPromise) {
        commit('SET_CALENDAR_OCCURRENCES', jobs);
        commit('SET_LAST_PROMISE', null);
      }
    });

    return await fetchEventPromise;
  },

  createCalendarEvent: async (
    { commit, dispatch }: any,
    payload: { data: CalendarEvent; originalOccurrence?: IJobData }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .createCalendarEvent({
          data: payload.data,
          originalOccurrence: payload.originalOccurrence
        })
        .then((event) => {
          commit('ADD_CALENDAR_EVENT', event);

          resolve(event);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  fetchCalendarEvents: async ({ commit, dispatch }: any, payload: number) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise<void>((resolve, reject) => {
      calendarService
        .fetchCalendarEvents(payload)
        .then((events) => {
          commit('SET_CALENDAR_EVENTS', events);
          resolve();
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject();
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchCalendarEvent: async (
    { commit, dispatch }: any,
    payload: { calendarId: number; eventId: number }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .fetchCalendarEvent(payload.calendarId, payload.eventId)
        .then((event: CalendarEvent) => {
          const index = state.events.findIndex(
            (s: CalendarEvent) => s.id === payload.eventId
          );
          if (index === -1) {
            commit('ADD_CALENDAR_EVENT', event);
          } else {
            commit('UPDATE_CALENDAR_EVENT', event);
          }
          resolve(event);
        });
    });
  },

  updateThisAndFutureOccurrences: async (_context: any, job: IJobData) =>
    calendarService.updateThisAndFutureOccurrences(job),

  deleteThisAndFutureOccurrences: async (_context: any, job: IJobData) =>
    calendarService.deleteThisAndFutureOccurrences(job),

  updateCalendarEvent: async (
    { commit, dispatch }: any,
    payload: { calendarId: number; eventId: number; data: CalendarEvent }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .updateCalendarEvent(payload.calendarId, payload.eventId, payload.data)
        .then((event: CalendarEvent) => {
          const index = state.events.findIndex(
            (s: CalendarEvent) => s.id === payload.eventId
          );

          if (index === -1) {
            commit('ADD_CALENDAR_EVENT', event);
          } else {
            commit('UPDATE_CALENDAR_EVENT', event);
          }
          resolve(event);
        })
        .catch((err) => {
          commit('SET_ERROR', err);
          reject(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  deleteCalendarEvent: async (
    { commit, dispatch }: any,
    payload: { calendarId: number; eventId: number }
  ) => {
    dispatch('clearError');
    commit('SET_LOADING', true);

    return await new Promise((resolve, reject) => {
      calendarService
        .deleteCalendarEvent(payload)
        .then((event) => {
          const index = state.events.findIndex(
            (s: CalendarEvent) => s.id === payload.eventId
          );
          if (index === -1) {
            commit('ADD_CALENDAR_EVENT', event);
          } else {
            commit('UPDATE_CALENDAR_EVENT', event);
          }
          resolve(event);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },

  fetchEmployeesWorkTime: async (
    { commit }: any,
    payload: { start: string; end: string }
  ) => {
    return await new Promise((resolve, reject) => {
      calendarService
        .fetchEmployeesWorkTime(payload.start, payload.end)
        .then((events: EmployeeWorkTime) => {
          resolve(events);
        });
    });
  }
};

export const getters = {
  getCalendarById: (state: any) => (id: number) => {
    return state.calendars.find((calendar: Calendar) => calendar.id === id);
  },

  getCalendarIds: (state: any): number[] => {
    return state.calendars.map((calendar: Calendar) => calendar.id);
  },

  getCalendarEventById: (state: any) => (id: number) => {
    return state.events.find((event: CalendarEvent) => event.id === id);
  },

  sortedCalendars: (state: any): Array<any> =>
    state.calendars.sort(
      (a: { order: number }, b: { order: number }) => a.order - b.order
    ),

  getCalendarByIdNew: (state: any) => (calendarId: any) =>
    state.calendarsById[calendarId],

  enabledCalendars(state: any, rootState: any) {
    return state.calendars.filter((calendar: CalendarDTO) => calendar.enabled);
  },

  disabledCalendars(state: any, rootState: any) {
    return state.calendars.filter((calendar: CalendarDTO) => !calendar.enabled);
  },

  getDraftEventsList(state: any): CalendarOccurrence[] {
    return state.occurrences.filter(
      (event: CalendarOccurrence) => event.state == JobStateType.DRAFT
    );
  }
};
