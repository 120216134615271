
import {
  CalendarEvent,
  CalendarOccurrence,
  IJobFieldErrorData,
  JobContactFieldError,
  JobDateFieldError,
  JobFieldError,
  RecurrenceEditType
} from '@/models/calendar';
import { RRule } from 'rrule';
import { cloneDeep, isEqual } from 'lodash';
import { FormRules } from '@/mixins/FormRules';
import { deepCopy } from '@/utils/deepCopy';
import { setPartsToUTCDate } from '@/utils/dateHelpers';

import moment from 'moment-timezone';

import mixins from '@/utils/mixins';
import vcalendar from '@/mixins/calendar';
import EditorMixin from './new/EditorMixin';
import { ErrorManager } from '@/models/error';

import auth from '@/mixins/auth';
import Vue from 'vue';
import { Attendee, Job } from '@/models/job';
import { IJobContactData, IJobData } from '@/models/job/index';
import { Contact, IContact } from '@/models';

// components
import RecurrenceSaveDialog, {
  RecurrenceSaveOptions
} from './new/RecurrenceSaveDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import { accountsProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { CalendarType, IJobContact } from '@/models/job/index';
import CalendarContactSection from './new/CalendarContactSection.vue';
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import ChoiceDialog from '@/components/core/ChoiceDialog.vue';
import ConfirmDialog from '@/components/core/ConfirmDialog.vue';
import { deepEqual } from '@/utils/helpers';

const baseMixins = mixins(EditorMixin, auth, vcalendar);

interface Options extends InstanceType<typeof baseMixins> {
  $refs: {
    files: HTMLInputElement;
    choiceDialog: InstanceType<typeof ChoiceDialog>;
    confirmDialog: InstanceType<typeof ConfirmDialog>;
  };
}

export default baseMixins.extend<Options>({ functional: false }).extend({
  mixins: [FormRules],

  components: {
    RecurrenceSaveDialog,
    CalendarContactSection,
    ErrorAlert,
    ChoiceDialog,
    ConfirmDialog
  },

  data() {
    return {
      CalendarType,
      tab: 0,
      validJobTimeForm: false,
      validJobDetailForm: false,
      titleValid: false,
      errorMessage: '',
      timelinePayload: { start: '' as string, end: '' as string },
      timerules: {
        required: (value: string) => {
          const pattern = /^((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))$/;
          if (value) return pattern.test(value) || 'am or pm is required';
          if (value == '') return 'This is required';
        }
      },

      /**
       * Difference betwwen start and end to set the end date value by
       */
      timeDelta: 0,
      isCreateCRMContact: false
    };
  },

  computed: {
    ...mapGetters({
      getContacts: 'contactV2/getContacts',
      getContactByEmail: 'contactV2/getContactByEmail'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    accountsProvider(): any {
      return accountsProvider(this.$store);
    },

    JobContactFieldError: () => JobContactFieldError,

    /**
     * Disable or enable button based on form validations
     *
     * @returns {boolean}
     */
    btnDisable(): boolean {
      return (
        this.validJobTimeForm && this.titleValid && this.validJobDetailForm
      );
    },

    /**
     * Returns the events title or an empty string if the event is still loading
     *
     * @returns {string}
     */
    title(): string | null {
      return this.calendarEventInstance?.title || '';
    },
    /**
     * Returns the location or null if the event is still loading
     *
     * @returns {string|null}
     */
    location(): string | null {
      return this.calendarEventInstance?.location || null;
    },
    /**
     * Returns the description or null if the event is still loading
     *
     * @returns {string|null}
     */
    description(): string | null {
      return this.calendarEventInstance?.description || null;
    },
    /**
     * Returns the end-date (without timezone) or null if the event is still loading
     *
     * @returns {Date|null}
     */
    endDate(): any {
      return this.calendarEventInstance?.date?.end || null;
    },

    /**
     * Formatted detail rules
     *
     */
    formatedRule(): string[] | null {
      return (this as any).details.recurrence.rules.length
        ? (this as any).details.recurrence.rules
            .map((rule: string) => RRule.fromString(rule).toText())
            .join(', ')
        : null;
    },

    /**
     * End details validator
     */
    startEndValidator(): boolean {
      if (this.details?.date) {
        return this.details?.date.end! < this.details?.date.start!;
      } else {
        return false;
      }
    },

    /**
     * Returns the timezone of the event's date or null if the event is still loading
     *
     * @returns {string|null}
     */
    timezone(): string | null {
      return this.calendarEventInstance?.date?.timezone || null;
    },
    /**
     * Returns the custom color of this event
     *
     * @returns {null|String}
     */
    color(): string | null {
      return this.calendarEventInstance?.color || null;
    },
    /**
     * Returns whether or not to display save buttons
     *
     * @returns {boolean}
     */
    showSaveButtons(): boolean {
      return this.isReadOnly === false;
    },
    /**
     * Returns whether or not to allow editing the event
     *
     * @returns {boolean}
     */
    isReadOnly(): boolean {
      if (!this.calendarEvent) {
        return false;
      }

      // const calendar = this.getCalendarById(this.calendarEvent.calendar);
      // if (!calendar) {
      //   return true;
      // }
      return false;
      // return calendar.readOnly;
    },

    calendarId(): number | null {
      return parseInt(this.$route.params.calendarId, 10);
    },
    eventId(): number | null {
      return parseInt(this.$route.params.eventId, 10);
    },
    recurrenceId(): number | null {
      return parseInt(this.$route.params.recurrenceId, 10);
    },

    canUpdateEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },
    canCreateEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },
    canDeleteEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },

    /**
     * Returns whether or not to disable the recurrence option
     *
     * @returns {boolean} boolean
     */
    canEditRecurrence(): boolean {
      return moment(this.details.date.start).utc(false) < moment().utc(true);
    },

    isEdit(): boolean {
      return this.$route.name !== 'SchedulerNewJobView';
    },

    isCrmSupported(): boolean {
      return this.subscription.contract?.is_crm_supported ?? false;
    }
  },

  watch: {
    allDay(val: boolean) {
      this.toggleAllDayEvent(val);
    },

    'details.date.end'() {
      this.updateTimeDelta();
    },

    'details.date.start'(value) {
      // set end date to be the newly calculated timeDelta
      this.details.date.end = new Date(value.valueOf() + this.timeDelta);
    }
  },

  beforeDestroy() {
    // reset details value to default value when leaving this component
    this.resetState();
  },

  async mounted() {
    const attendeeID = Number(this.$route.query.attendee);

    if (!isNaN(attendeeID)) {
      const attendee = await this.tryGetOrFetchAttendee(attendeeID);
      this.calendarEventInstance?.participants.push({ attendee: attendee });
    }
  },

  methods: {
    ...mapActions('snackbar', [
      'snackWarning',
      'snackMessage',
      'createSnack',
      'snackSuccess',
      'snackError'
    ]),

    ...mapActions({
      createContact: 'contactV2/createContact'
    }),

    updateTimeDelta() {
      this.timeDelta = this.details.date.end - this.details.date.start;
    },

    async tryGetOrFetchAttendee(attendee: number): Promise<Attendee> {
      if (!this.accountsProvider.getAccountById(attendee)) {
        await this.accountsProvider.fetchAccount(attendee);
      }
      return this.accountsProvider.getAccountById(attendee);
    },

    /**
     * Toggles all day event
     */
    toggleAllDayEvent(val: boolean) {
      if (val) {
        this.details.date = {
          start: moment(this.details?.date?.start)
            .utc(false)
            .startOf('day')
            .toDate(),

          end: moment(this.details?.date?.end).utc(false).endOf('day').toDate()
        };
      }
    },

    /**
     * Opens choice dialog to select
     * @returns Promose<boolean>
     */
    async openChoiceDialog(
      title?: string,
      buttonOptions?: {
        left?: {
          text?: string;
        };
        right?: {
          color?: string;
          text?: string;
          icon?: string;
          iconOnly?: boolean;
        };
      }
    ) {
      return await this.$refs.choiceDialog.open(title, buttonOptions)!;
    },

    /**
     * Opens confirm dialog to select
     * @returns Promose<boolean>
     */
    async openConfirmDialog(
      title?: string,
      buttonOptions?: {
        left?: {
          text: string;
        };
        right?: {
          color?: string;
          text?: string;
          icon?: string;
          iconOnly?: boolean;
        };
      }
    ) {
      return await this.$refs.confirmDialog.open(title, buttonOptions);
    },

    /**
     * Opens event recurrence save dialog to select
     * @returns Promose<boolean | Job>
     */
    async openRecurrenceSave(options: RecurrenceSaveOptions) {
      const recurrenceSaveDialog = this.$refs
        .recurrenceSaveDialog as InstanceType<typeof RecurrenceSaveDialog>;
      return await recurrenceSaveDialog.open(options);
    },

    async deleteOcc(ev: MouseEvent, title = 'Delete Recurring Event') {
      await this.openRecurrenceSave({
        occurrence: this.details,
        originalOccurrence: this.constantDetails,
        isDelete: true
      }).then((result) => {
        if (result === true) {
          this.closeEditor();
        }
      });
    },
    // api calls
    updateRule() {
      try {
        if (!this.details.recurrence.rules.length) {
          // if recurrence is not a list then revert event recurrence object
          // back to what it was
          this.details.recurrence = cloneDeep(this.constantDetails.recurrence);
          return;
        }

        const rules = (this.details.recurrence.rules as string[]).join('\n');

        const ruleFromDragEvent = RRule.fromString(rules);

        //* Only update the time and not the day
        //* setUTCHours mutates the dtStart value
        //* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setUTCHours

        //! if not editiing event then set the date as well
        if (!this.isEdit) {
          ruleFromDragEvent.origOptions.dtstart = this.details.date.start;
        } else {
          ruleFromDragEvent.origOptions.dtstart!.setUTCHours(
            this.details.date.start.getUTCHours(),
            this.details.date.start.getUTCMinutes()
          );
        }
        const ruleString = RRule.optionsToString(ruleFromDragEvent.origOptions);

        // split string by rrule option in string
        // and use that value
        // const [DSTART, RULES] = ruleString.split(/^RRULE:/gim);
        this.details.recurrence = {
          enabled: true,
          rules: [ruleString]
        };
      } catch (e) {
        this.details.recurrence = cloneDeep(this.constantDetails.recurrence);
      }
    },

    /**
     * Saves a calendar-object
     *
     * @returns {Promise<void>}
     */
    async save() {
      if (!this.details) {
        return;
      }

      if (isEqual(this.details, this.constantDetails)) {
        return this.closeEditor();
      }

      if (this.isReadOnly) {
        return;
      }

      this.clearAllErrors();

      switch (this.$route.name) {
        case 'SchedulerNewJobView': {
          this.isLoading = true;

          await this.createEvent()
            .catch((err) => {
              this.errorMessage = ErrorManager.extractApiError(err);
              this.requiresActionOnRouteLeave = true;
            })
            .finally(() => (this.isLoading = false));

          return 1;
        }

        case 'SchedulerEditJobView': {
          await this.openRecurrenceSave({
            occurrence: this.details,
            originalOccurrence: this.constantDetails,
            isDelete: false
          }).catch(() => {
            this.details = this.constantDetails;
          });
        }
      }
    },

    // api calls

    async createEvent() {
      this.updateRule();
      this.requiresActionOnRouteLeave = false;
      const details = this.details;

      for (let i = details.checklist.items.length; i--; ) {
        if (details.checklist.items[i].item_text === '') {
          details.checklist.items.splice(i, 1);
        }
      }
      const eventDetails = this.parseEvent(
        Object.assign({}, details),
        this.detailsOptions
      );
      this.createCalendarEvent({
        event: eventDetails
      })
        .then(async () => {
          const jobContact = (eventDetails as IJobData).contact;
          if (this.isCreateCRMContact && this.isCrmSupported && jobContact) {
            const contact = new Contact({
              ...jobContact,
              name: {
                first: jobContact.first_name!,
                last: jobContact.last_name!
              }
            });

            await this.createContact(contact);
          }

          this.closeEditor();
        })
        .catch((error: any) => {
          if (error.response) {
            this.setIfJobFieldError(error.response.data);
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    async sendEventInvite(result: Job, create = true) {
      this.requiresActionOnRouteLeave = false;
      if (
        await this.openChoiceDialog('Send schedule to employees?', {
          left: { text: 'Dont Send' },
          right: { text: 'Send', color: 'primary' }
        })
      ) {
        this.sendCalendarEventInvite({
          calendarId: result.calendar,
          eventId: result.id,
          start: result.date?.start,
          end: result.date?.end,
          create: create
        })
          .then(() => {
            this.snackMessage({
              msg: `Sent invite to employees`,
              timeout: 6 * 1000
            });
          })
          .catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          });
      }
      this.closeEditor();
    },

    async sendOccurrenceInvite(result: Job) {
      this.requiresActionOnRouteLeave = false;

      if (
        await this.openChoiceDialog('Send schedule to employees?', {
          left: { text: 'Dont Send' },
          right: { text: 'Send', color: 'primary' }
        })
      ) {
        const recurrenceId = new Date(result.original_start!).getTime();
        this.sendCalendarOccurrenceInvite({
          calendarId: result.calendar,
          eventId: result.event,
          recurrenceId
        })
          .then(() => {
            this.snackMessage({
              msg: `Sent invite to employees`,
              timeout: 6 * 1000
            });
          })
          .catch((error) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          });
      }
      this.closeEditor();
    },

    setIfJobFieldError(data: IJobFieldErrorData) {
      if (JobFieldError.isJobFieldError(data)) {
        this.fieldError = new JobFieldError(data);
      }
      if (data.contact) {
        // set the tab display to the Job Contact section
        this.tab = 1;
      }
    },

    /**
     * Updates the title of this event
     * and clears any errors
     */
    inputTitle() {
      if (this.fieldError.title.length) {
        this.fieldError.title.splice(0, this.fieldError.title.length);
      }
    },
    /**
     * Updates the location of this event
     * and clears any errors
     */
    inputLocation() {
      if (this.fieldError.location.length) {
        this.fieldError.location.splice(0, this.fieldError.location.length);
      }
    },
    /**
     * Updates the description of this event
     * and clears any errors
     */
    inputDescription() {
      if (this.fieldError.description.length) {
        this.fieldError.description.splice(
          0,
          this.fieldError.description.length
        );
      }
    },
    /**
     * Updates the date of this event
     * and clears any errors
     */
    inputDate() {
      this.fieldError.date.clearErrors();
    },

    clearAllErrors() {
      this.errorMessage = '';
      this.fieldError.clearErrors();
    },

    /**
     * Closes the editor and returns to normal calendar-view
     */
    async closeEditor(): Promise<any> {
      const params = Object.assign({}, this.$store.state.route.params);

      delete params.eventId;
      delete params.recurrenceId;
      this.$emit('saved');
      this.resetState();

      return this.$router.push({
        name: 'CalendarView',
        params
      });
    },

    /**
     * Resets the event-object back to it's original state and closes the editor
     */
    async cancel() {
      if (this.isLoading) {
        return;
      }

      // this.requiresActionOnRouteLeave = false;
      await this.closeCheck(this.closeEditor, () => {});
      this.requiresActionOnRouteLeave = false;
    },

    async closeCheck(
      trueFn = (): void | any => {},
      falseFn = (): void | any => {}
    ) {
      if (!isEqual(this.details, this.constantDetails)) {
        if (
          await this.openConfirmDialog('Discard Changes', {
            right: { text: 'Discard' }
          })
        ) {
          return trueFn();
        } else {
          return falseFn();
        }
      } else {
        return this.closeEditor();
      }
    }
  },

  /**
   * This is executed before entering the Editor routes
   *
   * @param {Object} to The route to navigate to
   * @param {Object} from The route coming from
   * @param {Function} next Function to be called when ready to load the next view
   */
  async beforeRouteEnter(to, from, next) {
    if (to.name === 'SchedulerNewJobView') {
      next(async (vm: any) => {
        if (!vm.canCreateEvent) {
          vm.requiresActionOnRouteLeave = false;
          // await for the next frame to clear data and exit
          return Vue.nextTick(() => {
            vm.closeEditor();
          });
        }
        vm.resetState();

        const start = Number(to.params.dtstart);
        const end = Number(to.params.dtend);
        try {
          await vm.loadingCalendars();
          await vm.getCalendarEventInstanceForNewEvent({
            start,
            end
          });
          vm.constantDetails = deepCopy(
            Object.freeze(vm.calendarEventInstance)
          );

          vm.details = vm.calendarEventInstance;
          vm.details.date = {
            start: setPartsToUTCDate(new Date(start)),
            end: setPartsToUTCDate(new Date(end)),
            timezone: vm.calendarEventInstance.date.timezone
          };
          vm.isActive = true;
          vm.updateTimeDelta();
        } catch (error) {
          vm.isError = true;
          vm.error =
            'It might have been deleted, or there was a typo in a link';
          vm.requiresActionOnRouteLeave = false;
          vm.closeEditor();
        } finally {
          vm.isLoading = false;
        }
      });
    } else {
      next(async (vm: any) => {
        if (!vm.canUpdateEvent) {
          vm.requiresActionOnRouteLeave = false;
          // await for the next frame to clear data and exit
          return Vue.nextTick(() => {
            vm.closeEditor();
          });
        }

        vm.resetState();
        const calendarId = to.params.calendarId;
        const eventId = to.params.eventId;
        const recurrenceId = to.params.recurrenceId;

        try {
          await vm.loadingCalendars();
          await vm.fetchCalendarOccurrence({
            calendarId: parseInt(eventId, 10),
            eventId: parseInt(eventId, 10),
            recurrenceId: parseInt(recurrenceId, 10)
          });
          if (vm.occurrencesByCalendarId[recurrenceId]) {
            vm.constantDetails = deepCopy(
              Object.freeze(vm.occurrencesByCalendarId[recurrenceId])
            );
            vm.details = vm.occurrencesByCalendarId[recurrenceId];
            vm.updateTimeDelta();
          } else {
            throw new Error('Calendar Event Occurrence with this id not found');
          }
          vm.isActive = true;
        } catch (error) {
          vm.isError = true;
          vm.error =
            'It might have been deleted, or there was a typo in a link';
          vm.requiresActionOnRouteLeave = false;
          vm.closeEditor();
        } finally {
          vm.isLoading = false;
        }
      });
    }
  },

  /**
   * This route is called when the user leaves the editor
   *
   * @param {Object} to The route to navigate to
   * @param {Object} from The route coming from
   * @param {Function} next Function to be called when ready to load the next view
   */
  async beforeRouteLeave(to, from, next) {
    // requiresActionOnRouteLeave is false when an action like deleting / saving / cancelling was already taken.
    // The responsibility of this method is to automatically save the event when the user clicks outside the editor
    if (!this.requiresActionOnRouteLeave) {
      next();
      this.$emit('saved');
      return;
    } else {
      try {
        if (!isEqual(this.details, this.constantDetails)) {
          if (
            await this.openConfirmDialog('Discard Changes', {
              right: { text: 'Discard' }
            })
          ) {
            return next();
          } else {
            return null;
          }
        } else {
          return next();
        }
      } catch (error) {
        console.error(error);
        next(false);
      }
    }
  }
});
