
import {
  IAttendee,
  IJobData,
  IJobDragData,
  IJobOccurrenceData,
  IJobTimelineOccurrencesData,
  IParticipant,
  JobStateType
} from '@/models/job/index';
import mixins from '@/utils/mixins';
import TimelineWeekIntervalMixin from '@/mixins/timeline/TimelineWeekIntervalMixin';
import { PropType } from 'vue';
import { CalendarTimestamp } from 'vuetify';
import { convertToUnit, getNameInitials } from '@/utils/helpers';
import { genStartEndTime } from '@/utils/dateHelpers';
import VNodes from './VNodes.vue';
import { CalendarOccurrence, TimelineTimestamp } from '@/models/calendar';
import moment from 'moment';
import { JobDate } from '@/models';
import { cloneDeep } from 'lodash';

const TOTAL_MINUTES_IN_DAY = 1440;
const DEFAULT_YAXIS_POS = 52;

export default mixins(TimelineWeekIntervalMixin).extend({
  name: 'TimelineWeek',

  components: { VNodes },

  props: {
    canCreateEvent: {
      type: Boolean
    },
    canUpdateEvent: {
      type: Boolean,
      default: () => false
    },
    timeline: {
      type: Array as PropType<IJobTimelineOccurrencesData[]>,
      default: () => []
    }
  },

  data() {
    return {
      timelineLoaded: false,
      height: 60,
      selectedDragEvent: null as null | IJobDragData
    };
  },

  computed: {
    JobStateType: () => JobStateType
  },

  watch: {
    focus: {
      handler(value) {
        if (value) {
          this.timelineDays = this.genCalendarTimestamp(value);
          this.genTimelineJobOccurrennces();
          this.updateCalendarTimestamp(this.timelineDays[0]);
        }
      }
    },
    calendarWeekdays: {
      handler(value) {
        this.timelineDays = this.genCalendarTimestamp(this.focus as string);
        this.updateCalendarTimestamp(this.timelineDays[0]);
      },
      deep: true,
      immediate: true
    }
  },

  async mounted() {
    this.$nextTick().then(() => {
      this.onResize();
      this.timelineLoaded = true;
      this.genTimelineJobOccurrennces();
      this.updateCalendarTimestamp(this.timelineDays[0]);
    });
  },

  methods: {
    getNameInitials,
    genStartEndTime,
    unit: convertToUnit,

    prev() {
      this.timelineDays[0].date = moment(this.timelineDays[0].date)
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      this.setFocus(this.timelineDays[0].date);
    },

    next() {
      this.timelineDays[0].date = moment(this.timelineDays[0].date)
        .add(1, 'week')
        .format('YYYY-MM-DD');

      this.setFocus(this.timelineDays[0].date);
    },

    genTimeScheduleText(time_scheduled_in_seconds: number): string {
      const hours = Math.floor(
        moment.duration(time_scheduled_in_seconds, 'seconds').asHours()
      );
      const minutes = moment
        .duration(time_scheduled_in_seconds, 'seconds')
        .minutes();

      const minuteStr = minutes ? `${minutes}m` : '';

      return `${hours}h ` + minuteStr;
    },

    jobEndNextDay(job: IJobOccurrenceData): boolean {
      const jobStartTime = moment(new Date(job.date!.start!)).utc();
      const startOfDay = moment(jobStartTime).startOf('day').utc(true);
      const jobEndTime = moment(new Date(job.date!.end!)).utc();

      return (
        moment.duration(jobEndTime.diff(startOfDay)).asMinutes() >
        TOTAL_MINUTES_IN_DAY
      );
    },

    genHoverStyle(jobs: IJobOccurrenceData[], date: string) {
      const jobListLength = this.getDayJobs(jobs, date).length;
      const topPosition = this.height * jobListLength;

      return {
        position: 'absolute',

        width: '90%',
        'border-radius': '4px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        left: 0,
        right: 0,
        top: this.unit(topPosition + this.height - DEFAULT_YAXIS_POS),
        height: this.unit(DEFAULT_YAXIS_POS)
      };
    },

    genCalendarTimestamp(timestamp: string): TimelineTimestamp[] {
      const timestampList = [] as CalendarTimestamp[];
      for (let i = 0; this.calendarWeekdays.length > i; i++) {
        const timestampDate = moment(timestamp).day(this.calendarWeekdays[i]);
        const timestampObj = {
          date: timestampDate.format('YYYY-MM-DD'),
          day: timestampDate.date(),
          month: timestampDate.month() + 1,
          year: timestampDate.year(),
          present:
            moment().format('YYYY-MM-DD') == timestampDate.format('YYYY-MM-DD'),
          time: '',
          weekday: timestampDate.weekday(),
          hour: timestampDate.hour(),
          minute: timestampDate.minute(),
          hasDay: true,
          hasTime: false,
          past: moment().isAfter(timestampDate),
          future: moment().isBefore(timestampDate),
          showtimelineWeekDate: false
        } as TimelineTimestamp;

        timestampList.push(timestampObj);
      }
      return timestampList;
    },

    onResize() {
      this.scrollPush = this.getScrollPush();
    },

    timelineHeightInterval(jobs: IJobOccurrenceData[]): number {
      let maxLength = 0;

      for (let i = 0; i < jobs.length; i++) {
        let dateStr: string;

        if (typeof jobs[i].date.start == 'undefined') {
          console.error('job start is undefined');
        } else {
          dateStr = moment(jobs[i].date.start).utc(false).format('YYYY-MM-DD');
        }

        const jobCount = jobs.filter((job) =>
          job.date.start.includes(dateStr)
        ).length;
        if (jobCount > maxLength) {
          maxLength = jobCount;
        }
      }
      const differenceInTopAndHeight =
        this.height + (this.height - DEFAULT_YAXIS_POS) / 2;
      return maxLength <= 1
        ? differenceInTopAndHeight * 2
        : this.height * maxLength + differenceInTopAndHeight;
    },

    getScrollPush(): number {
      const area = this.$refs.scrollArea as HTMLElement;
      const pane = this.$refs.pane as HTMLElement;

      return area && pane ? area.offsetHeight - pane.offsetHeight : 0;
    },

    genTimedEvent(job: IJobData, index: number): object {
      const jobOnYAxis = index * this.height;

      const jobStyle =
        job.state == JobStateType.DRAFT
          ? {
              top: this.unit(jobOnYAxis + this.height - DEFAULT_YAXIS_POS),
              width: '90%',
              'border-radius': '4px',
              'margin-left': 'auto',
              'margin-right': 'auto',
              left: 0,
              right: 0,
              background: 'white',
              'border-color': job.color,
              'border-style': 'dashed',
              'border-width': '1px',
              height: this.unit(DEFAULT_YAXIS_POS)
            }
          : {
              top: this.unit(jobOnYAxis + this.height - DEFAULT_YAXIS_POS),
              width: '90%',
              'border-radius': '4px',
              'margin-left': 'auto',
              'margin-right': 'auto',
              left: 0,
              right: 0,
              background: job.color,
              height: this.unit(DEFAULT_YAXIS_POS)
            };

      return jobStyle;
    },

    dragOver(attendee: IAttendee, date: string) {
      this.selectedDragEvent!.attendee = attendee;
      this.selectedDragEvent!.date = date;
    },

    endDrag() {
      const dragEventDate = (date: string) => {
        const delta = moment(this.selectedDragEvent?.job.date.end).diff(
          this.selectedDragEvent?.job.date.start,
          'minutes'
        );

        const eventStartDate = this.selectedDragEvent?.job.date.start.replace(
          this.selectedDragEvent?.job.date.start.slice(0, 10),
          date
        );

        const eventEndDate = moment(eventStartDate)
          .add(delta, 'minutes')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');

        return {
          start: eventStartDate!,
          end: eventEndDate,
          timezone: this.selectedDragEvent?.job.date.timezone!
        };
      };

      const originalDragEvent = cloneDeep(this.selectedDragEvent);

      const openDialog = !(
        this.selectedDragEvent?.job.participants.some(
          (participant: IParticipant) =>
            participant.attendee?.id === this.selectedDragEvent?.attendee.id
        ) &&
        this.selectedDragEvent.job.date.start.includes(
          this.selectedDragEvent.date
        )
      );

      this.selectedDragEvent!.job.date = dragEventDate(
        this.selectedDragEvent?.date!
      );

      if (openDialog) {
        this.$emit(
          'openTimelineOptionsDialog',
          this.selectedDragEvent,
          originalDragEvent
        );
      }
      this.selectedDragEvent = null;
    },

    startDrag(job: IJobDragData) {
      this.selectedDragEvent = cloneDeep(job);
    },

    getDayJobs(jobs: IJobOccurrenceData[], date: string): IJobOccurrenceData[] {
      const jobDayList = jobs.filter((job) =>
        (job.date?.start! as string).includes(date)
      );
      return jobDayList;
    },

    async sendEventInvite(job: IJobData, create: boolean) {
      this.$emit('send-invite:event', job, create);
    },

    async sendOccurrenceInvite(job: IJobData) {
      this.$emit('send-invite:occurrence', job);
    },

    genTimelineJobOccurrennces() {
      this.$emit('genJobOccurrences');
    },

    openCreateEventDialog(attendee: IAttendee, date: string) {
      const jobDate = new JobDate();

      //adds current time to focus date
      const utcdateTimeSting = date + 'T' + moment().format('HH:mm:ss');

      // the converts to utc before emitting because the 'add' method also does a utc conversion
      // this would cause the time to be off by the timezone offset amount
      jobDate.start = moment(utcdateTimeSting).utc(true).toDate();

      this.$emit('add', { attendee: attendee, date: jobDate });
    },

    async edit(occurrence: IJobTimelineOccurrencesData) {
      this.$emit('edit', occurrence);
    },

    async deleteEventDialog(occurrence: IJobTimelineOccurrencesData) {
      this.$emit('delete', occurrence);
    }
  }
});
