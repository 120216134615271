
import { clone } from 'lodash';
import { mapActions } from 'vuex';
import Vue from 'vue';
import { MovingInventory } from '@/models/movingInventory';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  data() {
    return {
      date: new Date(),
      movingInventory: new MovingInventory(),

      dateDialog: false,

      timesheetType: '' as string,
      errorMessage: '',
      fieldError: []
    };
  },

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      updateMovingInventory: 'movingInventory/updateMovingInventory'
    }),

    open(movingInventory: MovingInventory, timesheet: string) {
      this.clearErrors();
      this.movingInventory = clone(movingInventory);
      this.dateDialog = true;
      if (timesheet == 'delivery') {
        this.date =
          this.movingInventory.delivered_time !== null
            ? new Date(this.movingInventory.delivered_time!)
            : this.convertUTCDateToLocalDate(new Date());
      }
      if (timesheet == 'receive') {
        this.date =
          this.movingInventory.received_time !== null
            ? new Date(movingInventory.received_time!)
            : this.convertUTCDateToLocalDate(new Date());
      }
      this.timesheetType = clone(timesheet);
    },

    close() {
      this.clearErrors();
      this.dateDialog = false;
    },

    addTimestamp() {
      if (this.timesheetType == 'delivery') {
        this.movingInventory.delivered_time = this.date;
      }
      if (this.timesheetType == 'receive') {
        this.movingInventory.received_time = this.date;
      }

      delete this.movingInventory['customer'];
      delete this.movingInventory['vendor_shipped'];
      delete this.movingInventory['vendor_received'];
      delete this.movingInventory['job'];

      this.updateMovingInventory(this.movingInventory)
        .then(() => this.close())
        .catch((error: any) => {
          if (error.response.data.non_field_errors) {
            this.fieldError = error.response.data.non_field_errors;
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    clearErrors(): void {
      this.fieldError = [];
      this.errorMessage = '';
    },

    convertUTCDateToLocalDate(date: Date) {
      const _date = new Date(date);
      const newDate = new Date(
        _date.getTime() - _date.getTimezoneOffset() * 60 * 1000
      );
      return newDate;
    }
  }
});
