import { axios } from '@/plugins/axios';
import { MovingInventory } from '@/models/movingInventory';

export class MovingInventoryService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'v1/moving-inventory-management/moving-inventories/';
  }

  async createMovingInventory(movingInventory: MovingInventory) {
    return axios
      .post<MovingInventory>(`${this.endpoint}`, movingInventory)
      .then(({ data }) => data);
  }

  async fetchMovingInventories() {
    return axios
      .get<MovingInventory[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  async fetchMovingInventory(id: number) {
    return axios
      .get<MovingInventory>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  async updateMovingInventory(movingInventory: MovingInventory) {
    return axios
      .patch<MovingInventory>(
        `${this.endpoint}${movingInventory.id}/`,
        movingInventory
      )
      .then(({ data }) => data);
  }

  async deleteMovingInventory(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }

  async fetchSharedMovingInventory(id: number) {
    return axios
      .get(`${this.endpoint}${id}/shared/shared-item/`, {
        headers: {
          Authorization: null
        }
      })
      .then(({ data }) => data);
  }
}
