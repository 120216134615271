
import { BreakTime } from '@/models/timesheet';
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
  props: ['timesheet', 'breakTimeFormList'],
  data() {
    return {
      showbreakform: false,
      dialog: false,
      loading: false,
      breakTimeList: [] as BreakTime[],
      punchInErrorMessage: '',
      punchOutErrorMessage: '',
      valid: false
    };
  },

  computed: {
    punchOutErrorOccurred(): boolean {
      return this.punchOutErrorMessage.length > 1;
    },
    punchInErrorOccurred(): boolean {
      return this.punchInErrorMessage.length > 1;
    }
  },

  watch: {
    breakTimeFormList: {
      handler(value) {
        this.breakTimeList = value;
      },
      deep: true,
      immediate: true
    },

    valid: {
      handler(value) {
        this.$emit('update:valid', value);
      },
      immediate: true
    },

    /**
     * updates timesheet punchin time with data field
     *
     * @param val get local date job object
     *
     *
     */
    'timesheet.punch_in': {
      handler(value) {
        this.timesheet.punch_in = value;
        this.punchInPunchOutValidate();
      }
    },

    /**
     * updates timesheet punchout time with data field
     *
     * @param val get local date job object
     *
     *
     */
    'timesheet.punch_out': {
      handler(value) {
        this.timesheet.punch_out = value;
        this.punchInPunchOutValidate();
      }
    }
  },

  methods: {
    setBreakTime() {
      this.showbreakform = !this.showbreakform;
      if (!this.showbreakform) {
        this.$emit('update:breakTimeFormList', []);
      }
    },

    addBreakTime() {
      const breakInstance = new BreakTime();

      breakInstance.break_start = this.timesheet.punch_in;
      breakInstance.break_end = this.timesheet.punch_out;

      this.breakTimeFormList.push(breakInstance);
    },

    removeBreakTime(index: number) {
      this.breakTimeFormList.splice(index, 1);
    },

    breakStartComparisonRule(break_start: Date): string[] {
      const errors = [];
      if (moment(break_start).isBefore(this.timesheet.punch_in)) {
        errors.push('Break start cannot be before punch in');
      }
      if (moment(break_start).isAfter(this.timesheet.punch_out)) {
        errors.push('Break start cannot after punch out');
      }
      return errors;
    },

    breakEndComparisonRule(break_start: Date, break_end: Date): string[] {
      // break start cannot be before the occurrence_date
      // nor after the timesheet punch out
      const errors = [];
      if (moment(break_end).isBefore(break_start)) {
        errors.push('Break end cannot be before break start');
      }
      if (moment(break_end).isAfter(this.timesheet.punch_out)) {
        errors.push('Break end cannot be after punch out');
      }
      return errors;
    },

    punchInPunchOutValidate() {
      if (
        moment(this.timesheet.punch_in).isAfter(moment(new Date()).utc(true))
      ) {
        this.punchInErrorMessage = 'Punch in cannot be in the future';
        // return is used to exit once we get an error.
        return;
      } else {
        this.punchInErrorMessage = '';
      }

      if (
        moment(this.timesheet.punch_out).isBefore(
          new Date(this.timesheet.punch_in)
        )
      ) {
        this.punchOutErrorMessage = 'Punch out cannot be before punch in';

        return;
      } else {
        this.punchOutErrorMessage = '';
      }
    }
  }
});
