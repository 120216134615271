import {
  IContact,
  IContactName,
  IContactData,
  IContactFieldError,
  IContactFieldErrorData,
  IContactStatus,
  IContactCompany,
  IContactNameData,
  IContactNameFieldError,
  IContactNameFieldErrorData,
  IContactStatusFieldErrorData,
  IContactCompanyFieldErrorData,
  IContactCompanyFieldError,
  IContactStatusFieldError,
  IContactStatusData,
  IContactCompanyData,
  IContactNoteFieldErrorData,
  IContactNoteFieldError,
  IContactNoteData,
  IContactNote,
  ContactStatusChoices
} from './contact.types';

export class Contact implements IContact {
  readonly id?: number;
  readonly name: IContactName = new ContactName();
  readonly avatar?: string | File | null;
  readonly title?: string;
  readonly email?: string;
  phone?: string;
  readonly website?: string;
  readonly status?: IContactStatus;
  readonly company?: IContactCompany | null;
  readonly owner?: string;
  readonly created_at?: Date | string;
  readonly updated_at?: Date | string;

  constructor(data?: IContactData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.name) {
        this.name = new ContactName(data.name);
      }
      if (data.avatar) {
        this.avatar = data.avatar;
      }
      if (data.title) {
        this.title = data.title;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.website) {
        this.website = data.website;
      }
      if (data.status) {
        this.status = new ContactStatus(data.status);
      }
      if (data.company) {
        this.company = new ContactCompany(data.company);
      }
      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.created_at) {
        this.created_at = data.created_at;
      }
      if (data.updated_at) {
        this.updated_at = data.updated_at;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class ContactStatus implements IContactStatus {
  readonly display_name?: string | undefined;
  readonly value?: number | undefined;

  constructor(data?: IContactStatusData) {
    if (data) {
      if (data.display_name) {
        this.display_name = data.display_name;
      }

      this.value = data.value ?? ContactStatusChoices.LEAD;
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}
export class ContactCompany implements IContactCompany {
  readonly id?: number | undefined;
  readonly name?: string | undefined;
  readonly logo?: string | null | undefined;

  constructor(data?: IContactCompanyData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.name) {
        this.name = data.name;
      }
      if (data.logo) {
        this.logo = data.logo;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}
export class ContactName implements IContactName {
  readonly id?: number;
  readonly first: string = '';
  readonly last: string = '';
  readonly title?: string;

  constructor(data?: IContactNameData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      this.first = data.first;
      this.last = data.last;

      if (data.title) {
        this.title = data.title;
      }
    }
  }

  get fullName(): string {
    return `${this.first || ''} ${this.last || ''}`.trim();
  }

  get initials(): string {
    return this.fullName
      .toUpperCase()
      .split(' ')
      .map((n) => n[0])
      .join('')
      .trim();
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class ContactFieldError implements IContactFieldError {
  readonly title: string[] = [];
  readonly avatar: string[] = [];
  readonly email: string[] = [];
  readonly phone: string[] = [];
  readonly website: string[] = [];
  readonly owner: string[] = [];

  readonly name: IContactNameFieldError = new ContactNameFieldError();
  readonly status: IContactStatusFieldError = new ContactStatusFieldError();
  readonly company: IContactCompanyFieldError = new ContactCompanyFieldError();

  constructor(data?: IContactFieldErrorData) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }

      if (data.avatar) {
        this.avatar = data.avatar;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.website) {
        this.website = data.website;
      }
      if (data.owner) {
        this.owner = data.owner;
      }

      if (data.name) {
        this.name = new ContactNameFieldError(data.name);
      }
      if (data.status) {
        this.status = new ContactStatusFieldError(data.status);
      }
      if (data.company) {
        this.company = new ContactCompanyFieldError(data.company);
      }
    }
  }

  clearErrors(): void {
    this.title.splice(0, this.title.length);

    this.avatar.splice(0, this.avatar.length);
    this.email.splice(0, this.email.length);
    this.phone.splice(0, this.phone.length);
    this.website.splice(0, this.website.length);
    this.owner.splice(0, this.owner.length);

    this.name.clearErrors();
    this.status.clearErrors();
    this.company.clearErrors();
  }

  static isContactFieldError(data: IContactFieldErrorData): boolean {
    if (data.title && Array.isArray(data.title)) return true;

    if (data.avatar && Array.isArray(data.avatar)) return true;
    if (data.email && Array.isArray(data.email)) return true;
    if (data.phone && Array.isArray(data.phone)) return true;
    if (data.website && Array.isArray(data.website)) return true;
    if (data.owner && Array.isArray(data.owner)) return true;

    if (data.name && Array.isArray(data.name)) return true;
    if (data.status && Array.isArray(data.status)) return true;
    if (data.company && Array.isArray(data.company)) return true;

    return false;
  }
}

export class ContactStatusFieldError implements IContactStatusFieldError {
  readonly display_name: string[] = [];
  readonly value: string[] = [];

  constructor(data?: IContactStatusFieldErrorData) {
    if (data) {
      if (data.display_name) {
        this.display_name = data.display_name;
      }
      if (data.value) {
        this.value = data.value;
      }
    }
  }

  clearErrors(): void {
    this.display_name.splice(0, this.display_name.length);
    this.value.splice(0, this.value.length);
  }

  static isContactStatusFieldError(
    data: IContactStatusFieldErrorData
  ): boolean {
    if (data.display_name && Array.isArray(data.display_name)) return true;
    if (data.value && Array.isArray(data.value)) return true;
    return false;
  }
}

export class ContactNameFieldError implements IContactNameFieldError {
  readonly first: string[] = [];
  readonly last: string[] = [];
  readonly title: string[] = [];

  constructor(data?: IContactNameFieldErrorData) {
    if (data) {
      if (data.first) {
        this.first = data.first;
      }
      if (data.last) {
        this.last = data.last;
      }
      if (data.title) {
        this.title = data.title;
      }
    }
  }

  clearErrors(): void {
    this.first.splice(0, this.first.length);
    this.last.splice(0, this.last.length);
    this.title.splice(0, this.title.length);
  }

  static isContactNameFieldError(data: IContactNameFieldErrorData): boolean {
    if (data.first && Array.isArray(data.first)) return true;
    if (data.last && Array.isArray(data.last)) return true;
    if (data.title && Array.isArray(data.title)) return true;
    return false;
  }
}

export class ContactCompanyFieldError implements IContactCompanyFieldError {
  readonly name: string[] = [];
  readonly logo: string[] = [];

  constructor(data?: IContactCompanyFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
      if (data.logo) {
        this.logo = data.logo;
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
    this.logo.splice(0, this.logo.length);
  }

  static isContactCompanyFieldError(
    data: IContactCompanyFieldErrorData
  ): boolean {
    if (data.name && Array.isArray(data.name)) return true;
    if (data.logo && Array.isArray(data.logo)) return true;
    return false;
  }
}

/** ##### Contact Note ##### **/
export class ContactNote implements IContactNote {
  readonly id?: number;

  readonly text: string = '';

  readonly contact?: number;
  readonly owner?: number;
  readonly created_at?: Date | string;
  readonly updated_at?: Date | string;

  constructor(data?: IContactNoteData) {
    if (data) {
      this.text = data.text;

      if (data.id) {
        this.id = data.id;
      }

      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.contact) {
        this.contact = data.contact;
      }
      if (data.created_at) {
        this.created_at = data.created_at;
      }
      if (data.updated_at) {
        this.updated_at = data.updated_at;
      }
    }
  }

  validate(): boolean {
    if (!this.text) {
      return false;
    }
    return true;
  }
}

export class ContactNoteFieldError implements IContactNoteFieldError {
  readonly text: string[] = [];
  readonly contact: string[] = [];

  constructor(data?: IContactNoteFieldErrorData) {
    if (data) {
      if (data.text) {
        this.text = data.text;
      }
      if (data.contact) {
        this.contact = data.contact;
      }
    }
  }

  clearErrors(): void {
    this.text.splice(0, this.text.length);
    this.contact.splice(0, this.contact.length);
  }

  static isContactNoteFieldError(data: IContactNoteFieldErrorData): boolean {
    if (data.text && Array.isArray(data.text)) {
      return true;
    } else if (data.contact && Array.isArray(data.contact)) {
      return true;
    } else {
      return false;
    }
  }
}
