
import { mapActions, mapGetters } from 'vuex';
import { BreakTime, Timesheet } from '@/models/timesheet';
import Vue from 'vue';
import { ErrorManager } from '@/models/error';
import { cloneDeep } from 'lodash';

enum Step {
  EMPLOYEE = 1,
  OCCURRENCE = 2,
  PIPO = 3
}

export default Vue.extend({
  data() {
    return {
      Step,
      value: Step.EMPLOYEE,

      //Lists
      timesheet: new Timesheet(),
      breakTimeFormList: [] as BreakTime[],

      employee: 0 as number,
      itemID: 0 as number | undefined,

      //life cycle logic
      dialog: false,
      isEdit: false,
      isOccurrenceOpen: false,

      errorMessage: '',
      timesheetValid: true
    };
  },
  computed: {
    ...mapGetters({
      getTimesheetById: 'timesheets/getTimesheetById'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      createTimesheet: 'timesheets/createTimesheet',
      updateTimesheet: 'timesheets/updateTimesheet',
      fetchTimesheet: 'timesheets/fetchTimesheet',
      createBreakTime: 'timesheets/createBreakTime',
      updateBreakTime: 'timesheets/updateBreakTime'
    }),

    /**
     * sets timesheet data
     *
     *
     */
    setTimesheetData() {
      if (!this.isEdit) {
        this.timesheet.occurrence_date = new Date(
          this.timesheet.job.original_start!
        );
      }
      const punch_in = this.isEdit
        ? this.convertUTCDateToLocalDate(this.timesheet.punch_in as Date)
        : new Date(this.timesheet.job.date!.start!);

      const punch_out = this.isEdit
        ? this.convertUTCDateToLocalDate(this.timesheet.punch_out as Date)
        : new Date(this.timesheet.job.date!.end!);

      this.timesheet.punch_in = punch_in;
      this.timesheet.punch_out = punch_out;

      this.timesheet = new Timesheet(this.timesheet);
    },

    /**
     * converts date to local time
     *
     *
     * @param date date of job object
     *
     *
     */
    convertUTCDateToLocalDate(date: Date) {
      const _date = new Date(date);
      const newDate = new Date(
        _date.getTime() - _date.getTimezoneOffset() * 60 * 1000
      );
      return newDate;
    },

    /**
     * converts date to UTC
     *
     *
     * @param date get local date job object
     *
     *
     */
    convertDateToUTC(date: Date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
    },

    changeDetector() {
      this.dialog = this.employee == 0 || this.employee == null ? false : true;
      if (!this.dialog) {
        this.close();
      }
    },

    /**
     * save timesheet
     *
     *
     */
    async save() {
      const punch_in_utc = this.convertDateToUTC(
        this.timesheet.punch_in as Date
      );
      const punch_out_utc = this.convertDateToUTC(
        this.timesheet.punch_out as Date
      );

      //timesheet to be sent to server
      const timesheetObject = {
        job: this.timesheet.job.event ?? this.timesheet.job.id,
        id: this.itemID, //<-- optional value only passed when editing timesheet
        employee: this.employee,
        punch_in: punch_in_utc,
        punch_out: punch_out_utc,
        break_times: this.breakTimeListUTCConversion(
          cloneDeep(this.breakTimeFormList)
        ),
        occurrence_date: this.timesheet.occurrence_date
      };

      if (this.isEdit) {
        this.updateTimesheet(timesheetObject)
          .then(() => this.close())
          .catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          });
      } else {
        this.createTimesheet(timesheetObject)
          .then(() => this.close())
          .catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          });
      }
    },

    breakTimeListUTCConversion(breakTime: BreakTime[]) {
      for (let i = 0; breakTime.length > i; i++) {
        const breakStartUTCConversion = (
          breakTime[i].break_start! as Date
        ).toISOString();
        const breakEndUTCConversion = (
          breakTime[i].break_end! as Date
        ).toISOString();

        breakTime[i].break_start = breakStartUTCConversion;
        breakTime[i].break_end = breakEndUTCConversion;
      }
      return breakTime;
    },

    close() {
      this.$router.back();
    },

    async tryGetOrFetchTimesheet(timesheetId: number) {
      try {
        if (this.getTimesheetById(timesheetId)) {
          return this.getTimesheetById(timesheetId);
        } else {
          return this.fetchTimesheet(timesheetId)
            .then(() => this.getTimesheetById(timesheetId))
            .catch((error: any) => {
              this.errorMessage = ErrorManager.extractApiError(error);
            });
        }
      } catch (e) {
        console.log('NO INVENTORY FOUND', e);
      }
    },
    async resetTimesheetData() {
      this.isEdit =
        this.$route.params.action == 'Edit-Timesheet' ? true : false;

      if (this.isEdit) {
        const result = await this.tryGetOrFetchTimesheet(
          Number(this.$route.params.timesheetId)
        );

        this.timesheet = result;
        this.timesheet.punch_out =
          result.punch_out == null ? result.punch_in : result.punch_out;

        this.breakTimeFormList = result.break_times.map(
          (element: BreakTime) => {
            const breakInstance = new BreakTime();
            breakInstance.id = element.id;
            breakInstance.break_start = new Date(element.break_start!);
            breakInstance.break_end = new Date(element.break_end!);
            return breakInstance;
          }
        );
        this.employee = result.employee.id;
        this.itemID = result.id;
      }
      this.isOccurrenceOpen = true;
    }
  },

  async mounted() {
    this.resetTimesheetData();
  }
});
