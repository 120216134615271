// Utilities
import { make } from 'vuex-pathify';
import { toggle, set } from '../utils';
import dateFormat from '@/utils/dateFormat';
import { CalendarTimestamp } from 'vuetify';
import { TimelineTimestamp } from '@/models/calendar';
export const state = {
  currentVersion: process.env.VUE_APP_VERSION,
  companyName: 'StaffNet',
  now: dateFormat(new Date(), 'YYYY-MM-DD', false),
  today: dateFormat(new Date(), 'YYYY-MM-DD', false),
  type: 'week',
  focus: null,
  calendarWeekdays: [0, 1, 2, 3, 4, 5, 6] as number[],
  eventDialog: false as boolean,
  calendarStart: null as null | CalendarTimestamp,
  calendarEnd: null as null | CalendarTimestamp,
  timelineDays: [] as TimelineTimestamp[]
};

export const mutations = {
  setFocus: set('focus'),
  setType: set('type'),
  setWeekdays: set('calendarWeekdays'),
  setCalendarStart: set('calendarStart'),
  setCalendarEnd: set('calendarEnd'),
  setTimelineDays: set('timelineDays')
};

export const actions = {};

export const getters = { ...make.getters(state) };
