
import { CalendarOccurrence } from '@/models/calendar';
import { localDateFormat, setUTCPartsToDate } from '@/utils/dateHelpers';
import { mapActions } from 'vuex';
import Proxyable from '@/mixins/proxyable';
import moment, { utc } from 'moment';
import { PropType } from 'vue';

export default Proxyable.extend({
  props: {
    showAllJobs: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  data: () => ({
    //--lists--
    jobList: [],
    todayJobOccurrences: [] as CalendarOccurrence[],
    selectedJobOccurrence: '' as string | Date,
    dateEvents: [] as string[],
    today: new Date(),
    dateChange: new Date().toISOString().slice(0, 10),

    date: new Date().toISOString().slice(0, 10),
    isCalendarMonthChange: false //<--- identifies when to use showjob function
  }),

  watch: {
    internalValue: {
      handler() {
        if (this.internalValue.date) {
          const jobStartISOString = new Date(this.internalValue.date.start!)
            .toISOString()
            .slice(0, 10);

          if (jobStartISOString) {
            this.date = jobStartISOString;
          }
        }
      },
      immediate: true
    },
    /**
     * Gets called when the user changes date month on calendar
     * the code gets the events for the specified month
     *
     * @param newval gets new value of canlendar date
     *
     */
    dateChange(newval) {
      const date =
        newval.length > 7
          ? new Date(newval)
          : new Date(newval + '-' + '01T00:00:00');
      this.isCalendarMonthChange = true;

      this.fetchCalendarEventsForMonth(date);
    }
  },

  methods: {
    ...mapActions({
      fetchCalendarEvents: 'calendar/fetchCalendarEvents',
      fetchCalendarOccurrences: 'calendar/fetchCalendarOccurrences'
    }),
    localDateFormat,

    /**
     * Gets date of all job occurrences for a selected month and send data to Date picker as string
     *
     * @param calendardata fetches all the calendar events for specified month
     *
     *
     */
    setAllowedDatesfromOccurrences(calendarData: CalendarOccurrence[]) {
      for (let i = 0; i < calendarData.length; i++) {
        const localDateString = localDateFormat(
          calendarData[i].date!.start,
          'YYYY-MM-DD',
          calendarData[i].date!.timezone
        );
        this.dateEvents.push(localDateString);
      }
    },

    /**
     * filter jobs for selected calendar date
     *
     * @param event fetches data from selected calendar date
     */
    showJobs(event: string) {
      this.todayJobOccurrences = this.jobList.filter(
        (item: any) => item.date.start.slice(0, 10) === event
      );
    },

    /**
     * converts calendar occurrence object to timesheet  and send it with timezone
     *
     * @param job fetches data from selected card event
     */

    selectJobOccurrence(job: CalendarOccurrence, index: number) {
      this.internalValue = job;
      this.selectedJobOccurrence = `${job.date?.start!}__${index}`;
    },

    /**
     * fetch data method
     *
     */
    fetchCalendarEventsForMonth(date: Date) {
      let lastDay;

      const firstDay = moment(date).startOf('month').format('YYYY-MM-DDTHH:mm');

      if (this.showAllJobs) {
        lastDay = moment(
          setUTCPartsToDate(moment(date).endOf('month').toDate())
        ).format('YYYY-MM-DDTHH:mm');
      } else if (moment(this.today).isSame(date, 'month')) {
        lastDay = moment(
          setUTCPartsToDate(moment(this.today).endOf('day').toDate())
        ).format('YYYY-MM-DDTHH:mm');
      } else if (moment(date).isBefore(this.today, 'day')) {
        lastDay = moment(
          setUTCPartsToDate(moment(date).endOf('month').toDate())
        ).format('YYYY-MM-DDTHH:mm');
      } else {
        // Exit the function if we are after the date
        return;
      }

      const payload = {
        calendarId: 1,
        start: firstDay,
        end: lastDay
      };

      this.fetchCalendarOccurrences(payload).then((data) => {
        this.jobList = data;
        this.setAllowedDatesfromOccurrences(data);
        if (!this.isCalendarMonthChange) {
          this.showJobs(date.toISOString().slice(0, 10));
        }
      });
    },

    onComponentOpen() {
      //on edit
      if (Object.keys(this.internalValue).length !== 0) {
        this.isCalendarMonthChange = true;
        this.todayJobOccurrences = [
          // FIXME: we read for a value that doesnt exist on TimesheetDetailForm setTimesheetData()
          // date doesnt exists so neither does timezone
          { ...this.internalValue }
        ];
      }
      this.today = new Date();
      this.fetchCalendarEventsForMonth(this.today);
    }
  },

  async mounted() {
    this.onComponentOpen();
  }
});
