import { make } from 'vuex-pathify';
export const state = {
  error: {}
};

export const mutations = make.mutations(state);

export const actions = {
  clearError: ({ commit }: any) => commit('SET_ERROR', {})
};

export const getters = {
  getError: (state: any) => state.error
};
