import { IContactNoteState } from '..';
import { IContactNoteMutations } from './mutations.types';
import { IContactNoteData } from '@/models';

export const mutations: IContactNoteMutations = {
  SET_CONTACT_NOTES(
    state: IContactNoteState,
    payload: IContactNoteData[]
  ): void {
    state.contactNotes = payload;
  },

  ADD_CONTACT_NOTE(state: IContactNoteState, payload: IContactNoteData): void {
    state.contactNotes.unshift(payload);
  },

  UPDATE_CONTACT_NOTE(
    state: IContactNoteState,
    payload: IContactNoteData
  ): void {
    const index = state.contactNotes.findIndex(
      (contactnote: IContactNoteData) => contactnote.id === payload.id
    );

    state.contactNotes.splice(index, 1, payload);
  },

  REMOVE_CONTACT_NOTE(state: IContactNoteState, payload: number): void {
    const index = state.contactNotes.findIndex(
      (contactnote: IContactNoteData) => contactnote.id === payload
    );

    state.contactNotes.splice(index, 1);
  }
};
