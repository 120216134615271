
import Vue from 'vue';

// Helpers
import { ErrorManager } from '@/models/error';
import { mapActions, mapGetters } from 'vuex';

// Models
import { Company, ICompany } from '@/models';

// Components
import ErrorAlert from '../../common/ErrorAlert.vue';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import CompanyDetailFormDialog from './CompanyDetailFormDialog.vue';
import CompanyStatusDisplay from './CompanyStatusDisplay.vue';
import CRMStatusFilter from '../common/CRMStatusFilter.vue';

interface Options {
  $refs: {
    companyFormDialog: InstanceType<typeof CompanyDetailFormDialog>;
  };
}

export default Vue.extend<Options>({ functional: false }).extend({
  name: 'CompanyDataTable',

  components: {
    ErrorAlert,
    ConfirmationDialog,
    CompanyDetailFormDialog,
    CompanyStatusDisplay,
    CRMStatusFilter
  },

  data() {
    return {
      search: '',
      errorMessage: '',
      errorCompanyDialogMessage: '',

      deleteInProgress: false,
      deleteCompanyDialog: false,
      loading: false,

      selectedCompany: new Company(),

      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Company Name',
          value: 'name'
        },
        {
          text: 'Email',
          sortable: false,
          value: 'email'
        },
        {
          text: 'Phone Number',
          sortable: false,
          value: 'phone'
        },
        {
          text: 'Website',
          sortable: false,
          value: 'website'
        },

        {
          text: 'Status',
          value: 'status',
          width: '200px'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      activeView: -1 as number
    };
  },

  computed: {
    ...mapGetters({ companies: 'companyV2/getCompanies' }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    filterCompanies(): ICompany[] {
      return this.companies.filter((company: any) => {
        if (this.activeView == -1) {
          return -1; // true
        }
        return company.status.value == this.activeView;
      });
    }
  },

  methods: {
    ...mapActions({
      fetchCompanies: 'companyV2/fetchCompanies',
      deleteCompany: 'companyV2/deleteCompany'
    }),

    clearAllErrors() {
      this.errorMessage = '';
    },

    clearAllDialogErrors() {
      this.errorCompanyDialogMessage = '';
    },

    closeCompanyDeleteDialog() {
      this.deleteCompanyDialog = false;
    },

    /**
     * @public
     * Opens detail dialog
     */
    openCompanyDetailDialog(instance?: ICompany, isEdit = false) {
      this.$refs.companyFormDialog.open(instance, isEdit);
    },

    openDeleteCompanyDialog(company: ICompany) {
      // To avoid mutation reference value
      this.selectedCompany = new Company(company);
      this.deleteCompanyDialog = true;
    },

    destroyCompany(deleteId: number) {
      this.deleteCompany(deleteId)
        .then(() => (this.deleteCompanyDialog = false))
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    goToCompanyDetail(company: ICompany) {
      return this.$router.push({
        name: 'company-detail',
        params: { id: String(company.id) }
      });
    }
  },

  mounted() {
    this.loading = true;
    this.fetchCompanies()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
});
