
import Vue from 'vue';
import { isEmptyObject } from '@/utils/helpers';

export default Vue.extend({
  name: 'DataTasksCard',
  data: () => ({
    show: false
  }),
  computed: {},
  methods: {
    isEmptyObject: isEmptyObject
  }
});
