import {
  IEmployeeEmergencyContact,
  IEmployeeEmergencyContactData,
  IEmployeeEmergencyContactFieldError,
  IEmployeeEmergencyContactFieldErrorData
} from '.';
import {
  IEmployee,
  IEmployeeAttachment,
  IEmployeeAttachmentData,
  IEmployeeAttachmentFieldError,
  IEmployeeAttachmentFieldErrorData,
  IEmployeeData,
  IEmployeeFieldError,
  IEmployeeFieldErrorData,
  IEmployeeUser,
  IEmployeeUserData,
  IEmployeeUserFieldError,
  IEmployeeUserFieldErrorData
} from './employee.types';

export class Employee implements IEmployee {
  readonly id?: number;
  readonly user?: IEmployeeUser | number = new EmployeeUser();
  readonly role?: string = '';
  readonly rate?: number = 0;
  readonly phone?: string = '';
  readonly hire_date?: Date | string = '';
  readonly notes?: string = '';
  readonly drivers_license?: string = '';
  readonly dob?: Date | string = '';
  readonly attachments?: IEmployeeAttachment[] = [];
  readonly emergency_contact?: IEmployeeEmergencyContact[] = [];

  constructor(data?: IEmployeeData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.user) {
        this.user =
          typeof data.user === 'number'
            ? data.user
            : new EmployeeUser(data.user);
      }
      if (data.role) {
        this.role = data.role;
      }
      if (data.rate) {
        this.rate = data.rate;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.hire_date) {
        this.hire_date = data.hire_date;
      }

      if (data.notes) {
        this.notes = data.notes;
      }
      if (data.drivers_license) {
        this.drivers_license = data.drivers_license;
      }
      if (data.dob) {
        this.dob = data.dob;
      }

      if (data.attachments) {
        this.attachments = data.attachments.map(
          (attachment) => new EmployeeAttachment(attachment)
        );
      }
      if (data.emergency_contact) {
        this.emergency_contact = data.emergency_contact.map(
          (emergency_contact) => new EmployeeEmergencyContact(emergency_contact)
        );
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }

  addEmergencyContact(data: IEmployeeEmergencyContactData): void {
    this.emergency_contact?.push(new EmployeeEmergencyContact(data));
  }

  removeEmergencyContact(data: IEmployeeEmergencyContactData): void {
    const index = this.emergency_contact?.findIndex(
      (attachment) =>
        attachment.id == data.id ||
        (attachment.name == data.name && attachment.phone == data.phone)
    );
    if (index != null) {
      this.emergency_contact?.splice(index, 1);
    }
  }

  attachFile(file: File): void {
    this.attachments?.push(new EmployeeAttachment({ file: file }));
  }

  detachFile(file: string | File): void {
    const index = this.attachments?.findIndex(
      (attachment) => attachment.file == file
    );

    if (index != null) {
      this.attachments?.splice(index, 1);
    }
  }
}

export class EmployeeEmergencyContact implements IEmployeeEmergencyContact {
  readonly id?: number;
  readonly name?: string;
  readonly phone?: string;

  constructor(data?: IEmployeeEmergencyContactData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.name) {
        this.name = data.name;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class EmployeeUser implements IEmployeeUser {
  readonly id?: number;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly email?: string;
  readonly is_active?: boolean;

  constructor(data?: IEmployeeUserData) {
    if (data) {
      this.is_active = data.is_active;
      if (data.id) {
        this.id = data.id;
      }
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
    }
  }

  get fullName(): string {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }

  get initials(): string {
    return this.fullName
      .toUpperCase()
      .split(' ')
      .map((n) => n[0])
      .join('')
      .trim();
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class EmployeeAttachment implements IEmployeeAttachment {
  readonly id?: number;
  readonly file?: File | string;

  constructor(data?: IEmployeeAttachmentData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.file) {
        this.file = data.file;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class EmployeeFieldError implements IEmployeeFieldError {
  role: string[] = [];
  rate: string[] = [];
  phone: string[] = [];
  hire_date: string[] = [];
  notes: string[] = [];
  drivers_license: string[] = [];
  dob: string[] = [];

  user: IEmployeeUserFieldError | string[] = [];
  attachments: IEmployeeAttachmentFieldError | string[] = [];
  emergency_contact?: IEmployeeEmergencyContactFieldError | string[] =
    new EmployeeEmergencyContactFieldError();

  constructor(data?: IEmployeeFieldErrorData) {
    if (data) {
      if (data.role) {
        this.role = data.role;
      }
      if (data.rate) {
        this.rate = data.rate;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.hire_date) {
        this.hire_date = data.hire_date;
      }
      if (data.user) {
        this.user = Array.isArray(data.user)
          ? data.user
          : new EmployeeUserFieldError(data.user);
      }
      if (data.notes) {
        this.notes = data.notes;
      }
      if (data.drivers_license) {
        this.drivers_license = data.drivers_license;
      }
      if (data.dob) {
        this.dob = data.dob;
      }
      if (data.emergency_contact) {
        this.emergency_contact = Array.isArray(data.emergency_contact)
          ? data.emergency_contact
          : new EmployeeEmergencyContactFieldError(data.emergency_contact);
      }
      if (data.attachments) {
        this.attachments = Array.isArray(data.attachments)
          ? data.attachments
          : new EmployeeAttachmentFieldError(data.attachments);
      }
    }
  }

  clearErrors(): void {
    this.role.length = 0;
    this.rate.length = 0;
    this.phone.length = 0;

    // these methods of clearing data doesn't work while I was testing the component
    // this.hire_date.length = 0;
    // this.dob.length = 0;

    // this alternative is used instead
    this.hire_date.splice(0, this.hire_date.length);
    this.dob.splice(0, this.dob.length);

    this.notes.length = 0;
    this.drivers_license.length = 0;
    if (Array.isArray(this.user)) {
      this.user.length = 0;
    } else {
      this.user.clearErrors();
    }
    if (Array.isArray(this.attachments)) {
      this.attachments.length = 0;
    } else {
      this.attachments.clearErrors();
    }
  }

  static isEmployeeFieldError(data: IEmployeeFieldErrorData): boolean {
    if (data.user && Array.isArray(data.user)) return true;
    if (data.rate && Array.isArray(data.rate)) return true;
    if (data.phone && Array.isArray(data.phone)) return true;
    if (data.hire_date || Array.isArray(data.hire_date)) return true;
    if (data.attachments || Array.isArray(data.attachments)) return true;
    if (data.notes || Array.isArray(data.notes)) return true;
    if (data.drivers_license || Array.isArray(data.drivers_license))
      return true;
    if (data.dob || Array.isArray(data.dob)) return true;
    return false;
  }
}
export class EmployeeUserFieldError implements IEmployeeUserFieldError {
  first_name: string[] = [];
  last_name: string[] = [];
  email: string[] = [];

  constructor(data?: IEmployeeUserFieldErrorData) {
    if (data) {
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
    }
  }

  clearErrors(): void {
    this.first_name.length = 0;
    this.last_name.length = 0;
    this.email.length = 0;
  }

  static isEmployeeUserFieldError(data: IEmployeeUserFieldErrorData): boolean {
    if (data.first_name && Array.isArray(data.first_name)) return true;
    if (data.last_name && Array.isArray(data.last_name)) return true;
    if (data.email && Array.isArray(data.email)) return true;
    return false;
  }
}

export class EmployeeEmergencyContactFieldError
  implements IEmployeeEmergencyContactFieldError
{
  name: string[] = [];
  phone: string[] = [];

  constructor(data?: IEmployeeEmergencyContactFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
    }
  }

  clearErrors(): void {
    this.name.length = 0;
    this.phone.length = 0;
  }

  static isEmployeeEmergencyContactFieldError(
    data: IEmployeeEmergencyContactFieldErrorData
  ): boolean {
    if (data.name && Array.isArray(data.name)) return true;
    if (data.phone && Array.isArray(data.phone)) return true;
    return false;
  }
}

export class EmployeeAttachmentFieldError
  implements IEmployeeAttachmentFieldError
{
  file: string[] = [];

  constructor(data?: IEmployeeAttachmentFieldErrorData) {
    if (data) {
      if (data.file) {
        this.file = data.file;
      }
    }
  }

  clearErrors(): void {
    this.file.length = 0;
  }

  static isEmployeeAttachmentFieldError(
    data: IEmployeeAttachmentFieldErrorData
  ): boolean {
    if (data.file && Array.isArray(data.file)) return true;
    return false;
  }
}
