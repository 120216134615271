
import { IProduct, ProductPriceFrequencyOptions } from '@/models';
import { ErrorManager } from '@/models/error';
import dateFormat from '@/utils/dateFormat';
import {
  authProvider,
  subscriptionProvider
} from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { BillingSubscription } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/subscription';
import auth from '@/mixins/auth';

import { mapActions, mapGetters } from 'vuex';
import mixpanel from 'mixpanel-browser';

export default auth.extend({
  data() {
    return {
      tab: null,
      // dialog: false,
      loading: false,
      purchaseInProgress: false,
      errorMessage: '',
      detailErrorMessage: '',
      showDetailError: false,

      billingItems: [
        {
          text: 'Monthly Fees',
          value: ProductPriceFrequencyOptions.Monthly
        },
        { text: 'Annual Fees', value: ProductPriceFrequencyOptions.Yearly }
      ],
      billingFrequencyTab: ProductPriceFrequencyOptions.Monthly,

      billingFrequencyStringRepresentation: {
        [ProductPriceFrequencyOptions.Monthly]: 'month',
        [ProductPriceFrequencyOptions.Yearly]: 'year'
      }
    };
  },

  async mounted() {
    if (this.isUserSuperuser || this.isOnFreeTrial) {
      return;
    }
    this.loading = true;
    await this.fetchProducts();
    try {
      if (this.subscriptionProvider) {
        if (!this.subscription.product || this.checkSubscriptionInvalid()) {
          this.appProductsDialog = true;
          if (this.checkSubscriptionInvalid()) {
            this.errorMessage = `Your subscription has expired on ${dateFormat(
              this.subscription.valid_until,
              'MMMM DD, YYYY'
            )}. Please select a new subscription to continue`;
          }
        }
      }
    } catch (error) {
      this.errorMessage = ErrorManager.extractApiError(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    authProvider(): any {
      return authProvider(this.$store);
    },

    ...mapGetters({
      products: 'product/getProducts'
    }),

    appProductsDialog: {
      get(): any {
        return this.$store.state.app.appProductsDialog;
      },
      set: function (val: boolean) {
        this.setAppProductsDialog(val);
      }
    } as any,

    subscriptionProvider(): any {
      return subscriptionProvider(this.$store);
    },

    subscription(): BillingSubscription {
      return this.subscriptionProvider.getSubscription;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    detailErrorOccurred(): boolean {
      return this.detailErrorMessage.length > 0;
    },

    isUserSuperuser(): boolean {
      return this.authProvider.getCurrentUser.is_superuser;
    }
  },

  methods: {
    ...mapActions({
      fetchProducts: 'product/fetchProducts',
      buyProduct: 'product/buyProduct',
      setAppProductsDialog: 'app/setAppProductsDialog'
    }),

    clearErrors() {
      this.errorMessage = '';
      this.detailErrorMessage = '';
      this.showDetailError = false;
    },

    toggleDetailError() {
      this.showDetailError = !this.showDetailError;
    },

    checkSubscriptionInvalid(): boolean {
      const validUntil = this.subscription.valid_until;
      if (validUntil) {
        return new Date(validUntil).valueOf() < Date.now();
      }
      return false;
    },

    getProductIcon(index: number) {
      if (index == 0) {
        return require('@/assets/images/svg/startup.svg');
      } else if (index == 1) {
        return require('@/assets/images/svg/man.svg');
      } else {
        return require('@/assets/images/svg/skycraper.svg');
      }
    },

    getComputedProductPrice(product: IProduct) {
      const priceData = product.prices.find(
        (price) => price.frequency === this.billingFrequencyTab
      );
      if (!priceData) {
        return undefined;
      }

      return priceData.price;
    },

    selectProduct(product: IProduct) {
      this.checkoutProduct(product, this.billingFrequencyTab);
    },

    checkoutProduct(
      product: IProduct,
      frequency: ProductPriceFrequencyOptions
    ) {
      this.purchaseInProgress = true;
      this.clearErrors();
      this.buyProduct({
        product: product.id,
        frequency
      })
        .then(async (result) => {
          try {
            // Turning the track function into a promise
            // then awaiting that function to be sent first before redirecting
            // https://github.com/mixpanel/mixpanel-js/issues/158
            const mixPanelTrack = () =>
              new Promise((resolve) =>
                mixpanel.track(
                  'User Selected Product',
                  {
                    product: product.name
                  },
                  (returnedValue) => resolve(returnedValue)
                )
              );

            // await mixpanelTrack to return a value first before rerouting
            // the returned value is a boolean represented in binary either 1 or 0

            await mixPanelTrack();
          } catch (e) {
            console.warn(e);
          }

          // Reload page regardless
          window.location.href = result.redirect;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.detailErrorMessage = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.purchaseInProgress = false));
    }
  }
});
