import './axios';
import { VueConstructor } from 'vue/types/umd';
import { useVuetify } from './vuetify';
import { registerComponents } from './app';
import { installAltoleap } from './altoleap';
import { registerSentry } from './sentry';
import { registerMixpanel } from './mixpanel';

export function registerPlugins(Vue: VueConstructor, ...args: any) {
  installAltoleap(Vue, args[0]);
  registerComponents(Vue);
  // registerAxios(Vue);
  registerMixpanel(Vue, args[0], args[1]);
  registerSentry(Vue, args[1]);
  useVuetify(Vue);
}
export default registerPlugins;
