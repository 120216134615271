import { IFeedbackActions, FeedbackContext } from './actions.types';
import { IFeedbackData } from '@/models';

export const actions: IFeedbackActions = {
  sendFeedback(
    context: FeedbackContext,
    payload: IFeedbackData
  ): Promise<void> {
    return this.$services.feedback.sendFeedback(payload);
  }
};
