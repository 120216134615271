import { IJobState } from '@/store/job';
import { IJobGetters } from './getters.types';
import {
  Attendee,
  IJobTimelineOccurrencesData,
  JobTimelineOccurrences
} from '@/models/job/index';

export const getters: IJobGetters = {
  getJobTimelineOccurrences(state: IJobState): IJobTimelineOccurrencesData[] {
    const timelineList = [] as IJobTimelineOccurrencesData[];

    state.jobs.map((data) => {
      //the server sends back an empty user object along with a list of jobs with no participants
      //this is then used to represent the empty shift row of the timeline
      if (!data.user?.id) {
        //the attendee model is the same as the user object retrieved from server
        data.user = new Attendee({
          email: '',
          first_name: 'Empty',
          last_name: 'Shifts',
          is_active: true
        });

        const jobTimelineOccurrence = new JobTimelineOccurrences(data);
        timelineList.unshift(jobTimelineOccurrence);
      } else {
        const jobTimelineOccurrence = new JobTimelineOccurrences(data);
        timelineList.push(jobTimelineOccurrence);
      }

      return timelineList;
    });
    return timelineList;
  }
};
