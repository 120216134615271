import { ICompanyState } from '@/store/v2/company';
import { ICompanyGetters } from './getters.types';
import { ICompany, Company } from '@/models';

export const getters: ICompanyGetters = {
  getCompanies(state: ICompanyState): ICompany[] {
    return state.companies.map((data) => new Company(data));
  },

  getCompanyById:
    (state: ICompanyState) =>
    (id: number): ICompany | undefined => {
      const data = state.companies.find((company) => company.id === id);
      if (!data) {
        return undefined;
      }

      return new Company(data);
    }
};
