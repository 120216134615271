
import dateFormat from '@/utils/dateFormat';
import { dateFromNow } from '@/utils/dateHelpers';
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
  name: 'BoardTaskDueDate',
  props: {
    contentClass: {
      type: [String, Object, Array],
      required: false
    },
    completedAt: {
      type: String,
      required: false
    },
    dueDate: {
      type: String,
      required: true
    }
  },

  computed: {
    timeDifference(): number {
      return moment(this.dueDate)
        .startOf('day')
        .diff(moment(new Date()).startOf('day'), 'days');
    },

    timeFromNow(): string {
      return dateFromNow(this.dueDate, true);
    },
    body(): string {
      const { timeDifference } = this;
      if (timeDifference === 0) {
        return 'Today';
      } else if (timeDifference === 1) {
        return 'Tomorrow';
      } else if (timeDifference === -1) {
        return 'Yesterday';
      } else if (timeDifference > 0 && timeDifference < 7) {
        return dateFormat(this.dueDate, 'dddd');
      }

      const today = new Date();
      const isDueInCurrentYear =
        today.getFullYear() === moment(this.dueDate).get('year');
      let formatText = 'dddd MMMM D, YYYY';
      if (!isDueInCurrentYear) {
        formatText = formatText.concat(', YYYY');
      }
      return dateFormat(this.dueDate, formatText);
    },

    isPastDue() {
      let closed = false;

      if (this.completedAt) {
        closed = moment(this.completedAt).isAfter(this.dueDate);
      }

      if (this.timeDifference >= 0 || closed) return false;
      return true;
    }
  }
});
