
import { mapActions, mapGetters } from 'vuex';
import Proxyable from '@/mixins/proxyable';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '../common/ErrorAlert.vue';

export default Proxyable.extend({
  components: { ErrorAlert },
  props: ['fieldError'],

  data() {
    return {
      selectedCustomer: '' as any,
      formValid: false,
      emptyRule: [(v: any) => !!v || 'This is required'],

      errorMessage: ''
    };
  },
  watch: {
    formValid(value: boolean) {
      this.$emit('validData', value);
    }
  },

  computed: {
    ...mapGetters({
      contacts: 'contactV2/getContacts',
      companies: 'companyV2/getCompanies'
    }),

    customerList(): any {
      const customerList = [];

      customerList.push({ header: 'Contacts' });
      for (let i = 0; this.contacts.length > i; i++) {
        const contactObject = {
          id: this.contacts[i].id,
          name: `${this.contacts[i].name.first} ${this.contacts[i].name.last}`,
          type: 'Contact'
        };
        customerList.push(contactObject);
      }

      customerList.push({ divider: true });

      customerList.push({ header: 'Companies' });
      for (let i = 0; this.companies.length > i; i++) {
        const companyObject = {
          id: this.companies[i].id,
          name: this.companies[i].name,
          type: 'Company'
        };
        customerList.push(companyObject);
      }

      return customerList;
    }
  },

  methods: {
    ...mapActions({
      fetchContacts: 'contactV2/fetchContacts',
      fetchCompanies: 'companyV2/fetchCompanies'
    }),

    clearAllErrors() {
      this.errorMessage = '';
    },

    filterCustomerObject(item: any, queryText: string, itemText: any) {
      return (
        item.name?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.type?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    setCustomer() {
      if (this.selectedCustomer !== null) {
        if (this.selectedCustomer.type == 'Contact') {
          this.internalValue.customer = {
            contact: this.selectedCustomer.id,
            company: null
          };
        }

        if (this.selectedCustomer.type == 'Company') {
          this.internalValue.customer = {
            contact: null,
            company: this.selectedCustomer.id
          };
        }
      }
    }
  },

  mounted() {
    this.clearAllErrors();
    try {
      this.fetchCompanies();
      this.fetchContacts();
    } catch (error) {
      this.errorMessage = ErrorManager.extractApiError(error);
    }
    if (this.$route.params.movingInventoryId !== '0') {
      if (this.internalValue.customer.company !== null)
        this.selectedCustomer = {
          id: this.internalValue.customer.company.id,
          name: this.internalValue.customer.company.name,
          type: 'Company'
        };

      if (this.internalValue.customer.contact !== null)
        this.selectedCustomer = {
          id: this.internalValue.customer.contact.id,
          name: `${this.internalValue.customer.contact.name.first} ${this.internalValue.customer.contact.name.last}`,
          type: 'Contact'
        };
    }
  }
});
