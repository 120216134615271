
import Vue from 'vue';

export default Vue.extend({
  props: ['jobReport'],
  data() {
    return {
      imageList: [] as File[],
      filesList: [] as File[]
    };
  },

  methods: {
    setUploadedImages(uploadedFile: any) {
      this.filesList = [];
      const mediaList =
        uploadedFile.target.files || uploadedFile.dataTransfer.files;
      this.jobReport.media = mediaList;

      //cannot perform array functionalities on file list so it had to be converted to an Array list.
      Array.prototype.push.apply(this.filesList, this.jobReport.media);

      if (!this.jobReport.media.length) return;

      this.createImage(this.jobReport.media);
    },

    createImage(fileList: any) {
      this.imageList = [];
      for (let i = 0; fileList.length > i; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = e.target.result;
          this.imageList.push(image);
        };
        reader.readAsDataURL(fileList[i]);
      }
    },

    deleteImage(index: number) {
      this.imageList.splice(index, 1);
      this.filesList.splice(index, 1);

      //add the updated array list to jobReport media
      this.jobReport.media = this.filesList;
    },

    clearJobReportDetailFormData() {
      this.imageList = [];
      this.jobReport.media = [];
      this.filesList = [];
    }
  }
});
