import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { ActivityFeed, Inventory } from '@/models/inventory';
import { InventoryService } from '@/services/inventory/inventory-service';

const inventoryService = new InventoryService();

export const state = {
  inventories: [] as Inventory[],
  activityFeed: [] as ActivityFeed[]
};

//TODO MG - 3/29/2021 'Put inventory store features in one folder'

export type InventoryState = typeof state;

export const mutations: MutationTree<InventoryState> = {
  SET_INVENTORY(state, payload: Inventory[]) {
    state.inventories = payload;
  },

  ADD_INVENTORY(state, payload: Inventory) {
    state.inventories.push(payload);
  },

  UPDATE_INVENTORY(state, payload: Inventory) {
    const index = state.inventories.findIndex(
      (model: Inventory) => model.id === payload.id
    );
    state.inventories.splice(index, 1, payload);
  },

  REMOVE_INVENTORY(state, payload: number) {
    const index = state.inventories.findIndex(
      (model: Inventory) => model.id === payload
    );
    state.inventories.splice(index, 1);
  },

  SET_ACTIVITY_FEED(state, payload: ActivityFeed[]) {
    state.activityFeed = payload;
  }
};

export const actions: ActionTree<InventoryState, InventoryState> = {
  createInventory: async ({ commit }, payload: Inventory) => {
    return inventoryService
      .createInventory(payload)
      .then((inventory: Inventory) => {
        commit('ADD_INVENTORY', inventory);
        return inventory;
      });
  },

  fetchInventories: async ({ commit }, payload?: number) =>
    inventoryService
      .fetchInventories(payload)
      .then((inventory: Inventory[]) => {
        commit('SET_INVENTORY', inventory);
      }),

  fetchInventory: async ({ commit }, payload: number) =>
    inventoryService.fetchInventory(payload).then((model: Inventory) => {
      const index = state.inventories.findIndex(
        (v: Inventory) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_INVENTORY', model);
      } else {
        commit('UPDATE_INVENTORY', model);
      }
    }),

  updateInventory: async ({ state, commit }, payload: Inventory) =>
    inventoryService.updateInventory(payload).then((model: Inventory) => {
      const index = state.inventories.findIndex(
        (v: Inventory) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_INVENTORY', model);
      } else {
        commit('UPDATE_INVENTORY', model);
      }
    }),

  deleteInventory: async ({ commit }, payload: number) =>
    inventoryService.deleteInventory(payload).then(() => {
      commit('REMOVE_INVENTORY', payload);
    }),

  updateQuantity: async ({ commit }, payload: Inventory) =>
    inventoryService.updateQuantity(payload),

  fetchActivityFeeds: async ({ commit }) =>
    inventoryService
      .fetchActivityFeeds()
      .then((activityFeeds: ActivityFeed[]) => {
        commit('SET_ACTIVITY_FEED', activityFeeds);
      })
};

export const getters: GetterTree<InventoryState, InventoryState> = {
  getInventoryById: (state: InventoryState) => (id: number) => {
    return state.inventories.find((inventory) => inventory.id === Number(id));
  },
  getInventories: (state: InventoryState) => {
    return state.inventories;
  }
};
