<template>
  <v-row align="end" justify="end">
    <v-col cols="12">
      <v-row align="end" justify="end">
        <div class="text-xs-center">
          <v-btn
            v-if="facebookLink"
            fab
            dark
            color="primary darken-2"
            class="mx-2"
            :small="btnSize"
            router
            :href="facebookLink"
            target="_blank"
          >
            <v-icon dark>mdi-facebook</v-icon>
          </v-btn>

          <v-btn
            v-if="twitterLink"
            fab
            dark
            raised
            color="primary"
            class="mx-2"
            router
            :small="btnSize"
            :href="twitterLink"
            target="_blank"
          >
            <v-icon dark>mdi-twitter</v-icon>
          </v-btn>

          <v-btn
            v-if="linkedinLink"
            fab
            dark
            raised
            color="info darken-1"
            class="mx-2"
            router
            :small="btnSize"
            :href="linkedinLink"
            target="_blank"
          >
            <v-icon dark>mdi-linkedin</v-icon>
          </v-btn>

          <v-btn
            v-if="instagramLink"
            fab
            dark
            raised
            class="bottom-gradient mx-2"
            router
            :small="btnSize"
            :href="instagramLink"
            target="_blank"
          >
            <v-icon dark>mdi-instagram</v-icon>
          </v-btn>

          <v-btn
            v-if="youtubeLink"
            fab
            dark
            raised
            class="red lighten-1 mx-2"
            router
            :small="btnSize"
            :href="youtubeLink"
            target="_blank"
          >
            <v-icon dark>mdi-youtube</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'social-media',
  props: {
    facebookLink: {
      type: String,
      default: ''
    },
    linkedinLink: {
      type: String,
      default: ''
    },
    twitterLink: {
      type: String,
      default: ''
    },
    instagramLink: {
      type: String,
      default: ''
    },
    youtubeLink: {
      type: String,
      default: ''
    },
    btnSize: {
      type: Boolean,
      default: false
    }
  },
  // ["facebookLink", "linkedinLink", "twitterLink", "instagramLink", "btn-size"],
  data: () => ({}),
  methods: {}
};
</script>
<style scoped>
.bottom-gradient {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
</style>
