
import { deepEqual } from '@/utils/helpers';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default Vue.extend({
  data() {
    return {
      selectedCategories: [] as any[],
      categories: [] as any[]
    };
  },
  computed: {
    ...mapGetters({
      getAccountsByName: 'accounts/getAccountsByName'
    })
  },
  methods: {
    ...mapActions({
      fetchAccounts: 'accounts/fetchAccounts'
    }),

    findExistingIndex(item: string): any {
      return (this.selectedCategories || []).findIndex((i: object) =>
        deepEqual(i, item)
      );
    },
    selectCategory(data: string) {
      const internalValue = (this.selectedCategories || []).slice();
      const i = this.findExistingIndex(data);
      i !== -1 ? internalValue.splice(i, 1) : internalValue.push(data);
      this.setValue(
        internalValue.map((i: object) => {
          return i;
        })
      );
      return;
    },
    setValue(value: any) {
      const oldValue = this.selectedCategories;
      this.selectedCategories = value;
      value !== oldValue && this.$emit('change', value);
    },
    setSelectedItems() {
      const selectedItems = [];
      const values = !Array.isArray(this.selectedCategories)
        ? [this.selectedCategories]
        : this.selectedCategories;

      for (const value of values) {
        const index = this.getAccountsByName.findIndex((v: string) =>
          deepEqual(v, value)
        );

        if (index > -1) {
          selectedItems.push(this.getAccountsByName[index]);
        }
      }

      this.categories = selectedItems;
    },

    setUserFullName(fullName: string) {
      return fullName == ' ' ? 'No Name' : fullName;
    }
  },
  async beforeMount() {
    await this.fetchAccounts(true).then(() => {
      this.categories = this.getAccountsByName;
      this.selectedCategories = this.categories;
    });
  }
});
