import { axios } from '@/plugins/axios';
import * as Sentry from '@sentry/vue';
import { IStore } from '../store.types';

function createRequestInterceptor(store: any) {
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error: any) {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );
}

function createResponseInterceptor(store?: IStore) {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const sentryError = new Error(
        `${error.message} ${JSON.stringify(error.response?.data, null, 4)}`
      );
      sentryError.name = `Axios: ${error.name}`;
      sentryError.stack = error.stack;

      Sentry.captureException(sentryError);
      Sentry.setExtra('error', error);
      return Promise.reject(error);
    }
  );
}

const plugin = (store: IStore) => {
  createResponseInterceptor(store);
  createRequestInterceptor(store);
};

export default plugin;
