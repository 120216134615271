import {
  IOrganization,
  IOrganizationData,
  IOrganizationFieldError,
  IOrganizationFieldErrorData
} from './organization.types';

export class Organization implements IOrganization {
  readonly id?: number;
  readonly name: string = '';
  readonly created_at?: Date | string;

  constructor(data?: IOrganizationData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.name) {
        this.name = data.name;
      }
      if (data.created_at) {
        this.created_at = data.created_at;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class OrganizationFieldError implements IOrganizationFieldError {
  name: string[] = [];

  constructor(data?: IOrganizationFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
  }

  static isOrganizationFieldError(data: IOrganizationFieldErrorData): boolean {
    if (data.name && Array.isArray(data.name)) return true;
    return false;
  }
}
