import Vue from 'vue';
import VueRouter from 'vue-router';
import { authMeta, noAuthMeta } from './meta';
import AltoleapAccountsRouter from '@vue-altoleap-libraries/vue-altoleap-accounts-ui/src/router';
import { AccountsRoutes } from '@vue-altoleap-libraries/vue-altoleap-accounts-ui/src/router/index';
import { NotificationRoutes } from '@vue-altoleap-libraries/vue-altoleap-notifications-ui/src/router/index';
// import AltoleapCalendarRouter from 'vue-altoleap-calendar-ui/src/router';

// META
import Meta from 'vue-meta';

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-ssr',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

import scrollBehavior from './scroll-behavior';

// TOKEN
import { TokenService } from '@/services/token';

// Utilities
import { layout, root, route, subRoute } from './utils';

Vue.use(VueRouter);

const routes = root([
  {
    path: '',
    redirect: { name: 'home' }
  },

  layout(
    'legals',
    'legals',
    [
      route(
        '',
        'legals',
        [
          subRoute({
            path: 'privacy',
            name: 'privacy',
            component: 'views/legals/Privacy',
            meta: { label: 'Privacy' }
          }),

          subRoute({
            path: 'terms',
            name: 'terms',
            component: 'views/legals/Terms',
            meta: { label: 'Terms' }
          })
        ],
        noAuthMeta,
        { name: 'privacy' }
      )
    ],
    { name: 'privacy' },
    noAuthMeta
  ),

  ...AltoleapAccountsRouter.AuthRedirectRoutes,
  {
    path: 'auth',
    redirect: { name: 'sign-in' },
    props: true
  },
  subRoute({
    path: 'auth/sign-up',
    name: 'sign-up',
    component: 'views/auth/SignUpWizard'
  }),

  subRoute({
    path: 'auth/email-confirm/:key/',
    name: 'email-confirm',
    component: 'views/auth/EmailConfirm',
    props: true
  }),

  AltoleapAccountsRouter.AuthRoutes,

  layout('', 'Blank', [
    route(
      'shared-inventory',
      'shared-inventory',
      [
        subRoute({
          path: ':movingItemId/shared/shared-item',
          name: 'shared-moving-item-list',
          component: 'components/sharedMovingInventory/SharedMovingItemList',
          meta: { label: 'Shared Moving Item Management List' }
        })
      ],
      noAuthMeta
    )
  ]),

  layout('', 'Blank', [
    {
      path: '/v1/jobs/:calendarId/events/:eventId/(occurrences)?/:occurrenceId?/token/:token/rsvp/:rsvp/',
      redirect: { name: 'calendar-event-page' }
    },
    {
      path: '/v1/jobs/:calendarId/events/:eventId/(occurrences)?/:occurrenceId?/token/:token/rsvp/:rsvp/',
      redirect: { name: 'calendar-event-page' }
    },

    subRoute({
      path: '/calendar/:calendarId/events/:eventId/(occurrences)?/:occurrenceId?/token/:token/rsvp/:rsvp/',
      name: 'calendar-event-page',
      component: 'components/data/calendar/EventPage'
    })
  ]),

  layout(
    '',
    'app',
    [
      route('', 'home', [], authMeta),

      AccountsRoutes,

      route(
        'organizations',
        'organizations',
        [
          subRoute({
            path: '',
            name: 'organizations-list',
            component: 'views/organizations/OrganizationList',
            meta: { label: 'Organization List' }
          }),
          subRoute({
            path: ':organizationId',
            name: 'organization-detail',
            component: 'views/organizations/OrganizationDetail',
            meta: { label: 'Organization' }
          })
        ],
        authMeta,
        { name: 'organizations-list' }
      ),

      route(
        'settings',
        'settings',
        [
          subRoute({
            path: 'tasks',
            name: 'tasks-management',
            component: 'views/settings/TasksManagement',
            meta: { label: 'tasks' }
          })
        ],
        authMeta,
        { name: 'tasks-management' }
      ),

      route(
        'tasks',
        'tasks',
        [
          subRoute({
            path: 'board',
            name: 'tasks-board',
            component: 'views/tasks/Board',
            meta: { label: 'board' }
          }),

          subRoute({
            path: 'issues',
            name: 'tasks-issues',
            component: 'views/tasks/Issues',
            meta: { label: 'issues' }
          })
        ],
        authMeta,
        { name: 'tasks-board' }
      ),

      NotificationRoutes,

      route(
        'inventory',
        'inventory',
        [
          subRoute({
            path: '',
            name: 'inventory-management',
            component: 'components/inventory/Index',
            meta: { label: 'Inventory Management' }
          }),

          subRoute({
            path: 'item-master',
            name: 'item-master',
            component: 'components/inventory/ItemMasterList',
            meta: { label: 'Item Master' }
          }),

          subRoute({
            path: 'item-detail/:itemNumber',
            name: 'item-detail',
            component: 'components/inventory/ItemMasterDetail',
            meta: { label: 'Item Detail' }
          }),

          subRoute({
            path: 'inventory-detail/:itemNumber',
            name: 'inventory-detail',
            component: 'components/inventory/InventoryDetail',
            meta: { label: 'Inventory Detail' }
          }),

          subRoute({
            path: 'inventoryTable',
            name: 'inventoryTable',
            component: 'components/inventory/InventoryList',
            meta: { label: 'Inventory' }
          }),

          subRoute({
            path: 'warehouse',
            name: 'warehouse',
            component: 'components/inventory/WarehouseList',
            meta: { label: 'Warehouse' }
          }),

          subRoute({
            path: 'warehouse/:warehouseId',
            name: 'warehouse-detail',
            component: 'views/inventory/WarehouseDetail',
            meta: { label: 'Warehouse Detail' }
          })
        ],
        authMeta,
        { name: 'inventory-management' }
      ),

      route(
        'moving-inventory',
        'moving-inventory',
        [
          subRoute({
            path: '',
            name: 'moving-inventory-management',
            component: 'components/movingInventory/MovingInventoryList',
            meta: { label: 'Moving Inventory Management' }
          }),

          subRoute({
            path: 'form/:movingInventoryId/:action',
            name: 'moving-inventory-form',
            component: 'components/movingInventory/MovingInventoryFormPage',
            meta: { label: 'Moving Inventory Management Detail' }
          }),

          subRoute({
            path: 'list/:movingItemId/items/:action',
            name: 'moving-item-list',
            component: 'components/movingInventory/movingItem/MovingItemList',
            meta: { label: 'Moving Item Management List' }
          })
        ],
        authMeta,
        { name: 'moving-inventory-management' }
      ),

      route(
        'crm',
        'crm',
        [
          subRoute({
            path: 'companies',
            name: 'company',
            component: 'views/crm/CompanyListPage',
            meta: { label: 'Company' }
          }),
          subRoute({
            path: 'companies/:id',
            name: 'company-detail',
            component: 'views/crm/CompanyDetailPage',
            meta: { label: 'Company Detail' }
          }),
          subRoute({
            path: 'contacts',
            name: 'contact',
            component: 'views/crm/ContactListPage',
            meta: { label: 'Contact' }
          }),
          subRoute({
            path: 'contacts/:id',
            name: 'contact-detail',
            component: 'views/crm/ContactDetailPage',
            meta: { label: 'Contact Detail' }
          })
        ],
        authMeta,
        { name: 'company' }
      ),

      route(
        'schedule',
        'calendar',
        [
          subRoute({
            path: '',
            name: 'calendar-detail',
            component: 'views/calendar/CalendarPage'
          }),

          subRoute({
            path: ':view?/',
            name: 'CalendarView',
            component: 'views/calendar/CalendarPage',
            children: [
              subRoute({
                path: 'edit/:calendarId/event/:eventId/:recurrenceId/',
                name: 'SchedulerEditJobView',
                component: 'components/data/calendar/CalendarEdit'
              }),

              subRoute({
                path: 'new/:calendarId/event/:dtstart/:dtend?',
                name: 'SchedulerNewJobView',
                component: 'components/data/calendar/CalendarEdit'
              })
            ]
          })
        ],
        authMeta,
        { name: 'calendar-detail' }
      ),

      route('overview', 'dashboard', [], authMeta),

      route(
        'employees',
        'employees',
        [
          subRoute({
            path: '',
            name: 'employees-list',
            component: 'views/employees/EmployeeList'
          }),
          subRoute({
            path: ':employeeId',
            name: 'employee-detail',
            component: 'views/employees/EmployeeDetail'
          })

          // subRoute({
          //   path: 'form/:timesheetId/:action',
          //   name: 'timesheet-form',
          //   component: 'views/timesheets/Form'
          // })
        ],
        authMeta,
        { name: 'employees-list' }
      ),

      route(
        'subscription',
        'subscription',
        [
          subRoute({
            path: 'payment/cancel',
            name: 'subscription-cancel',
            component: 'views/subscription/PaymentCancelPage'
          })
        ],
        authMeta,
        { name: 'subscription' }
      ),

      route(
        'timesheets',
        'timesheets',
        [
          subRoute({
            path: '',
            name: 'timesheets-list',
            component: 'views/timesheets/List'
          }),

          subRoute({
            path: 'form/:timesheetId/:action',
            name: 'timesheet-form',
            component: 'views/timesheets/Form'
          }),

          subRoute({
            path: ':timesheetId/',
            name: 'timesheet-detail',
            component: 'views/timesheets/TimesheetDetailPage'
          })
        ],
        authMeta,
        { name: 'timesheets-list' }
      ),

      route(
        'jobReports',
        'jobReports',
        [
          subRoute({
            path: '',
            name: 'jobReports-list',
            component: 'views/job-reports/List'
          }),

          subRoute({
            path: 'jobReport-form',
            name: 'jobReport-form',
            component: 'views/job-reports/Form'
          }),

          subRoute({
            path: 'view/:jobReportId/',
            name: 'jobReports-detail',
            component: 'views/job-reports/Detail'
          })
        ],
        authMeta,
        { name: 'jobReports-list' }
      )
    ],
    { name: 'dashboard' },
    authMeta
  ),

  subRoute({
    path: '*',
    name: '404',
    component: 'views/common/404',
    meta: { label: '404' }
  })
]);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

const tokenService = new TokenService();

AltoleapAccountsRouter.AuthGuard(router, tokenService);

export default router;
