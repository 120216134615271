import { IEmployeeData } from '@/models';
import { IEmployeeService } from './employee.types';
import { AxiosInstance } from 'axios';
import { objectToFormData } from '@/utils/objectToFormData';

export class EmployeeService implements IEmployeeService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/employees/';
  }

  fetchEmployees(): Promise<IEmployeeData[]> {
    return this.axios
      .get<IEmployeeData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchEmployee(id: number): Promise<IEmployeeData> {
    return this.axios
      .get<IEmployeeData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createEmployee(data: IEmployeeData): Promise<IEmployeeData> {
    const formData = objectToFormData(data);

    return this.axios
      .post<IEmployeeData>(`${this.endpoint}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  updateEmployee(data: IEmployeeData): Promise<IEmployeeData> {
    const formData = objectToFormData(data);

    if (
      !formData.has('dob') &&
      Object.prototype.hasOwnProperty.call(data, 'dob')
    ) {
      formData.append('dob', data.dob as any);
    }
    if (
      !formData.has('hire_date') &&
      Object.prototype.hasOwnProperty.call(data, 'hire_date')
    ) {
      formData.append('hire_date', data.hire_date as any);
    }

    return this.axios
      .patch<IEmployeeData>(`${this.endpoint}${data.id}/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => data);
  }

  deleteEmployee(id: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }

  deleteEmployeeAttachment(id: number, attachmentId: number): Promise<any> {
    return this.axios.put(
      `${this.endpoint}${id}/delete_attachment/${attachmentId}/`
    );
  }
}
