
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';

import { CompanyNote, CompanyNoteFieldError, ICompanyNote } from '@/models';

import { diffDeep } from '@/utils/helpers';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '@/components/common/ErrorAlert.vue';

export default Vue.extend({
  name: 'CompanyNoteDetailFormDialog',

  components: { ErrorAlert },

  props: {
    companyId: Number
  },

  data: () => ({
    loading: false,
    isEdit: false,
    dialog: false,

    companyNoteOriginalCopy: null as null | any,
    companyNote: null as null | any,
    errorMessage: '',
    fieldError: new CompanyNoteFieldError()
  }),

  methods: {
    ...mapActions({
      updateCompanyNote: 'companyNoteV2/updateCompanyNote',
      createCompanyNote: 'companyNoteV2/createCompanyNote'
    }),

    open(companyNote: ICompanyNote = new CompanyNote(), isEdit = false) {
      this.companyNoteOriginalCopy = companyNote;
      this.companyNote = cloneDeep(this.companyNoteOriginalCopy);
      this.isEdit = isEdit;
      this.clearAllErrors();
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    async saveCompanyNote(): Promise<void> {
      this.clearAllErrors();

      this.loading = true;

      try {
        if (this.isEdit) {
          const companyNoteDelta = diffDeep(
            this.companyNote,
            this.companyNoteOriginalCopy,
            true
          );
          await this.updateCompanyNote({
            companyId: this.companyId,
            note: companyNoteDelta
          });
        } else {
          this.companyNote!.company = this.companyId;
          await this.createCompanyNote({
            companyId: this.companyId,
            note: this.companyNote
          });
        }

        this.loading = false;

        this.close();
      } catch (error: any) {
        this.loading = false;
        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (
            CompanyNoteFieldError.isCompanyNoteFieldError(error.response.data)
          ) {
            this.fieldError = new CompanyNoteFieldError(error.response.data);
          }
        }
        this.errorMessage = ErrorManager.extractApiError(error);
      }
    }
  }
});
