import { axios } from '@/plugins/axios';
import { Item } from '@/models/inventory';

export class ItemMasterService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'v1/inventory-management/items/';
  }

  async createItem(item: Item) {
    return axios.post<Item>(`${this.endpoint}`, item).then(({ data }) => data);
  }

  async fetchItems() {
    return axios.get<Item[]>(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchItem(id: number) {
    return axios.get<Item>(`${this.endpoint}${id}/`).then(({ data }) => data);
  }

  async updateItem(item: any) {
    return axios
      .patch<Item>(`${this.endpoint}${item.id}/`, item.data)
      .then(({ data }) => data);
  }

  async deleteItem(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }
}
