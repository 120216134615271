import { axios } from '@/plugins/axios';
import { Warehouse } from '@/models/inventory';

export class WarehouseService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'v1/inventory-management/warehouses/';
  }

  async createWarehouse(warehouse: Warehouse) {
    return axios
      .post<Warehouse>(`${this.endpoint}`, warehouse)
      .then(({ data }) => data);
  }

  async fetchWarehouses() {
    return axios.get<Warehouse[]>(`${this.endpoint}`).then(({ data }) => data);
  }

  async fetchWarehouse(id: number) {
    return axios
      .get<Warehouse>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  async updateWarehouse(warehouse: Warehouse) {
    return axios
      .put<Warehouse>(`${this.endpoint}${warehouse.id}/`, warehouse)
      .then(({ data }) => data);
  }

  async deleteWarehouse(id: number) {
    return axios.delete(`${this.endpoint}${id}/`);
  }
}
