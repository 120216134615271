
import snackbar from '@/mixins/snackbar';
import mixins from '@/utils/mixins';

export default mixins(snackbar).extend({
  name: 'core-snack-bar',
  computed: {
    bind() {
      if (this.snackbar.to) return { to: this.snackbar.to };
      if (this.snackbar.href) {
        return {
          href: this.snackbar.href,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {};
    },
    computedColor() {
      if (this.snackbar.color !== 'store') {
        return !this.computedIcon ? 'primary lighten-3' : null;
      }
      return 'green';
    }
  },
  watch: {
    $route() {
      this.snack = false;
    },
    snackbar() {
      if (localStorage.getItem(this.snackbar.id)) return;
      this.snack = true;
    }
  },

  methods: {
    markViewed() {
      if (this.snackbar.id) {
        localStorage.setItem(this.snackbar.id, 'true');
      }
      this.snack = false;
    },
    onClick() {
      // this.$ga.event("snackbar", "click", this.snackbar.id);
      this.markViewed();

      this.snackbar.handler && this.snackbar.handler();

      if (this.snackbar.action)
        return this.snackbar.action || this.snackbar.action();
    }
  }
});
