import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import {
  CalendarEvent,
  CalendarOccurrence,
  JobFieldError,
  Participant
} from '@/models/calendar';
type AltoleapEvent = CalendarEvent | CalendarOccurrence;

enum ReminderTimeType {
  Minute = 0,
  Hour,
  Day
}

export default Vue.extend({
  data: () => ({
    // value for dialog value
    isActive: false,
    // Indicator whether or not the event is currently loading
    isLoading: true,
    // Indicator whether or not loading the event failed
    isError: false,
    // Error message in case there was an error
    error: null,
    // The calendar-id of the selected calendar
    // calendarId: null,
    // Whether or not an action is required on leave
    requiresActionOnRouteLeave: true,
    // Whether or not it is a recurrence-exception
    isRecurrenceException: false,
    // details for calendar instance object
    details: null as any,
    // Constant details set at beginning of route for calendar instance object
    constantDetails: null as any,

    // testing
    allDay: false,

    // detail options to for reminder
    detailsOptions: {
      reminder: {
        minutes: 0,
        minutesList: [
          { text: 'minute', value: 0 },
          { text: 'hour', value: 1 },
          { text: 'day', value: 2 }
        ]
      }
    },

    // participant
    searchParticipantsQuery: '',
    fieldError: new JobFieldError()
  }),
  computed: {
    ...mapState({
      calendars: (state: any) => state.calendarEvent.events,
      initialCalendarsLoaded: (state: any) =>
        state.calendar.initialCalendarsLoaded,
      occurrencesByCalendarId: (state: any) =>
        state.calendarOccurrence.occurrencesByCalendarId,
      calendarEvent: (state: any) => state.calendarEvent.calendarEvent,
      calendarEventInstance: (state: any): any =>
        state.calendarEvent.calendarEventInstance
    }),
    ...mapGetters('calendar', ['getCalendarById', 'getCalendarEventById'])
  },

  methods: {
    ...mapActions('calendar', ['fetchCalendarEvent']),

    ...mapActions('calendarEvent', [
      'createCalendarEvent',
      'getCalendarEventInstanceForNewEvent',
      'updateCalendarEvent',
      'sendCalendarEventInvite',
      'deleteCalendarEvent'
    ]),

    ...mapActions('calendarOccurrence', [
      'fetchCalendarOccurrence',
      'updateEventOccurrence',
      'sendCalendarOccurrenceInvite',
      'deleteCalendarOccurrence'
    ]),

    convertReminderTime(
      check: boolean,
      cond: ReminderTimeType,
      time: number
    ): number {
      const MinutesInDay = 1440;
      const MinutesInHour = 60;
      if (check) {
        switch (cond) {
          case ReminderTimeType.Minute:
            break;
          case ReminderTimeType.Hour:
            time = time * MinutesInHour;
            break;
          case ReminderTimeType.Day:
            time = time * MinutesInDay; //(60 * 24);
            break;
          default:
            break;
        }
      }
      return time;
    },

    parseEvent(event: AltoleapEvent, options: any): Partial<AltoleapEvent> {
      if (!isEmpty(event)) {
        const parseEventParticipants = (event: AltoleapEvent) => {
          if (event.participants!.length) {
            event.participants =
              event.participants.map((participant: Participant) => {
                return (
                  { id: participant.id, attendee: participant.attendee.id } ||
                  []
                );
              }) || [];
          }

          return event;
        };
        event.reminder!.minutes = this.convertReminderTime(
          event.reminder!.enabled,
          options.reminder.minutes,
          event.reminder!.minutes
        );

        event.recurrence!.enabled = !!event.recurrence?.rules!.length;

        event = parseEventParticipants(event);

        return event;
      } else return event;
    },

    /**
     * Resets the internal state after changing the viewed calendar-object
     */
    resetState() {
      this.isLoading = true;
      this.isError = false;
      this.error = null;

      this.details = null;

      this.constantDetails = null;
      this.requiresActionOnRouteLeave = true;
      this.isRecurrenceException = false;
      this.fieldError = new JobFieldError();
    },
    /**
     * This function returns a promise that resolves
     * once the calendars were fetched from the server
     *
     * @returns {Promise<void>}
     */
    loadingCalendars(): Promise<void> {
      if (this.initialCalendarsLoaded) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const watcher = this.$watch('initialCalendarsLoaded', () => {
          resolve();
          watcher();
        });
      });
    }
  }
});
