import { vueRouterInstrumentation, init } from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueRouter from 'vue-router';
import { VueConstructor } from 'vue';

export function registerSentry(Vue: VueConstructor, router: VueRouter): void {
  if (process.env.VUE_APP_ENABLE_LOGGING === 'true') {
    init({
      Vue,
      environment: process.env.VUE_APP_ENVIRONMENT,
      release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_WEBSITE_URL!, /^\//]
        })
      ],
      debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
      tracesSampleRate:
        process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.25 : 1,
      tracingOptions: {
        trackComponents: true
      },
      // Vue specific
      logErrors: process.env.VUE_APP_ENABLE_LOGGING === 'true',
      attachProps: true,
      attachStacktrace: true
    });
  }
}
