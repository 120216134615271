
import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { ExtractVue } from '@/utils/mixins';

import { IOrganization } from '@/models';
import { ErrorManager } from '@/models/error';
import dateFormat from '@/utils/dateFormat';

import { User } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/userData';
import OrganizationDetailFormDialog from './OrganizationDetailFormDialog.vue';
import { dateFromNow } from '@/utils/dateHelpers';

enum OrganizationUserView {
  All = -1,
  Active = 1,
  InActive = 2,
  Admin = 3
}

export default Vue.extend({
  components: { OrganizationDetailFormDialog },
  name: 'OrganizationList',
  props: ['id'],

  data() {
    return {
      loading: false,
      searchUsersQuery: '',
      errorMessage: '',
      organizationUserHeaders: [
        {
          text: '#',
          value: 'index',
          align: 'start',
          sortable: false,
          filterable: false,
          width: 60
        },

        { text: 'User', value: 'full_name', sortable: false },

        {
          text: 'Last Activity',
          value: 'last_login',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Date joined',
          value: 'date_joined',
          align: 'start',
          sortable: true,
          filterable: true
        }
      ],

      views: [
        { text: 'All', value: OrganizationUserView.All },
        { text: 'Active', value: OrganizationUserView.Active },
        { text: 'Inactive', value: OrganizationUserView.InActive },
        { text: 'Admin', value: OrganizationUserView.Admin }
      ],
      activeView: OrganizationUserView.Active
    };
  },

  computed: {
    ...mapGetters({
      getOrganizationById: 'organization/getOrganizationById',
      organizationUsers: 'organization/getOrganizationUsers'
    }),

    organizationId(): number {
      return Number(this.id || this.$route.params.organizationId);
    },

    organization(): IOrganization {
      return this.getOrganizationById(this.organizationId);
    },

    filteredOrganizationUserList(): User[] {
      return this.organizationUsers.filter((user: User) => {
        switch (this.activeView) {
          case OrganizationUserView.Active:
            return user.is_active == true;

          case OrganizationUserView.InActive:
            return user.is_active == false;

          case OrganizationUserView.Admin:
            return user.is_admin == true;

          case OrganizationUserView.All:
          default:
            return true;
        }
      });
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    isMobile(): boolean {
      const { mobile, width, name, mobileBreakpoint } =
        this.$vuetify.breakpoint;

      // Check if local mobileBreakpoint matches
      // the application's mobileBreakpoint
      if (mobileBreakpoint === this.$vuetify.breakpoint.mobileBreakpoint)
        return mobile;

      const mobileWidth = parseInt(
        String(this.$vuetify.breakpoint.mobileBreakpoint),
        10
      );
      const isNumber = !isNaN(mobileWidth);

      return isNumber
        ? width < mobileWidth
        : name === this.$vuetify.breakpoint.mobileBreakpoint;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'organization/fetchOrganization',
      deleteOrganization: 'organization/deleteOrganization',
      fetchOrganizationUsers: 'organization/fetchOrganizationUsers'
    }),

    openOrganizationDetailForm(): void {
      const organizationFormDialog = this.$refs
        .organizationFormDialog as ExtractVue<
        typeof OrganizationDetailFormDialog
      >;
      organizationFormDialog.open(this.organization);
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    dateFromNow,

    dateFormat
  },

  mounted() {
    this.loading = true;
    this.fetchOrganization(this.organizationId)
      .then((result) => {
        this.fetchOrganizationUsers(this.organizationId);
      })
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  }
});
