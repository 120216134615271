import {
  ICompany,
  ICompanyData,
  ICompanyFieldError,
  ICompanyFieldErrorData,
  ICompanyStatus,
  ICompanyStatusFieldErrorData,
  ICompanyStatusFieldError,
  ICompanyStatusData,
  ICompanyNoteFieldErrorData,
  ICompanyNoteFieldError,
  ICompanyNoteData,
  ICompanyNote,
  ICompanyAddressFieldErrorData,
  ICompanyAddressFieldError,
  ICompanyAddress,
  ICompanyAddressData,
  CompanyStatusChoices
} from './company.types';

import {
  ICountryFieldError,
  ICountry,
  CountryFieldError,
  Country
} from '../country';

export class Company implements ICompany {
  readonly id?: number;
  readonly name?: string;
  readonly email?: string;
  readonly logo?: string | null = null;
  readonly owner?: string;
  phone?: string;
  readonly website?: string;

  readonly address?: ICompanyAddress = new CompanyAddress();
  readonly status?: ICompanyStatus = new CompanyStatus();

  readonly updated_at?: Date | string;
  readonly created_at?: Date | string;

  constructor(data?: ICompanyData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.logo) {
        this.logo = data.logo;
      }
      if (data.name) {
        this.name = data.name;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.website) {
        this.website = data.website;
      }
      if (data.address) {
        this.address = new CompanyAddress(data.address);
      }
      if (data.status) {
        this.status = new CompanyStatus(data.status);
      }
      if (data.updated_at) {
        this.updated_at = data.updated_at;
      }
      if (data.created_at) {
        this.created_at = data.created_at;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }

  get initials(): string {
    return (
      this.name
        ?.toUpperCase()
        .split(' ')
        .map((n) => n[0])
        .join('')
        .trim() ?? ''
    );
  }
}

export class CompanyStatus implements ICompanyStatus {
  readonly display_name?: string | undefined;
  readonly value?: number | undefined;

  constructor(data?: ICompanyStatusData) {
    if (data) {
      if (data.display_name) {
        this.display_name = data.display_name;
      }

      this.value = data.value ?? CompanyStatusChoices.LEAD;
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class CompanyAddress implements ICompanyAddress {
  readonly id?: number;
  readonly address1?: string;
  readonly address2?: string;
  readonly city?: string;
  readonly country?: ICountry;
  readonly postal_code?: string;
  readonly region?: string;

  constructor(data?: ICompanyAddressData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.address1) {
        this.address1 = data.address1;
      }
      if (data.address2) {
        this.address2 = data.address2;
      }
      if (data.city) {
        this.city = data.city;
      }
      if (data.country) {
        this.country = new Country(data.country);
      }
      if (data.postal_code) {
        this.postal_code = data.postal_code;
      }
      if (data.region) {
        this.region = data.region;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class CompanyFieldError implements ICompanyFieldError {
  readonly name: string[] = [];
  readonly email: string[] = [];
  readonly logo: string[] = [];
  readonly owner: string[] = [];
  readonly phone: string[] = [];
  readonly website: string[] = [];

  readonly address: ICompanyAddressFieldError = new CompanyAddressFieldError();
  readonly status: ICompanyStatusFieldError = new CompanyStatusFieldError();

  constructor(data?: ICompanyFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.logo) {
        this.logo = data.logo;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.website) {
        this.website = data.website;
      }

      if (data.address) {
        this.address = new CompanyAddressFieldError(data.address);
      }
      if (data.status) {
        this.status = new CompanyStatusFieldError(data.status);
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
    this.email.splice(0, this.email.length);
    this.logo.splice(0, this.logo.length);
    this.owner.splice(0, this.owner.length);
    this.phone.splice(0, this.phone.length);
    this.website.splice(0, this.website.length);

    this.address.clearErrors();
    this.status.clearErrors();
  }

  static isCompanyFieldError(data: ICompanyFieldErrorData): boolean {
    if (data.name && Array.isArray(data.name)) return true;
    if (data.email && Array.isArray(data.email)) return true;
    if (data.logo && Array.isArray(data.logo)) return true;
    if (data.owner && Array.isArray(data.owner)) return true;
    if (data.phone && Array.isArray(data.phone)) return true;
    if (data.website && Array.isArray(data.website)) return true;

    return false;
  }
}

export class CompanyAddressFieldError implements ICompanyAddressFieldError {
  readonly address1: string[] = [];
  readonly address2: string[] = [];
  readonly city: string[] = [];
  readonly postal_code: string[] = [];
  readonly region: string[] = [];
  readonly country: ICountryFieldError = new CountryFieldError();

  constructor(data?: ICompanyAddressFieldErrorData) {
    if (data) {
      if (data.address1) {
        this.address1 = data.address1;
      }
      if (data.address2) {
        this.address2 = data.address2;
      }
      if (data.city) {
        this.city = data.city;
      }
      if (data.postal_code) {
        this.postal_code = data.postal_code;
      }
      if (data.region) {
        this.region = data.region;
      }
      if (data.country) {
        this.country = new CountryFieldError(data.country);
      }
    }
  }

  clearErrors(): void {
    this.address1.splice(0, this.address1.length);
    this.address2.splice(0, this.address2.length);
    this.city.splice(0, this.city.length);
    this.postal_code.splice(0, this.postal_code.length);
    this.region.splice(0, this.region.length);
    if (this.country) {
      this.country.clearErrors();
    }
  }

  static isCompanyAddressFieldError(
    data: ICompanyAddressFieldErrorData
  ): boolean {
    if (data.address1 && Array.isArray(data.address1)) return true;
    if (data.address2 && Array.isArray(data.address2)) return true;
    if (data.city && Array.isArray(data.city)) return true;
    if (data.postal_code && Array.isArray(data.postal_code)) return true;
    if (data.region && Array.isArray(data.region)) return true;
    if (data.country && Array.isArray(data.country)) return true;

    return false;
  }
}

export class CompanyStatusFieldError implements ICompanyStatusFieldError {
  readonly display_name: string[] = [];
  readonly value: string[] = [];

  constructor(data?: ICompanyStatusFieldErrorData) {
    if (data) {
      if (data.display_name) {
        this.display_name = data.display_name;
      }
      if (data.value) {
        this.value = data.value;
      }
    }
  }

  clearErrors(): void {
    this.display_name.splice(0, this.display_name.length);
    this.value.splice(0, this.value.length);
  }

  static isCompanyStatusFieldError(
    data: ICompanyStatusFieldErrorData
  ): boolean {
    if (data.display_name && Array.isArray(data.display_name)) return true;
    if (data.value && Array.isArray(data.value)) return true;
    return false;
  }
}

/** ##### Company Note ##### **/
export class CompanyNote implements ICompanyNote {
  readonly id?: number;

  readonly text: string = '';

  readonly company?: number;
  readonly owner?: number;
  readonly created_at?: Date | string;
  readonly updated_at?: Date | string;

  constructor(data?: ICompanyNoteData) {
    if (data) {
      this.text = data.text;

      if (data.id) {
        this.id = data.id;
      }

      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.company) {
        this.company = data.company;
      }
      if (data.created_at) {
        this.created_at = data.created_at;
      }
      if (data.updated_at) {
        this.updated_at = data.updated_at;
      }
    }
  }

  validate(): boolean {
    if (!this.text) {
      return false;
    }
    return true;
  }
}

export class CompanyNoteFieldError implements ICompanyNoteFieldError {
  readonly text: string[] = [];
  readonly owner: string[] = [];
  readonly company: string[] = [];

  constructor(data?: ICompanyNoteFieldErrorData) {
    if (data) {
      if (data.text) {
        this.text = data.text;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.company) {
        this.company = data.company;
      }
    }
  }

  clearErrors(): void {
    this.text.splice(0, this.text.length);
    this.owner.splice(0, this.owner.length);
    this.company.splice(0, this.company.length);
  }

  static isCompanyNoteFieldError(data: ICompanyNoteFieldErrorData): boolean {
    if (data.text && Array.isArray(data.text)) {
      return true;
    } else if (data.owner && Array.isArray(data.owner)) {
      return true;
    } else if (data.company && Array.isArray(data.company)) {
      return true;
    } else {
      return false;
    }
  }
}
