
import Proxyable from '@/mixins/proxyable';
import { ErrorManager } from '@/models/error';
import { accountsProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { Account } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/account';
import { PropType } from 'vue';
import { mapActions } from 'vuex';

export default Proxyable.extend({
  name: 'AccountPicker',

  props: {
    errorMessages: {
      type: [String, Array] as PropType<string | string[]>,
      default: () => ''
    }
  },

  data() {
    return {
      loading: false,
      internalErrorMessage: ''
    };
  },

  computed: {
    errorMessageList(): string[] {
      let errorList: string[] = [];

      errorList = errorList.concat(
        this.genInternalMessages(this.errorMessages)
      );

      if (this.internalErrorMessage) {
        errorList.push(this.internalErrorMessage);
      }

      return errorList;
    },

    accountsProvider(): any {
      return accountsProvider(this.$store);
    },

    accounts(): Account[] {
      return (this as any).accountsProvider.accounts;
    },

    computedAccounts(): Account[] {
      return this.accounts.map((account, index) => {
        return {
          ...account,
          full_name:
            `${account.first_name} ${account.last_name}`.trim() ||
            `${index}: No Name`
        };
      });
    }
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    this.internalErrorMessage = '';
  },

  methods: {
    ...mapActions('accounts', ['fetchAccounts']),

    genInternalMessages(messages: string[] | string | null): string[] {
      if (!messages) return [];
      else if (Array.isArray(messages)) return messages;
      else return [messages];
    },

    init() {
      this.loading = true;

      this.fetchAccounts()
        .catch((err: any) => {
          this.internalErrorMessage = ErrorManager.extractApiError(err);
        })
        .finally(() => (this.loading = false));
    }
  }
});
