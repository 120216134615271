
import Vue from 'vue';
import dateFormat from '@/utils/dateFormat';
import { mapActions } from 'vuex';
import { CheckList, JobReport } from '@/models/jobReport';
import moment from 'moment';
import { ErrorManager } from '@/models/error';
import axios from 'axios';

export default Vue.extend({
  data: () => ({
    job_start_day: '' as string,
    job_end_day: '' as string,
    errorMessage: '',
    jobReport: new JobReport()
  }),

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      fetchJobReport: 'jobReports/fetchJobReportById',
      updateJobReportChecklist: 'jobReports/updateJobReportChecklist',
      updateJobReport: 'jobReports/updateJobReport',
      addJobReportImage: 'jobReports/addJobReportImage'
    }),
    dateFormat,

    downloadImage(url: string) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.jobReport.job.title} image.png`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    /**
     * identifies which dialog should be opened
     *
     *
     * @param dialogname name of the dialog to be opened
     * @param index index number of the image list
     *
     */
    openDialog(dialogName: string, index: number) {
      if (dialogName == 'edit job detail') {
        (this as any).$refs.jobReportFormDialog.open(this.jobReport);
      } else {
        (this as any).$refs.jobReportImageDialog.open(index, this.jobReport);
      }
    },

    /**
     * converts date to local time
     *
     *
     * @param date date of job object
     *
     *
     */
    convertUTCDateToLocalDate(date: Date) {
      const _date = new Date(date);
      const newDate = new Date(
        _date.getTime() - _date.getTimezoneOffset() * 60 * 1000
      );
      return newDate;
    },

    /**
     *
     * @param item checklist item to be updated
     *
     *
     */
    checklistchange(item: CheckList) {
      this.updateJobReportChecklist({
        jobReportId: this.jobReport.id,
        data: item
      }).catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
    },

    /**
     * set job report data
     *
     * @param jobReport job report data
     *
     *
     */
    setJobReport(jobReport: JobReport) {
      this.jobReport = jobReport;
      this.job_start_day = moment
        .tz(
          this.jobReport.job.date.start! as string,
          'YYYY-MM-DDTHH:mm:ss',
          this.jobReport.job!.date!.timezone as string
        )
        .utc()
        .format();

      this.job_end_day = moment
        .tz(
          this.jobReport.job.date.end! as string,
          'YYYY-MM-DDTHH:mm:ss',
          this.jobReport.job!.date!.timezone as string
        )
        .utc()
        .format();
    },

    /**
     * uploads image to server
     *
     *
     * @param event uploaded image file
     *
     *
     */
    async uploadImage(uploadedFile: any) {
      const reader = new FileReader();
      const imagefile =
        uploadedFile.target.files || uploadedFile.dataTransfer.files;
      reader.readAsDataURL(imagefile[0]);
      const cacheImage = imagefile[0];
      const formData = new FormData();
      formData.append('image', cacheImage);

      await this.addJobReportImage({
        jobReportId: this.jobReport.id,
        data: formData
      })
        .then((response: JobReport) => {
          this.jobReportData();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    /**
     * on screen load it fetches job report and job event information
     *
     *
     */
    jobReportData() {
      this.fetchJobReport(this.$route.params.jobReportId)
        .then((data: JobReport) => {
          this.setJobReport(data);
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },

    setUserFullName(fullName: string) {
      return fullName == ' ' ? 'No Name' : fullName;
    }
  },

  async mounted() {
    this.jobReportData();
  }
});
