import { IProduct, IProductData } from '../product.types';
import { Product } from '../product';

export const mockProductsData = (): IProductData[] => [
  {
    id: 1,
    name: 'Nano Plan',
    description:
      'Perfect fit for small startup companies looking to grow and make an impact the right way (and quickly!)',
    prices: [
      {
        id: 1,
        price: 0.0,
        frequency: 0
      },
      {
        id: 2,
        price: 0.0,
        frequency: 1
      }
    ],
    plan: {
      id: 1,
      is_crm_supported: false,
      is_inventory_management_supported: false,
      max_active_users: 5
    },
    created_at: '2021-09-06T23:07:50.232835Z',
    updated_at: '2021-10-02T17:22:00.469394Z'
  },
  {
    id: 2,
    name: 'Micro Plan',
    description:
      'Great fit for small to medium companies grinding away but looking to improve efficiencies, improve communication with employees and clients, along with organizing daily operations to level up their business!',
    prices: [
      {
        id: 3,
        price: 79.99,
        frequency: 0
      },
      {
        id: 4,
        price: 815.9,
        frequency: 1
      }
    ],
    plan: null,
    created_at: '2021-10-02T18:15:50.327859Z',
    updated_at: '2021-10-02T18:15:50.327903Z'
  }
];

export const mockProducts = (
  data: IProductData[] = mockProductsData()
): IProduct[] => data.map((item) => new Product(item));
